import { Common } from '@/api';

const defaultState = {
    data: [
        { name: 'smsc', type: 'р', balance: 'n/a' },
        { name: 'twilio', type: '$', balance: 'n/a' }
    ],
    loading: true
};

export default {
    namespaced: true,
    state: { ...defaultState },
    getters: {
        data: state => state.data,
        loading: state => state.loading
    },
    mutations: {
        setData(state, { data }) {
            state.data = data;
        },
        setLoading(state, { loading }) {
            state.loading = loading;
        }
    },
    actions: {
        getSmsBalance({ commit }) {
            commit('setLoading', { loading: true });
            return Common.getSmsBalance()
                .then(response => commit('setData', { data: response.data }))
                .finally(() => commit('setLoading', { loading: false }));
        }
    }
};
