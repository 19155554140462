<template>
    <div class="hotel-card-view">
        <hotel-card-full :hotel-id="hotelId" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import breadcrumbs from '@/mixins/breadcrumbs';
import HotelCardFull from '@/modules/hotel/components/card/HotelCardFull';
import { ROUTES } from '@/enums';

export default {
    name: 'HotelCardView',

    components: {
        HotelCardFull
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        ...mapState('hotel/card', [
            'hotel'
        ]),

        $breadcrumbs() {
            const bc = [
                {
                    title: 'Отели',
                    route: ROUTES.HOTEL.LIST
                }
            ];
            if (this.title) {
                bc.push({
                    title: this.title,
                    route: ROUTES.HOTEL.CARD.SHOW,
                    params: {
                        hotelId: this.hotelId
                    }
                });
            }
            return bc;
        },

        title() {
            return this.$prop('hotel.attributes.title');
        },

        hotelId() {
            return this.$route.params.hotelId;
        }
    },

    methods: {}
};
</script>

<style lang="scss">
@import "@vars";

</style>
