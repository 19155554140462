<template>
    <div class="event-add-view">
        <h1>
            Новый отель
        </h1>

        <hotel-form />
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { ROUTES } from '@/enums';
import HotelForm from '../components/HotelForm';
import breadcrumbs from '@/mixins/breadcrumbs';

export default {
    name: 'HotelAddView',

    components: {
        HotelForm
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        $breadcrumbs() {
            return [
                {
                    title: 'Отели',
                    route: ROUTES.HOTEL.LIST
                },
                {
                    title: 'Создание нового отеля',
                    route: ROUTES.HOTEL.ADD
                }
            ];
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
