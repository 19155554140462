<template>
    <div
        v-if="isReady"
        class="hotel-building-room-view"
    >
        <h1 class="app-h1">
            {{ roomToView.attributes.title }}
        </h1>

        <el-row :gutter="16">
            <el-col :span="12">
                <el-card shadow="none">
                    <p>
                        Категория: {{ roomCategory }}
                    </p>
                    <p>
                        Этаж: {{ roomToView.attributes.floor }}
                    </p>
                    <p>
                        Кровати: {{ roomToView.attributes.beds }}
                    </p>
                    <p>
                        Вид: {{ roomToView.attributes.window_view }}
                    </p>
                    <p>
                        Размер: {{ roomToView.attributes.size }}
                    </p>
                    <p>
                        Комментарий: {{ roomToView.attributes.comment }}
                    </p>
                    <p>
                        Статус:
                        <el-switch
                            v-model="roomToView.attributes.enabled"
                            disabled
                        />
                    </p>
                </el-card>
            </el-col>
            <el-col :span="12">
                <room-places :room-id="roomId" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';
import RoomPlaces from '@/modules/hotel/components/RoomPlaces';

export default {
    name: 'HotelBuildingRoomView',

    components: {
        RoomPlaces
    },

    mixins: [
        breadcrumbs
    ],

    data() {
        return {
            /** @type {PlaceResource[]} */
            places: []
        };
    },

    computed: {
        ...mapState('hotel/card', [
            'hotel',
            'buildingToView',
            'roomToView'
        ]),

        isReady() {
            return !!this.roomToView;
        },

        $breadcrumbs() {
            const bc = [
                {
                    title: 'Отели',
                    route: ROUTES.HOTEL.LIST
                }
            ];
            if (this.hotel) {
                bc.push({
                    title: this.hotel.attributes.title,
                    route: ROUTES.HOTEL.CARD.SHOW,
                    params: {
                        hotelId: this.hotelId
                    }
                });
            }
            if (this.buildingToView && this.buildingToView.attributes) {
                bc.push({
                    title: `Корпус: ${this.buildingToView.attributes.title}`,
                    route: ROUTES.HOTEL.CARD.BUILDING.SHOW,
                    params: {
                        hotelId: this.hotelId,
                        buildingId: this.buildingId
                    }
                });
            }
            if (this.roomToView && this.roomToView.attributes) {
                bc.push({
                    title: `Номер: ${this.roomToView.attributes.title}`,
                    route: ROUTES.HOTEL.CARD.BUILDING.ROOM.SHOW,
                    params: {
                        hotelId: this.hotelId,
                        buildingId: this.buildingId,
                        roomId: this.roomId
                    }
                });
            }
            return bc;
        },

        hotelId() {
            return this.$route.params.hotelId;
        },

        buildingId() {
            return this.$route.params.buildingId;
        },

        roomId() {
            return this.$route.params.roomId;
        },

        roomCategory() {
            return this.$prop('roomToView.relationships.category.attributes.title', '');
        }
    },

    mounted() {
        this._getRoom();
        if (!this.hotel || this.hotel.id !== this.hotelId) {
            this._getHotel();
        }
        if (!this.buildingToView || this.buildingToView.id !== this.buildingId) {
            this._getBuilding();
        }
    },

    methods: {
        ...mapActions('hotel/card', [
            'getRoom',
            'getBuilding',
            'getHotel'
        ]),

        _getRoom() {
            if (!this.buildingId || !this.hotelId || !this.roomId) {
                return;
            }
            this.getRoom({
                hotelId: this.hotelId,
                buildingId: this.buildingId,
                roomId: this.roomId
            });
        },

        _getHotel() {
            if (!this.hotelId) {
                return;
            }
            this.getHotel({
                hotelId: this.hotelId
            });
        },

        _getBuilding() {
            if (!this.buildingId || !this.hotelId) {
                return;
            }
            this.getBuilding({
                hotelId: this.hotelId,
                buildingId: this.buildingId
            });
        }
    }
};
</script>
