<template>
    <div class="app-phone-input">
        <el-input
            :id="id"
            ref="input"
            v-model="phone"
            :size="size"
            :disabled="isDisabled"
            :name="id"
            :placeholder="placeholder"
            @input="_onInput"
        >
            <div slot="suffix">
                <div class="app-phone-input__country">
                    {{ country }}
                </div>
            </div>
        </el-input>
    </div>
</template>

<script>
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default {
    name: 'AppPhoneInput',

    props: {
        value: {
            type: [Number, String],
            default: null
        },

        placeholder: {
            type: String,
            default: 'Введите номер телефона'
        },

        defaultCountry: {
            type: String,
            default: 'RU'
        },

        isDisabled: {
            type: Boolean,
            default: false
        },

        size: {
            type: String,
            default: undefined
        }
    },

    data() {
        return {
            phone: null,
            country: null,
            isValid: false,
            id: null
        };
    },

    watch: {
        value() {
            this._onValueChanged();
        }
    },

    beforeMount() {
        this.id = `phone-input-${this.uniqId}`;
    },

    mounted() {
        this._onValueChanged();
    },

    methods: {
        clear() {
            this.phone = null;
            this.country = null;
            this.isValid = false;
        },

        _onInput() {
            this._checkPhone();
            this.$emit('input', this.phone);
            this.$emit('change', {
                phone: this.phone,
                country: this.country,
                isValid: this.isValid
            });
        },

        _onValueChanged() {
            if (!this.value) {
                this.phone = null;
            }
            if (this.value && this.value !== this.phone) {
                this.phone = this.value;
                this._checkPhone();
            }
        },

        _checkPhone() {
            const parsed = this.phone
                ? parsePhoneNumberFromString(this.phone, this.defaultCountry)
                : null;
            if (parsed && parsed.isValid()) {
                this.phone = parsed.number;
                this.country = parsed.country;
                this.isValid = true;
            } else {
                this.country = null;
                this.isValid = false;
            }
        }
    }
};
</script>

<style lang="scss">
.app-phone-input {
    &__country {
        user-select: none;
        font-size: 16px;
        line-height: 41px;
        padding: 0 10px;
    }
}
</style>
