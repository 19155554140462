import { ClientLk } from '@/api/index';

const defaultState = {
    eventInfo: {},
    eventInfoLoading: false
};

export default {
    namespaced: true,
    state: { ...defaultState },
    getters: {
        loading: state => state.eventInfoLoading,
        eventInfo: state => state.eventInfo.attributes || {},
        eventId: state => state.eventInfo.id
    },
    mutations: {
        setLoading(state, { loading }) {
            state.eventInfoLoading = loading;
        },
        setEventInfo(state, { data }) {
            state.eventInfo = data;
        }
    },
    actions: {
        fetchEventInfo({ commit }) {
            commit('setLoading', { loading: true });
            return ClientLk.getEventInfo()
                .then(response => commit('setEventInfo', { data: response.data.data }))
                .catch(() => {})
                .finally(() => commit('setLoading', { loading: false }));
        }
    }
};
