<template>
    <el-dialog
        :title="title"
        :visible.sync="isVisible"
        width="40%"
        @close="_onDialogClose"
    >
        <el-form
            ref="form"
            :rules="rules"
            :model="form"
            label-position="top"
            @submit.native.prevent="_send"
        >
            <el-form-item
                label="Название"
                prop="title"
            >
                <el-input v-model="form.title" />
            </el-form-item>

            <el-form-item
                label="Описание"
                prop="description"
            >
                <el-input v-model="form.description" />
            </el-form-item>

            <el-button
                type="primary"
                @click="_send"
            >
                {{ buttonText }}
            </el-button>

            <el-button
                type="text"
                @click="_cancel"
            >
                Отмена
            </el-button>
        </el-form>
    </el-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import { Service } from '@/api';
import showError from '@/utils/showError';

export default {
    name: 'ServiceFormPopup',

    data() {
        return {
            isVisible: false,
            form: {}
        };
    },

    computed: {
        ...mapState('service/list', [
            'services',
            'areServicesLoading',
            'serviceToEdit'
        ]),

        title() {
            return this.isEditMode
                ? `Редактирование услуги "${this.form.title}"`
                : 'Добавление новой услуги';
        },

        buttonText() {
            return this.isEditMode
                ? `Сохранить`
                : `Добавить`;
        },

        rules() {
            return {
                title: [
                    {
                        required: true,
                        message: 'Название услуги обязательно'
                    }
                ]
            };
        },

        serviceId() {
            return _.get(this, 'serviceToEdit.id');
        },

        isEditMode() {
            return !!this.serviceId;
        }
    },

    watch: {
        serviceToEdit() {
            this._setFormFromService();
        }
    },

    methods: {
        ...mapActions('service/list', [
            'setServiceToEdit',
            'getServices'
        ]),

        open() {
            this.isVisible = true;
        },

        close() {
            this.isVisible = false;
        },

        _setFormFromService() {
            this.form = {
                ..._.get(this.serviceToEdit, 'attributes', {})
            };
        },

        _onDialogClose() {
            this.setServiceToEdit({ serviceId: null });
            this._resetForm();
        },

        _send() {
            this.$refs.form.validate()
                .then(this._doSend.bind(this))
                .catch(showError('Проверьте форму'));
        },

        _doSend() {
            this.loading = true;
            const onStoreResponse = service => {
                this.$emit('added', { service });
                this.close();
            };
            const onUpdateResponse = service => {
                this.$emit('updated', { service });
                this.close();
            };
            const setLoadingFalse = () => (this.loading = false);
            /** @type {CreateServiceRequest} */
            const service = { ...this.form };
            if (this.isEditMode) {
                service.id = this.serviceId;
                Service.update(service)
                    .then(onUpdateResponse)
                    .catch(showError('Не удалось обновить услугу'))
                    .finally(setLoadingFalse);
            } else {
                Service.store(service)
                    .then(onStoreResponse)
                    .catch(showError('Не удалось получить данные об услуге'))
                    .finally(setLoadingFalse);
            }
        },

        _cancel() {
            this._resetForm();
            this.close();
        },

        _resetForm() {
            this.form = {};
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.service-list {
  &__icon {
    font-size: 1.2em;
    cursor: pointer;
    visibility: hidden;
  }

  &__edit {
    color: #0486fe;
    margin-left: 10px;
  }

  &__remove {
    color: #eb2929;
  }

  .el-table__row {
    &:hover {
      .service-list__icon {
        visibility: visible;
      }
    }
  }
}
</style>
