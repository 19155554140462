import { ROUTES } from '@/enums';
import { doPut, doPost, doDelete } from '@/utils/api';

export default {
    removeServicePlan({ eventId, eventServiceId, planId }) {
        return doDelete(ROUTES.EVENT.API.SERVICE.PLAN.DELETE, { eventId, eventServiceId, planId });
    },

    /**
     * @param {UUID} eventId
     * @param {UUID} scheduleId
     * @param {UUID} planId
     * @returns {Promise<Object>}
     */
    removeSchedulePlan({ eventId, scheduleId, schedulePlanId }) {
        return doDelete(ROUTES.EVENT.API.SCHEDULE.PLAN.DELETE, { eventId, scheduleId, schedulePlanId });
    },

    /**
     * Создает новый тип участия.
     *
     * @param eventId
     * @param scheduleId
     * @returns {Promise<SchedulePlanResource>}
     */
    addNewSchedulePlan({ eventId, scheduleId }) {
        return doPost(ROUTES.EVENT.API.SCHEDULE.PLAN.STORE, { eventId, scheduleId });
    },

    /**
     * @param {UUID} eventId
     * @param {UUID} scheduleId
     * @param {UUID} schedulePlanId
     * @param {SchedulePlanUpdateRequest} request
     * @returns {Promise<Object>}
     */
    updateSchedulePlan({ eventId, scheduleId, schedulePlanId, request }) {
        return doPut(ROUTES.EVENT.API.SCHEDULE.PLAN.UPDATE, {
            eventId,
            scheduleId,
            schedulePlanId
        }, request);
    }
};
