export default {
    LIST: 'online-event.list',
    ADD: 'online-event.add',
    EDIT: 'online-event.edit',
    CARD: {
        PARTICIPANTS: 'online-event.card.participants'
    },
    API: {
        LIST: 'online-events',
        PARTICIPANTS: (onlineEventId) => `/online-events/${onlineEventId}/participants`
    }
};
