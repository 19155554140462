<template>
    <el-dialog
        class="app-lk-sms-message__dialog"
        :visible.sync="isVisible"
        :width="modalWidth"
        :close-on-click-modal="false"
        @close="_onDialogClose"
    >
        <div
            v-loading="loading"
            class="app-lk-sms-message__row"
        >
            <div class="app-lk-sms-message__col-text">
                <div class="app-lk-sms-message__header">
                    Введите код
                </div>
                <div class="app-lk-sms-message__text">
                    Последние 4 цифры номера телефона, который сейчас вам позвонит.
                </div>
                <el-form
                    v-model="form"
                    @submit.native.prevent="_confirmSmsCode"
                >
                    <el-form-item>
                        <el-input
                            ref="codeInput"
                            v-model="form.code"
                            v-mask="'####'"
                            required
                        />
                    </el-form-item>
                </el-form>
            </div>
            <div class="app-lk-sms-message__col-image">
                <div class="app-lk-sms-message__code-picture" />
            </div>
        </div>
        <div class="app-lk-sms-message__controls">
            <div
                v-if="showText"
                class="app-lk-sms-message__timer-text"
            >
                Подождите звонка {{ timeOut }} сек.
            </div>
            <el-button
                v-else
                type="success"
                @click="_onRepeatCallButtonClick"
            >
                Повторить
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'ConfirmSmsModal',
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        userFound: {
            type: Boolean,
            default: false
        },
        timeOut: {
            type: [Number],
            default: 0
        },
        timerStart: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: {
                code: ''
            },
            isVisible: false,
            lastEventId: null,
            /** @type {CreateParticipantRequest[]} */
            participants: [],
            focus: false
        };
    },
    computed: {
        codeExists() {
            return this.form.code.length === 4;
        },
        showText() {
            return this.timeOut > 0;
        },
        modalWidth() {
            return window.innerWidth > 680 ? '510px' : '100%';
        }
    },
    watch: {
        codeExists() {
            if (this.codeExists) {
                this._confirmSmsCode();
            }
        }
    },
    methods: {
        openModal() {
            this.isVisible = true;
        },

        closeModal() {
            this.isVisible = false;
        },
        _onRepeatCallButtonClick() {
            this.$emit('repeat-call');
        },
        _onDialogClose() {
            this.$emit('close-modal');
        },
        _confirmSmsCode() {
            this.$emit('confirm-code', { code: this.form.code });
        }
    }
};
</script>

<style lang="scss">
.app-lk-sms-message {
  &__header {
    font-size: 36px;
    font-family: "PT Sans", sans-serif;
    color: #000000;
    margin-bottom: 10px;
  }
  &__text {
    margin-bottom: 10px;
    font-size: 18px;
    word-break: break-word;
  }
  &__row {
    display: flex;
  }
  &__col-text {
    flex: 1;
  }
  &__col-image {
    flex: 1;
    @media (max-width: 680px) {
      display: none;
    }
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    @media (max-width: 680px) {
      display: block;
      text-align: center;
    }
  }
  &__warn {
    color: red;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
  }
  &__code-picture {
    width: 100%;
    min-height: 100%;
    height: 210px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/assets/img/code.jpg");
  }

  &__timer-text {
    color: #979797;
    font-size: 14px;
    font-family: "PT Sans", sans-serif;
  }
}
</style>
