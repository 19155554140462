<template>
    <el-card
        class="participants-total-widget"
        shadow="hover"
    >
        <div class="participants-total-widget__header">
            Общие данные
        </div>
        <div class="participants-total-widget__body">
            <el-collapse v-model="activeParticipants">
                <el-collapse-item
                    v-for="(participant, index) in participantList"
                    :key="index"
                    :title="`${participant.attributes.surname} ${participant.attributes.name}`"
                    :name="index"
                >
                    <div class="participants-total-widget__item">
                        <span class="participants-total-widget__item-name">Дата рождения:</span>
                        {{ participant.attributes.date_of_birth | moment('DD MMM YYYY') }}
                    </div>
                    <div class="participants-total-widget__item">
                        <span class="participants-total-widget__item-name">Номер телефона:</span>
                        {{ participant.attributes.phone || 'Не указан' }}
                    </div>
                    <div class="participants-total-widget__item">
                        <span class="participants-total-widget__item-name">Скидка:</span>
                        {{ participant.attributes.discount }}₽
                    </div>
                    <div class="participants-total-widget__item">
                        <span class="participants-total-widget__item-name">Предоплата:</span>
                        {{ participant.attributes.prepaid_sum }}₽, {{ participant.attributes.prepay_status }}
                    </div>
                    <div class="participants-total-widget__item">
                        <span class="participants-total-widget__item-name">Осталось доплатить:</span>
                        {{ participant.attributes.debt }}₽
                    </div>
                </el-collapse-item>
            </el-collapse>
            <div class="participants-total-widget__count">
                <div class="participants-total-widget__count-item">
                    Внесли предоплаты: {{ totalPrepaid }} ₽
                </div>
                <div class="participants-total-widget__count-item">
                    Общая скидка: {{ totalDiscount }} ₽
                </div>
                <div class="participants-total-widget__count-item">
                    Осталось доплатить: {{ totalDebt }} ₽
                </div>
            </div>
            <el-divider />
            <promo-code-control />
        </div>
    </el-card>
</template>

<script>
import PromoCodeControl from "../promoCodeControl/PromoCodeControl";

export default {
    name: 'ParticipantTotalWidget',
    components: { PromoCodeControl },
    props: {
        participantList: {
            type: Array,
            default: () => []
        },
        totalPrepaid: {
            type: [Number],
            default: 0
        },
        totalDiscount: {
            type: Number,
            default: 0
        },
        totalDebt: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            activeParticipants: [0]
        };
    }
};
</script>

<style lang="scss">
.participants-total-widget {
  border: 1px solid #D8D8D8 !important;
  max-width: 305px;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    max-width: 100%;
  }

  &__header {
    font-size: 28px;
    margin: 20px 0;
  }

  &__item {
    font-size: 14px;
    line-height: 20px;
  }

  &__item-name {
    color: #979797;
  }

  &__count {
    margin-top: 20px;
    line-height: 24px;
  }

  &__count-item {
    text-align: right;
    font-size: 14px;
    color: #000000;

    &:last-child {
      font-size: 18px;
    }
  }

  .el-collapse-item__header {
    font-size: 15px;
    color: #000000;
  }
}
</style>
