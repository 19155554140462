import { ClientOnlineLk } from '@/api/index';

const defaultState = {
    eventInfo: {},
    eventInfoLoading: false,
    captains: []
};

export default {
    namespaced: true,
    state: { ...defaultState },
    getters: {
        loading: state => state.eventInfoLoading,
        eventInfo: state => state.eventInfo.attributes || {},
        eventId: state => state.eventInfo.id,
        captains: state => state.captains
    },
    mutations: {
        setLoading(state, { loading }) {
            state.eventInfoLoading = loading;
        },
        setEventInfo(state, { data }) {
            state.eventInfo = data;
        }
    },
    actions: {
        fetchEventInfo({ commit }) {
            commit('setLoading', { loading: true });
            return ClientOnlineLk.getEventInfo()
                .then(response => {
                    commit('setEventInfo', { data: response.data.data });
                    return Promise.resolve(response);
                })
                .catch(err => Promise.reject(err))
                .finally(() => commit('setLoading', { loading: false }));
        }
    }
};
