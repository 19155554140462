import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import ROUTES from '@/enums/routes';

export default {

    /**
     * @param {UUID} placeId
     * @param {UUID} participantId
     * @returns {Promise<{}>}
     */
    async checkIn({ placeId, participantId }) {
        const url = urlBuilder(ROUTES.HOTEL.API.BUILDING.ROOM.PLACE.CHECK_IN, {
            placeId,
            participantId
        });
        try {
            let response = await api.post(url);
            return {
                success: true,
                data: response.data.data
            };
        } catch (e) {
            let message;
            if (e.response) {
                message = e.response.data.message;
            }
            return {
                success: false,
                message
            };
        }
    },

    async checkOut({ participantId }) {
        const url = ROUTES.HOTEL.API.BUILDING.ROOM.PLACE.CHECK_OUT;
        try {
            let response = await api.post(url, {
                participant_id: participantId
            });
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            let message;
            if (e.response) {
                message = e.response.data.message;
            }
            return {
                success: false,
                message
            };
        }
    },

    async reCheckIn({ placeId, participantId, notifyReception }) {
        const url = urlBuilder(ROUTES.HOTEL.API.BUILDING.ROOM.PLACE.RE_CHECK_IN, {
            placeId,
            participantId,
            notifyReception: Number(notifyReception)
        });
        try {
            let response = await api.post(url);
            return {
                success: true,
                data: response.data.data
            };
        } catch (e) {
            let message;
            if (e.response) {
                message = e.response.data.message;
            }
            return {
                success: false,
                message
            };
        }
    },

    /**
     * @param {CreatePlaceRequest} data
     * @returns {Promise<PlaceResource>}
     */
    update(data) {
        const url = urlBuilder(ROUTES.HOTEL.API.BUILDING.ROOM.PLACE.UPDATE, {
            placeId: data.place_id
        });
        return api.put(url, data)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {CreatePlaceRequest} data
     * @returns {Promise<PlaceResource>}
     */
    store(data) {
        const url = urlBuilder(ROUTES.HOTEL.API.BUILDING.ROOM.PLACE.STORE);
        return api.post(url, data)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} placeId
     * @returns {Promise<PlaceResource>}
     */
    destroy({ placeId }) {
        const url = urlBuilder(ROUTES.HOTEL.API.BUILDING.ROOM.PLACE.DESTROY, {
            placeId
        });
        return api.delete(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    show({ placeId }) {
        const url = urlBuilder(ROUTES.HOTEL.API.BUILDING.ROOM.PLACE.SHOW, {
            placeId
        });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    }
};
