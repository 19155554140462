import Auth from '../modules/auth/OnlineAuth';
import Dashboard from '../modules/dashboard/OnlineDashboard';
import OnlineRegistration from '@/containers/lk/modules/registerGroup/OnlineRegistration';

export default [
    {
        path: '/online/lk/auth',
        name: 'LkOnlineAuth',
        component: Auth
    },
    {
        path: '/online/lk/:eventId',
        name: 'LkOnlineDashboard',
        component: Dashboard
    },
    {
        path: '/online/lk/:eventId/reg',
        name: 'OnlineRegistration',
        component: OnlineRegistration
    }
];
