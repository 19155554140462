import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import specialist from './modules/specialist';
import schedule from './modules/schedule';

export default {
    namespaced: true,
    state: {
        /** @type {EventServiceResource[]} */
        services: [],
        /** @type {ServiceResource[]} */
        allServices: [],
        /** @type {Boolean} */
        areEventServicesLoading: false,
        /** @type {EventServiceResource | null} */
        eventServiceToEdit: null,
        /** @type {EventSpecialistResource[]} */
        specialists: [],
        /** @type {Boolean} */
        areSpecialistsLoading: false,
        /** @type {EventLocationResource[]} */
        locations: [],
        /** @type {Boolean} */
        areLocationsLoading: false
    },
    getters,
    mutations,
    actions,
    modules: {
        specialist,
        schedule
    }
};
