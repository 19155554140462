import mutations from './mutations';
import actions from './actions';

export default {
    namespaced: true,
    state: {
        isLoading: false,
        selectedBuildingId: null,
        /** @type {JustHotelResource | null} */
        hotel: null,
        buildingCard: null,
        /** @type {EventPlaceResource[]} */
        eventPlacesWithParticipants: []
    },
    getters: {},
    mutations,
    actions
};
