<template>
    <div class="transfer-registration-print-list">
        <h3>Трансфер: {{ typeTranslated }}</h3>
        <el-table :data="data">
            <el-table-column
                width="280"
                label="ФИО"
                prop="fio"
                align="left"
            />
            <el-table-column
                width="150"
                label="Место"
                prop="place"
            />
            <el-table-column
                width="130"
                prop="phone"
                label="Телефон"
                align="center"
            />
            <el-table-column
                width="80"
                prop="time"
                label="Время"
                align="center"
            />
        </el-table>
    </div>
</template>

<script>
import JSONStorage from '@/utils/storage';
import _ from 'lodash';

export default {
    name: 'PrintTransferTable',
    data() {
        return {
            data: []
        };
    },
    computed: {
        checkingList() {
            return JSONStorage.get('transferPrintData').data || [];
        },
        typeTranslated() {
            let type;
            if (this.mode === 'arrival') {
                type = 'Прибытие';
            }
            if (this.mode === 'departure') {
                type = 'Отбытие';
            }
            return type;
        },
        mode() {
            return this.$route.query.mode;
        }
    },
    created() {
        let timeField = `${this.mode}Time`;
        let placeField = `${this.mode}TransferPlace`;
        this.data = this.checkingList.map((item, idx) => ({
            fio: `${idx + 1}. ${_.get(item, 'attributes.fullname') || '---'}`,
            phone: _.get(item, 'attributes.phone') || '---',
            place: _.get(item, `attributes.${placeField}.name`) || '---',
            time: _.get(item, `attributes.${timeField}`) || '---'
        }));
    },
    mounted() {
        if (this.data) {
            setTimeout(() => window.print(), 1000);
        }
        this.clearStorage();
    },
    destroyed() {
        this.clearStorage();
    },
    methods: {
        clearStorage() {
            JSONStorage.remove('transferPrintData');
        }
    }
};
</script>

<style lang="scss">
.transfer-registration-print-list {
  max-width: 640px;
  margin: 0 auto;
  padding: 35px 0;
}
</style>
