const getKeysFromOptions = options => [
    ...Object.keys((options.data && options.data()) || {}),
    ...Object.keys(options.props || {})
];

const defineDescriptor = (src, dest, name) => {
    if (!dest.hasOwnProperty(name)) {
        const descriptor = Object.getOwnPropertyDescriptor(src, name);
        Object.defineProperty(dest, name, descriptor);
    }
};

const merge = objs => {
    const res = {};
    objs.forEach(obj => {
        obj &&
        Object.getOwnPropertyNames(obj)
            .forEach(name =>
                defineDescriptor(obj, res, name)
            );
    });
    return res;
};

const buildFromProps = (obj, props) => {
    const res = {};
    props.forEach(prop => defineDescriptor(obj, res, prop));
    return res;
};

export default {
    props: {
        template: String,
        providedMethods: {
            type: Object,
            default: () => ({})
        },
        providedProps: {
            type: Object,
            default: () => ({})
        }
    },
    render(h) {
        if (this.template) {
            const { $data, $props, $options } = this.$parent;
            // const methodKeys = Object.keys($options.methods || {});
            const providedMethodsKeys = Object.keys(this.providedMethods || {});
            const providedPropsKeys = Object.keys(this.providedProps || {});
            const allKeys = getKeysFromOptions($options)
            // .concat(methodKeys)
                .concat(providedPropsKeys)
                .concat(providedMethodsKeys);
            // const methods = buildFromProps(this.$parent, methodKeys);
            const providedMethods = buildFromProps(this.providedMethods, providedMethodsKeys);
            const providedProps = buildFromProps(this.providedProps, providedPropsKeys);
            const props = merge([
                $data,
                $props,
                // methods,
                providedMethods,
                providedProps
            ]);
            const dynamic = {
                template: this.template || '<div></div>',
                props: allKeys,
                computed: $options.computed,
                components: $options.components
            };

            return h(dynamic, {
                props
            });
        }
    }
};
