import api from '@/api/managerApi';

export default {

    getArrivalBookedTime(eventId, params) {
        return api.get(`/event/${eventId}/transfer/arrival-booked-time`, { params });
    },

    getDepartureBookedTime(eventId, params) {
        return api.get(`/event/${eventId}/transfer/departure-booked-time`, { params });
    },

    //TODO: Удалить эти методы

    getTransferParticipantsList(eventId, params) {
        return api.get(`/transfer/${eventId}/participants`, { params });
    },

    getTransferParticipant(participantId) {
        return api.get(`/transfer/participants/${participantId}`);
    },

    updateTransferParticipant(participantId, data) {
        return api.put(`/transfer/participants/${participantId}`, data);
    },

    deleteTransferParticipant(participantId, data) {
        return api.delete(`/transfer/participants/${participantId}`);
    }
};
