<template>
    <div
        v-if="isReady"
        class="place-form"
    >
        <el-form
            ref="form"
            :rules="rules"
            :model="form"
            label-position="top"
        >
            <el-row :gutter="16">
                <el-col :span="18">
                    <el-form-item
                        prop="bed_type"
                        label="Тип кровати"
                    >
                        <el-select
                            v-model="form.bed_type"
                            style="width: 100%;"
                            placeholder="Выберите тип кровати"
                        >
                            <el-option
                                v-for="item in bedTypes"
                                :key="item.type"
                                :label="item.title"
                                :value="item.type"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item
                        prop="is_secondary"
                        label="Тип:"
                    >
                        <el-switch
                            v-model="form.is_secondary"
                            active-text="Дополнительное"
                            inactive-text="Основное"
                            :active-value="true"
                            :inactive-value="false"
                        />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item
                prop="comment"
                label="Комментарий"
            >
                <el-input v-model="form.comment" />
            </el-form-item>
        </el-form>

        <el-row>
            <el-button
                type="primary"
                @click="_trySend"
            >
                {{ buttonText }}
            </el-button>
            <el-button
                type="primary"
                plain
                @click="_cancel"
            >
                Отменить
            </el-button>
        </el-row>
    </div>
</template>

<script>
import _ from 'lodash';
import { Hotel } from '@/api';

const defaultForm = {
    is_secondary: false
};

export default {
    name: 'PlaceForm',

    props: {
        placeId: {
            type: String,
            default: null
        },

        roomId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            form: { ...defaultForm },
            /** @type {PlaceResource | null} */
            place: null,
            bedTypes: [
                {
                    type: 0,
                    title: 'Место на односпальной кровати'
                },
                {
                    type: 1,
                    title: 'Место на двуспальной кровати'
                }
            ]
        };
    },

    computed: {
        isReady() {
            return (this.isEditMode ? !!this.place : true) && !!this.roomId;
        },

        rules() {
            return {};
        },

        isEditMode() {
            return !!this.placeId;
        },

        buttonText() {
            return this.isEditMode ? 'Сохранить' : 'Создать';
        }
    },

    watch: {
        placeId() {
            this._getPlace();
        }
    },

    mounted() {
        this._getPlace();
    },

    methods: {
        _trySend() {
            this.$refs.form.validate(isValid => {
                if (!isValid) {
                    this.$message.error('Проверьте форму');
                    return;
                }
                this._send();
            });
        },

        _cancel() {
            this.$emit('cancel');
        },

        _send() {
            /** @type {CreatePlaceRequest} */
            const data = this._formToCreatePlaceRequest();
            if (this.isEditMode) {
                Hotel.Place.update(data)
                    .then(place => {
                        this.$message.success('Место обновлено');
                        this.$emit('updated', { place });
                    })
                    .catch(e => {
                        this.$message.error('Не удалось обновить место');
                        this.$emit('error');
                    });
            } else {
                Hotel.Place.store(data)
                    .then(place => {
                        this.$message.success('Место добавлено');
                        this._reset();
                        this.$emit('created', { place });
                    })
                    .catch(e => {
                        this.$message.error('Не удалось добавить место');
                        this.$emit('error');
                    });
            }
        },

        /**
         * @returns {CreatePlaceRequest}
         * @private
         */
        _formToCreatePlaceRequest() {
            const data = this.form;
            data.room_id = this.roomId;
            if (this.isEditMode) {
                data.place_id = this.placeId;
            }
            return data;
        },

        _getPlace: _.debounce(function() {
            if (!this.placeId) {
                this._reset();
                return;
            }
            /** @param {PlaceResource} place */
            const onResponse = place => {
                this.place = place;
                this.form = this._formFromPlace();
            };
            Hotel.Place.show({ placeId: this.placeId })
                .then(onResponse)
                .catch(e => {
                    this.$message.error('Место не найден');
                });
        }, 100),

        _formFromPlace() {
            return {
                id: this.place.id,
                bed_type: this.place.attributes.bed_type,
                comment: this.place.attributes.comment,
                is_secondary: this.place.attributes.is_secondary
            };
        },

        _reset() {
            this.form = { ...defaultForm };
        }
    }
};
</script>
