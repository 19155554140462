<template>
    <div class="hotel-list">
        <el-table
            :data="preparedData"
            style="width: 100%"
        >
            <el-table-column label="Название">
                <template slot-scope="scope">
                    <router-link :to="getCardRoute(scope.row)">
                        {{ scope.row.title }}
                    </router-link>
                    <i
                        class="el-icon-edit-outline hotel-list__icon hotel-list__edit"
                        @click="edit(scope.row)"
                    />
                </template>
            </el-table-column>

            <el-table-column
                :width="120"
                align="center"
            >
                <template slot-scope="scope">
                    <i
                        class="el-icon-delete hotel-list__icon hotel-list__remove"
                        @click="tryRemove(scope)"
                    />
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { Hotel } from '@/api';
import { ROUTES } from '@/enums';

export default {
    name: 'HotelList',

    data() {
        return {
            /** @type {HotelResource[]} */
            hotels: []
        };
    },

    computed: {
        preparedData() {
            /**
             * @param {HotelResource} x
             * @returns {Object}
             */
            const mapper = x => {
                return {
                    id: x.id,
                    title: x.attributes.title,
                    guests: 1236
                };
            };
            return this.hotels.map(mapper);
        }
    },

    mounted() {
        this._loadData();
    },

    methods: {
        getCardRoute(item) {
            return {
                name: ROUTES.HOTEL.CARD.SHOW,
                params: {
                    hotelId: item.id
                }
            };
        },

        edit(item) {
            this.$router.push({
                name: ROUTES.HOTEL.EDIT,
                params: {
                    hotelId: item.id
                }
            });
        },

        tryRemove({ row, $index }) {
            // TODO: Временно отключено
            return false;
            this.$confirm('Вы уверены, что хотите удалить отель?')
                .then(() => this._remove({ row, index: $index }))
                .catch(() => {
                });
        },

        _remove({ row, index }) {
            /**
             * @param {HotelResource} hotel
             */
            const onResponse = hotel => {
                this.$message({
                    message: `Отель "${hotel.attributes.title}" удалён`,
                    type: 'success'
                });
                this.hotels.splice(index, 1);
            };
            const onError = e => {
                this.$message.error('Не удалось удалить отель');
            };
            Hotel.remove({ hotelId: row.id })
                .then(onResponse)
                .catch(onError);
        },

        _loadData() {
            Hotel.getAllHotels()
                .then(hotels => {
                    this.hotels = hotels;
                })
                .catch(e => {
                });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.hotel-list {
    &__icon {
        font-size: 1.2em;
        cursor: pointer;
        visibility: hidden;
    }

    &__edit {
        color: #0486fe;
        margin-left: 10px;
    }

    &__remove {
        color: #eb2929;
    }

    .el-table__row {
        &:hover {
            .hotel-list__icon {
                visibility: visible;
            }
        }
    }
}
</style>
