import client from '../userApi';

export default {
    getEventInfo() {
        return client.get('/lk/event-for-register');
    },

    getParticipants(eventId) {
        return client.get(`/lk/${eventId}/participants`);
    },

    addParticipant(eventId, data) {
        return client.post(`/lk/${eventId}/participants/`, data);
    },

    editParticipant(eventId, participantId, data) {
        return client.put(`/lk/${eventId}/participants/${participantId}`, data);
    },

    deleteParticipant(eventId, participantId) {
        return client.delete(`/lk/${eventId}/participants/${participantId}`);
    },

    prepayGroup(eventId) {
        return client.post(`/lk/${eventId}/participants/prepay-group`);
    },

    applyPromoCode({ eventId, promoCode }) {
        return client.post(`/lk/${eventId}/promo-code`, {'promo_code': promoCode});
    }

};
