<template>
    <div class="user-list-view">
        <h1>
            Редактировать пользователя
        </h1>
        <manager-update-form />
    </div>
</template>

<script>

import ManagerUpdateForm from '@/modules/manager/components/ManagerUpdateForm';
import breadcrumbs from '@/mixins/breadcrumbs';
import ROUTES from '../enums/routes';

export default {
    name: 'ManagerUpdateView',
    components: { ManagerUpdateForm },
    mixins: [
        breadcrumbs
    ],
    computed: {
        $breadcrumbs() {
            return [
                {
                    title: 'Пользователи',
                    route: ROUTES.LIST
                },
                {
                    title: 'Редактировать',
                    route: ROUTES.EDIT
                }
            ];
        }
    }
};
</script>
