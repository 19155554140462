<template>
    <div class="room-places">
        <room-place
            v-for="place in places"
            :key="place.id"
            class="room-places__place"
            :place="place"
            @edit="_onEdit"
            @remove="_onRemove"
        />

        <el-button
            type="primary"
            plain
            @click="_addPlace"
        >
            <i class="el-icon-plus" />
            Добавить место
        </el-button>

        <place-form-dialog
            ref="dialog"
            :place-id="placeIdToEdit"
            :room-id="roomId"
            @close="_onEditDialogClose"
            @created="_onPlaceCreated"
            @updated="_onPlaceUpdated"
        />
    </div>
</template>

<script>
import { Hotel } from '@/api';
import RoomPlace from '@/modules/hotel/components/RoomPlace';
import PlaceFormDialog from '@/modules/hotel/components/PlaceFormDialog';

export default {
    name: 'RoomPlaces',

    components: {
        PlaceFormDialog,
        RoomPlace
    },

    props: {
        roomId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            /** @type {PlaceResource[]} */
            places: [],
            placeIdToEdit: null
        };
    },

    mounted() {
        this._getRoomPlaces();
    },

    methods: {
        _onEdit(data) {
            this.placeIdToEdit = _.get(data, 'place.id');
            this.$refs.dialog.open();
        },

        _onEditDialogClose() {
            this._resetEditMode();
        },

        _resetEditMode() {
            this.placeIdToEdit = null;
        },

        _onRemove({ place }) {
            this.$confirm('Вы уверены, что хотите удалить место?')
                .then(() => {
                    Hotel.Place.destroy({
                        placeId: place.id
                    })
                        .then(() => {
                            this.$message.success('Удалено успешно');
                            this._onPlaceDeleted();
                        })
                        .catch(e => {
                            this.$message.error('Не удалось удалить место');
                            this._getRoomPlaces();
                        });
                })
                .catch(() => {
                });
        },

        _addPlace() {
            this._resetEditMode();
            this.$refs.dialog.open();
        },

        _getRoomPlaces() {
            if (!this.roomId) {
                return;
            }
            /**
             * @param {PlaceResource[]} places
             */
            const onResponse = places => {
                this.places = places;
            };
            Hotel.Room.getPlaces({
                roomId: this.roomId
            })
                .then(onResponse)
                .catch(e => {
                });
        },

        _onPlaceCreated() {
            this.$refs.dialog.close();
            this._getRoomPlaces();
        },

        _onPlaceUpdated() {
            this.$refs.dialog.close();
            this._getRoomPlaces();
        },

        _onPlaceDeleted() {
            this._getRoomPlaces();
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.room-places {
    &__place {
        margin-bottom: 16px;
    }
}
</style>
