<template>
    <div>
        <register-header-message />
        <register-leader ref="leaderForm" />
        <register-group />
        <div class="registration-view__container">
            <p>
                Продолжая регистрацию, вы даете согласие на обработку ваших персональных данных
                и соглашаетесь <a
                    :href="eulaLink"
                    target="_blank"
                >с условиями договора-оферты.</a>
            </p>
        </div>
        <el-button
            type="primary"
            @click="_send"
        >
            К оплате
        </el-button>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import RegisterLeader from '@/modules/cabinet/components/register/RegisterLeader';
import RegisterGroup from '@/modules/cabinet/components/register/RegisterGroup';
import { mapParticipantResourceToCreateParticipantRequest } from '@/modules/cabinet/mappers';
import { Event } from '@/api';
import RegisterHeaderMessage from '@/modules/cabinet/components/register/RegisterHeaderMessage';

export default {
    name: 'RegisterForm',

    components: {
        RegisterHeaderMessage,
        RegisterGroup,
        RegisterLeader
    },

    computed: {
        ...mapState('cabinet', [
            'event'
        ]),

        ...mapState('auth', [
            'user',
            'isAuthorized'
        ]),

        ...mapState('cabinet/registration', [
            'participants',
            'leader'
        ]),

        eventId() {
            return this.$prop('event.id');
        },

        eulaLink() {
            return 'http://blagostfest.org/dogovor-publichnoi-oferty/';
        }
    },

    methods: {
        ...mapMutations('cabinet/registration', [
            'setLeader',
            'setParticipants',
            'setIsRegistrationInProcess'
        ]),

        ...mapMutations('auth', [
            'setAuth'
        ]),

        _send() {
            this.$refs.leaderForm.validate()
                .then(() => {
                    const leader = this.leader;
                    const participants = this.participants;
                    this._tryRegistration({ leader, participants });
                })
                .catch(e => {
                    const message = _.get(e, 'message', 'Пожалуйста, проверьте форму');
                    this.$message.error(message);
                });
        },

        /**
         * @param {CreateParticipantRequest} leader
         * @param {CreateParticipantRequest[]} participants
         * @private
         */
        _tryRegistration({ leader, participants }) {
            this.setIsRegistrationInProcess(true);
            /**  @param {RegistrationResponseResource} response */
            const onResponse = response => {
                const auth = response.relationships.auth;
                this.setAuth({
                    accessToken: auth.access_token,
                    expiresAt: auth.expires_at,
                    user: auth.user
                });
                const participants = _.get(response, 'relationships.participants', [])
                    .map(mapParticipantResourceToCreateParticipantRequest);
                this.setParticipants({ participants });
                const leader = participants.find(x => !x.parent_participant_id);
                this.setLeader({ leader });
                const message = participants && participants.length > 1
                    ? 'Участники успешно зарегистрированы'
                    : 'Участник успешно зарегистрирован';
                this.$message(message);
            };
            /**
             * Перед отправкой конвертим даты в строку
             * без информации о timezone.
             * Всё из-за долбанного el-date-picker.
             */
            if (leader.date_of_birth instanceof Date) {
                leader.date_of_birth = leader.date_of_birth.toDateString();
            }
            participants = participants.map(participant => {
                if (participant.date_of_birth instanceof Date) {
                    participant.date_of_birth = participant.date_of_birth.toDateString();
                }
                return participant;
            });
            Event.Registration.register({
                eventId: this.eventId,
                participant: leader,
                participants
            })
                .then(onResponse)
                .catch(e => {
                    let message = 'Не удалось добавить участника';
                    if (e.errors) {
                        message = Object.keys(e.errors)
                            .map(x => e.errors[x].join('<br>'))
                            .join('<br>');
                    } else if (e.message) {
                        message = e.message;
                    }
                    this.$message({
                        dangerouslyUseHTMLString: true,
                        message,
                        type: 'error'
                    });
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.setIsRegistrationInProcess(false);
                    });
                });
        }
    }
};
</script>
