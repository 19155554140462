import ROUTES from './routes';
import PARTICIPANTS from '@/modules/participant/enums';
import EVENT from '@/modules/event/enums';

const DATES = {
    SHORT_FORMAT: 'DD.MM.YYYY'
};

export {
    ROUTES,
    DATES,
    PARTICIPANTS,
    EVENT
};
