<template>
    <el-dialog
        :title="title"
        :visible.sync="isVisible"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="registration-page-info"
        modal
        fullscreen
        width="80%"
    >
        <div
            v-if="type === 'prepay'"
            class="registration-page-info__text"
        >
            <p>
                Вы внесли предоплату за участие в фестивале,
                теперь позвоните менеджеру чтобы забронировать номер:
            </p>
            <p>
                +7 918 962-84-76 — Юлия (12:00–19:00, пн–сб)
                <br>
                +7 918 370-73-16 — Олег (дополнительный)
            </p>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'RegistrationPageInfoModal',

    props: {
        isVisible: {
            type: Boolean,
            default: false
        },

        type: {
            type: String,
            default: 'prepay'
        }
    },

    data() {
        return {
            title: null
        };
    },

    computed: {},

    methods: {}
};
</script>

<style lang="scss">
@import "@vars";

.registration-page-info {
    @include flex-row-centered;
    height: 100%;
    border-radius: 0 !important;

    .el-dialog__body {
        @include flex-row-centered;
    }

    &__text {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 36px;
        color: #239f18;
        max-width: 860px;
        margin: auto;
    }
}
</style>
