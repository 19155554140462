<template>
    <div class="user-list-view">
        <h1>
            Список пользователей
        </h1>
        <manager-list />
    </div>
</template>

<script>
import ManagerList from '@/modules/manager/components/ManagerList';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';

export default {
    name: 'ManagerListView',
    components: { ManagerList },
    mixins: [
        breadcrumbs
    ],
    computed: {
        $breadcrumbs() {
            return [
                {
                    title: 'Пользователи',
                    route: ROUTES.MANAGER.LIST
                }
            ];
        }
    }
};
</script>
