import Auth from '../api';

export default {
    namespaced: true,

    state: {
        user: null,
        accessToken: null,
        expiresAt: null,
        isAuthorized: false
    },

    actions: {
        async login({ commit }, { username, password }) {
            /** @type {ApiAccessToken} */
            let result = await Auth.login({ username, password });
            if (result.success) {
                commit('setAuth', {
                    accessToken: result.data.access_token,
                    expiresAt: result.data.expires_at,
                    user: result.data.user
                });
            }
            return result;
        },

        async logout({ commit }) {
            let response = await Auth.logout();
            if (response.success) {
                commit('setAuth', {
                    accessToken: '',
                    expiresAt: '',
                    user: ''
                });
            }
        },

        async me({ commit }, { accessToken }) {
            let result = await Auth.me();
            if (result.success) {
                commit('setAuth', {
                    accessToken,
                    expiresAt: '',
                    user: result.data
                });
            }
        }
    },

    getters: {},

    mutations: {
        setAuth(state, { accessToken, expiresAt, user }) {
            state.accessToken = accessToken;
            state.expiresAt = expiresAt;
            state.user = user;
            state.isAuthorized = !!user;
            localStorage.setItem('Manager.Auth.Token', JSON.stringify(accessToken));
            localStorage.setItem('Manager.Auth.User', JSON.stringify(user));
        }
    }
};
