<template>
    <section v-loading="isLoading">
        <div>Логин: <span class="manager-update-form__login">{{ form.username }}</span></div>
        <el-form
            ref="form"
            :model="form"
            :rules="rules"
        >
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item
                        label="Имя"
                        prop="name"
                    >
                        <el-input
                            v-model="form.name"
                            placeholder="Иван"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="Фамилия"
                        prop="surname"
                    >
                        <el-input
                            v-model="form.surname"
                            placeholder="Иванов"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item
                        label="Имя в дательном падеже"
                        prop="dative_name"
                    >
                        <el-input
                            v-model="form.dative_name"
                            placeholder="Иванову"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="Пароль"
                        prop="password"
                    >
                        <el-input
                            v-model="form.password"
                            type="password"
                            show-password
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item
                        label="Роль"
                        prop="role"
                    >
                        <el-select
                            v-model="form.role"
                            placeholder="Выберите роль"
                        >
                            <el-option
                                v-for="role in roles"
                                :key="role.value"
                                :label="role.title"
                                :value="role.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    *пока не работает <br>
                    <el-switch
                        v-model="form.enabled"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-text="Активен"
                        inactive-text="Отключен"
                    />
                </el-col>
            </el-row>
            <el-row>
                <el-button
                    type="primary"
                    @click="_onUpdateClickBtn"
                >
                    Обновить
                </el-button>
                <el-button @click="_onCancelClickBtn">
                    Отменить
                </el-button>
            </el-row>
        </el-form>
    </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ROLES from '../enums/roles';
import ROUTES from '../enums/routes';

export default {
    name: 'ManagerUpdateForm',
    data() {
        return {
            isLoading: false,
            form: {
                name: null,
                surname: null,
                dative_name: null,
                username: null,
                password: null,
                role: ROLES.MANAGER,
                enabled: true
            },
            rules: {
                name: [
                    { required: true, message: 'Введите данные', trigger: 'blur' },
                    { min: 3, message: 'Должно содержать минимум 3 симовола', trigger: 'blur' }
                ],
                surname: [
                    { required: true, message: 'Введите данные', trigger: 'blur' },
                    { min: 3, message: 'Должно содержать минимум 3 симовола', trigger: 'blur' }
                ],
                dative_name: [
                    { required: true, message: 'Введите данные', trigger: 'blur' },
                    { min: 3, message: 'Должно содержать минимум 3 симовола', trigger: 'blur' }
                ],
                password: [
                    { min: 6, message: 'Должно содержать минимум 6 символов', trigger: 'blur' }
                ]
            }
        };
    },
    computed: {

        ...mapState('enums', [
            'managerRoles'
        ]),

        managerId() {
            return this.$route.params.managerId;
        },

        roles() {
            return this.managerRoles;
        }
    },
    async mounted() {
        this.isLoading = true;
        if (this.managerId) {
            await this._getManagerData();
            this.isLoading = false;
        }
    },

    methods: {
        ...mapActions('manager', [
            'getManager',
            'updateManager'
        ]),

        _onUpdateClickBtn() {
            this._submitForm('form');
        },

        _onCancelClickBtn() {
            this.form = {};
            this.$router.push({ name: ROUTES.LIST });
        },

        async _tryUpdateManager() {
            let response = await this.updateManager({
                managerId: this.managerId,
                data: this.form
            });
            if (response.success) {
                this.$message.success(response.data);
                this.$router.push(
                    {
                        name: ROUTES.LIST
                    }
                );
            } else {
                this.$message.error(JSON.stringify(response.error));
            }
        },

        _submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this._tryUpdateManager(this.form);
                } else {
                    return false;
                }
            });
        },

        async _getManagerData() {
            let response = await this.getManager(this.managerId);
            if (response.success) {
                this.form = _.get(response, 'data.attributes');
            } else {
                this.$message.error('Ошибка: ' + JSON.stringify(response.error));
                this.$router.push({ name: ROUTES.LIST });
            }

        }
    }
};
</script>

<style scoped>
.manager-update-form__login {
    font-size: 16px;
    font-weight: bold;
}
</style>
