import { ClientAuth, ClientLk } from '@/api';
import client from '@/api/userApi';

/* Класс авторизации внутри приложения */
class AccessManager {
    _store
    _router
    _loading
    _message
    _userTokenName = 'Auth.Token'
    _managerTokenName = 'Manager.Auth.Token'
    _onlineMode = false
    _clientAuth = ClientAuth
    _clientLk = ClientLk

    constructor({ store, router, loading, message }) {
        this._store = store;
        this._router = router;
        this._loading = loading;
        this._message = message;
    }

    authAttempt({ phone }, onlineMode = false) {
        this._onlineMode = onlineMode;
        return this._clientAuth.login({ phone })
            .then(response => {
                this._message.success('Вход успешен. Добро пожаловать в личный кабинет');
                this._setToken(this._userTokenName, response.data.access_token);
                client.defaults.headers['Authorization'] = `Bearer ${this._getToken(this._userTokenName)}`;
                this.storeParticipant(response.data.user);
                return this.__fetchEventInfo(onlineMode);
            })
            .catch(err => {
                return Promise.reject(err.response);
            });
    }

    loginUser({ phone, code }, onlineMode = false) {
        this._onlineMode = onlineMode;
        return this._clientAuth.auth({ phone, code })
            .then(response => {
                this._message.success('Вход успешен. Добро пожаловать в личный кабинет');
                this._setToken(this._userTokenName, response.data.access_token);
                client.defaults.headers['Authorization'] = `Bearer ${this._getToken(this._userTokenName)}`;
                this.storeParticipant(response.data.user);
                return this.__fetchEventInfo(onlineMode);
            })
            .catch(err => {
                this._message.error('Введенный код неверен. Попробуйте еще раз');
                return Promise.reject(err.resonse);
            });
    }

    storeParticipant(data) {
        this._store.commit('lk/participant/setParticipant', { data });
    }

    loginManager() {
        // TODO change login manager logic
    }

    logoutUser() {
        this._clientAuth.logout()
            .then(response => {
                this._removeToken(this._userTokenName);
                this._store.commit('lk/participant/resetData');
                this._router.replace({ name: 'LkAuth' });
            });
    }

    logoutManager() {
        this._removeToken(this._managerTokenName);
    }

    autoLoginUser(onlineMode = false) {
        this._onlineMode = onlineMode;
        const loadScreen = this._loading({ fullscreen: true, background: '#fff' });
        return this._clientAuth.me()
            .then(response => {
                // this._store.commit('lk/participant/setParticipant', { data: response.data });
                this.storeParticipant(response.data);
                return this.__fetchEventInfo(onlineMode);
            })
            .catch(() => {
                this._router.replace({ name: onlineMode ? 'LkOnlineAuth' : 'LkAuth' });
            })
            .finally(() => loadScreen.close());
    }

    autoLoginManager() {
        // TODO change auth manager logic
    }

    _setToken(type, token) {
        localStorage.setItem(type, token);
    }

    _getToken(type) {
        return localStorage.getItem(type) || '';
    }

    _removeToken(type) {
        localStorage.removeItem(type);
    }

    /* Получение данных о событии при входе в кабинет */
    __fetchEventInfo(onlineMode = false) {
        return this._clientLk.getEventInfo()
            .then(response => {
                const { id } = response.data.data;
                this._store.commit('lk/event/setEventInfo', { data: response.data.data });
                this._router.replace({ name: onlineMode ? 'LkOnlineDashboard' : 'LkDashboard', params: { eventId: id } });
                Promise.resolve();
            });
    }
}

export default AccessManager;
