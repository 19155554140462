import manager from '../modules/manager/store';
import { Event, Auth } from '@/api';

export default {
    namespaced: true,

    state: {
        user: null,
        manager: null,
        accessToken: null,
        expiresAt: null,
        isAuthorized: false
    },

    actions: {

        async logout({ commit }) {
            let response = await Auth.logout();
            if (response.success) {
                commit('setAuth', {
                    accessToken: '',
                    expiresAt: '',
                    user: ''
                });
            }
        },

        async me({ commit }, { accessToken }) {
            let result = await Auth.me();
            if (result.success) {
                commit('setAuth', {
                    accessToken,
                    expiresAt: '',
                    user: result.data
                });
            }
        },

        getEvent({ commit }, { eventId }) {
            commit('setEvent', { event: null });
            return Event.show({ eventId })
                .then(event => {
                    commit('setEvent', { event });
                    return Promise.resolve({ event });
                })
                .catch(e => {
                });
        }
    },

    getters: {},

    mutations: {
        setAuth(state, { accessToken, expiresAt, user }) {
            state.accessToken = accessToken;
            state.expiresAt = expiresAt;
            state.user = user;
            state.isAuthorized = !!user;
            localStorage.setItem('Auth.Token', JSON.stringify(accessToken));
            localStorage.setItem('Auth.User', JSON.stringify(user));
        },

        setEvent(state, { event }) {
            state.event = event;
        }
    },

    modules: {
        manager
    }
};
