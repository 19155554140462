import { ParticipantAgeWidget } from '@/api';

const defaultState = {
    loading: false,
    ageSettings: [],
    ageTable: []
};

export default {
    namespaced: true,
    state: { ...defaultState },
    getters: {
        loading: state => state.loading,
        ageSettings: state => state.ageSettings,
        ageTable: state => state.ageTable
    },
    mutations: {
        setLoading(state, { loading }) {
            state.loading = loading;
        },
        setAgeSettings(state, { data }) {
            state.ageSettings = data;
        },
        setAgeTable(state, { data }) {
            state.ageTable = data;
        }
    },
    actions: {
        getSettings({ commit }, { eventId }) {
            return ParticipantAgeWidget.getFilterAge({ eventId })
                .then(res => {
                    commit('setAgeSettings', { data: res.data });
                })
                .catch(err => Promise.reject(err));
        },

        fetchData({ commit }, { eventId, data = {} }) {
            commit('setLoading', { loading: true });
            return ParticipantAgeWidget.getWidgetTable({ eventId, data })
                .then(res => {
                    commit('setAgeTable', { data: res.data });
                })
                .catch(err => Promise.reject(err))
                .finally(() => commit('setLoading', { loading: false }));
        }
    }
};
