export default {
    put(key, dataToSave) {
        const time = (new Date()).getTime();
        const data = JSON.stringify(dataToSave);
        localStorage.setItem(key + '__created_at', time + '');
        localStorage.setItem(key, data);
        return { data, time };
    },

    get(key) {
        try {
            const data = JSON.parse(localStorage.getItem(key));
            const time = parseInt(localStorage.getItem(key + '__created_at'));
            return { data, time };
        } catch (e) {
            return {
                data: null,
                time: null
            };
        }
    },

    remove(key) {
        localStorage.removeItem(key + '__created_at');
        return localStorage.removeItem(key);
    }
};
