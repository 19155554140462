import { Service } from '@/api';

export default {
    namespaced: true,

    state: {
        /** @type {ServiceResource[]} */
        services: [],
        servicesAreLoading: false,
        /** @type {ServiceResource | null} */
        serviceToEdit: null,
        /** @type {Boolean} */
        isServiceToEditLoading: false
    },

    actions: {
        getServices({ commit }) {
            commit('setServicesAreLoading', true);
            return new Promise((resolve, reject) => {
                Service.list()
                    .then(services => {
                        commit('setServices', { services });
                        resolve({ services });
                    })
                    .catch(e => {
                        commit('setServices', { services: [] });
                        reject(e);
                    })
                    .finally(() => {
                        commit('setServicesAreLoading', false);
                    });
            });
        },

        // getSpecialists({ commit }) {
        //     commit('setServicesAreLoading', true);
        //     return new Promise((resolve, reject) => {
        //         Service.list()
        //             .then(services => {
        //                 commit('setServices', { services });
        //                 resolve({ services });
        //             })
        //             .catch(e => {
        //                 commit('setServices', { services: [] });
        //                 reject(e);
        //             })
        //             .finally(() => {
        //                 commit('setServicesAreLoading', false);
        //             });
        //     });
        // },

        /**
         * @param commit
         * @param {UUID} serviceId
         * @return {Promise<ServiceResource | null>}
         */
        setServiceToEdit({ commit }, { serviceId }) {
            commit('setServiceToEdit', { serviceToEdit: null });
            commit('setIsServiceToEditLoading', true);
            return new Promise((resolve, reject) => {
                if (!serviceId) {
                    resolve(null);
                    return;
                }
                Service.show({ serviceId })
                    .then(serviceToEdit => {
                        commit('setServiceToEdit', { serviceToEdit });
                        resolve(serviceToEdit);
                    })
                    .catch(reject)
                    .finally(() => {
                        commit('setIsServiceToEditLoading', false);
                    });
            });
        }
    },

    getters: {},

    mutations: {
        /**
         * @param state
         * @param {ServiceResource[]} services
         */
        setServices(state, { services }) {
            state.services = services;
        },

        /**
         * @param state
         * @param {Boolean} servicesAreLoading
         */
        setServicesAreLoading(state, servicesAreLoading) {
            state.servicesAreLoading = servicesAreLoading;
        },

        setServiceToEdit(state, { serviceToEdit }) {
            state.serviceToEdit = serviceToEdit;
        },

        setIsServiceToEditLoading(state, isServiceToEditLoading) {
            state.isServiceToEditLoading = isServiceToEditLoading;
        }
    }
};
