export default {
    /**
     * @param state
     * @param {EventServiceResource[]} services
     */
    setServices(state, { services }) {
        state.services = services;
    },

    setAreEventServicesLoading(state, areEventServicesLoading) {
        state.areEventServicesLoading = areEventServicesLoading;
    },

    /**
     * @param state
     * @param {ServiceResource[]} allServices
     */
    setAllServices(state, { allServices }) {
        state.allServices = allServices;
    },

    setSpecialists(state, { specialists }) {
        state.specialists = specialists;
    },

    setAreSpecialistsLoading(state, areSpecialistsLoading) {
        state.areSpecialistsLoading = areSpecialistsLoading;
    },

    setLocations(state, { locations }) {
        state.locations = locations;
    },

    setAreLocationsLoading(state, areLocationsLoading) {
        state.areLocationsLoading = areLocationsLoading;
    },

    /**
     * @param state
     * @param {EventServiceResource | null} eventServiceToEdit
     */
    setEventServiceToEdit(state, { eventServiceToEdit }) {
        state.eventServiceToEdit = eventServiceToEdit;
    }
};
