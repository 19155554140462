import ROUTES from './routes';
import TRANSACTIONS from './transactions';
import DIALOG_CARDS from './dialogCards';
import SCHEDULE from './schedule';

export default {
    ROUTES,
    TRANSACTIONS,
    DIALOG_CARDS,
    SCHEDULE
};
