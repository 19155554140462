<template>
    <div class="app-onlinelk-registration">
        <h1 class="app-onlinelk-registration__header app-h1">
            {{ eventInfo.title }}
        </h1>
        <h2 class="app-onlinelk-registration__header app-h2">
            Регистрация участника
        </h2>
        <h3 class="app-onlinelk-registration__header  app-onlinelk-registration__header--red app-h3">
            Обратите внимание, что количество мест <br> ограничено и
            скоро регистрация будет закрыта
        </h3>

        <el-row
            type="flex"
            justify="center"
        >
            <el-col class="app-onlinelk-registration__form-col">
                <el-form
                    ref="form"
                    :rules="rules"
                    :model="form"
                    label-position="top"
                    hide-required-asterisk
                >
                    <el-form-item
                        prop="phone"
                        label="Номер телефона"
                        class="app-lk-participant-modal__form-item"
                    >
                        <el-input
                            v-model="registerUser.phone"
                            disabled
                        />
                    </el-form-item>

                    <el-form-item
                        prop="phone2"
                        label="Номер телефона с WhatsApp"
                        class="app-lk-participant-modal__form-item"
                    >
                        <app-phone-input
                            v-model="form.phone2"
                            placeholder="Введите номер телефона с WhatsApp"
                            @change="_onPhoneChanged"
                        />
                    </el-form-item>
                    <el-form-item
                        label="Фамилия"
                        prop="lastname"
                        :rules="[{ required: true, message: 'Укажите фамилию', trigger: 'blur'}]"
                        class="app-lk-participant-modal__form-item"
                    >
                        <el-input v-model="form.lastname" />
                    </el-form-item>
                    <el-form-item
                        label="Имя"
                        prop="firstname"
                        :rules="[{ required: true, message: 'Укажите имя', trigger: 'blur' }]"
                        class="app-lk-participant-modal__form-item"
                    >
                        <el-input v-model="form.firstname" />
                    </el-form-item>
                    <el-form-item
                        v-if="false"
                        label="Дата рождения"
                        prop="date_of_birth"
                        :rules="[{ required: false, message: 'Укажите дату рождения', trigger: 'blur'}]"
                        class="app-lk-participant-modal__form-item app-lk-participant-modal__date-form-item"
                    >
                        <flat-pickr
                            v-model="form.date_of_birth"
                            style="width: 100%; padding:0 30px;"
                            class="el-input__inner app-lk-participant-modal__date-input"
                            :config="pickerOptions"
                            placeholder="Выберите дату"
                        />
                        <span class="el-input__prefix"><i class="el-input__icon el-icon-date" /><!----></span>
                    </el-form-item>
                    <el-form-item
                        label="Город"
                        prop="city"
                        :rules="[{ required: true, message: 'Укажите город', trigger: 'blur' }]"
                        class="app-lk-participant-modal__form-item"
                    >
                        <el-autocomplete
                            v-model="form.city"
                            style="width: 100%;"
                            :fetch-suggestions="_getCitySuggestion"
                            placeholder="Город"
                            :trigger-on-focus="false"
                            @select="_onCitySuggestionSelect"
                        />
                    </el-form-item>
                    <el-form-item
                        label="Электронная почта"
                        :rules="[{ required: true, message: 'Укажите Email', trigger: 'blur'}]"
                        prop="email"
                        class="app-lk-participant-modal__form-item"
                    >
                        <el-input v-model="form.email" />
                    </el-form-item>
                    <el-form-item
                        v-if="false"
                        label="Капитан"
                        prop="online_captain_id"
                        class="app-lk-participant-modal__form-item"
                        :rules="[{ required: false, message: 'Выберите капитана', trigger: 'blur'}]"
                    >
                        <el-select
                            v-model="form.online_captain_id"
                            class="app-onlinelk-registration__select"
                            filterable
                        >
                            <el-option
                                v-for="captain in captains"
                                :key="captain.id"
                                :label="_makeCaptainChoice(captain)"
                                :value="captain.id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox
                            v-model="form.termsAgree"
                            style="display: flex; margin-top: 10px; align-items: center; white-space: normal"
                        >
                            <a
                                :href="ofertaUrl"
                                target="_blank"
                            >Согласен с договором-офертой</a> и даю разрешение на обработку моих
                            персональных данных
                        </el-checkbox>
                    </el-form-item>
                    <el-form-item style="display: flex; justify-content: center">
                        <el-button
                            type="primary"
                            :disabled="isPrepayButtonDisabled"
                            @click="_onFormSubmit"
                        >
                            {{ payBtnText }}
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import AppPhoneInput from '@/components/form/AppPhoneInput';
import client from '@/utils/dadataClient';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import moment from 'moment';

export default {
    name: 'OnlineRegistration',
    components: { AppPhoneInput, flatPickr },
    data() {
        return {
            isPrepayButtonDisabled: false,
            form: {
                firstname: '',
                lastname: '',
                date_of_birth: undefined,
                city: undefined,
                online_captain_id: undefined,
                email: undefined,
                phone: '',
                phone2: '',
                termsAgree: true
            },
            client: client,
            isPhoneValid: false,
            rules: {
                phone2: [
                    {
                        trigger: 'change',
                        required: false,
                        validator: (rule, value, callback) => {
                            if (!value) {
                                return callback(new Error('Укажите  телефон'));
                            }
                            if (this.isPhoneValid) {
                                return callback();
                            }
                            return callback(new Error('Проверьте телефон'));
                        },
                        message: 'Укажите телефон'
                    }
                ],
                email: [
                    { type: 'email', message: 'Почта указана неверно', trigger: 'blur' }
                ],
                online_captain_id: [
                    { required: true, message: 'Пожалуйста укажите капитана', trigger: 'blur' }
                ]
            }
        };
    },
    computed: {
        ...mapGetters('lk/onlineEvent', [
            'captains',
            'eventInfo',
            'eventId'
        ]),
        ...mapGetters('lk/onlineParticipant', [
            'participant',
            'registerUser',
            'captains',
            'captainsIsLoading'
        ]),

        payBtnText() {
            return `Оплатить ${this.eventInfo.prepay} р.`;
        },

        pickerOptions() {
            return {
                wrap: true,
                dateFormat: 'd.m.Y',
                maxDate: 'today',
                allowInput: true
            };
        },

        ofertaUrl(){
            return 'https://blagostonline.ru/oferta';
        }
    },
    mounted() {
        const formData = _.get(this.participant, 'attributes', {});
        this._prepareForm(formData);
    },
    methods: {
        ...mapActions('lk/onlineParticipant', [
            'fetchParticipant',
            'addParticipant',
            'fetchCaptains'
        ]),
        _makeCaptainChoice(captain) {
            return `${captain.attributes.lastname} ${captain.attributes.firstname}`;
        },
        _prepareForm(data) {
            this.form = { termsAgree: true, ...data };
            this.form.location = data ? data.city : undefined;
            let dateOfBirth = _.get(this.participant, 'attributes.date_of_birth');
            this.form.date_of_birth = dateOfBirth
                ? moment(this.participant.attributes.date_of_birth)
                    .format('DD.MM.YYYY') : null;
            this.isPhoneValid = true;
            this.$refs.form.clearValidate(this.form);
        },
        _phoneValidator(rule, value, callback) {
            if (!value) {
                return callback();
            }
            if (this.isPhoneValid) {
                return callback();
            }
            callback(new Error('Проверьте телефон'));
        },
        _makePaymentRequest(response) {
            const url = _.get(response, 'url');
            const payload = _.get(response, 'payload');

            const robokassaForm = document.createElement('form');
            robokassaForm.method = 'POST';
            robokassaForm.action = url;

            const fields = [
                'Culture',
                'Description',
                'Encoding',
                'IncCurrLabel',
                'InvId',
                'IsTest',
                'MerchantLogin',
                'OutSum',
                'Receipt',
                'SignatureValue'
            ];

            fields.forEach(fieldName => {
                const inputField = document.createElement('input');
                inputField.type = 'hidden';
                inputField.name = fieldName;
                inputField.value = payload[fieldName];
                robokassaForm.appendChild(inputField);
            });
            document.body.appendChild(robokassaForm);
            robokassaForm.submit();
        },
        _prepay() {
            this.addParticipant({ eventId: this.eventId, participantId: this.participant.id, data: this.form })
                .then(response => {
                    this._makePaymentRequest(response.data);
                })
                .catch(err => {
                    this.isPrepayButtonDisabled = false;
                    let errorMsg = _.get(err, 'response.data.message');
                    this.$message.error(`Ошибка: ${errorMsg}`);
                });
        },
        _onFormSubmit() {
            this.$refs.form.validate()
                .then(() => {
                    if (!this.form.termsAgree) {
                        return this.$message.info('Вы не дали согласие на обработку персональных данных');
                    }
                    this.isPrepayButtonDisabled = true;
                    setTimeout(() => {
                        this._prepay();
                    }, 500);
                })
                .catch(e => {
                    this.$message.error(`Проверьте введённые данные`);
                });
        },
        /**
         * @param {string} phone
         * @param {Boolean} isValid
         * @param {String} country
         */
        _onPhoneChanged({ phone, isValid, country }) {
            this.isPhoneValid = isValid;
            this.$refs.form && this.$refs.form.validateField('phone2');
        },
        /**
         * @param {{
         *     suggestion: DaDataSuggestion,
         *     value: String,
         * }} data
         */
        _onCitySuggestionSelect(data) {
            this.form.location_provider = 'dadata';
            this.form.location_payload = data.suggestion;
            this.form.location = data.suggestion.unrestricted_value;
            this.form.city = data.suggestion.data.city;
            this.form.country = data.suggestion.data.country;
            this.$refs.form.validateField('city');
        },

        /**
         * @param {String} query - Например, 'Моск'
         * @param {Function} cb
         */
        _getCitySuggestion(query, cb) {
            this.form.location_provider = null;
            this.form.location_payload = null;
            this.form.country = null;
            this.form.location = null;
            this.areSuggestionLoading = true;
            const onError = e => {
                const errorMessage = _.get(e, 'message', 'Не удалось получить список городов для подсказки.');
                this.$message.error(errorMessage);
            };
            this._suggest(query)
                .then(suggestions => {
                    /**
                     * При ошибке все равно резолвится,
                     * нет возможности ошибку отдельно обработать
                     */
                    if (!suggestions || !suggestions.length) {
                        // onError({ message: 'Ничего не найдено' });
                    }
                    cb(suggestions.map(suggestion => ({
                        value: suggestion.value,
                        suggestion
                    })));
                })
                .catch(onError)
                .finally(() => {
                    this.areSuggestionLoading = false;
                });
        },

        _suggest(query) {
            return this.client.suggest(
                'address',
                query,
                5,
                {
                    from_bound: {
                        value: 'city'
                    },
                    to_bound: {
                        value: 'city'
                    },
                    locations: [
                        {
                            country: '*',
                            city_type_full: 'город'
                        }
                    ]
                }
            );
        }
    }
};
</script>

<style lang="scss">
.app-onlinelk-registration {
  &__form-col {
    width: 100%;
    max-width: 400px;
  }

  &__select {
    width: 100%;
  }

  &__header {
    text-align: center;

    &--red {
      color: red;
    }
  }

  &__row {
    margin: -3px;
  }

  &__checkbox {
    display: flex;
    margin-top: 10px;
    align-items: center;
  }
}
</style>
