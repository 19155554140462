import AccessManager from '@/services/AccessManager';
import { ClientOnlineLk, ClientOnlineAuth } from '@/api';

class OnlineLkAccessManager extends AccessManager {
    constructor({ store, router, loading, message }) {
        super({ store, router, loading, message });
        this._clientAuth = ClientOnlineAuth;
        this._clientLk = ClientOnlineLk;
        this._onlineMode = true;
    }

    /* Получение данных о событии при входе в кабинет */
    __fetchEventInfo(onlineMode = false) {
        return this._clientLk.getEventInfo()
            .then(response => {
                const { id } = response.data.data;
                this._store.commit('lk/onlineEvent/setEventInfo', { data: response.data.data });
                this._router.replace({ name: 'LkOnlineDashboard', params: { eventId: id } });
                Promise.resolve();
            });
    }

    logoutUser() {
        this._clientAuth.logout()
            .then(response => {
                this._removeToken(this._userTokenName);
                this._store.commit('lk/participant/resetData');
                this._router.replace({ name: 'LkOnlineAuth' });
            });
    }

    storeParticipant(data) {
        this._store.commit('lk/participant/setParticipant', { data });
        this._store.commit('lk/onlineParticipant/registerUser', { data });
    }
}

export default OnlineLkAccessManager;
