import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import { ROUTES } from '@/enums';
import Participant from './participant';
import Registration from './registration';
import Hotel from './hotel';
import Transactions from './transactions';
import Service from './service';
import TransferParticipants from './transferParticipants';

export default {
    Participant,
    Registration,
    Hotel,
    Transactions,
    Service,
    TransferParticipants,
    /**
     * @param {UUID} eventId
     * @returns {Promise<EventResource>}
     */
    show({ eventId }) {
        return api.get(urlBuilder(ROUTES.EVENT.API.CARD.SHOW, { eventId }))
            .then(response => Promise.resolve(response.data.data));
    },

    /**
     * @param {CreateEventRequest} data
     * @returns {Promise<EventResource>}
     */
    store(data) {
        return api.post(urlBuilder(ROUTES.EVENT.API.STORE), data)
            .then(response => Promise.resolve(response.data.data));
    },

    /**
     * @param {CreateEventRequest} data
     * @returns {Promise<EventResource>}
     */
    update(data) {
        return api.put(urlBuilder(ROUTES.EVENT.API.UPDATE, {
            eventId: data.event_id
        }), data)
            .then(response => Promise.resolve(response.data.data));
    },

    remove({ eventId }) {
        return api.delete(urlBuilder(ROUTES.EVENT.API.DELETE, { eventId }))
            .then(response => Promise.resolve(response.data.data));
    },

    /**
     * @returns {Promise<EventResource[]>}
     */
    getAllEvents(params = {}) {
        const url = urlBuilder(ROUTES.EVENT.API.LIST);
        return api.get(url, params)
            .then(response => {
                return Promise.resolve(response.data.data);
            });
    },

    getEventsToMove({ eventId }) {
        const url = urlBuilder(ROUTES.EVENT.API.EVENTS_TO_MOVE, { eventId });
        return api.get(url)
            .then(response => {
                return Promise.resolve(response.data.data);
            });
    },

    movePersonnel({ fromEvent, toEvent }) {
        const url = urlBuilder(ROUTES.EVENT.API.MOVE_PERSONAL_TO_EVENT);
        return api.post(url, { fromEvent, toEvent })
            .then(response => {
                return Promise.resolve(response);
            });
    }
};
