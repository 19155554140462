import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import { ROUTES } from '@/enums';
import CONFIG from '@/config';
import _ from 'lodash';
import axios from 'axios';

export default {

    /**
     * @returns {Promise<PaymentResource>}
     */
    getQueue() {
        return api.get(urlBuilder(ROUTES.E_QUEUE.API.GET_QUEUE))
            .then(response => Promise.resolve(response.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {Integer} number
     * @returns {Promise<PaymentResource>}
     */
    setNewCounter({ number }) {
        return api.post(urlBuilder(ROUTES.E_QUEUE.API.UPDATE_QUEUE_COUNTER), {
            current_number: number
        })
            .then(response => Promise.resolve(response.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    cacheNumber(number) {
        let url = `${CONFIG.API_HOST}/storage/speech/numbers/${number}.ogg`;
        return axios.get(url);
    }

};
