import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import { ROUTES } from '@/enums';

export default {

    /** @type {ParticipantsByStatusAndEventResource}  */
    async getParticipantsByStatus({ eventId }) {
        const url = urlBuilder(ROUTES.STATS.API.PARTICIPANTS.BY_STATUS, {
            eventId
        });
        try {
            let response = await api.get(url);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                message: e.message
            };
        }
    }
};
