import { ROUTES } from '@/enums';

/**
 * Components
 */
const EventAddView = () => import(/* webpackChunkName: "EventAddView" */ '@/modules/online-event/views/EventAddView');
const OnlineEventListView = () => import(/* webpackChunkName: "EventListView" */ '@/modules/online-event/views/OnlineEventListView');
// const EventCardView = () => import(/* webpackChunkName: "EventCardView" */ '@/modules/online-event/views/EventCardView');
const EventParticipantListView = () => import(/* webpackChunkName: "EventParticipantCardView" */ '@/modules/online-event/views/EventParticipantListView');
const EventEditView = () => import(/* webpackChunkName: "EventEditView" */ '@/modules/online-event/views/EventEditView');
// const EventServiceParticipants = () => import(/* webpackChunkName: "EventServiceParticipants" */ '@/modules/online-event/components/card/service/EventServiceParticipants');

export default [
    {
        path: '',
        name: ROUTES.ONLINE_EVENT.LIST,
        component: OnlineEventListView
    },

    {
        path: 'add',
        name: ROUTES.ONLINE_EVENT.ADD,
        component: EventAddView
    },

    {
        path: ':eventId/edit',
        name: ROUTES.ONLINE_EVENT.EDIT,
        component: EventEditView
    },

    {
        path: ':onlineEventId',
        redirect: {
            name: ROUTES.ONLINE_EVENT.CARD.PARTICIPANTS
        }
    },

    {
        path: ':onlineEventId/participants',
        name: ROUTES.ONLINE_EVENT.CARD.PARTICIPANTS,
        component: EventParticipantListView
    }
];
