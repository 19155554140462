<template>
    <div
        v-if="isReady"
        class="register-group"
    >
        <div class="register-group__content">
            <h2 class="app-h2">
                С вами еще кто-то едет?
            </h2>
            <p>
                Пожалуйста, добавьте всех, даже детей до трех лет,
                няню или бабушку, если они едут, чтобы сидеть с ребенком.
            </p>
            <participants-list
                :participants="participants"
                @remove="_onParticipantRemove"
                @edit="_onParticipantEdit"
            />
            <el-alert
                v-if="isEmpty"
                :closable="false"
                style="max-width: 450px; margin: 30px auto;"
                type="info"
                title="Нажмите «Добавить» и заполните форму для добавления участника"
            />
            <group-member-form
                ref="dialog"
                :index="participantToEditIndex"
                :participant="participantToEdit"
                @added="_onParticipantAdded"
                @updated="_onParticipantUpdated"
            />
            <el-button
                type="text"
                @click="_openAddForm"
            >
                <div class="fas fa-plus" />
                Добавить
            </el-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Event } from '@/api';
import GroupMemberForm from '@/modules/cabinet/components/register/GroupMemberForm';
import ParticipantsList from '@/modules/cabinet/components/common/ParticipantsList';
import { mapParticipantResourceToCreateParticipantRequest } from '@/modules/cabinet/mappers';

export default {
    name: 'RegisterGroup',

    components: {
        ParticipantsList,
        GroupMemberForm
    },

    props: {
        userId: {
            type: String,
            default: null
        },

        parentParticipantId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            participantToEdit: null,
            participantToEditIndex: -1
        };
    },

    computed: {
        ...mapState('cabinet', [
            'event'
        ]),

        ...mapState('cabinet/registration', [
            'participants',
            'leader'
        ]),

        ...mapState('enums', [
            'sexOptions'
        ]),

        isReady() {
            return !!this.eventId;
        },

        isEmpty() {
            return !this.participants || !this.participants.length;
        },

        eventId() {
            return this.$prop('event.id');
        },

        leaderId() {
            return this.$prop('leader.id', null);
        }
    },

    watch: {
        parentParticipantId() {
            this._loadParticipants();
        }
    },

    mounted() {
        this._loadParticipants();
    },

    methods: {
        ...mapMutations('cabinet/registration', [
            'setParticipants',
            'addParticipant',
            'removeParticipantByIndex'
        ]),

        _onParticipantUpdated({ participant }) {
            this.participantToEdit = participant;
        },

        _onParticipantAdded({ participant }) {
            this.addParticipant({ participant });
        },

        _loadParticipants() {
            if (!this.parentParticipantId || !this.eventId) {
                return;
            }
            Event.Registration.getGroupParticipants({
                eventId: this.eventId,
                parentParticipantId: this.parentParticipantId
            })
                .then(resources => {
                    const participants = resources.map(mapParticipantResourceToCreateParticipantRequest);
                    this.setParticipants({ participants });
                });
        },

        _openAddForm() {
            this.participantToEdit = null;
            this.participantToEditIndex = -1;
            this.$refs.dialog.open();
        },

        _send() {

        },

        _resetForm() {
            this.$refs.form.reset();
        },

        _onParticipantRemove({ index, participant }) {
            if (participant.id) {
                Event.Registration.deleteGroupParticipant({
                    participantId: participant.id,
                    eventId: this.eventId,
                    parentParticipantId: this.leaderId
                })
                    .then(() => {
                        this.removeParticipantByIndex({ index });
                    })
                    .catch(e => {
                        const message = _.get(e, 'message', 'Не удалось удалить участника');
                        this.$message.error(message);
                    });
            } else {
                this.removeParticipantByIndex({ index });
            }
        },

        _onParticipantEdit({ index, participant }) {
            this.participantToEdit = participant;
            this.participantToEditIndex = index;
            this.$refs.dialog.open();
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.register-group {
  &__content {
    max-width: 585px;
    margin: 20px auto;
  }
}
</style>
