<template>
    <div class="cabinet-payment-info">
        <h2 class="app-h2">
            Информация о заказе:
        </h2>

        <cabinet-payment-invoice-row
            v-for="invoice in invoices"
            :key="invoice.id"
            :invoice="invoice"
        />
    </div>
</template>

<script>
import _ from 'lodash';
import { Cabinet } from '@/api';
import CabinetPaymentInvoiceRow from '@/modules/cabinet/components/payment/CabinetPaymentInvoiceRow';

export default {
    name: 'CabinetPaymentInfo',

    components: {
        CabinetPaymentInvoiceRow
    },

    props: {
        paymentId: {
            type: [Number, String],
            default: null
        }
    },

    data() {
        return {
            payment: null
        };
    },

    computed: {
        /** @type {InvoiceResource[]} */
        invoices() {
            return this.$prop('payment.relationships.invoices');
        }
    },

    watch: {
        paymentId() {
            this._loadPaymentInfo();
        }
    },

    mounted() {
        this._loadPaymentInfo();
    },

    methods: {
        _loadPaymentInfo: _.debounce(function() {
            if (!this.paymentId) {
                this.payment = null;
                return;
            }
            /**
             * @param {PaymentResource} payment
             */
            const onResolve = payment => {
                this.payment = payment;
            };
            const onError = e => {
                this.$message.error('Не удалось получить информацию о заказе');
            };
            Cabinet.Payment.show({
                paymentId: this.paymentId
            })
                .then(onResolve)
                .catch(onError);
        }, 100)
    }
};
</script>
