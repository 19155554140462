<!--suppress ALL -->
<template>
    <el-tooltip :content="title">
        <i :class="classes" />
    </el-tooltip>
</template>

<script>
export default {
    name: 'GenderIcon',

    props: {
        gender: {
            type: [Number, String],
            default: 0
        }
    },

    computed: {
        classes() {
            return {
                'fas': true,
                'fa-male': this.gender == 1,
                'fa-female': this.gender == 2,
                'fa-question': this.gender < 1 || this.gender > 2
            };
        },

        title() {
            switch (this.gender) {
            case 1:
                return 'Муж.';
            case 2:
                return 'Жен.';
            default:
                return 'Неизв.';
            }
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
