import { ROUTES } from '@/enums';
import api from '@/api/userApi';

export default {
    /**
     * @param eventId
     * @param phone
     * @returns {Promise<TestPhoneResultResource>}
     */
    testPhone({ eventId, phone }) {
        return new Promise((resolve, reject) => {
            const url = ROUTES.AUTH.API.TEST_PHONE;
            /** @type {TestPhoneRequest} */
            const request = { phone, eventId };
            return api.post(url, request)
                .then(response => resolve(_.get(response, 'data.data')))
                .catch(reject);
        });
    },

    async me() {
        try {
            let response = await api.get(ROUTES.AUTH.API.USER.ME);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false
            };
        }
    }
};
