export default {
    TRANSFER_PLACES: {
        LIST: 'places.list',
        ADD: 'places.add',
        EDIT: 'placed.edit'
    },
    TRANSFER_PARTICIPANTS: {
        LIST: 'transfer-participants.list',
        ADD: 'transfer-participants.add',
        EDIT: 'transfer-participants.edit'
    }
};
