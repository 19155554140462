export default {
    LIST: 'specialist.list',
    API: {
        /** GET */
        LIST: 'specialist',
        /** GET */
        SHOW: 'specialist/{specialistId}',
        /** PUT */
        UPDATE: 'specialist/{specialistId}',
        /** POST */
        STORE: 'specialist',
        /** DELETE */
        DELETE: 'specialist/{specialistId}'
    }
};
