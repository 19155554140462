import card from '@/modules/hotel/store/modules/card';

export default {
    namespaced: true,
    state: {},
    actions: {},
    getters: {},
    mutations: {},
    modules: {
        card
    }
};
