import { EventParticipant } from '@/api';

const defaultState = {
    data: [],
    loading: false
};

export default {
    namespaced: true,
    state: { ...defaultState },
    getters: {
        loading: state => state.loading,
        data: state => state.data
    },
    mutations: {
        setLoading(state, { loading }) {
            state.loading = loading;
        },
        setData(state, { data }) {
            state.data = data;
        }
    },
    actions: {
        fetchData({ commit }, { group, id }) {
            commit('setLoading', { loading: true });
            return EventParticipant.getLogInfo({ group, id })
                .then(response => {
                    commit('setData', { data: response.data.data });
                })
                .finally(() => commit('setLoading', { loading: false }));
        }
    }
};
