<template>
    <div
        v-if="isReady"
        class="hotel-building-rooms-list"
    >
        <app-table
            :rows="preparedData"
            :config="config"
            removable
            @remove="remove"
        />
        <el-row :gather="20">
            <el-button
                type="text"
                @click="addRoom"
            >
                <i class="el-icon-plus" />
                Добавить номер
            </el-button>
        </el-row>
    </div>
</template>

<script>
import _ from 'lodash';
import { ROUTES } from '@/enums';
import AppTable from '@/components/table/AppTable';

const titleTemplate = room => {
    return `<div>
                <router-link :to="getCardRoute()">
                    ${room.title}
                </router-link>
                <router-link :to="getEditRoute()">
                    <i class="el-icon-edit-outline hotel-building-rooms-list__icon hotel-building-rooms-list__edit"/>
                </router-link>
            </div>`;
};
export default {
    name: 'HotelBuildingRoomsList',
    components: { AppTable },
    props: {
        hotel: {
            type: Object,
            default: null
        },

        building: {
            type: Object,
            default: null
        },

        /** @type {RoomResource[]} */
        rooms: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        isReady() {
            return this.hotel && this.building && this.rooms;
        },

        config() {
            return {
                methods: row => ({
                    getEditRoute: this.getEditRoute.bind(this, row),
                    getCardRoute: this.getCardRoute.bind(this, row)
                }),
                columns: [
                    {
                        title: 'Номер',
                        template: titleTemplate
                    },
                    {
                        title: 'Этаж',
                        render: row => row.floor
                    },
                    {
                        title: 'Тип номера',
                        render: row => row.type
                    },
                    {
                        title: 'Кровать',
                        render: row => row.bed
                    },
                    {
                        title: 'Комнат',
                        render: row => row.rooms
                    },
                    {
                        title: 'Мест',
                        render: row => row.places
                    },
                    {
                        title: 'Доп. мест',
                        render: row => row.additional_places
                    },
                    {
                        title: 'Размер',
                        render: row => row.size
                    },
                    {
                        title: 'Вид',
                        render: row => row.window_view
                    },
                    {
                        title: 'Комментарий',
                        render: row => row.comment
                    },
                    {
                        title: 'Статус',
                        render: row => row.enabled ? 'Вкл' : 'Откл'
                    }
                ]
            };
        },

        preparedData() {
            if (!this.rooms) {
                return [];
            }
            /**
             * @param {RoomResource} x
             * @returns {Object}
             */
            const mapper = x => {
                return {
                    id: x.id,
                    title: x.attributes.title,
                    floor: x.attributes.floor,
                    bed: x.attributes.bed,
                    type: _.get(x, 'relationships.category.attributes.title'),
                    rooms: 0,
                    places: 0,
                    additional_places: 0,
                    size: x.attributes.size,
                    window_view: x.attributes.window_view,
                    comment: x.attributes.comment,
                    enabled: x.attributes.enabled
                };
            };
            return this.rooms.map(mapper);
        },

        hotelId() {
            return this.$route.params.hotelId;
        },

        buildingId() {
            return this.$route.params.buildingId;
        }
    },

    methods: {
        getCardRoute(item) {
            return {
                name: ROUTES.HOTEL.CARD.BUILDING.ROOM.SHOW,
                params: {
                    hotelId: this.hotel.id,
                    buildingId: this.building.id,
                    roomId: item.id
                }
            };
        },

        getEditRoute(item) {
            return {
                name: ROUTES.HOTEL.CARD.BUILDING.ROOM.EDIT,
                params: {
                    hotelId: this.hotelId,
                    buildingId: this.buildingId,
                    roomId: item.id
                }
            };
        },

        getAddRoute() {
            return {
                name: ROUTES.HOTEL.CARD.BUILDING.ROOM.ADD,
                params: {
                    hotelId: this.hotelId,
                    buildingId: this.buildingId
                }
            };
        },

        addRoom() {
            this.$router.push(this.getAddRoute());
        },

        remove({ row, $index }) {
            this.$message('В разработке');
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.hotel-building-rooms-list {
    &__icon {
        font-size: 1.2em;
        cursor: pointer;
        visibility: hidden;
    }

    &__edit {
        color: #0486fe;
        margin-left: 10px;
    }

    &__remove {
        color: #eb2929;
    }

    .el-table__row {
        &:hover {
            .hotel-building-rooms-list__icon {
                visibility: visible;
            }
        }
    }
}
</style>
