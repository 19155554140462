import ROUTES from '@/enums/routes';
import CabinetRegisterView from '@/modules/cabinet/views/CabinetRegisterView';
import CabinetProfileView from '@/modules/cabinet/views/CabinetProfileView';
import CabinetPaymentSuccessView from '@/modules/cabinet/views/CabinetPaymentSuccessView';

export default [
    {
        path: ':eventId/payment/:paymentId/success',
        name: ROUTES.CABINET.PAYMENT.SUCCESS,
        component: CabinetPaymentSuccessView
    },

    {
        path: ':eventId/register',
        name: ROUTES.CABINET.EVENT.REGISTER,
        component: CabinetRegisterView
    },

    {
        path: ':eventId',
        name: ROUTES.CABINET.PROFILE,
        component: CabinetProfileView
    }
];
