import _ from 'lodash';
import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import ROUTES from '@/enums/routes';
import Building from './building';
import Room from './room';
import RoomCategory from './roomCategory';
import Place from './place';

export default {
    Building,
    Room,
    RoomCategory,
    Place,

    /**
     * @param {UUID} hotelId
     * @returns {Promise<HotelResource>}
     */
    show({ hotelId }) {
        return api.get(urlBuilder(ROUTES.HOTEL.API.CARD, { hotelId }))
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * Из-за косяка со слишком большими ресурсами
     * заведен отдельный ресурс JustHotelResource
     * для облегченной версии информации об отеле.
     *
     * @param {UUID} hotelId
     * @returns {Promise<JustHotelResource>}
     */
    getJustHotel({ hotelId }) {
        return api.get(urlBuilder(ROUTES.HOTEL.API.GET_JUST_HOTEL, { hotelId }))
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param hotelId
     * @return {Promise<HotelShowResource>}
     */
    getHotelShowResource({ hotelId }) {
        return api.get(urlBuilder(ROUTES.HOTEL.API.GET_SHOW_RESOURCE, { hotelId }))
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {CreateHotelRequest} data
     * @returns {Promise<any>}
     */
    store(data) {
        return api.post(urlBuilder(ROUTES.HOTEL.API.STORE), data)
            .then(response => Promise.resolve(_.get(response, 'data.data', null)));
    },

    /**
     * @param {CreateHotelRequest} data
     * @returns {Promise<HotelResource>}
     */
    update(data) {
        return api.put(urlBuilder(ROUTES.HOTEL.API.UPDATE, {
            hotelId: data.hotel_id
        }), data)
            .then(response => Promise.resolve(response.data.data));
    },

    remove({ hotelId }) {
        return api.delete(urlBuilder(ROUTES.HOTEL.API.DELETE, { hotelId }))
            .then(response => Promise.resolve(response.data.data));
    },

    /**
     * @returns {Promise<HotelResource[]>}
     */
    getAllHotels() {
        return api.get(urlBuilder(ROUTES.HOTEL.API.LIST))
            .then(response => Promise.resolve(response.data.data));
    }
};
