<template>
    <div class="hotel-list-view">
        <h1>
            Список отелей
        </h1>

        <hotel-list />
    </div>
</template>

<script>
import HotelList from '../components/HotelList';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';

export default {
    name: 'HotelListView',

    components: {
        HotelList
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        $breadcrumbs() {
            return [
                {
                    title: 'Отели',
                    route: ROUTES.HOTEL.LIST
                }
            ];
        }
    },

    methods: {}
};
</script>

<style lang="scss">
@import "@vars";

</style>
