import api from '@/api/managerApi';
import { ROUTES } from '@/enums';
import Participant from './participant';

export default {
    Participant,

    getAllEvents(params = {}) {
        const url = ROUTES.ONLINE_EVENT.API.LIST;

        return api.get(url, params)
            .then(response => {
                return Promise.resolve(response.data.data);
            });
    },

    getOnlineEvent(eventId) {
        return api.get(`/online-events/${eventId}`);
    },
    createOnlineEvent(data) {
        return api.post('/online-events', data);
    },
    updateOnlineEvent(eventId, data) {
        return api.put(`/online-events/${eventId}`, data);
    },
    deleteOnlineEvent(eventId) {
        return api.delete(`/online-events/${eventId}`);
    }
};
