import Stats from '@/modules/stats/api';

export default {
    async getParticipantsByStatus({ commit }, { eventId }) {
        let response = await Stats.getParticipantsByStatus({ eventId });
        if (response.success) {
            commit('setParticipantsByStatus', response.data);
        }
    }

};
