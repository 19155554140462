import SpecialistListView from '@/modules/specialist/views/SpecialistListView';
import { ROUTES } from '@/enums';

export default [
    {
        path: '',
        name: ROUTES.SPECIALIST.LIST,
        component: SpecialistListView
    }
];
