import api from '@/api/managerApi';

export default {

    /**
     * Получение списка доступных мест
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    getPlaces(params) {
        return api.get('/transfer/places', { params });
    },

    getPlace(placeId) {
        return api.get(`/transfer/places/${placeId}`);
    },

    createPlace(data) {
        return api.post(`/transfer/places`, data);
    },

    updatePlace(placeId, data) {
        return api.put(`/transfer/places/${placeId}`, data);
    },

    deletePlace(placeId) {
        return api.delete(`/transfer/places/${placeId}`);
    }

};
