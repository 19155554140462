<template>
    <div class="event-add-view">
        <h1>Настроить очередь</h1>
        <div class="hotel-form">
            <el-form
                ref="form"
                :rules="rules"
                :model="form"
                label-position="left"
            >
                <el-row :gutter="20">
                    <el-col :span="2">
                        <el-form-item
                            prop="title"
                            label="Номер"
                        >
                            <el-input
                                v-model="form.number"
                                @keyup.native="_onInputChange"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-button
                            :type="button.type"
                            class="submit-button"
                            :loading="button.isLoading"
                            :icon="button.icon"
                            @click="trySend"
                        >
                            Сохранить
                        </el-button>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
import { ROUTES } from '@/enums';
import breadcrumbs from '@/mixins/breadcrumbs';
import { EQueue } from '@/api';

export default {
    name: 'HotelAddView',

    mixins: [
        breadcrumbs
    ],

    data() {
        return {
            form: {},
            button: this._defaultButton()
        };
    },

    computed: {
        $breadcrumbs() {
            return [
                {
                    title: 'Электронная очередь',
                    route: ROUTES.E_QUEUE.SCREEN
                },
                {
                    title: 'Настройка очереди',
                    route: ROUTES.E_QUEUE.CONFIG
                }
            ];
        },

        rules() {
            return {
                number: [
                    { required: true, message: 'Введите новый номер' }
                ]
            };
        }

    },

    mounted() {
        this._getNumber();
    },

    methods: {
        trySend() {
            if (!this.form.number) {
                this.$message.error('Проверьте форму');
                return;
            }
            this._send();
        },

        _send() {
            this.button = this._loadingButton();
            EQueue.Screen.setNewCounter({ number: this.form.number })
                .then(() => {
                    setTimeout(() => {
                        this.button = this._successButton();
                    }, 500);
                })
                .catch((e) => {
                    this.$message.error('Не удалось сохранить значение');
                });
        },

        _getNumber() {
            EQueue.Screen.getCounter()
                .then((response) => {
                    this.form = { number: response.attributes.current_number };
                })
                .catch((e) => {
                    this.$message.error('Не удалось сохранить значение');
                });
        },

        _onInputChange() {
            this.button = this._defaultButton();
        },

        _loadingButton() {
            return {
                isLoading: true,
                icon: '',
                type: 'primary'
            };
        },

        _defaultButton() {
            return {
                isLoading: false,
                icon: '',
                type: 'primary'
            };
        },

        _successButton() {
            return {
                isLoading: false,
                icon: 'el-icon-check',
                type: 'success'
            };
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.submit-button {
  margin-top: 40px !important;
}
</style>
