import ServiceListView from '@/modules/service/views/ServiceListView';
import { ROUTES } from '@/enums';

export default [
    {
        path: '',
        name: ROUTES.SERVICE.LIST,
        component: ServiceListView
    }
];
