/**
 * @param {ParticipantResource} x
 * @returns {CreateParticipantRequest}
 */
export const mapParticipantResourceToCreateParticipantRequest = x => {
    let dateOfBirth = null;
    try {
        if (
            !(x.attributes.date_of_birth instanceof Date) &&
            typeof x.attributes.date_of_birth === 'string'
        ) {
            dateOfBirth = new Date(x.attributes.date_of_birth);
        } else {
            dateOfBirth = x.attributes.date_of_birth;
        }
    } catch (e) {

    }
    return {
        id: x.id,
        date_of_birth: dateOfBirth,
        gender: x.attributes.gender,
        name: x.attributes.name,
        surname: x.attributes.surname,
        patronymic: x.attributes.patronymic,
        user_id: x.attributes.user_id,
        event_id: x.attributes.event_id,
        parent_participant_id: x.attributes.parent_participant_id,
        phone: x.attributes.phone,
        email: x.attributes.email,
        city: x.attributes.city,
        country: x.attributes.country,
        status: x.attributes.status,
        is_small_child: x.attributes.is_small_child
    };
};
