import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import { ROUTES } from '@/enums';
import _ from 'lodash';

export default {
    /**
     * @param {UUID} eventId
     * @param {UUID[]} specialistIds
     */
    setEventSpecialists({ eventId, specialistIds }) {
        const url = urlBuilder(ROUTES.EVENT.API.SPECIALIST.SET_SPECIALISTS, { eventId });
        /** @type {SetEventSpecialistsRequest} */
        const request = { specialist_ids: specialistIds };
        return api.put(url, request)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    getEventSpecialists({ eventId }) {
        const url = urlBuilder(ROUTES.EVENT.API.SPECIALIST.INDEX, { eventId });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    }
};
