import api from '@/api/managerApi';

export default {

    getParticipantTransferGroup(participantId) {
        return api.get(`/participant/${participantId}/transfer-group`);
    },

    updateParticipantTransferGroup(data) {
        return api.post('/participant/update-transfer-group', data);
    },

    getParticipantTransferList(eventId, params) {
        return api.get(`/event/${eventId}/transfer`, { params });
    },

    updateParticipantTransfer(eventId, participantTransferId, data) {
        return api.put(`/event/${eventId}/transfer/${participantTransferId}`, data);
    },

    getTimeList(eventId) {
        return api.get(`/transfer/${eventId}/timelists`);
    },

    getTransferParticipant(participantId) {
        return api.get(`/transfer/${participantId}`);
    },

    updateGroup(eventId, groupId, params) {
        return api.put(`/transfer/${eventId}/${groupId}`, params);
    },

    updateTransferParticipant(eventId, participantId, data) {
        return api.put(`/transfer/${eventId}/${participantId}`, data);
    },

    deleteTransferParticipant(participantId, data) {
        return api.delete(`/transfer/participants/${participantId}`);
    }
};
