<template>
    <div
        v-loading="servicesAreLoading"
        class="service-list"
    >
        <el-table
            :data="preparedData"
            style="width: 100%"
        >
            <el-table-column label="Название">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.attributes.title }}
                        <i
                            class="el-icon-edit service-list__icon service-list__edit"
                            @click="_tryEdit(scope)"
                        />
                    </div>
                    <div style="font-size: .8em; color: #888;">
                        {{ scope.row.attributes.description }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                :width="120"
                align="center"
            >
                <template slot-scope="scope">
                    <i
                        class="el-icon-delete service-list__icon service-list__remove"
                        @click="_tryRemove(scope)"
                    />
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Service } from '@/api';
import showError from '@/utils/showError';

export default {
    name: 'ServiceList',

    data() {
        return {};
    },

    computed: {
        ...mapState('service/list', [
            'services',
            'servicesAreLoading'
        ]),

        preparedData() {
            return this.services.map(x => x);
        }
    },

    mounted() {
        this._loadData();
    },

    methods: {
        ...mapActions('service/list', [
            'getServices',
            'setServiceToEdit'
        ]),

        reload() {
            this._loadData();
        },

        _tryEdit({ row, $index }) {
            this.setServiceToEdit({ serviceId: row.id });
            this.$emit('edit', { service: row });
        },

        _tryRemove({ row, $index }) {
            this.$confirm('Вы уверены, что хотите удалить услугу?')
                .then(() => this._remove({ row, index: $index }))
                .catch(() => {
                });
        },

        _remove({ row }) {
            const onResponse = () => {
                this.$message.success(`Услуга удалена`);
                this.reload();
            };
            Service.remove({ serviceId: row.id })
                .then(onResponse)
                .catch(showError('Не удалось удалить услугу'));
        },

        _loadData() {
            this.getServices();
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.service-list {
    &__icon {
        font-size: 1.2em;
        cursor: pointer;
        visibility: hidden;
    }

    &__edit {
        color: #0486fe;
        margin-left: 10px;
    }

    &__remove {
        color: #eb2929;
    }

    .el-table__row {
        &:hover {
            .service-list__icon {
                visibility: visible;
            }
        }
    }
}
</style>
