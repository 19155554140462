<template>
    <el-dialog
        title="Вы уже регистрировались ранее?"
        :visible.sync="isVisible"
        width="40%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        @close="_onDialogClose"
    >
        <div v-loading="loading">
            <p>
                Вы уже зарегистрировались на фестиваль. Мы отправили на ваш телефон СМС с паролем, пожалуста, введите
                его в
                поле ниже для подтверждения.
            </p>
            <el-form
                v-if="!isLastGroupInfoVisible"
                v-model="form"
                @submit.native.prevent="_send"
            >
                <el-form-item label="Пароль из СМС">
                    <el-input
                        ref="codeInput"
                        v-model="form.code"
                        v-focus
                        autofocus
                        required
                    />
                </el-form-item>

                <el-button
                    type="primary"
                    @click="_send"
                >
                    Отправить
                </el-button>
            </el-form>

            <div v-if="isLastGroupInfoVisible">
                <br>
                <el-alert
                    :closable="false"
                    type="info"
                    show-icon
                    title="Мы нашли о вас информацию с предыдущего фестиваля"
                    description="Подтвердите её, если все верно. На следующем шаге вы сможете добавить или удалить гостей."
                />

                <participants-list
                    readonly
                    :participants="participants"
                />

                <el-row>
                    <el-col :span="12">
                        <el-button
                            type="primary"
                            plain
                            @click="_cancel"
                        >
                            Отмена
                        </el-button>
                    </el-col>
                    <el-col
                        :span="12"
                        align="right"
                    >
                        <el-button
                            type="primary"
                            @click="_confirmFoundGroup"
                        >
                            Да, это я
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
        <template slot="footer">
            <el-alert
                v-if="smsCode.show"
                :closable="false"
            >
                <slot name="title">
                    СМС код: {{ smsCode.code }}
                </slot>
            </el-alert>
        </template>
    </el-dialog>
</template>

<script>

import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import { Event } from '@/api';
import ParticipantsList from '@/modules/cabinet/components/common/ParticipantsList';
import { mapParticipantResourceToCreateParticipantRequest } from '@/modules/cabinet/mappers';

export default {
    name: 'SendSmsDialog',

    components: {
        ParticipantsList
    },

    props: {
        userId: {
            type: String,
            default: null
        },

        eventId: {
            type: String,
            default: null
        },

        smsCode: {
            type: Object,
            default: () => ({
                code: '',
                show: false
            })
        }
    },

    data() {
        return {
            form: {},
            isVisible: false,
            lastEventId: null,
            loading: false,
            /** @type {CreateParticipantRequest[]} */
            participants: []
        };
    },

    computed: {
        ...mapState('cabinet/registration', [
            'leader'
        ]),

        isLastGroupInfoVisible() {
            return this.lastEventId !== this.eventId && this.participants && this.participants.length;
        },

        phone() {
            return this.$prop('leader.phone', '');
        }

    },

    watch: {
        isVisible(visible) {
            if (visible && this.$refs.codeInput) {
                this.$nextTick(() => {
                    this.$refs.codeInput.focus();
                });
            }
        }
    },

    methods: {
        ...mapMutations('auth', [
            'setAuth'
        ]),

        ...mapMutations('cabinet/registration', [
            'setParticipants',
            'setLeader'
        ]),

        toggle() {
            this.isVisible = !this.isVisible;
        },

        open() {
            this.isVisible = true;
        },

        close() {
            this._close();
        },

        /**
         * Кнопка "Да, это я"
         * @private
         */
        _confirmFoundGroup() {
            const participants = this.participants
                .filter(x => !!x.parent_participant_id)
                .map(x => {
                    x.__previous_participant_id = x.id;
                    x.id = undefined;
                    return x;
                });
            this.setParticipants({ participants });
            const leader = this.participants
                .find(x => !x.parent_participant_id);
            leader.__previous_participant_id = leader.id;
            this.setLeader({ leader });
            this._close();
        },

        _reset() {
            this.form = {};
            this.participants = [];
        },

        _confirm() {
            this.setParticipants({
                participants: this.participants
            });
            const leader = this.participants.find(x => !x.parent_participant_id);
            this.setLeader({ leader });
        },

        _cancel() {
            this._close();
        },

        _send() {
            this.loading = true;
            this.participants = [];
            /**
             * @param {UUID} response.event_id
             * @param {ParticipantResource[]} response.participants
             */
            const onResponse = response => {
                this.participants = response.participants
                    .map(mapParticipantResourceToCreateParticipantRequest);
                this.lastEventId = response.event_id;
                if (this.lastEventId === this.eventId) {
                    // незавершенная регистрация
                    const auth = response.auth;
                    if (auth) {
                        this.setAuth({
                            accessToken: auth.access_token,
                            expiresAt: auth.expires_at,
                            user: auth.user
                        });
                        this._close();
                        this._confirm();
                    }
                } else {
                    // регистрация на основе пред. группы
                    // ждем, пока пользователь согласится
                    // с использование информации по прошлой группе
                }
            };
            Event.Registration.getLastGroupByPhone({
                code: this.form.code,
                userId: this.userId,
                eventId: this.eventId,
                phone: this.phone
            })
                .then(onResponse)
                .catch(e => {
                    let message;
                    if (_.get(e, 'errors.code')) {
                        message = e.errors.code[0];
                    } else {
                        message = _.get(e, 'message');
                    }
                    this.$message.error(message);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.loading = false;
                    }, 300);
                });
        },

        _close() {
            this.isVisible = false;
        },

        _onDialogClose() {
            this._reset();
            this.$emit('close');
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
