/**
 * Routes
 */
import participantRoutes from '@/modules/participant/routes';
import authRoutes from '@/modules/auth/routes';
import eventRoutes from '@/modules/event/routes';
import onlineEventRoutes from '@/modules/online-event/routes';
import hotelRoutes from '@/modules/hotel/routes';
import cabinetRoutes from '@/modules/cabinet/routes';
import managerRoutes from '@/modules/manager/routes';
import serviceRoutes from '@/modules/service/routes';
import specialistRoutes from '@/modules/specialist/routes';
import printRoutes from '@/modules/print/routes';
import eQueueRoutes from '@/modules/e-queue/routes';
import receptionRoutes from '@/modules/reception/routes';
import notificationsRoutes from '@/modules/notifications/routes';
import lkRoutes from '@/containers/lk/routes';
import onlineRoutes from '@/containers/lk/online/routes';
import transferRoutes from '@/modules/transfer/routes';
import { ROUTES } from '@/enums';
import ROLES from '@/modules/manager/enums/roles';

/**
 * Components
 */
const Home = () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue');
const ParticipantModule = () => import(/* webpackChunkName: "ParticipantModule" */ '@/modules/participant/ParticipantModule.vue');
const AuthModule = () => import(/* webpackChunkName: "AuthModule" */ '@/modules/auth/AuthModule.vue');
const EventModule = () => import(/* webpackChunkName: "EventModule" */ '@/modules/event/EventModule.vue');
const OnlineEventModule = () => import(/* webpackChunkName: "EventModule" */ '@/modules/online-event/OnlineEventModule.vue');
const HotelModule = () => import(/* webpackChunkName: "HotelModule" */ '@/modules/hotel/HotelModule.vue');
const CabinetModule = () => import(/* webpackChunkName: "CabinetModule" */ '@/modules/cabinet/CabinetModule.vue');
const ManagerModule = () => import(/* webpackChunkName: "ManagerModule" */ '@/modules/manager/ManagerModule');
const ServiceModule = () => import(/* webpackChunkName: "ServiceModule" */ '@/modules/service/ServiceModule');
const SpecialistModule = () => import(/* webpackChunkName: "SpecialistModule" */ '@/modules/specialist/SpecialistModule');
const PrintModule = () => import(/* webpackChunkName: "PrintModule" */ '@/modules/print/PrintModule');
const EQueueModule = () => import(/* webpackChunkName: "EQueueModule" */ '@/modules/e-queue/EQueueModule');
const EQueueScreenModule = () => import(/* webpackChunkName: "EQueueScreenModule" */ '@/modules/e-queue/EQueueScreenModule');
const ReceptionModule = () => import(/* webpackChunkName: "ReceptionModule" */ '@/modules/reception/ReceptionModule');
const NotificationsModule = () => import(/* webpackChunkName: "NotificationsModule" */ '@/modules/notifications/NotificationsModule.vue');
const TransferModule = () => import(/* webpackChunkName: "NotificationsModule" */ '@/modules/transfer/TransferModule');

const ClientLk = () => import(/* webpackChunkName: "LkIndex" */ '@/containers/lk/Index');
const OnlineLk = () => import(/* webpackChunkName: "OnlineIndex" */ '@/containers/lk/online/Index');

/**
 * Роли досутпа для операторов программы
 * @type {(string)[]}
 */
const managerAccessRoles = [ROLES.ADMIN, ROLES.SENIOR_MANAGER, ROLES.MANAGER];

export default [
    {
        path: '/',
        name: ROUTES.HOME,
        component: Home,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/auth',
        name: 'auth',
        component: AuthModule,
        children: authRoutes
    },

    {
        path: '/participants',
        component: ParticipantModule,
        children: participantRoutes,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/print',
        component: PrintModule,
        children: printRoutes,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/events',
        name: 'events',
        component: EventModule,
        children: eventRoutes,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/online-events',
        name: 'onlineEvents',
        component: OnlineEventModule,
        children: onlineEventRoutes,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/transfer',
        name: 'transfer',
        component: TransferModule,
        children: transferRoutes,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/hotels',
        component: HotelModule,
        children: hotelRoutes,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/cabinet',
        name: 'cabinet',
        component: CabinetModule,
        children: cabinetRoutes
    },

    {
        path: '/managers',
        component: ManagerModule,
        children: managerRoutes,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/service',
        component: ServiceModule,
        children: serviceRoutes,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/specialist',
        component: SpecialistModule,
        children: specialistRoutes,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/e-queue-screen',
        component: EQueueScreenModule,
        name: ROUTES.E_QUEUE.SCREEN,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/e-queue',
        component: EQueueModule,
        children: eQueueRoutes,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/reception',
        component: ReceptionModule,
        children: receptionRoutes,
        meta: {
            requiresAuth: true,
            accessRoles: ['admin', 'manager', 'hotel']
        }
    },

    {
        path: '/notifications',
        component: NotificationsModule,
        children: notificationsRoutes,
        meta: {
            requiresAuth: true,
            accessRoles: managerAccessRoles
        }
    },

    {
        path: '/lk',
        component: ClientLk,
        children: lkRoutes
    },

    {
        path: '/online/lk',
        component: OnlineLk,
        children: onlineRoutes
    }
];
