<template>
    <div class="print-checking-list">
        <div class="print-checking-list__title">
            Бланк заселения
        </div>
        <div
            v-if="listItems"
        >
            <template
                v-for="(value, key) in listItems"
            >
                <div
                    v-for="(listItem, listItemKey) in value"
                    :key="listItemKey"
                    class="list-item"
                >
                    <div class="list-item__fullname">
                        {{ listItem.fullname }}
                    </div>
                    <div class="list-item__check list-item-check">
                        <span class="list-item-check__icon1"><i class="far fa-building" /></span>
                        <span class="list-item-check__text">{{ listItem.building }}, </span>
                        <span class="list-item-check__icon">№</span>
                        <span class="list-item-check__text">{{ listItem.room }}</span>
                    </div>
                </div>
                <div
                    :key="key"
                    class="list-divider"
                />
            </template>
        </div>

        <div v-if="!listItems">
            Нет данных...
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import JSONStorage from '@/utils/storage';

export default {
    name: 'CheckingList',
    data() {
        return {
            listItems: null
        };
    },
    computed: {
        ...mapState('event/participants', [
            'selectedParticipants'
        ]),

        checkingList() {
            return JSONStorage.get('checkingList').data;
        }
    },
    created() {
        this.listItems = this.checkingList;
    },
    mounted() {
        if (this.listItems) {
            setTimeout(() => window.print(), 1000);
        }
        this.clearStorage();
    },
    destroyed() {
        this.clearStorage();
    },
    methods: {
        clearStorage() {
            JSONStorage.remove('checkingList');
        }
    }
};
</script>

<style lang="scss">

.print-checking-list {
  margin: 0 auto;
  padding: 35px 0;
  max-width: 570px;

  .list-divider {
    height: 30px;

    &:last-child {
      height: 0;
    }
  }

  .list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    &__fullname {
      font-size: 20px;
      text-transform: capitalize;
    }

    &-check {
      &__icon1 {
        display: inline-block;
        font-size: 22px;
        padding-right: 5px;
        position: relative;
        bottom: 3px;

      }

      &__icon {
        font-size: 28px;
        padding-right: 5px;
      }

      &__text {
        font-size: 48px;
      }
    }

  }

  &__title {
    margin-bottom: 30px;
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    letter-spacing: 3px;
    font-size: 24px;
    text-transform: uppercase;
  }
}
</style>
