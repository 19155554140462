export default {
    LIST: 'hotel.list',
    ADD: 'hotel.add',
    /** {hotelId} */
    EDIT: 'hotel.edit',
    CARD: {
        /** {hotelId} */
        SHOW: 'hotel.card',
        BUILDING: {
            /** {hotelId} */
            ADD: 'hotel.card.building.add',
            /** {hotelId} {buildingId} */
            EDIT: 'hotel.card.building.edit',
            /** {hotelId} {buildingId} */
            SHOW: 'hotel.card.building.show',

            ROOM: {
                /** {hotelId} {buildingId} */
                ADD: 'hotel.card.room.add',
                /** {hotelId} {buildingId} {roomId} */
                EDIT: 'hotel.card.room.edit',
                /** {hotelId} {buildingId} {roomId} */
                SHOW: 'hotel.card.room.show'
            }
        }
    },

    API: {
        /** GET */
        LIST: 'hotel',
        /** GET */
        CARD: 'hotel/{hotelId}',
        /** GET */
        GET_JUST_HOTEL: 'just-hotel/{hotelId}',
        /** GET */
        GET_SHOW_RESOURCE: 'hotel-show-resource/{hotelId}',
        /** PUT */
        UPDATE: 'hotel/{hotelId}',
        /** POST */
        STORE: 'hotel',
        /** DELETE */
        DELETE: 'hotel/{hotelId}',

        ROOM_CATEGORY: {
            /** GET */
            LIST: 'room-category'
        },

        BUILDING: {
            /** POST */
            STORE: 'building',
            /** PUT */
            UPDATE: 'building/{buildingId}',
            /** GET */
            SHOW: 'building/{buildingId}',
            /** DELETE */
            DELETE: 'building/{buildingId}',

            ROOM: {
                /** POST */
                STORE: 'room',

                /** PUT */
                UPDATE: 'room/{roomId}',

                /** GET */
                SHOW: 'room/{roomId}',

                /** DELETE */
                DELETE: 'room/{roomId}',

                /** GET {roomId} */
                PLACES: 'room/{roomId}/places',

                PLACE: {
                    /** GET {placeId} */
                    SHOW: 'place/{placeId}',
                    /** POST */
                    STORE: 'place',
                    /** PUT {placeId} */
                    UPDATE: 'place/{placeId}',
                    /** DELETE {placeId} */
                    DESTROY: 'place/{placeId}',

                    /** POST */
                    CHECK_IN: 'place/{placeId}/checkin/{participantId}',
                    /** POST */
                    CHECK_OUT: 'place/checkout',
                    /** POST */
                    RE_CHECK_IN: '/place/{placeId}/recheckin/{participantId}/notify-reception/{notifyReception}'
                }

            }
        }
    }
};
