<template>
    <div class="registration-payment">
        <register-header-message />
        <div class="registration-payment__content">
            <participants-list
                payment-info
                :cost="cost"
                :participants="participants"
                :leader="leader"
                :readonly="isRegistered"
                @remove="_onParticipantRemove"
                @edit="_onParticipantEdit"
            />
            <div class="registration-payment__total">
                Итого: {{ totalSum }}
                <i class="fas fa-ruble-sign" />
            </div>

            <div v-if="areActionsAvailable">
                <el-row :gutter="16">
                    <el-col :span="12">
                        <el-button
                            plain
                            type="primary"
                            @click="_openAddForm"
                        >
                            <i class="el-icon-plus" />
                            Добавить еще участника
                        </el-button>
                    </el-col>
                    <el-col
                        :span="12"
                        align="right"
                    >
                        <el-button
                            type="primary"
                            :disabled="isPrepayButtonDisabled"
                            @click="_onPrepayBtnClick"
                        >
                            {{ prepayButtonText }}
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </div>

        <group-member-form
            ref="dialog"
            :index="participantToEditIndex"
            :participant="participantToEdit"
            @added="_onParticipantAdded"
        />

        <registration-page-info-modal
            :is-visible="isInfoModalVisible"
            :type="infoModalType"
        />
    </div>
</template>

<script>
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import { Event } from '@/api';
import { PARTICIPANTS } from '@/enums';
import GroupMemberForm from '@/modules/cabinet/components/register/GroupMemberForm';
import ParticipantsList from '@/modules/cabinet/components/common/ParticipantsList';
import RegistrationPageInfoModal from '@/modules/cabinet/components/register/RegistrationPageInfoModal';
import RegisterHeaderMessage from '@/modules/cabinet/components/register/RegisterHeaderMessage';

export default {
    name: 'RegisterPayment',

    components: {
        RegisterHeaderMessage,
        RegistrationPageInfoModal,
        ParticipantsList,
        GroupMemberForm
    },

    data() {
        return {
            participantToEdit: null,
            participantToEditIndex: -1,
            isInfoModalVisible: false,
            infoModalType: 'prepay',
            isPrepayButtonDisabled: false,
            prepayButtonText: 'Внести предоплату'
        };
    },

    computed: {
        ...mapState('auth', [
            'user'
        ]),

        ...mapState('cabinet', [
            'event'
        ]),

        ...mapState('cabinet/registration', [
            'participants',
            'leader'
        ]),

        ...mapState('enums', [
            'sexOptions'
        ]),

        totalSum() {
            return this.participantsWithoutSmallChildren.length * this.cost;
        },

        participantsWithoutSmallChildren() {
            let participants = _.defaultTo(this.participants, []);
            return participants.filter(value => {
                return value.is_small_child === false;
            });
        },

        cost() {
            return parseFloat(this.$prop('event.attributes.prepay', 0));
        },

        isEmpty() {
            return !this.participants || !this.participants.length;
        },

        eventId() {
            return this.$prop('event.id');
        },

        leaderId() {
            return this.$prop('leader.id', null);
        },

        leaderUserId() {
            return this.$prop('leader.user_id', null);
        },

        leaderStatus() {
            return this.$prop('leader.status', 'NEW');
        },

        /**
         * Авторизованный сейчас участник -- лидер этой группы.
         */
        isGroupLeader() {
            return this.$prop('user.id') === this.leaderUserId;
        },

        isNew() {
            /**
             * Если статуса нет, считаем пользователя новым.
             */
            return this.$prop('leader.status', PARTICIPANTS.STATUSES.NEW) === PARTICIPANTS.STATUSES.NEW;
        },

        isRegistered() {
            return !this.isNew;
        },

        areActionsAvailable() {
            return this.isGroupLeader && this.isNew;
        }
    },

    mounted() {
        this._checkLeaderStatus();
    },

    methods: {
        ...mapMutations('cabinet/registration', [
            'removeParticipantByIndex',
            'addParticipant'
        ]),

        _checkLeaderStatus() {
            this.$nextTick(() => {
                if (this.leaderStatus === PARTICIPANTS.STATUSES.PREPAID) {
                    this._showPrepaidPopup();
                }
            });
        },

        _showPrepaidPopup() {
            this.infoModalType = 'prepay';
            this.isInfoModalVisible = true;
        },

        /**
         * Участник добавлен в стейт, пробуем добавить его в группу
         * на сервере, если не удастся -- удаляем из стейта.
         *
         * @param {CreateParticipantRequest} participant
         * @private
         */
        _onParticipantAdded({ participant }) {
            /**
             * @param {CreateParticipantRequest} participant
             */
            const onResponse = participant => {
                this.$message.success('Новый участник группы создан');
                this.addParticipant({
                    participant: _.get(participant, 'attributes', [])
                });
            };
            Event.Registration.addGroupParticipant({
                eventId: this.eventId,
                parentParticipantId: this.leaderId,
                participant
            })
                .then(onResponse)
                .catch(e => {
                    const message = _.get(e, 'message', 'Не удалось добавить участника');
                    this.$message.error(message);
                    const index = _.findIndex(this.participants, participant);
                    this.removeParticipantByIndex({ index });
                });
        },

        _onPrepayBtnClick() {
            // Отключаем кнопку и выжидаем timeout перед редиректом на систему оплаты.
            this.isPrepayButtonDisabled = true;
            this.prepayButtonText = 'Переход к оплате...';
            setTimeout(() => {
                this._prepay();
            }, 500);
        },

        _prepay: _.debounce(function() {
            /** @param {RedirectResource} redirect */
            const onResponse = redirect => {
                window.location.href = redirect.attributes.url;
            };
            Event.Registration.prepay({
                eventId: this.eventId,
                participantId: this.leaderId
            })
                .then(onResponse)
                .catch(e => {
                    const message = _.get(e, 'message', 'Не удалось выполнить предоплату');
                    this.$message(message);
                });
        }, 1000),

        _openAddForm() {
            this.participantToEdit = null;
            this.participantToEditIndex = -1;
            this.$refs.dialog.open();
        },

        _onParticipantRemove({ index, participant }) {
            if (participant.id) {
                Event.Registration.deleteGroupParticipant({
                    participantId: participant.id,
                    eventId: this.eventId,
                    parentParticipantId: this.leaderId
                })
                    .then(() => {
                        this.removeParticipantByIndex({ index });
                    })
                    .catch(e => {
                        const message = _.get(e, 'message', 'Не удалось удалить участника');
                        this.$message.error(message);
                    });
            } else {
                this.removeParticipantByIndex({ index });
            }
        },

        _onParticipantEdit({ index, participant }) {
            this.participantToEdit = participant;
            this.participantToEditIndex = index;
            this.$refs.dialog.open();
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.registration-payment {
  &__content {
    max-width: 585px;
    margin: 20px auto;
  }

  &__total {
    margin: 20px 0;
    text-align: right;
    font-size: 20px;
  }
}
</style>
