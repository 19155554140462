<template>
    <el-dialog
        title="Место"
        :visible.sync="isVisible"
        width="60%"
        @close="_onClose"
    >
        <place-form
            :place-id="placeId"
            :room-id="roomId"
            @created="_onCreated"
            @updated="_onUpdated"
            @cancel="_onCancel"
        />
    </el-dialog>
</template>

<script>
import PlaceForm from '@/modules/hotel/components/PlaceForm';

export default {
    name: 'PlaceFormDialog',

    components: {
        PlaceForm
    },

    props: {
        placeId: {
            type: String,
            default: null
        },

        roomId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            isVisible: false
        };
    },

    methods: {
        toggle() {
            this.isVisible = !this.isVisible;
        },

        open() {
            this.isVisible = true;
        },

        close() {
            this.isVisible = false;
        },

        _onClose() {
            this.$emit('close');
        },

        _onUpdated() {
            this.$emit('updated');
        },

        _onCreated() {
            this.$emit('created');
        },

        _onCancel() {
            this.$emit('cancelled');
            this.close();
        }
    }
};
</script>
