<template>
    <div class="guest-list">
        <h3>
            Список гостей, Фестиваль "Благость"
        </h3>

        <el-table
            :data="list"
            border
            style="width: 100%"
        >
            <el-table-column
                prop="name"
                label="Имя"
            />
            <el-table-column
                prop="phone"
                label="Телефон"
            />
            <el-table-column
                width="180px"
                align="center"
            >
                <template>
                    <el-button
                        type="primary"
                        icon="el-icon-edit"
                        circle
                    />
                    <el-button
                        type="info"
                        icon="el-icon-message"
                        circle
                    />
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        circle
                    />
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'ParticipantList',

    computed: {
        list() {
            return [
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                },
                {
                    name: 'Василий П.',
                    phone: '+7 (999) 999-99-99'
                }
            ];
        }
    }
};
</script>
