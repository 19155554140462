import ManagerApi from '@/modules/manager/api';

export default {

    async getManagerList({ commit }) {
        let response = await ManagerApi.getManagerList();
        if (response.success) {
            commit('setManagerList', response.data);
        } else {
            return response.error;
        }
    },

    async addManager({ commit }, data) {
        let response = await ManagerApi.addManager(data);
        return response;
    },
    async updateManager({ commit }, { managerId, data }) {
        let response = await ManagerApi.updateManager({ managerId, data });
        return response;
    },
    async getManager({ commit }, managerId) {
        let response = await ManagerApi.getManager(managerId);
        return response;
    }
};
