<template>
    <div
        v-if="isReady"
        class="hotel-building-edit-view"
    >
        <h1 class="app-h1">
            Редактированеи копуса отеля "{{ hotelTitle }}"
        </h1>

        <hotel-building-form
            :hotel-id="hotelId"
            :building-id="buildingId"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';
import HotelBuildingForm from '../components/HotelBuildingForm';

export default {
    name: 'HotelBuildingEditView',

    components: {
        HotelBuildingForm
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        ...mapState('hotel/card', [
            'hotel',
            'buildingToEdit'
        ]),

        isReady() {
            return !!this.buildingId;
        },

        hotelId() {
            return this.$route.params.hotelId;
        },

        buildingId() {
            return this.$route.params.buildingId;
        },

        $breadcrumbs() {
            const bc = [
                {
                    title: 'Отели',
                    route: ROUTES.HOTEL.LIST
                }
            ];
            if (this.hotelTitle) {
                bc.push({
                    title: this.hotelTitle,
                    route: ROUTES.HOTEL.CARD.SHOW,
                    params: {
                        hotelId: this.hotelId
                    }
                });
                if (this.buildingToEdit) {
                    bc.push({
                        title: this.buildingToEdit.attributes.title,
                        route: ROUTES.HOTEL.CARD.BUILDING.SHOW,
                        params: {
                            hotelId: this.hotelId,
                            buildingId: this.buildingId
                        }
                    });
                }
            }
            bc.push({
                title: 'Редактирование корпуса',
                route: ROUTES.HOTEL.CARD.BUILDING.EDIT,
                params: {
                    hotelId: this.hotelId,
                    buildingId: this.buildingId
                }
            });
            return bc;
        },

        hotelTitle() {
            return this.$prop('hotel.attributes.title');
        }
    },

    mounted() {
        if (!this.hotel) {
            this._loadHotel();
        }
    },

    methods: {
        ...mapActions('hotel/card', [
            'getHotel'
        ]),

        _loadHotel() {
            this.getHotel({
                hotelId: this.hotelId
            });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
