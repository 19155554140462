import _ from 'lodash';
import api from '@/api/userApi';
import urlBuilder from '@/utils/urlBuilder';
import { ROUTES } from '@/enums';

export default {
    /**
     * @param {UUID} eventId
     * @returns {Promise<EventStatusResource>}
     */
    getEventStatus({ eventId }) {
        const url = urlBuilder(ROUTES.EVENT.API.REGISTRATION.GET_EVENT_STATUS, { eventId });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {String} phone
     * @param {UUID} eventId
     * @return {Promise<EventPhoneStatusResource>}
     */
    checkPhone({ phone, eventId }) {
        const url = urlBuilder(ROUTES.EVENT.API.REGISTRATION.CHECK_PHONE, { eventId });
        return api.post(url, { phone })
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param eventId
     * @param participantId
     * @returns {Promise<RedirectResource>}
     */
    prepay({ eventId, participantId }) {
        const url = urlBuilder(ROUTES.EVENT.API.REGISTRATION.PREPAY, {
            eventId,
            participantId
        });
        return api.post(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} eventId
     * @param {UUID} leaderId
     */
    getPaymentInfoForGroup({ eventId, leaderId }) {
        const url = urlBuilder(ROUTES.EVENT.API.REGISTRATION.GET_PAYMENT_INFO_FOR_GROUP, {
            eventId,
            leaderId
        });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * Редактирование участника в момент регистрации.
     *
     * @param eventId
     * @param participantId
     * @param parentParticipantId
     * @param {CreateParticipantRequest} participant
     * @returns {Promise<T>}
     */
    updateParticipantInfo({ eventId, participantId, parentParticipantId, participant }) {
        const url = urlBuilder(ROUTES.EVENT.API.REGISTRATION.UPDATE_GROUP_PARTICIPANT, {
            eventId,
            participantId,
            parentParticipantId
        });
        return api.put(url, participant)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param eventId
     * @param parentParticipantId
     * @param participant
     * @returns {Promise<any | never>}
     */
    addGroupParticipant({ eventId, parentParticipantId, participant }) {
        const url = urlBuilder(ROUTES.EVENT.API.REGISTRATION.ADD_GROUP_PARTICIPANT, {
            eventId,
            parentParticipantId
        });
        return api.post(url, participant)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    getLastGroupByPhone({ eventId, code, phone, userId }) {
        const url = urlBuilder(ROUTES.EVENT.API.REGISTRATION.GET_LAST_GROUP_BY_PHONE, { eventId });
        return api.post(url, {
            code,
            phone,
            userId
        })
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * Получение последней группы для авторизованного участника.
     *
     * @param eventId
     * @return {Promise<Object>}
     */
    getLastGroup({ eventId }) {
        const url = urlBuilder(ROUTES.EVENT.API.REGISTRATION.GET_LAST_GROUP, { eventId });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} eventId
     * @param {CreateParticipantRequest} participant
     * @param {CreateParticipantRequest[]} participants
     * @returns {Promise<RegistrationResponseResource>}
     */
    register({ eventId, participant, participants }) {
        const url = urlBuilder(ROUTES.EVENT.API.REGISTRATION.REGISTER, { eventId });
        return api.post(url, {
            participant,
            participants
        })
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    getGroupParticipants({ parentParticipantId, eventId }) {
        const url = urlBuilder(ROUTES.EVENT.API.REGISTRATION.GROUP_PARTICIPANTS, {
            eventId,
            parentParticipantId
        });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    deleteGroupParticipant({ participantId, eventId, parentParticipantId }) {
        const url = urlBuilder(ROUTES.EVENT.API.REGISTRATION.DELETE_GROUP_PARTICIPANT, {
            eventId,
            parentParticipantId,
            participantId
        });
        return api.delete(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    }
};
