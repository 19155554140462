import Hotel from '../../hotel/api';
import Event from '../api';

export default {
    /**
     * Заселение участника в место в номере.
     *
     * @param commit
     * @param {UUID} participantId
     * @param {UUID} placeId
     * @returns {Promise<{}>}
     */
    async checkIn({ commit }, { participantId, placeId }) {
        let response = await Hotel.Place.checkIn({ placeId, participantId });
        if (response.success) {
            return {
                success: true,
                message: 'Гость успешно заселен'
            };
        } else {
            return {
                success: false,
                message: response.message
            };
        }
    },

    async reCheckIn({ commit }, { participantId, placeId, notifyReception }) {
        let response = await Hotel.Place.reCheckIn({ placeId, participantId, notifyReception });
        if (response.success) {
            return {
                success: true,
                message: 'Гость успешно переселен'
            };
        } else {
            return {
                success: false,
                message: response.message
            };
        }
    },

    getEvent({ dispatch }, { eventId }) {
        return dispatch('getResource', { eventId });
    },

    getResource({ commit }, { eventId }) {
        return new Promise((resolve, reject) => {
            Event.show({ eventId })
                .then(event => {
                    commit('setResource', { event });
                    resolve({ event });
                })
                .catch(reject);
        });
    },

    /**
     * Получает список фестивалей
     *
     * @param commit
     * @returns {Promise<unknown>}
     */
    getEventsToMove({ commit }, { eventId }) {
        return new Promise((resolve, reject) => {
            Event.getEventsToMove({ eventId })
                .then(events => commit('setEventsToMove', events))
                .catch(reject);
        });
    }

};
