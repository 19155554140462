import { ROUTES } from '@/modules/transfer/enums';

const TransferPlacesAddView = () => import(/* webpackChunkName: "TransferPlacesAddView" */ '@/modules/transfer/views/TransferPlacesAddView');
const TransferPlacesEditView = () => import(/* webpackChunkName: "TransferPlacesEditView" */ '@/modules/transfer/views/TransferPlacesUpdateView');
const TransferPlacesListView = () => import(/* webpackChunkName: "TransferPlacesListView" */ '@/modules/transfer/views/TransferPlacesListView');

export default [
    {
        path: 'places',
        name: ROUTES.TRANSFER_PLACES.LIST,
        component: TransferPlacesListView
    },
    {
        path: 'places/add',
        name: ROUTES.TRANSFER_PLACES.ADD,
        component: TransferPlacesAddView
    },
    {
        path: 'places/:transferPlaceId',
        name: ROUTES.TRANSFER_PLACES.EDIT,
        component: TransferPlacesEditView
    }
];
