<template>
    <div
        class="room-place"
        :class="classes"
    >
        <el-card shadow="none">
            <el-row>
                <el-col :span="4">
                    <bed-info-icon
                        :type="bedType"
                        :title="bedTypeTitle"
                    />
                </el-col>
                <el-col :span="14">
                    <span>
                        {{ comment }}&nbsp;
                    </span>
                    <span class="room-place__text">
                        {{ text }}
                    </span>
                </el-col>
                <el-col
                    :span="6"
                    align="right"
                >
                    <i
                        class="room-place__icon-edit el-icon-edit"
                        @click="_edit"
                    />
                    <i
                        class="room-place__icon-remove el-icon-delete"
                        @click="_remove"
                    />
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import BedInfoIcon from '@/modules/hotel/components/common/BedInfoIcon';

export default {
    name: 'RoomPlace',

    components: {
        BedInfoIcon
    },

    props: {
        /**
         * @type {PlaceResource}
         */
        place: {
            type: Object,
            default: null
        }
    },

    computed: {
        isSecondary() {
            return !!this.$prop('place.attributes.is_secondary');
        },

        bedType() {
            return this.$prop('place.attributes.bed_type');
        },

        bedTypeTitle() {
            return this.$prop('place.attributes.bed_type_info.title');
        },

        comment() {
            return this.$prop('place.attributes.comment');
        },

        text() {
            return this.isSecondary
                ? 'Дополнительный номер'
                : '';
        },

        classes() {
            return {
                'room-place--is-secondary': this.isSecondary
            };
        }
    },

    methods: {
        _remove() {
            this.$emit('remove', { place: this.place });
        },

        _edit() {
            this.$emit('edit', { place: this.place });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.room-place {
    .el-row {
        margin: 0;
    }

    &__text {
        font-style: italic;
        font-size: .8em;
        display: block;
    }

    &--is-secondary {
        .el-card__body {
            background: #f6f6f6;
        }
    }

    &__icon-remove {
        cursor: pointer;
        color: $color-error;
    }

    &__icon-edit {
        cursor: pointer;
        color: $color-primary;
        margin-right: 5px;
    }
}
</style>
