<template>
    <div class="hotel-building-room-view">
        <hotel-building-room-form
            :building-id="buildingId"
            :hotel-id="hotelId"
            :room-id="roomId"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';
import HotelBuildingRoomForm from '../components/HotelBuildingRoomForm';

export default {
    name: 'HotelBuildingRoomEditView',

    components: {
        HotelBuildingRoomForm
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        ...mapState('hotel/card', [
            'hotel',
            'buildingToView',
            'roomToEdit'
        ]),

        $breadcrumbs() {
            const bc = [
                {
                    title: 'Отели',
                    route: ROUTES.HOTEL.LIST
                }
            ];
            if (this.hotel) {
                bc.push({
                    title: this.hotel.attributes.title,
                    route: ROUTES.HOTEL.CARD.SHOW,
                    params: {
                        hotelId: this.hotelId
                    }
                });
            }
            if (this.buildingToView) {
                bc.push({
                    title: `Корпус: ${this.buildingToView.attributes.title}`,
                    route: ROUTES.HOTEL.CARD.BUILDING.SHOW,
                    params: {
                        hotelId: this.hotelId,
                        buildingId: this.buildingId
                    }
                });
            }
            bc.push({
                title: `Номер: ${this.$prop('roomToEdit.attributes.title')}`,
                route: ROUTES.HOTEL.CARD.BUILDING.ROOM.EDIT,
                params: {
                    hotelId: this.hotelId,
                    buildingId: this.buildingId,
                    roomId: this.roomId
                }
            });

            return bc;
        },

        hotelId() {
            return this.$route.params.hotelId;
        },

        buildingId() {
            return this.$route.params.buildingId;
        },

        roomId() {
            return this.$route.params.roomId;
        },

        roomTitle() {
            return this.$prop('roomToEdit.attributes.title');
        }
    },

    mounted() {
        if (!this.hotel || this.hotel.id !== this.hotelId) {
            this._getHotel();
        }
        if (!this.buildingToView || this.buildingToView.id !== this.buildingId) {
            this._getBuilding();
        }
    },

    methods: {
        ...mapActions('hotel/card', [
            'getRoom',
            'getBuilding',
            'getHotel'
        ]),

        _getHotel() {
            if (!this.hotelId) {
                return;
            }
            this.getHotel({
                hotelId: this.hotelId
            });
        },

        _getBuilding() {
            if (!this.buildingId || !this.hotelId) {
                return;
            }
            this.getBuilding({
                hotelId: this.hotelId,
                buildingId: this.buildingId
            });
        }
    }
};
</script>
