<template>
    <div class="event-add-view">
        <h1>
            Кабинет менеджера
        </h1>

        <div v-if="offLine">
            Вы не в линии
        </div>
        <div v-else>
            Текущий обслуживаемый номер: <strong>{{ number }}</strong>
        </div>

        <el-row :gutter="20">
            <el-col :span="6">
                <el-button
                    :type="button.type"
                    class="submit-button"
                    :loading="button.isLoading"
                    :icon="button.icon"
                    @click="_callCustomer"
                >
                    {{ offLine ? 'Встать в линию' : 'Пригласить следующего посетителя' }}
                </el-button>
            </el-col>
            <el-col
                v-if="!offLine"
                :span="4"
            >
                <el-button
                    :type="outButton.type"
                    class="submit-button"
                    :loading="outButton.isLoading"
                    :icon="outButton.icon"
                    @click="_quitLine"
                >
                    Выйти с линии
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { ROUTES } from '@/enums';
import breadcrumbs from '@/mixins/breadcrumbs';
import { EQueue } from '@/api';

export default {
    name: 'EQueueManagerView',

    mixins: [
        breadcrumbs
    ],

    data() {
        return {
            button: this._defaultButton(),
            outButton: this._outButton(),
            number: null
        };
    },

    computed: {
        $breadcrumbs() {
            return [
                {
                    title: 'Электронная очередь',
                    route: ROUTES.E_QUEUE.SCREEN
                },
                {
                    title: 'Кабинет менеджера',
                    route: ROUTES.E_QUEUE.MANAGER
                }
            ];
        },
        offLine() {
            return !this.number;
        }
    },

    mounted() {
        this._loadCurrentNumber();
    },

    methods: {

        _callCustomer() {
            EQueue.Screen.callCustomer()
                .then((response) => {
                    this.number = response.attributes ? response.attributes.number : null;
                })
                .catch((e) => {
                    this.$message.error('Не удалось вызвать человека');
                });
        },

        _quitLine() {
            EQueue.Screen.quitLine()
                .then(() => {
                    this.number = null;
                })
                .catch((e) => {
                    this.$message.error('Не удалось выйти с линии');
                });
        },

        _loadCurrentNumber: function() {
            EQueue.Screen.getCustomer()
                .then((response) => {
                    this.number = response.attributes ? response.attributes.number : null;
                })
                .catch(e => {
                    this.$message.error('Не удалось получить информацию о текущей очереди');
                });
        },

        _defaultButton() {
            return {
                isLoading: false,
                icon: '',
                type: 'primary'
            };
        },

        _outButton() {
            return {
                isLoading: false,
                icon: '',
                type: 'info'
            };
        }
    }
};
</script>

<style lang="scss">
    @import "@vars";

</style>
