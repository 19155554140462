<template>
    <div class="service-list-view">
        <h1 class="app-h1">
            Список услуг
        </h1>

        <el-button
            type="primary"
            @click="_openAddForm"
        >
            Добавить услугу
        </el-button>

        <service-list
            ref="list"
            @edit="_onServiceEdit"
        />
        <service-form-popup
            ref="form"
            @added="_onServiceAdded"
            @updated="_onServiceUpdated"
        />
    </div>
</template>

<script>
import ServiceList from '../components/ServiceList';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';
import ServiceFormPopup from '@/modules/service/components/ServiceFormPopup';

export default {
    name: 'ServiceListView',

    components: {
        ServiceFormPopup,
        ServiceList
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        $breadcrumbs() {
            return [
                {
                    title: 'Услуги',
                    route: ROUTES.SERVICE.LIST
                }
            ];
        }
    },

    methods: {
        _onServiceEdit() {
            this.$refs.form.open();
        },

        _openAddForm() {
            this.$refs.form.open();
        },

        /**
         * @param {ServiceResource} service
         * @private
         */
        _onServiceAdded({ service }) {
            this.$message.success(`Услуга "${service.attributes.title}" добавлена успешно`);
            this.$refs.list.reload();
        },

        _onServiceUpdated({ service }) {
            this.$message.success(`Услуга "${service.attributes.title}" обновлена успешно`);
            this.$refs.list.reload();
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
