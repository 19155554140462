import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import ROUTES from '@/enums/routes';
import _ from 'lodash';

export default {
    store(data) {
        return api.post(urlBuilder(ROUTES.HOTEL.API.BUILDING.ROOM.STORE), data)
            .then(response => Promise.resolve(response.data.data));
    },

    /**
     * @param {CreateRoomRequest} data
     * @returns {Promise<RoomResource>}
     */
    update(data) {
        return api.put(urlBuilder(ROUTES.HOTEL.API.BUILDING.ROOM.UPDATE, {
            roomId: data.room_id
        }), data)
            .then(response => Promise.resolve(response.data.data));
    },

    remove({ roomId }) {
        return api.delete(urlBuilder(ROUTES.HOTEL.API.BUILDING.ROOM.DELETE, { roomId }))
            .then(response => Promise.resolve(response.data.data));
    },

    /**
     * @param roomId
     * @param {Boolean} withParticipants
     * @returns {Promise<RoomResource>}
     */
    show({ roomId, withParticipants }) {
        const params = {
            with: []
        };
        if (withParticipants) {
            params.with.push('PlaceResource.Participants');
        }
        return api.get(urlBuilder(ROUTES.HOTEL.API.BUILDING.ROOM.SHOW, { roomId }), { params })
            .then(response => Promise.resolve(response.data.data));
    },

    /**
     * @param {UUID} roomId
     * @returns {Promise<PlaceResource[]>}
     */
    getPlaces({ roomId }) {
        const url = urlBuilder(ROUTES.HOTEL.API.BUILDING.ROOM.PLACES, {
            roomId
        });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    }

};
