import { TransferWidget } from '@/api/index';
const defaultState = {
    departureList: [],
    departureLoading: false,
    transferGroup: {}
};

export default {
    namespaced: true,
    state: { ...defaultState },
    getters: {
        departureLoading: state => state.departureLoading,
        departureList: state => state.departureList,
        transferGroup: state => state.transferGroup
    },
    mutations: {
        setDepartureLoading(state, { loading }) {
            state.departureLoading = loading;
        },
        setDepartureList(state, { data }) {
            state.departureList = data;
        },
        setTransferGroup(state, { data }) {
            state.transferGroup = data;
        }
    },
    actions: {
        fetchDepartureList({ commit }, { search }) {
            commit('setDepartureLoading', { loading: true });
            return TransferWidget.fetchDepartureList(search)
                .then(response => {
                    commit('setDepartureList', { data: response.data });
                })
                .catch(err => Promise.reject(err))
                .finally(() => commit('setDepartureLoading', { loading: true }));
        },
        fetchTransferGroup({ commit }, { eventId, mainParticipantId }) {
            return TransferWidget.fetchTransferGroup({ eventId, participantId: mainParticipantId })
                .then(response => Promise.resolve(response))
                .catch(err => Promise.reject(err));
            // .finally(() => ());
        }
    }
};
