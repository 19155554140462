import api from '@/modules/event/api/transferParticipants';

export default {
    namespaced: true,
    state: {
        participantTransferList: [],
        participantTransferListMeta: {
            currentPage: null,
            firstPageUrl: null,
            from: null,
            lastPage: null,
            lastPageUrl: null,
            path: null,
            perPage: null,
            prevPageUrl: null,
            to: null,
            total: null
        }
    },
    getters: {},
    mutations: {
        setParticipantTransferList(state, data) {
            state.participantTransferList = data;
        },
        setParticipantTransferListMeta(state, meta) {
            state.participantTransferListMeta = {
                currentPage: meta.current_page,
                firstPageUrl: meta.first_page_url,
                from: meta.from,
                lastPage: meta.last_page,
                lastPageUrl: meta.last_page_url,
                path: meta.path,
                perPage: meta.per_page,
                prevPageUrl: meta.prev_page_url,
                to: meta.to,
                total: meta.total
            };
        }
    },
    actions: {
        getParticipantTransferList({ commit }, { eventId, params }) {
            return api.getParticipantTransferList(eventId, params)
                .then(response => {
                    commit('setParticipantTransferList', response.data.data);
                    commit('setParticipantTransferListMeta', response.data.meta);
                    return Promise.resolve(response);
                })
                .catch(err => Promise.reject(err));
        },

        getParticipantTransferGroup({ commit }, participantId) {
            return api.getParticipantTransferGroup(participantId)
                .then(response => response.data.data)
                .catch(err => err);
        },

        updateParticipantTransferGroup({ commit }, data) {
            return api.updateParticipantTransferGroup(data)
                .then(response => response.data)
                .catch(err => err);
        },

        updateParticipantTransfer({ commit }, { eventId, participantTransferId, data }) {
            return api.updateParticipantTransfer(eventId, participantTransferId, data)
                .then(response => {
                    return Promise.resolve(response.data);
                })
                .catch(err => Promise.reject(err));
        },

        getPrintTransferList({ commit, state }, { eventId, data }) {
            return api.TransferParticipants.getParticipantTransferList(eventId, data)
                .then(response => Promise.resolve(response))
                .catch(err => Promise.reject(err));
        },

        getTimeList({ commit }, { eventId }) {
            return api.TransferParticipants.getTimeList(eventId)
                .then(response => {
                    commit('setArrivalLists', { data: response.data });
                    return Promise.resolve(response);
                })
                .catch(err => Promise.reject(err));
        },

        getTransferInfo({ commit }, { participantId }) {
            commit('setCardLoading', { loading: true });
            return api.TransferParticipants.getTransferParticipant(participantId)
                .then(response => {
                    commit('setTransferInfo', { data: response.data });
                    return Promise.resolve(response);
                })
                .catch(err => Promise.reject(err))
                .finally(() => commit('setCardLoading', { loading: false }));
        },

        updateTransfer({ commit }, { participantId, data }) {
            commit('setCardLoading', { loading: true });
            return api.TransferParticipants.updateTransferParticipant(participantId, data)
                .then(response => {
                    commit('setTransferInfo', { data: response.data });
                    return Promise.resolve(response);
                })
                .catch(err => Promise.reject(err))
                .finally(() => commit('setCardLoading', { loading: false }));
        },
        updateTransferGroup({ commit }, { eventId, groupId, data }) {
            commit('setCardLoading', { loading: true });
            return api.TransferParticipants.updateGroup(eventId, groupId, data)
                .then(response => Promise.resolve(response))
                .catch(err => Promise.reject(err))
                .finally(() => commit('setCardLoading', { loading: false }));
        }
    }
};
