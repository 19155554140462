import { ClientAuth, ClientLk } from '@/api/index';
import _ from 'lodash';

const defaultState = () => {
    return {
        participant: {}, // Зарегистрированный в лк участник
        authorized: false,
        authorizing: false,
        participantList: [],
        leaderId: null,
        groupId: null,
        participantListLoading: false,
        paySum: 0,
        isGroupPrepaid: false,
        rawData: {}
    };
};

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        participant: state => state.participant,
        authorizing: state => state.authorizing,
        authorized: state => state.authorized,
        participantList: state => state.participantList,
        participantListLoading: state => state.participantListLoading,
        leaderId: state => state.leaderId,
        groupId: state => state.groupId,
        groupLeader: state => state.participantList ? state.participantList.find(participant => participant.id === state.leaderId) : false,
        paySum: state => state.paySum,
        isGroupPrepaid: state => state.isGroupPrepaid,
        totalPrepaid: state => _.get(state, 'rawData.total_prepaid', 0),
        totalDiscount: state => _.get(state, 'rawData.total_discount', 0),
        totalDebt: state => _.get(state, 'rawData.total_debt', 0)
    },
    mutations: {
        setParticipant(state, { data }) {
            state.participant = data;
            state.authorized = true;
        },
        setIsAuthorized(state, { data }) {
            state.authorized = data;
        },
        setAuthorizing(state, { authorizing }) {
            state.authorizing = authorizing;
        },
        setParticipantList(state, { data }) {
            state.participantList = data;
        },
        setParticipantListLoading(state, { loading }) {
            state.participantListLoading = loading;
        },
        setLeaderId(state, { id }) {
            state.leaderId = id;
        },
        setGroupId(state, { id }) {
            state.groupId = id;
        },
        setPaySum(state, { sum }) {
            state.paySum = sum;
        },
        setIsGroupPrepaid(state, { status }) {
            state.isGroupPrepaid = status;
        },
        resetData(state) {
            Object.assign(state, defaultState());
        },
        setRawData(state, data) {
            state.rawData = data;
        }
    },
    actions: {
        // Оставлено. Но вся логика вынесена в плагин AccessManager
        authAttempt({ commit }, { phone }) {
            commit('setAuthorizing', { authorizing: true });
            return ClientAuth.login({ phone })
                .then(res => {
                    this.$router.push({ name: 'LkDashboard', params: { eventId: 'test-event-id' } });
                })
                .catch(() => {
                })
                .finally(() => commit('setAuthorizing', { authorizing: false }));
        },

        fetchParticipantList({ commit, state }, { eventId }) {
            commit('setParticipantListLoading', { loading: true });
            return ClientLk.getParticipants(eventId)
                .then(res => {
                    commit('setRawData', res.data.data);
                    commit('setLeaderId', { id: res.data.data ? res.data.data.leader_id : null });
                    commit('setGroupId', { id: res.data.data ? res.data.data.group_id : null });
                    commit('setParticipantList', { data: res.data.data ? res.data.data.participants : [] });
                    commit('setPaySum', { sum: res.data.data ? res.data.data.sum : 0 });
                    commit('setIsGroupPrepaid', { status: res.data.data ? res.data.data.is_group_prepaid : false });
                    return Promise.resolve(res);
                })
                .catch(err => Promise.reject(err))
                .finally(() => commit('setParticipantListLoading', { loading: false }));
        },

        checkSmsCode({ commit }, { phone, code }) {
            commit('setAuthorizing', { authorizing: true });
            return ClientAuth.auth({ phone, code })
                .then(res => Promise.resolve(res))
                .catch(err => Promise.reject(err))
                .finally(() => commit('setAuthorizing', { authorizing: false }));
        },

        addParticipant({ commit }, { eventId, data }) {
            return ClientLk.addParticipant(eventId, data)
                .then(res => Promise.resolve(res))
                .catch(err => Promise.reject(err));
        },

        editParticipant({ commit }, { eventId, participantId, data }) {
            return ClientLk.editParticipant(eventId, participantId, data)
                .then(res => Promise.resolve(res))
                .catch(err => Promise.reject(err));
        },

        deleteParticipant({ commit }, { eventId, participantId }) {
            return ClientLk.deleteParticipant(eventId, participantId)
                .then(res => Promise.resolve(res))
                .catch(err => Promise.reject(err));
        },

        prepayGroup({ commit }, { eventId }) {
            return ClientLk.prepayGroup(eventId)
                .then(response => Promise.resolve(response.data.data))
                .catch(err => Promise.reject(err));
        },

        getEventInfo() {
            return ClientLk.getEventInfo();
        },

        applyPromoCode(context, { eventId, promoCode }) {
            return ClientLk.applyPromoCode({ eventId, promoCode })
                .then(response => Promise.resolve(response.data))
                .catch(err => Promise.reject(err));

        }
    }
};
