export default {
    LIST: 'manager.list',
    ADD: 'manager.add',
    EDIT: 'manager.edit',
    API: {
        /** GET */
        LIST: 'managers',
        /** GET */
        GET: 'managers/{managerId}',
        /** POST */
        STORE: 'managers',
        /** PUT */
        UPDATE: 'managers/{managerId}'
    }
};
