<template>
    <div class="participant-service-enrollment">
        <div
            v-for="item in data.list"
            :key="item.id"
            class="participant-service-enrollment-item"
        >
            <div class="participant-service-enrollment-item__fullname">
                {{ data.participantFullname }}
            </div>
            <div class="participant-service-enrollment-item__centered">
                <div class="participant-service-enrollment-item__datestart">
                    {{ item.dateStart }}
                </div>
                <div class="participant-service-enrollment-item__service">
                    {{ item.serviceTitle }}
                </div>
                <template
                    v-for="specialist in item.specialists"
                >
                    <div
                        :key="specialist.id"
                        class="participant-service-enrollment-item__specialist"
                    >
                        {{ specialist.attributes.name }}
                    </div>
                </template>
                <div class="participant-service-enrollment-item__plan">
                    {{ item.plan.title }}: {{ item.plan.price }} ₽
                </div>
            </div>
            <div class="participant-service-enrollment-item__location">
                {{ item.location }}
            </div>
        </div>
    </div>
</template>

<script>
import JSONStorage from '@/utils/storage';

export default {
    name: 'ParticipantServiceEnrollment',

    data() {
        return {
            data: []
        };
    },

    created() {
        this.data = JSONStorage.get('service-participant-enrollment').data;
    },

    mounted() {
        setTimeout(() => window.print(), 1000);
        this._removeData();
    },

    destroyed() {
        this._removeData();
    },

    methods: {
        _removeData() {
            JSONStorage.remove('service-participant-enrollment');
        }
    }
};
</script>

<style lang="scss">

.participant-service-enrollment {
  display: block;
  width: 680px;
  box-sizing: border-box;

  &-item {
    display: inline-block;
    width: 335px;
    height: 300px;
    border: 1px solid #000;
    padding-left: 25px;
    padding-top: 34px;
    box-sizing: border-box;

    &__fullname {
      padding-bottom: 60px;
      font-size: 18px;
    }

    &__centered {
      padding-bottom: 50px;
    }

    &__datestart {
      font-size: 14px;
      font-weight: bold;
    }

    &__specialist {
      color: #ccc;
      margin-bottom: 10px;
    }

    &__plan {
      font-size: 14px;
    }

  }
}
</style>
