import actions from './actions';

export default {
    namespaced: true,
    state: {
        statusList: [],
        eventHistoryList: [],
        notVisitedEventList: []
    },
    getters: {},
    mutations: {
        setStatusList(state, { data }) {
            state.statusList = data;
        },
        setEventHistoryList(state, data) {
            state.eventHistoryList = data;
        },
        setNotVisitedEventList(state, data) {
            state.notVisitedEventList = data;
        }
    },
    actions
};
