import { mapMutations } from 'vuex';

export default {
    watch: {
        $breadcrumbs() {
            this.$doBreadcrumbs();
        }
    },

    mounted() {
        this.$doBreadcrumbs();
    },

    methods: {
        ...mapMutations([
            'setBreadcrumbs',
            'resetBreadcrumbs'
        ]),

        $doBreadcrumbs() {
            if (this.$breadcrumbs && this.$breadcrumbs.length) {
                this.setBreadcrumbs({
                    breadcrumbs: this.$breadcrumbs
                });
            } else {
                this.resetBreadcrumbs();
            }
        }
    }
};
