export default {
    SCREEN: 'e-queue.screen',
    CONFIG: 'e-queue.config',
    MANAGER: 'e-queue.manager',
    API: {
        GET_QUEUE_COUNTER: '/e-queue/start',
        UPDATE_QUEUE_COUNTER: '/e-queue/start',
        GET_QUEUE: '/e-queue',
        CALL_CUSTOMER: '/e-queue/call-customer',
        SHOW_CUSTOMER: '/e-queue/show-customer',
        QUIT_LINE: '/e-queue/quit-line'
    }
};
