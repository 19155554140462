export default {
    MANAGER: 'manager',
    SYSTEM: 'system',
    ADMIN: 'admin',
    HOTEL: 'hotel',
    SENIOR_MANAGER: 'senior-manager',
    RU: {
        MANAGER: 'менеджер',
        SYSTEM: 'системный',
        ADMIN: 'администратор',
        HOTEL: 'отель',
        SENIOR_MANAGER: 'старший менеджер'
    }
};
