import api from '@/api/managerApi';
import { ROUTES } from '@/enums';

export default {

    getList({ onlineEventId, query }) {
        const url = ROUTES.ONLINE_EVENT.API.PARTICIPANTS(onlineEventId);
        return api.get(url, { params: query })
            .then(response => Promise.resolve(response.data));
    }
};
