import _ from 'lodash';
import { STATUSES } from '@/modules/event/components/card/transfer/config';
import api from '@/api/modules/transfer';

export default {
    namespaced: true,
    state: {
        places: [],
        transferData: null
    },
    getters: {},
    mutations: {

        setPlaces(state, { data }) {
            state.places = data;
        },

        setTransferData(state, { data }) {
            state.transferData = data;
        }
    },
    actions: {
        getPlaces({ commit }, data) {
            return api.getPlaces(data)
                .then(response => {
                    commit('setPlaces', { data: response.data.data });
                    return Promise.resolve(response.data.data);
                })
                .catch(err => Promise.reject(err));
        },
        getTransferData({ commit }, eventId) {
            return api.getTransferData(eventId)
                .then(response => {
                    commit('setTransferData', { data: response.data.data });
                    return Promise.resolve(response.data.data);
                })
                .catch(err => Promise.reject(err));
        },
        updateTransferData(state, { eventId, data }) {
            return api.updateTransferData(eventId, data)
                .then(response => {
                    return Promise.resolve(response.data);
                })
                .catch(err => Promise.reject(err));
        }
    }
};
