import Vue from 'vue';
import Vuex from 'vuex';
import { ROUTES } from '@/enums';
import event from '@/modules/event/store';
import hotel from '@/modules/hotel/store';
import sidebar from '@/store/modules/sidebar';
import enums from '@/store/modules/enums';
import config from '@/store/modules/config';
import cabinet from '@/modules/cabinet/store';
import auth from '@/modules/auth/store';
import participant from '@/modules/participant/store';
import service from '@/modules/service/store';
import specialist from '@/modules/specialist/store';
import stats from '@/modules/stats/store';
import manager from '@/modules/manager/store';
import reception from '@/modules/reception/store';
import transfer from '@/modules/transfer/store';
import lk from '@/store/lk';
import eventNew from '@/store/event';
import smsBalance from '@/store/common/smsBalance/smsBalance';
import widgets from '@/store/widgets';
import onlineEvent from '@/store/onlineEvent';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        breadcrumbs: [],
        cursorX: 0,
        cursorY: 0
    },

    mutations: {
        /**
         * @param state
         * @param {Breadcrumb[]} breadcrumbs
         */
        setBreadcrumbs(state, { breadcrumbs }) {
            state.breadcrumbs = [
                {
                    title: 'Главная',
                    route: ROUTES.HOME
                },
                ...breadcrumbs
            ];
        },

        resetBreadcrumbs(state) {
            state.breadcrumbs = [
                {
                    title: 'Главная',
                    route: ROUTES.HOME
                }
            ];
        }
    },

    actions: {},

    modules: {
        auth,
        config,
        cabinet,
        sidebar,
        enums,
        event,
        hotel,
        participant,
        service,
        specialist,
        stats,
        manager,
        reception,
        lk,
        eventNew,
        smsBalance,
        widgets,
        onlineEvent,
        transfer
    }
});
