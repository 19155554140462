<template>
    <div
        v-if="isReady"
        class="hotel-building-room-form"
    >
        <el-form
            ref="form"
            :rules="rules"
            :model="form"
            label-position="top"
        >
            <el-row :gutter="16">
                <el-col :span="8">
                    <el-form-item
                        prop="title"
                        label="Название"
                    >
                        <el-input v-model="form.title" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item
                        prop="room_category_id"
                        required
                        label="Тип номера"
                    >
                        <el-select
                            v-model="form.room_category_id"
                            placeholder="Выберите тип номера"
                        >
                            <el-option
                                v-for="item in categories"
                                :key="item.id"
                                :label="item.attributes.title"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item
                        prop="floor"
                        label="Этаж"
                    >
                        <el-input
                            v-model="form.floor"
                            type="number"
                        />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="16">
                <el-col :span="6">
                    <el-form-item
                        prop="rooms_number"
                        label="Количество комнат"
                    >
                        <el-input v-model="form.rooms_number" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item
                        prop="size"
                        label="Размер"
                    >
                        <el-radio-group v-model="form.size">
                            <el-radio-button label="S" />
                            <el-radio-button label="M" />
                            <el-radio-button label="L" />
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item
                        prop="extra_places"
                        label="Возм. выкупа"
                    >
                        <el-switch v-model="form.extra_places" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item
                        prop="enabled"
                        label="Доступен"
                    >
                        <el-switch v-model="form.enabled" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item
                prop="window_view"
                label="Вид из окна"
            >
                <el-input
                    v-model="form.window_view"
                    type="textarea"
                />
            </el-form-item>

            <el-form-item
                prop="comment"
                label="Комментарий"
            >
                <el-input
                    v-model="form.comment"
                    type="textarea"
                />
            </el-form-item>
        </el-form>

        <el-row>
            <el-button
                type="primary"
                @click="trySend"
            >
                {{ buttonText }}
            </el-button>
        </el-row>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Hotel } from '@/api';
import { ROUTES } from '@/enums';

export default {
    name: 'HotelBuildingRoomForm',

    props: {
        hotelId: {
            type: String,
            default: null
        },

        buildingId: {
            type: String,
            default: null
        },

        roomId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            form: {
                size: 'S'
            },
            /** @type {RoomCategoryResource[]} */
            categories: []
        };
    },

    computed: {
        ...mapState('hotel/card', [
            'roomToEdit'
        ]),

        rules() {
            return {
                title: [
                    {
                        required: true,
                        message: 'Введите название номера'
                    }
                ]
            };
        },

        isEditMode() {
            return !!this.roomId;
        },

        buttonText() {
            return this.isEditMode ? 'Сохранить' : 'Создать';
        },

        isReady() {
            return !!this.roomToEdit;
        }
    },

    watch: {
        roomToEdit() {
            this.form = this.roomToEdit
                ? this._formFromRoom(this.roomToEdit)
                : {};
        }
    },

    mounted() {
        if (this.roomId) {
            this._getRoom();
        }
        this._getRoomCategories();
    },

    methods: {
        ...mapActions('hotel/card', [
            'getRoomToEdit'
        ]),

        trySend() {
            this.$refs.form.validate(isValid => {
                if (!isValid) {
                    this.$message.error('Проверьте форму');
                    return;
                }
                this._send();
            });
        },

        _getRoomCategories() {
            /** @param {RoomCategoryResource[]} categories */
            const onResponse = categories => {
                this.categories = categories;
            };
            Hotel.RoomCategory.list()
                .then(onResponse)
                .catch(e => {
                    this.$message('Не удалось получить список доступных категорий номеров');
                });
        },

        _send() {
            const data = this._formToCreateRoomRequest();
            if (this.isEditMode) {
                Hotel.Room.update(data)
                    .then(response => {
                        this.$message.success('Номер обновлен');
                    })
                    .catch(e => {
                        this.$message.error('Не удалось обновить номер');
                    });
            } else {
                Hotel.Room.store(data)
                    .then(response => {
                        this.$message.success('Номер создан');
                        // this._reset();
                    })
                    .catch(e => {
                        this.$message.error('Не удалось создать номер');
                    });
            }
        },

        /**
         * @returns {CreateRoomRequest}
         * @private
         */
        _formToCreateRoomRequest() {
            const data = this.form;
            data.hotel_id = this.hotelId;
            data.building_id = this.buildingId;
            if (this.isEditMode) {
                data.room_id = this.roomId;
            }
            return data;
        },

        _getRoom() {
            const onError = e => {
                this.$message.error('Номер не найден');
                this.$router.push({
                    name: ROUTES.HOTEL.CARD.BUILDING.SHOW,
                    params: {
                        hotelId: this.hotelId,
                        buildingId: this.buildingId
                    }
                });
            };
            /** @param {RoomResource} room */
            const onResponse = room => {
                if (!room) {
                    return onError(room);
                }
            };
            this.getRoomToEdit({
                hotelId: this.hotelId,
                buildingId: this.buildingId,
                roomId: this.roomId
            })
                .then(onResponse)
                .catch(onError);
        },

        /**
         * @param {RoomResource} room
         * @returns {{}}
         * @private
         */
        _formFromRoom(room) {
            return {
                building_id: room.attributes.id,
                title: room.attributes.title,
                floor: room.attributes.floor,
                room_category_id: room.attributes.room_category_id,
                beds: room.attributes.beds,
                rooms_number: room.attributes.rooms_number,
                extra_places: room.attributes.extra_places,
                size: room.attributes.size,
                window_view: room.attributes.window_view,
                comment: room.attributes.comment,
                enabled: room.attributes.enabled
            };
        },

        _reset() {
            this.form = {};
        }
    }
};
</script>

<style lang="scss">
@import "@vars";
</style>
