import { Specialist } from '@/api';
import _ from 'lodash';

export default {
    namespaced: true,

    state: {
        /** @type {SpecialistResource[]} */
        specialists: [],
        meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            per_page: 15,
            total: 0
        },
        specialistsAreLoading: false,
        /** @type {SpecialistResource | null} */
        specialistToEdit: null,
        /** @type {Boolean} */
        isSpecialistToEditLoading: false
    },

    actions: {
        getSpecialists({ commit }, params) {
            commit('setSpecialistsAreLoading', true);
            return new Promise((resolve, reject) => {
                Specialist.list(params)
                    .then(response => {
                        let specialists = _.get(response, 'data', []);
                        let meta = _.get(response, 'meta', {});
                        commit('setSpecialists', { specialists });
                        commit('setMeta', { meta });
                        resolve({ specialists });
                    })
                    .catch(e => {
                        commit('setSpecialists', { specialists: [] });
                        reject(e);
                    })
                    .finally(() => {
                        commit('setSpecialistsAreLoading', false);
                    });
            });
        },

        /**
         * @param commit
         * @param {UUID} specialistId
         * @return {Promise<SpecialistResource | null>}
         */
        setSpecialistToEdit({ commit }, { specialistId }) {
            commit('setSpecialistToEdit', { specialistToEdit: null });
            commit('setIsSpecialistToEditLoading', true);
            return new Promise((resolve, reject) => {
                if (!specialistId) {
                    resolve(null);
                }
                Specialist.show({ specialistId })
                    .then(specialistToEdit => {
                        commit('setSpecialistToEdit', { specialistToEdit });
                        resolve(specialistToEdit);
                    })
                    .catch(reject)
                    .finally(() => {
                        commit('setIsSpecialistToEditLoading', false);
                    });
            });
        }
    },

    getters: {},

    mutations: {
        /**
         * @param state
         * @param {SpecialistResource[]} specialists
         */
        setSpecialists(state, { specialists }) {
            state.specialists = specialists;
        },

        setMeta(state, { meta }) {
            state.meta = Object.assign(state.meta, meta);
        },

        /**
         * @param state
         * @param {Boolean} specialistsAreLoading
         */
        setSpecialistsAreLoading(state, specialistsAreLoading) {
            state.specialistsAreLoading = specialistsAreLoading;
        },

        setSpecialistToEdit(state, { specialistToEdit }) {
            state.specialistToEdit = specialistToEdit;
        },

        setIsSpecialistToEditLoading(state, isSpecialistToEditLoading) {
            state.isSpecialistToEditLoading = isSpecialistToEditLoading;
        }
    }
};
