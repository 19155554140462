<template>
    <div class="cabinet-payment-success-view">
        <h1 class="app-h1">
            Заказ успешно оплачен
        </h1>

        <cabinet-payment-info :payment-id="paymentId" />
    </div>
</template>

<script>
import CabinetPaymentInfo from '@/modules/cabinet/components/payment/CabinetPaymentInfo';

export default {
    name: 'CabinetPaymentSuccessView',

    components: {
        CabinetPaymentInfo
    },

    computed: {
        paymentId() {
            return this.$prop('$route.params.paymentId');
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.cabinet-payment-success-view {
}
</style>
