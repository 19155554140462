<template>
    <el-card class="cabinet-payment-invoice-row">
        <b>{{ title }}</b>
        <p>
            {{ description }}
        </p>
        <p>
            Сумма: {{ amount }} руб.
        </p>
        <p>
            Скидка: {{ discount }} руб.
        </p>
        <p>
            Дата: {{ date | moment('calendar') }}
        </p>
    </el-card>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'CabinetPaymentInvoiceRow',

    props: {
        /** @type {InvoiceResource} */
        invoice: {
            type: Object,
            default: null
        }
    },

    computed: {
        description() {
            switch (this.type) {
            case 'prepay':
                return 'Участник: ' + this.$prop('invoice.attributes.payload.participant_appellation');
            default:
                return 'Нет описания';
            }
        },

        amount() {
            return this.$prop('invoice.attributes.amount');
        },

        discount() {
            return this.$prop('invoice.attributes.discount');
        },

        type() {
            return this.$prop('invoice.attributes.type');
        },

        date() {
            return this.$prop('invoice.attributes.created_at');
        },

        title() {
            switch (this.type) {
            case 'prepay':
                return this.$prop('invoice.attributes.payload.description');
            default:
                return this.type;
            }
        }
    },

    mounted() {
    },

    methods: {}
};
</script>

<style lang="scss">
.cabinet-payment-invoice-row {
    margin: 20px 0;
}
</style>
