<template>
    <div
        v-loading="loading"
        class="app-lk"
    >
        <div
            v-if="currentMessage === messages.SUCCESS"
            class="app-lk-info__text-success"
        >
            Вы успешно оплатили участие в фестивале. На ваш WhatsApp и в почту мы пришлем инструкции о дальнейших
            действиях.
        </div>
        <div
            v-else-if="currentMessage === messages.ERROR"
            class="app-lk-info__text-error"
        >
            Оплата не прошла.
            <el-link
                class="app-lk-info__link"
                @click="_onErrorLinkClick"
            >
                Перейдите на страницу регистрации
            </el-link>
            и попробуйте еще раз.
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

const messages = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
};

export default {
    name: 'OnlineDashboard',
    data() {
        return {
            currentMessage: '',
            messages
        };
    },
    computed: {
        ...mapGetters('lk/onlineEvent', [
            'loading',
            'eventInfo'
        ]),
        ...mapGetters('lk/onlineParticipant', [
            'participant'
        ]),
        calculateOffsetTop() {
            return window.innerWidth < 450 ? '2vh' : '15vh';
        },
        eventId() {
            return this.$route.params.eventId;
        }
    },
    mounted() {
        this.fetchParticipant({ eventId: this.eventId })
            .then(() => {
                this._checkParticipantStatus();
            });
    },
    methods: {
        ...mapActions('lk/onlineEvent', [
            'fetchEventInfo'
        ]),
        ...mapActions('lk/onlineParticipant', [
            'fetchParticipant'
        ]),
        ...mapActions('event/service/schedule', [
            'getScheduleParticipantInfo'
        ]),
        _onErrorLinkClick() {
            this.$router.push({ name: 'OnlineRegistration', params: { eventId: this.eventId } });
        },
        _checkParticipantStatus() {
            const participantStatus = _.get(this.participant, 'attributes.status', false);
            if (participantStatus === 'NEW') {
                return this.$router.replace({
                    name: 'OnlineRegistration',
                    params: {
                        eventId: this.eventId
                    }
                });
            }

            if (participantStatus === 'PAID') {
                this.currentMessage = messages.SUCCESS;
                return;
            }

            if (!participantStatus) {
                this.currentMessage = messages.ERROR;
            }
        }
    }
};
</script>

<style lang="scss">
.app-lk {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  @media (max-width: 450px) {
    padding: 0px;
  }

  &__header {
    font-size: 36px;
    line-height: 47px;
    @media (max-width: 450px) {
      font-size: 24px;
    }
  }
}

.app-lk-info {
  max-width: 600px;
  margin: 0 auto;

  &__header {
    margin: 0;
    padding-bottom: 10px;
    font-size: 36px;
    font-weight: normal;
    @media (max-width: 450px) {
      font-size: 24px;
    }
  }

  &__text {
    margin: 0;
    padding-bottom: 10px;
    font-size: 22px;
    @media (max-width: 450px) {
      font-size: 16px;
    }
  }

  &__text-success {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    @media (max-width: 450px) {
      font-size: 16px;
    }
  }

  &__text-error {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    @media (max-width: 450px) {
      font-size: 16px;
    }
  }

  &__link {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 25px !important;
    color: #409EFF !important;

    &:after {
      border-bottom: none !important;
    }

    @media (max-width: 450px) {
      font-size: 16px !important;
    }
  }

}
</style>
