import Auth from '@/modules/auth/api';
import Event from '@/modules/event/api';
import OnlineEvent from '@/modules/online-event/api';
import Hotel from '@/modules/hotel/api';
import Cabinet from '@/modules/cabinet/api';
import EQueue from '@/modules/e-queue/api';
import Participant from '@/modules/participant/api';
import Service from '@/modules/service/api';
import Specialist from '@/modules/specialist/api';
import Notifications from '@/modules/notifications/api';
import ClientAuth from './modules/clientAuth';
import ClientLk from './modules/clientLk';
import ClientOnlineAuth from './modules/clientOnlineAuth';
import ClientOnlineLk from './modules/clientOnlineLk';
import EventParticipant from './modules/participant';
import Common from './modules/common';
import Transfer from './modules/transfer';
// Widgets
import ParticipantAgeWidget from './widgets/participantsAgeWidget';
import TransferWidget from './widgets/transferWidget';

export {
    Auth,
    ClientAuth,
    ClientLk,
    ClientOnlineAuth,
    ClientOnlineLk,
    Event,
    OnlineEvent,
    Hotel,
    Cabinet,
    Participant,
    Service,
    EQueue,
    Specialist,
    Notifications,
    EventParticipant,
    Common,
    ParticipantAgeWidget,
    TransferWidget,
    Transfer
};
