import list from '@/modules/specialist/store/modules/list';

export default {
    namespaced: true,
    state: {},
    actions: {},
    getters: {},
    mutations: {},
    modules: {
        list
    }
};
