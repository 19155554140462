import axios from 'axios';
import CONFIG from '@/config';

const getToken = () => {
    const token = localStorage.getItem('Manager.Auth.Token');
    return token && token !== 'undefined' ? 'Bearer ' + JSON.parse(token) : '';
};

const client = axios.create({
    baseURL: CONFIG.API_HOST + CONFIG.API_PREFIX,
    headers: {
        Authorization: getToken()
    }
});

client.interceptors.request.use(
    (config) => {
        let token = getToken();
        if (token) {
            config.headers['Authorization'] = `${token}`;
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

export default client;
