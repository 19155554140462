<template>
    <div
        v-if="isReady"
        class="hotel-card-full"
    >
        <h1 class="app-h1">
            {{ hotel.attributes.title }}
        </h1>

        <h2 class="app-h2">
            Список корпусов
        </h2>

        <app-table
            :rows="preparedData"
            :config="tableConfig"
        />

        <el-row>
            <el-button
                type="text"
                @click="addBuilding"
            >
                <i class="el-icon-plus" />
                Добавить корпуса
            </el-button>
        </el-row>
    </div>
</template>

<script>
import _ from 'lodash';
import { ROUTES } from '@/enums';
import { Hotel } from '@/api';
import AppTable from '@/components/table/AppTable';

export default {
    name: 'HotelCardFull',

    components: { AppTable },

    props: {
        hotelId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            /** @type {JustHotelResource | null} */
            hotel: null,
            /** @type {BuildingResource[]} */
            buildings: []
        };
    },

    computed: {
        isReady() {
            return !!this.hotel;
        },

        tableConfig() {
            return {
                methods: row => ({
                    getShowRoute: this.getShowRoute.bind(this, row),
                    getEditRoute: this.getEditRoute.bind(this, row),
                    remove: this.remove.bind(this, row)
                }),
                columns: [
                    {
                        title: 'Название',
                        template: () => `
                            <div>
                                <router-link :to="getShowRoute()">
                                    {{ row.title }}
                                </router-link>
                                <router-link :to="getEditRoute()">
                                    <i class="el-icon-edit-outline hotel-list__icon hotel-list__edit"/>
                                </router-link>
                            </div>
                        `
                    },
                    'description|Описание',
                    'floors_count|Этажей',
                    'rooms_count|Комнат',
                    {
                        title: 'Статус',
                        template: () => ` <el-switch
                            v-model="row.enabled"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            disabled/>`
                    },
                    {
                        template: () => `<i
                            class="el-icon-delete hotel-list__icon hotel-list__remove"
                            @click="remove()"/>`
                    }
                ]
            };
        },

        preparedData() {
            /**
             * @param {BuildingResource} building
             * @returns {Array}
             */
            const mapper = building => ({
                id: building.id,
                title: building.attributes.title,
                description: building.attributes.description,
                floors_count: building.attributes.floors_count,
                rooms_count: building.attributes.rooms_count,
                enabled: building.attributes.enabled
            });
            return this.buildings.map(mapper);
        }
    },

    mounted() {
        this._loadHotel();
    },

    methods: {
        addBuilding() {
            this.$router.push({
                name: ROUTES.HOTEL.CARD.BUILDING.ADD,
                params: {
                    hotelId: this.hotelId
                }
            });
        },

        getShowRoute(item) {
            return {
                name: ROUTES.HOTEL.CARD.BUILDING.SHOW,
                params: {
                    hotelId: this.hotelId,
                    buildingId: item.id
                }
            };
        },

        getEditRoute(item) {
            return {
                name: ROUTES.HOTEL.CARD.BUILDING.EDIT,
                params: {
                    hotelId: this.hotelId,
                    buildingId: item.id
                }
            };
        },

        remove(row) {
        },

        edit({ row }) {
        },

        _loadHotel() {
            /**
             * @param {HotelShowResource} resource
             */
            const onResponse = resource => {
                this.hotel = resource.relationships.hotel;
                this.buildings = resource.relationships.buildings;
            };

            Hotel.getHotelShowResource({
                hotelId: this.hotelId
            })
                .then(onResponse)
                .catch(e => {
                    const message = _.get(e, 'message', 'Не удалось получить информацию об отеле');
                    this.$message.error(message);
                });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.hotel-card-full {
    .el-switch.is-disabled {
        opacity: 1;
    }

    .el-switch.is-disabled .el-switch__core, .el-switch.is-disabled .el-switch__label {
        cursor: default;
    }

    &__icon {
        font-size: 1.2em;
        cursor: pointer;
        visibility: hidden;
    }

    &__edit {
        color: #0486fe;
        margin-left: 10px;
    }

    &__remove {
        color: #eb2929;
    }

    .el-table__row {
        &:hover {
            .hotel-list__icon {
                visibility: visible;
            }
        }
    }
}
</style>
