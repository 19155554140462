import ReceptionApi from '@/modules/reception/api';

export default {
    async getNewParticipantList({ commit }) {
        let response = await ReceptionApi.getNewParticipantList();
        if (response.success) {
            commit('setNewParticipantList', response.data);
        } else {
            return response.error;
        }
    },
    async getParticipantWithKeyList({ commit }, { page, userInput }) {
        commit('setParticipantWithKeyListIsLoading', true);
        let response = await ReceptionApi.getParticipantWithKeyListPaginated(page, userInput);
        commit('setParticipantWithKeyListIsLoading', false);

        if (response.success) {
            commit('setParticipantWithKeyList', response.data);
        } else {
            return response.error;
        }
    },
    async giveAKeyToNewParticipant({ commit, state, dispatch }, id) {
        let newParticipantList = state.newParticipantList.filter((participant) => {
            return participant.id !== id;
        });

        commit('setNewParticipantList', { data: newParticipantList });

        commit('setParticipantWithKeyListIsLoading', true);
        let response = await ReceptionApi.giveAKeyToNewParticipant(id);
        commit('setParticipantWithKeyListIsLoading', false);

        if (!response.success) {
            return response.error;
        }

        await dispatch('getParticipantWithKeyList', { page: 1, userInput: null });
    },
    async removeParticipantFromLists({ commit, state }, id) {
        let newParticipantList = state.newParticipantList.filter((participant) => {
            return participant.id !== id;
        });

        let participantWithKeyList = state.participantWithKeyList.filter((participant) => {
            return participant.id !== id;
        });

        commit('setNewParticipantList', { data: newParticipantList });
        commit('setParticipantWithKeyList', { data: participantWithKeyList });
    },
    async pushNewParticipantToList({ commit, state }, participant) {
        let newParticipantList = state.newParticipantList;
        newParticipantList.push({
            id: participant.id,
            attributes: {
                fullname: participant.fullname,
                previous_place: participant.previous_place,
                event: participant.event
            },
            relationships: {
                places: [{
                    relationships: {
                        building: { attributes: { title: participant.places[0].building.title } },
                        room: { attributes: { title: participant.places[0].room.title } }
                    }
                }]
            }
        });

        commit('setNewParticipantList', { data: newParticipantList });
    }
};
