<template>
    <div
        v-loading="loading"
        class="app-lk"
    >
        <div class="app-lk-info">
            <h1 class="app-lk-info__header">
                Поздравляем!
            </h1>
            <p class="app-lk-info__text">
                Вы зарегистрировались на фестиваль и внесли предоплату.
            </p>
            <!--            <p class="app-lk-info__text">Вы зарегистрировались на фестиваль и внесли предоплату. Теперь позвоните-->
            <!--                менеджеру для заселения.</p>-->
        </div>
        <div class="app-lk__header">
            {{ participantFullname }}
        </div>
        <el-row
            justify="center"
            type="flex"
            style="flex-wrap: wrap"
        >
            <el-col
                :span="8"
                :xs="24"
            >
                <transfer-registration-form />
            </el-col>
            <el-col
                v-if="items.length > 0"
                :span="8"
            >
                <schedule-participant-enrol-list />
            </el-col>
            <el-col
                :span="8"
                :xs="24"
            >
                <participant-total-widget
                    v-loading="participantListLoading"
                    :participant-list="participantList"
                    :total-debt="totalDebt"
                    :total-discount="totalDiscount"
                    :total-prepaid="totalPrepaid"
                />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import ScheduleParticipantEnrolList from '@/containers/lk/components/ScheduleParticipantEnrolList';
import ParticipantTotalWidget from '@/containers/lk/components/participantTotalWidget/ParticipantTotalWidget';
import TransferRegistrationForm from '@/containers/lk/components/TransferRegistrationForm';

export default {
    name: 'Dashboard',
    components: {
        ScheduleParticipantEnrolList,
        TransferRegistrationForm,
        ParticipantTotalWidget
    },
    data() {
        return {
            activeParticipants: [0],
            items: []
        };
    },
    computed: {
        ...mapGetters('lk/event', [
            'loading',
            'eventInfo',
            'eventId'
        ]),
        ...mapGetters('lk/participant', [
            'participant',
            'participantList',
            'participantListLoading',
            'leaderId',
            'groupLeader',
            'paySum',
            'isGroupPrepaid',
            'totalPrepaid',
            'totalDiscount',
            'totalDebt'
        ]),

        isEmptyGroup() {
            return this.participantList.length === 0;
        },
        calculateOffsetTop() {
            return window.innerWidth < 450 ? '2vh' : '15vh';
        },
        showPaymentButton() {
            return this.paySum > 0;
        },
        participantFullname() {
            let eventId = _.get(this.$route, 'params.eventId');
            let currentParticipant = this.participantList.find(participant => participant.event_id === eventId);
            return _.get(currentParticipant, 'fullname');
        }
    },
    watch: {
        eventId: {
            handler: function(value) {
                if (value) {
                    this.fetchParticipantList({ eventId: this.eventId })
                        .then(() => {
                            if (this.participantList.length === 0) {
                                this._checkIfGroupIsPrepaid();
                            }
                        });
                }
            }
        },
        deep: true,
        immediate: true
    },
    mounted() {
        if (this.eventId && !this.participantListLoading) {
            this.fetchParticipantList({ eventId: this.eventId })
                .then(() => {
                    this._checkIfGroupIsPrepaid();
                });
        }
    },
    methods: {
        ...mapActions('lk/event', [
            'fetchEventInfo'
        ]),
        ...mapActions('lk/participant', [
            'fetchParticipantList',
            'addParticipant',
            'editParticipant',
            'deleteParticipant',
            'prepayGroup'
        ]),
        ...mapActions('event/service/schedule', [
            'getScheduleParticipantInfo'
        ]),
        _checkIfGroupIsPrepaid() {
            if (!this.isGroupPrepaid) {
                return this.$router.replace({ name: 'registerGroup', params: { eventId: this.eventId } });
            }
        }
    }
};
</script>

<style lang="scss">
.app-lk {
  padding: 20px;
  max-width: none !important;
  margin: 0 auto;
  @media (max-width: 450px) {
    padding: 0;
  }

  &__header {
    font-size: 36px;
    line-height: 47px;
    @media (max-width: 450px) {
      font-size: 24px;
    }
  }
}

.app-lk-info {
  max-width: 600px;
  margin: 0 auto;

  &__header {
    margin: 0;
    padding-bottom: 10px;
    font-size: 36px;
    font-weight: normal;
    @media (max-width: 450px) {
      font-size: 24px;
    }
  }

  &__text {
    margin: 0;
    padding-bottom: 10px;
    font-size: 22px;
    @media (max-width: 450px) {
      font-size: 16px;
    }
  }

}
</style>
