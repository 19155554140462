import api from '@/api/managerApi';
import { ROUTES } from '@/enums';
import urlBuilder from '@/utils/urlBuilder';

export default {
    async list({ eventId, participantId }) {
        try {
            const url = urlBuilder(ROUTES.EVENT.API.PARTICIPANT.TRANSACTIONS.LIST, {
                eventId,
                participantId
            });
            let response = await api.get(url);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                data: e.response.message
            };
        }
    },

    /** @type {TransactionData} transactionData */
    async store({ transactionData, eventId, participantId }) {
        const url = urlBuilder(ROUTES.EVENT.API.PARTICIPANT.TRANSACTIONS.STORE, { eventId, participantId });
        try {
            let response = await api.post(url, transactionData);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                data: e.response.data
            };
        }
    }
};
