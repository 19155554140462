import { Event } from '@/api';
import registration from '@/modules/cabinet/store/modules/registration';

export default {
    namespaced: true,

    state: {
        event: null
    },

    actions: {
        getEvent({ commit }, { eventId }) {
            commit('setEvent', { event: null });
            return Event.show({ eventId })
                .then(event => {
                    commit('setEvent', { event });
                    return Promise.resolve({ event });
                })
                .catch(e => {
                });
        }
    },

    getters: {},

    mutations: {
        setEvent(state, { event }) {
            state.event = event;
        }
    },

    modules: {
        registration
    }
};
