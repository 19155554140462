import { ROUTES } from '@/enums';
import ParticipantsSettlementList from '@/modules/print/participants/ParticipantsSettlementList';
import ServiceScheduledParticipants from '@/modules/print/participants/ServiceScheduledParticipants';
import ParticipantServiceEnrollment from '@/modules/print/participants/ParticipantServiceEnrollment';
import PrintTransferTable from '@/modules/event/components/card/transfer/PrintTransferTable';

export default [
    {
        path: 'checking-list',
        name: ROUTES.PRINT.PARTICIPANTS.CHECKING_LIST,
        component: ParticipantsSettlementList
    },
    {
        path: 'service-scheduled-list',
        name: ROUTES.PRINT.SERVICE.SCHEDULED_LIST,
        component: ServiceScheduledParticipants
    },
    {
        path: 'participant-service-enrollment',
        name: ROUTES.PRINT.PARTICIPANTS.SERVICE_ENROLLMENT,
        component: ParticipantServiceEnrollment
    },
    {
        path: 'transfer-list',
        name: 'transfer.print',
        component: PrintTransferTable
    }
];
