<template>
    <div class="event-add-view">
        <hotel-form :hotel-id="hotelId" />
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { ROUTES } from '@/enums';
import HotelForm from '../components/HotelForm';
import breadcrumbs from '@/mixins/breadcrumbs';

export default {
    name: 'HotelEditView',

    components: {
        HotelForm
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        $breadcrumbs() {
            const bc = [
                {
                    title: 'Отели',
                    route: ROUTES.HOTEL.LIST
                }
            ];
            if (this.title) {
                bc.push({
                    title: this.title,
                    route: ROUTES.HOTEL.CARD.SHOW,
                    params: {
                        hotelId: this.hotelId
                    }
                });
            }
            bc.push({
                title: 'Редактирование',
                route: ROUTES.HOTEL.EDIT,
                params: {
                    hotelId: this.hotelId
                }
            });
            return bc;
        },

        hotelId() {
            return this.$route.params.hotelId;
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
