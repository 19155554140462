import ROUTES from '@/enums/routes';
import AuthLoginView from '@/modules/auth/views/AuthLoginView';

export default [
    {
        path: 'login',
        name: ROUTES.AUTH.LOGIN,
        component: AuthLoginView,
        props: true
    }
];
