<template>
    <el-card
        v-loading="isLoading"
        class="transfer-registration-form"
        shadow="hover"
    >
        <div class="transfer-registration-form-header">
            <div class="transfer-registration-form-header__title">
                Трансфер
            </div>
        </div>
        <el-form
            v-if="showNotBookedForm"
            ref="form"
            :model="form"
            :rules="rules"
        >
            <p style="font-size: 13px;">
                {{ notBookedText.arrival }}
            </p>
            <el-form-item
                label="Куда прибываете?"
                prop="arrivalTransferPlaceId"
            >
                <el-select
                    v-model="form.arrivalTransferPlaceId"
                    placeholder="Выберите место"
                    @change="_onArrivalPlaceChange"
                >
                    <el-option
                        v-for="place in transferPlaces"
                        :key="place.id"
                        :value="place.id"
                        :label="place.name"
                    />
                </el-select>
            </el-form-item>
            <template v-if="arrivalPlaceIsNedeed">
                <el-row
                    class="transfer-registration-form__time-row"
                >
                    <el-col :span="11">
                        <el-form-item
                            label="Во сколько"
                            prop="arrivalTime"
                        >
                            <flat-pickr
                                v-model="form.arrivalTime"
                                placeholder="        :        "
                                class="el-input__inner app-lk-participant-modal__date-input"
                                :config="timePickerConfig"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <p class="transfer-registration-form__field-description">
                    Укажите время в билете, запас
                    накидывать не
                    нужно
                </p>
            </template>
            <br>
            <p style="font-size: 13px;">
                {{ notBookedText.departure }}
            </p>
            <el-form-item
                label="Откуда убываете?"
                prop="departureTransferPlaceId"
            >
                <el-select
                    v-model="form.departureTransferPlaceId"
                    placeholder="Выберите место"
                    @change="_onDeparturePlaceChange"
                >
                    <el-option
                        v-for="place in transferPlaces"
                        :key="place.id"
                        :value="place.id"
                        :label="place.name"
                    />
                </el-select>
            </el-form-item>
            <template v-if="departurePlaceIsNedeed">
                <el-row
                    class="transfer-registration-form__time-row"
                >
                    <el-col :span="11">
                        <el-form-item
                            label="Во сколько"
                            prop="departureTime"
                        >
                            <flat-pickr
                                v-model="form.departureTime"
                                placeholder="        :        "
                                class="el-input__inner app-lk-participant-modal__date-input"
                                :config="timePickerConfig"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <p class="transfer-registration-form__field-description">
                    Укажите время в билете, запас накидывать не
                    нужно
                </p>
            </template>
            <div style="display: flex; flex-direction: column;">
                <el-button
                    type="primary"
                    @click="_onSaveBtnClick"
                >
                    Сохранить
                </el-button>
                <span style="margin: 10px 0; text-align: center; color: #303133; font-size: 14px;">или</span>
                <el-button
                    type="danger"
                    plain
                    @click="_onRefuseButtonClick"
                >
                    Отказаться
                </el-button>
            </div>
        </el-form>
        <el-row v-if="showRefusedForm">
            <p> {{ refusedText }} </p>
            <el-col align="center">
                <el-button
                    type="primary"
                    @click="onEditButtonClick"
                >
                    Редактировать
                </el-button>
            </el-col>
        </el-row>
        <el-row v-if="showBookedForm">
            <!--    eslint-disable-->
            <p v-html="bookedText" />
            <!--    eslint-enable-->
            <p class="transfer-registration-form__register-header">
                Записаны
            </p>
            <el-table
                :show-header="false"
                :data="transferGroup"
            >
                <el-table-column
                    prop="fullname"
                />
            </el-table>
            <br>
            <el-col align="center">
                <el-button
                    type="primary"
                    @click="onEditButtonClick"
                >
                    Редактировать
                </el-button>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { mapActions, mapState } from 'vuex';
import { STATUSES } from '@/modules/event/components/card/transfer/config';
import _ from 'lodash';

const TRANSFER_NOT_NEEDED = 'TRANSFER_NOT_NEEDED';

export default {
    name: 'TransferRegistrationForm',
    components: {
        flatPickr
    },
    data() {
        let validateForm = (rule, value, callback) => {

            let transferIsNotNeeded =
                this.form.arrivalTransferPlaceId === TRANSFER_NOT_NEEDED &&
                this.form.departureTransferPlaceId === TRANSFER_NOT_NEEDED;

            if (transferIsNotNeeded) {
                callback();
                return;
            }

            let hasArrivalPlace = this.arrivalPlaceIsNedeed && !_.isEmpty(this.form.arrivalTransferPlaceId);
            let hasArrivalTime = !_.isEmpty(this.form.arrivalTime);
            let hasArrivalData = hasArrivalPlace && hasArrivalTime;

            let hasDeparturePlace = this.departurePlaceIsNedeed && !_.isEmpty(this.form.departureTransferPlaceId);
            let hasDepartureTime = !_.isEmpty(this.form.departureTime);

            let hasDepartureData = hasDeparturePlace && hasDepartureTime;

            if (hasArrivalData || hasDepartureData) {
                callback();
                return;
            }

            callback(new Error('Заполните или прибытие, или отбытие'));
        };
        return {
            isLoading: false,
            isEditMode: false,
            form: {
                arrivalTime: null,
                arrivalTransferPlaceId: null,
                departureTime: null,
                departureTransferPlaceId: null
            },
            timePickerConfig: {
                wrap: true,
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                time_24hr: true
            },
            rules: {
                arrivalTransferPlaceId: [
                    { validator: validateForm, trigger: 'blur' }
                ],
                arrivalTime: [
                    { validator: validateForm, trigger: 'blur' }
                ],
                departureTransferPlaceId: [
                    { validator: validateForm, trigger: 'blur' }
                ],
                departureTime: [
                    { validator: validateForm, trigger: 'blur' }
                ]
            }
        };
    },
    computed: {
        ...mapState('lk/lkTransfer', [
            'places',
            'transferData'
        ]),
        ...mapState('lk/event', [
            'eventInfo'
        ]),
        arrivalPlaceIsNedeed() {
            return this.form.arrivalTransferPlaceId !== TRANSFER_NOT_NEEDED;
        },
        departurePlaceIsNedeed() {
            return this.form.departureTransferPlaceId !== TRANSFER_NOT_NEEDED;
        },
        transferPlaces() {
            let places = this.places.map(item => ({
                id: item.id,
                name: item.name
            }));
            places.push({
                id: TRANSFER_NOT_NEEDED,
                name: 'Сам доберусь'
            });
            return places;
        },
        eventId() {
            return this.$route.params.eventId;
        },
        transferStatus() {
            return _.get(this.transferData, 'attributes.status');
        },
        showRefusedForm() {
            return !this.isEditMode && this.transferStatus === STATUSES.REFUSED_STATUS;
        },
        showBookedForm() {
            return !this.isEditMode && this.transferStatus === STATUSES.BOOKED_STATUS;
        },
        showNotBookedForm() {
            return this.isEditMode || this.transferStatus === STATUSES.NOT_BOOKED_STATUS;
        },
        transferGroup() {
            let group = _.get(this.transferData, 'attributes.group', []);
            return group.map(item => {
                return {
                    id: item.id,
                    fullname: _.get(item, 'attributes.fullname')
                };
            });
        },
        eventStartDate() {
            return _.get(this.eventInfo, 'attributes.start_at', 'Не указано');
        },
        eventEndDate() {
            return _.get(this.eventInfo, 'attributes.end_at', 'Не указано');
        },
        refusedText() {
            return `Вы отказались от трансфера.
             Это означает что на фестиваль и с фестиваля вы будете добираться самостоятельно.
             Если что-то поменяется вы можете изменить свое решение.`;
        },
        bookedText() {
            let eventStartDate = this.eventStartDate;
            let eventEndDate = this.eventEndDate;
            let arrivalPlace = _.get(this.transferData, 'attributes.arrivalTransferPlace.name');
            let arrivalTime = _.get(this.transferData, 'attributes.arrivalTime');
            let departurePlace = _.get(this.transferData, 'attributes.departureTransferPlace.name');
            let departureTime = _.get(this.transferData, 'attributes.departureTime');

            let bookedText = '';

            let hasArrivalData = arrivalPlace && arrivalTime;
            let hasDepartureData = departurePlace && departureTime;

            if (hasArrivalData) {
                bookedText = `Вы записались на трансфер. </br></br>
                <b>Заезд:</b> ${eventStartDate}, ${arrivalPlace}, ${arrivalTime}. </br>
                На месте вас будут встречать с табличками Благости.</br></br>
                <b>Разъезд:</b> Вы решили добираться самостоятельно. Если что-то поменяется вы можете изменить это.`;
            }

            if (hasDepartureData) {
                bookedText = `Вы записались на трансфер. </br></br>
                <b>Заезд:</b> Вы решили добираться самостоятельно. Если что-то поменяется вы можете изменить это. </br></br>
                <b>Разъезд:</b> ${eventEndDate}, ${departurePlace}, ${departureTime}.  </br>
                Отправление автобуса уточните в регистратуре в конце фестиваля.`;
            }

            if (hasArrivalData && hasDepartureData) {
                bookedText = `Вы записались на трансфер. </br></br>
                    <b>Заезд:</b> ${eventStartDate}, ${arrivalPlace}, ${arrivalTime}. </br>
                    На месте вас будут встречать с табличками Благости.  </br></br>
                    <b>Разъезд:</b> ${eventEndDate}, ${departurePlace}, ${departureTime}. </br>
                    Отправление автобуса уточните в регистратуре в конце фестиваля.`;
            }

            return bookedText;
        },
        notBookedText() {
            let eventStartDate = this.eventStartDate;
            let eventEndDate = this.eventEndDate;
            return {
                arrival: `Встречать будем ${eventStartDate} весь день в аэропорту Анапы, на ЖД Анапы и Тоннельной.
                 Если вы приезжаете в другой день или другое место, то не заполняйте данные.`,
                departure: `Обратно развозить будем ${eventEndDate} весь день на те же станции.
                 Если вы уезжаете в другой день или в другое место, то откажитесь от трансфера.`
            };
        }
    },
    mounted() {
        this._loadData();
    },
    methods: {
        ...mapActions('lk/lkTransfer', [
            'getPlaces',
            'getTransferData',
            'updateTransferData'
        ]),
        _onArrivalPlaceChange(value) {
            this.form.arrivalTime = null;
        },
        _onDeparturePlaceChange(value) {
            this.form.departureTime = null;
        },
        _onRefuseButtonClick() {
            this._refuseTransfer();
        },
        _refuseTransfer() {
            let updateData = {
                arrivalTime: null,
                arrivalTransferPlaceId: null,
                departureTime: null,
                departureTransferPlaceId: null,
                status: STATUSES.REFUSED_STATUS
            };
            this._tryUpdateTransferData(updateData);
        },
        _onSaveBtnClick() {
            const transferIsNotNeeded = !this.arrivalPlaceIsNedeed && !this.departurePlaceIsNedeed;
            if (transferIsNotNeeded) {
                this._refuseTransfer();
                return;
            }

            const form = this.$refs.form;
            form.validate((valid) => {
                if (valid) {
                    let updateData = { ...this.form, status: STATUSES.BOOKED_STATUS };
                    this._tryUpdateTransferData(updateData);
                    return;
                }
                return false;
            });
        },
        _tryUpdateTransferData(data) {
            if (data.arrivalTransferPlaceId === TRANSFER_NOT_NEEDED) {
                data.arrivalTransferPlaceId = null;
            }

            if (data.departureTransferPlaceId === TRANSFER_NOT_NEEDED) {
                data.departureTransferPlaceId = null;
            }

            this.updateTransferData({
                eventId: this.eventId,
                data
            })
                .then((response) => {
                    if (response.success) {
                        this.$message.success(response.message);
                        return;
                    }
                    throw new Error(response.message);
                })
                .catch((error) => {
                    let message = _.get(error, 'response.data.message') || error.message;
                    this.$message.error(message);
                })
                .finally(() => {
                    this._loadData();
                });
        },

        onEditButtonClick() {
            this.isEditMode = true;
        },

        _loadData() {
            this.isLoading = true;
            Promise.all([
                this.getPlaces(),
                this.getTransferData(this.eventId)
            ])
                .then(() => {
                    this.form.arrivalTime = _.get(this.transferData, 'attributes.arrivalTime');
                    this.form.arrivalTransferPlaceId = _.get(this.transferData, 'attributes.arrivalTransferPlace.id', null);
                    this.form.departureTime = _.get(this.transferData, 'attributes.departureTime');
                    this.form.departureTransferPlaceId = _.get(this.transferData, 'attributes.departureTransferPlace.id', null);
                })
                .finally(() => {
                    this.isEditMode = false;
                    this.isLoading = false;
                });
        }
    }
};
</script>

<style lang="scss">
.transfer-registration-form {
  border: 1px solid #D8D8D8 !important;
  width: 100%;
  max-width: 305px;
  height: 100%;
  @media(max-width: 768px) {
    max-width: 100%;
  }

  &__field-description {
    font-size: 13px;
    color: darkgray;
    margin-top: -8px;
  }

  &__register-header {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }

  &__time-label {
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 10px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }

  &__time-row {
    margin: 15px 0;
  }

  &__native-number-input {
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &-header {
    margin-bottom: 10px;

    &__title {
      font-size: 24px;
    }
  }

  .line {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;
  }
}
</style>
