<template>
    <div class="app-table">
        <el-row :gather="20">
            <el-col :span="filtersSpan">
                <div v-if="isFilterAvailable">
                    <el-row
                        :gutter="16"
                        class="app-table__filter-row"
                    >
                        <el-col
                            v-if="isSearchAvailable"
                            :span="12"
                        >
                            <el-input
                                v-model="localFilter.userInput"
                                :suffix-icon="searchInputIcon"
                            />
                        </el-col>
                        <slot name="filter" />
                        <el-col
                            :span="actionButtonsSpan"
                            class="action-buttons"
                        >
                            <slot name="actionButtons" />
                        </el-col>
                    </el-row>
                </div>
            </el-col>
            <el-col
                :span="6"
                align="right"
            >
                <el-dropdown
                    v-if="areColumnsConfigurable"
                    :hide-on-click="false"
                >
                    <el-button
                        type="text"
                        class="el-dropdown-link"
                    >
                        Настроить столбцы
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="column in columns"
                            :key="column.title"
                        >
                            <el-checkbox v-model="column.visible">
                                {{ column.title }}
                            </el-checkbox>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-row>

        <el-table
            ref="table"
            :data="preparedRows"
            style="width: 100%"
            :row-style="tableRowStyle"
            @selection-change="_onSelectionChange"
            @header-click="_onHeaderClick"
        >
            <el-table-column
                v-if="appSelectable"
                width="35px"
                align="center"
            >
                <template slot-scope="scope">
                    <i
                        v-if="!_isRowSelected(scope.row)"
                        class="far fa-square check-square"
                        @click="_selectRow(scope.row)"
                    />
                    <i
                        v-else
                        class="far fa-check-square check-check-square"
                        @click="_deselectRow(scope.row)"
                    />
                </template>
            </el-table-column>
            <el-table-column
                v-if="selectable"
                type="selection"
                width="55"
            />
            <el-table-column
                v-for="column in preparedColumns"
                :key="column.title"
                :label="column.title"
                v-bind="column.props"
            >
                <template slot-scope="scope">
                    <!-- eslint-disable -->
                    <div
                        v-if="column.render"
                        v-html="column.render(scope.row)"
                    />
                    <!-- eslint-enable -->
                    <v-runtime-template
                        v-else
                        :provided-props="{row: scope.row, rows}"
                        :provided-methods="preparedConfig.methods(scope.row)"
                        :template="column.template(scope.row)"
                    />
                </template>
            </el-table-column>
            <el-table-column v-if="hasControls">
                <template slot-scope="scope">
                    <i
                        v-if="removable"
                        class="el-icon-delete app-table__icon app-table__remove"
                        @click="_remove(scope)"
                    />
                    <i
                        v-if="editable"
                        class="el-icon-edit app-table__icon app-table__edit"
                        @click="_edit(scope)"
                    />
                </template>
            </el-table-column>
        </el-table>
        <div
            v-if="paginatable"
            class="app-table__pager"
        >
            <el-pagination
                :current-page="pagination.currentPage"
                :page-size="pagination.perPage"
                :total="pagination.totalRecords"
                layout="prev, jumper, slot, next"
                @current-change="_onPaginatorChange"
            >
                <span class="app-table__pager-last-page"> из {{ pagination.lastPage }} </span>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import VRuntimeTemplate from '@/utils/v-runtime-template';

export default {
    name: 'AppTable',

    components: {
        VRuntimeTemplate
    },

    props: {
        selectable: {
            type: Boolean,
            default: false
        },

        appSelectable: {
            type: Boolean,
            default: false
        },

        selectedRows: {
            type: Array,
            default: () => []
        },

        removable: {
            type: Boolean,
            default: false
        },

        editable: {
            type: Boolean,
            default: false
        },

        paginatable: {
            type: Boolean,
            default: false
        },

        configurableColumns: {
            type: Boolean,
            default: true
        },

        filterable: {
            type: Boolean,
            default: true
        },

        searchable: {
            type: Boolean,
            default: true
        },

        rows: {
            type: Array,
            default: () => []
        },

        config: {
            type: Object,
            default: () => ({})
        },

        pagination: {
            type: Object,
            default: () => ({
                totalRecords: 1,
                perPage: 1,
                lastPage: 1,
                currentPage: 1
            })
        },

        tableRowStyle: {
            type: Function,
            default: () => {
            }
        },

        clearSelection: {
            type: Boolean,
            default: false
        },

        /**
         * @type {AppTableFilter | null}
         */
        filter: {
            type: Object,
            default: null
        },

        actionButtonsSpan: {
            type: Number,
            default: 2
        },

        filtersSpan: {
            type: Number,
            default: 18
        }
    },

    data() {
        return {
            columns: [],
            localFilter: {
                userInput: null
            }
        };
    },

    computed: {
        hasControls() {
            return !!this.removable;
        },

        preparedColumns() {
            return this.columns
                .filter(x => x.visible)
                .map(x => {
                    if (!x.props) {
                        x.props = {};
                    }
                    return x;
                });
        },

        searchInputIcon() {
            return 'el-icon-search';
        },

        preparedRows() {
            return this.rows;
        },

        isSearchAvailable() {
            return this.searchable;
        },

        areColumnsConfigurable() {
            return this.configurableColumns;
        },

        isFilterAvailable() {
            return true;
        },

        preparedConfig() {
            return {
                ...{
                    methods: () => ({}),
                    columns: []
                },
                ...this.config
            };
        }
    },

    watch: {
        clearSelection(v) {
            if (v) {
                this._clearSelection();
            }
        },

        localFilter: {
            handler() {
                this._onLocalFilterChange();
            },
            deep: true
        }
    },

    mounted() {
        this._setupColumns();
        this._setupLocalFilters();
    },

    methods: {
        _setupLocalFilters() {
            if (this.filter) {
                this.localFilter = { ...this.filter };
                this._onLocalFilterChange();
            }
        },

        _isRowSelected(row) {
            return this.selectedRows.some(x => x.id === row.id);
        },

        _onLocalFilterChange: _.debounce(function() {
            this.$emit('filterChanged', {
                filter: this.localFilter
            });
        }, 300),

        _clearSelection() {
            this.$refs.table.clearSelection();
        },

        _remove(scope) {
            this.$emit('remove', { scope });
        },

        _edit(scope) {
            this.$emit('edit', { scope });
        },

        _setupColumns() {
            if (!this.preparedConfig) {
                this.columns = [];
                return;
            }
            this.columns = this.preparedConfig.columns
                .map(x => {
                    let newX = x;
                    if (typeof x === 'string') {
                        x = x.split('|');
                    }
                    if (Array.isArray(x)) {
                        newX = {
                            render: row => row[x[0]],
                            title: x[1] ? x[1] : x[0],
                            visible: true
                        };
                    }
                    if (typeof newX.visible === 'undefined') {
                        newX.visible = true;
                    }
                    if (typeof newX.methods === 'undefined') {
                        newX.methods = () => ({});
                    }
                    return newX;
                });
        },

        _onSelectionChange(e) {
            this.$emit('selectionChange', e);
        },

        _onPaginatorChange(page) {
            this.$emit('paginatorChange', page);
        },

        _selectRow(row) {
            this.$emit('rowSelected', row);
        },

        _deselectRow(row) {
            this.$emit('rowRemoved', row);
        },

        _onHeaderClick(column, event) {
            this.$emit('headerClick', column, event);
        }

    }
};
</script>

<style lang="scss">
@import "@vars";

.app-table {
  &__filter-row {
    margin: 0;
  }

  &__pager {
    text-align: center;
    margin: 30px auto;
  }

  &__pager-last-page {
    font-weight: normal;
  }

  &__icon {
    font-size: 1.2em;
    cursor: pointer;
    visibility: hidden;
  }

  &__fas {
    position: relative;
    top: -.2em;
  }

  &__edit {
    color: #0486fe;
  }

  &__remove {
    color: #eb2929;
  }

  .el-table__row {
    height: 50px;

    &:hover {
      .app-table__icon {
        visibility: visible;
      }
    }
  }
}
</style>
