import registration from './registration';

export default {
    namespaced: true,
    state: {
        /**
         * Формат дат, отображаемых в дейтпикере.
         */
        datepickerDateFormat: 'dd.MM.yyyy'
    },
    actions: {},
    getters: {},
    mutations: {},
    modules: {
        registration
    }
};
