<template>
    <div
        v-if="isReady"
        class="hotel-form"
    >
        <el-form
            ref="form"
            :rules="rules"
            :model="form"
            label-position="top"
        >
            <el-form-item
                prop="title"
                label="Название"
            >
                <el-input v-model="form.title" />
            </el-form-item>

            <el-form-item
                prop="categories"
                label="Категории номеров"
            >
                <el-select
                    v-model="form.room_categories"
                    multiple
                    placeholder="Выберите доступные категории"
                >
                    <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.attributes.title"
                        :value="item.id"
                    />
                </el-select>
            </el-form-item>
        </el-form>

        <el-row>
            <el-button
                type="primary"
                @click="trySend"
            >
                {{ buttonText }}
            </el-button>
        </el-row>
    </div>
</template>

<script>
import { Hotel } from '@/api';
import { ROUTES } from '@/enums';

export default {
    name: 'HotelForm',

    props: {
        hotelId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            form: {
                room_categories: []
            },
            /** @type {HotelResource | null} */
            hotel: null,
            /** @type {RoomCategoryResource[]} */
            categories: []
        };
    },

    computed: {
        isReady() {
            return this.isEditMode
                ? !!this.hotel && !!this.categories
                : true;
        },

        rules() {
            return {
                title: [
                    { required: true, message: 'Введите название отеля' }
                ]
            };
        },

        isEditMode() {
            return !!this.hotelId;
        },

        buttonText() {
            return this.isEditMode ? 'Сохранить' : 'Создать';
        }
    },

    mounted() {
        if (this.hotelId) {
            this._getHotel();
        }
        this._getRoomCategories();
    },

    methods: {
        trySend() {
            this.$refs.form.validate(isValid => {
                if (!isValid) {
                    this.$message.error('Проверьте форму');
                    return;
                }
                this._send();
            });
        },

        _send() {
            const data = this._formToCreateHotelRequest();
            if (this.isEditMode) {
                Hotel.update(data)
                    .then(response => {
                        this.$message.success('Отель обновлен');
                    })
                    .catch(e => {
                        this.$message.error('Не удалось обновить отель');
                    });
            } else {
                Hotel.store(data)
                    .then(response => {
                        this.$message.success('Отель создан');
                        this._reset();
                    })
                    .catch(e => {
                        this.$message.error('Не удалось создать отель');
                    });
            }
        },

        /**
         * @returns {CreateHotelRequest}
         * @private
         */
        _formToCreateHotelRequest() {
            const data = this.form;
            if (this.isEditMode) {
                data.hotel_id = this.hotelId;
            }
            return data;
        },

        _getRoomCategories() {
            /** @param {RoomCategoryResource[]} categories */
            const onResponse = categories => {
                this.categories = categories;
            };
            Hotel.RoomCategory.list()
                .then(onResponse)
                .catch(e => {
                    this.$message('Не удалось получить список доступных категорий номеров');
                });
        },

        _getHotel() {
            /** @param {HotelResource} hotel */
            const onResponse = hotel => {
                this.hotel = hotel;
                this.form = this._formFromHotel();
            };
            Hotel.show({ hotelId: this.hotelId })
                .then(onResponse)
                .catch(e => {
                    this.$message.error('Отель не найден');
                    this.router.push({
                        name: ROUTES.HOTEL.LIST
                    });
                });
        },

        _formFromHotel() {
            let categories = this.$prop('hotel.relationships.room_categories', []);
            return {
                title: this.hotel.attributes.title,
                id: this.hotel.id,
                room_categories: categories.map(x => x.id)
            };
        },

        _reset() {
            this.form = {};
        }
    }
};
</script>

<style lang="scss">
@import "@vars";
</style>
