import client from '@/api/managerApi';

export default {
    fetchDepartureList({ search }) {
        return client.get('/departures', { params: { search } });
    },
    fetchTransferGroup({ eventId, participantId }) {
        return client.get(`/widgets/transfer/transferGroup/${eventId}/${participantId}`);
    },
    toggleGroupTransfer({ eventId, participantId }) {
        return client.post(`/widgets/transfer/${eventId}/${participantId}/info`);
    }
};
