import actions from './actions';
import mutations from './mutations';

export default {
    namespaced: true,
    state: {
        newParticipantList: [],
        participantWithKeyList: [],
        participantWithKeyListMeta: [],
        participantWithKeyListIsLoading: false
    },
    getters: {},
    mutations,
    actions
};
