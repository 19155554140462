<template>
    <div
        v-loading="specialistsAreLoading"
        class="specialist-list"
    >
        <el-table
            :data="preparedData"
            style="width: 100%"
        >
            <el-table-column label="Имя">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.attributes.name }}
                    </div>
                    <div style="font-size: .8em; color: #888;" />
                </template>
            </el-table-column>
            <el-table-column
                label="Логин"
            >
                <template slot-scope="scope">
                    <div> {{ scope.row.attributes.username ? scope.row.attributes.username : 'Не задан' }}</div>
                </template>
            </el-table-column>
            <el-table-column
                label="Включен"
            >
                <template slot-scope="scope">
                    <el-switch
                        :value="scope.row.attributes.enabled"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        @change="_changeStatus(scope.row.attributes)"
                    />
                </template>
            </el-table-column>
            <el-table-column
                :width="120"
                align="center"
            >
                <template slot-scope="scope">
                    <i
                        class="el-icon-edit specialist-list__icon specialist-list__edit"
                        @click="_tryEdit(scope)"
                    />
                    <i
                        class="el-icon-delete specialist-list__icon specialist-list__remove"
                        @click="_tryRemove(scope)"
                    />
                </template>
            </el-table-column>
        </el-table>
        <el-row
            type="flex"
            justify="center"
        >
            <el-pagination
                layout="total, prev, pager, next, sizes"
                :current-page.sync="meta.current_page"
                :page-count="meta.last_page"
                :page-size="Number.parseInt(meta.per_page)"
                :total="meta.total"
                :page-sizes="[15, 30, 40, 50]"
                @size-change="_onPageSizeChange"
                @current-change="_onPageChange"
            />
        </el-row>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Specialist } from '@/api';
import showError from '@/utils/showError';

export default {
    name: 'SpecialistList',

    data() {
        return {};
    },

    computed: {
        ...mapState('specialist/list', [
            'specialists',
            'specialistsAreLoading',
            'meta'
        ]),

        preparedData() {
            return this.specialists.map(x => x);
        }
    },

    mounted() {
        this._loadData();
    },

    methods: {
        ...mapActions('specialist/list', [
            'getSpecialists',
            'setSpecialistToEdit'
        ]),

        reload() {
            this._loadData();
        },

        _onPageSizeChange(pageSize) {
            this.$router.push({ query: { ...this.$route.query, limit: pageSize, page: '1' } });
            this._loadData();
        },

        _onPageChange(page) {
            this.$router.push({ query: { ...this.$route.query, page: page } });
            this._loadData();
        },

        _tryEdit({ row, $index }) {
            this.setSpecialistToEdit({ specialistId: row.id });
            this.$emit('edit', { specialist: row });
        },

        _tryRemove({ row, $index }) {
            this.$confirm('Вы уверены, что хотите удалить специалиста?')
                .then(() => this._remove({ row, index: $index }))
                .catch(() => {
                });
        },

        _changeStatus(attributes) {
            Specialist.update(Object.assign(attributes, { enabled: !attributes.enabled }))
                .then(() => {
                    this.$message.success('Статус обновлен');
                    this.reload();
                })
                .catch(showError('Не удалось изменить статус'));
        },

        _remove({ row }) {
            const onResponse = () => {
                this.$message.success(`Специалист удален`);
                this.reload();
            };
            Specialist.remove({ specialistId: row.id })
                .then(onResponse)
                .catch(showError('Не удалось удалить специалиста'));
        },

        _loadData() {
            this.getSpecialists(this.$route.query);
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.specialist-list {
    &__icon {
        font-size: 1.2em;
        cursor: pointer;
        visibility: hidden;
    }

    &__edit {
        color: #0486fe;
        margin-left: 10px;
    }

    &__remove {
        color: #eb2929;
    }

    .el-table__row {
        &:hover {
            .specialist-list__icon {
                visibility: visible;
            }
        }
    }
}
</style>
