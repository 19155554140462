import { transferPlacesApi, transferParticipantsApi } from '@/modules/transfer/api';

export default {
    namespaced: true,
    state: {
        places: [],
        arrivalTime: null,
        departureTime: null,
        bookedFilter: {}
    },
    getters: {},
    mutations: {
        setPlaces(state, { data }) {
            state.places = data;
        },
        setArrivalTime(state, { data }) {
            state.arrivalTime = data;
        },
        setDepartureTime(state, { data }) {
            state.departureTime = data;
        },
        setBookedFilter(state, data) {
            state.bookedFilter = data;
        }
    },
    actions: {
        getPlaces({ commit }, params) {
            return transferPlacesApi.getPlaces(params)
                .then(response => {
                    commit('setPlaces', { data: response.data.data });
                    return Promise.resolve(response.data.data);
                })
                .catch(err => Promise.reject(err));
        },
        getPlace({ commit }, placeId) {
            return transferPlacesApi.getPlace(placeId)
                .then(response => {
                    return Promise.resolve(response.data);
                })
                .catch(err => Promise.reject(err));
        },
        createPlace({ commit }, data) {
            return transferPlacesApi.createPlace(data)
                .then(response => {
                    return Promise.resolve(response.data);
                })
                .catch(err => Promise.reject(err));
        },
        updatePlace({ commit }, { placeId, data }) {
            return transferPlacesApi.updatePlace(placeId, data)
                .then(response => {
                    return Promise.resolve(response.data);
                })
                .catch(err => Promise.reject(err));
        },
        deletePlace({ commit }, placeId) {
            return transferPlacesApi.deletePlace(placeId)
                .then(response => {
                    return Promise.resolve(response.data);
                })
                .catch(err => Promise.reject(err));
        },
        getArrivalTime({ commit }, { eventId, params }) {
            return transferParticipantsApi.getArrivalBookedTime(eventId, params)
                .then(response => {
                    commit('setArrivalTime', { data: response.data.data });
                    return Promise.resolve(response.data.data);
                })
                .catch(err => Promise.reject(err));
        },
        getDepartureTime({ commit }, { eventId, params }) {
            return transferParticipantsApi.getDepartureBookedTime(eventId, params)
                .then(response => {
                    commit('setDepartureTime', { data: response.data.data });
                    return Promise.resolve(response.data.data);
                })
                .catch(err => Promise.reject(err));
        }
    }
};
