<template>
    <el-card
        v-if="hasItems"
        class="schedule-participant-enrol-list"
        shadow="hover"
    >
        <div class="schedule-participant-enrol-list-header">
            <div class="schedule-participant-enrol-list-header__title">
                Услуги
            </div>
        </div>
        <schedule-participant-enrol-list-item
            v-for="item in list"
            :key="item.id"
            :item="item"
        />
    </el-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import ScheduleParticipantEnrolListItem from '@/containers/lk/components/ScheduleParticipantEnrolListItem';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    name: 'ScheduleParticipantEnrolList',
    components: {
        ScheduleParticipantEnrolListItem
    },

    data() {
        return {
            list: []
        };
    },

    computed: {
        ...mapGetters('lk/participant', [
            'participant'
        ]),
        ...mapState('event/service/schedule', [
            'participantInfo',
            'scheduleInfos'
        ]),

        participantId() {
            if (this.$prop('participant.participants') && this.$prop('participant.participants').length > 0) {
                return this.participant.participants.find(participant => participant.event_id === this.eventId).id;
            }

            return null;
        },

        hasItems() {
            return this.list.length > 0;
        },

        eventId() {
            return this.$route.params.eventId;
        }
    },

    watch: {
        async participantId() {
            await this._loadEnrollList();
        }
    },

    methods: {
        ...mapActions('participant', [
            'getEnrollList'
        ]),

        async _loadEnrollList() {
            if (this.participantId) {
                let response = await this.getEnrollList({ participantId: this.participantId });
                if (response.success) {
                    this._prepareList(response.data);
                }
            }
        },

        _prepareList(list) {
            this.list = list.filter(item => {
                return moment(item.attributes.datetime_start_not_formatted).diff(moment()) > 0;
            }).map(item => {
                return {
                    id: item.id,
                    dateStart: moment(_.get(item, 'attributes.datetime_start_not_formatted')).format('D MMMM в HH:mm'),
                    serviceTitle: _.get(item, 'relationships.service.attributes.title'),
                    location: _.get(item, 'relationships.location.attributes.title'),
                    specialists: _.get(item, 'relationships.specialists'),
                    plan: {
                        title: _.get(item, 'relationships.scheduleplan.attributes.title'),
                        price: parseInt(_.get(item, 'relationships.scheduleplan.attributes.price'), 10)
                    }
                };
            });
        }
    }
};
</script>

<style lang="scss">
.schedule-participant-enrol-list {
    border: 1px solid #D8D8D8 !important;
    height: 100%;
    margin: 10px;
    &-header {
        margin-bottom: 10px;

        &__title {
            font-size: 24px;
        }
    }
}
</style>
