export default {
    DASHBOARD: 'reception.dashboard',
    PRINT: { PARTICIPANT_WITH_KEY_LIST: 'reception.print.participants-with-keys' },
    API: {
        /** GET */
        NEW_PARTICIPANT_LIST: 'reception/new-participants',
        PARTICIPANT_WITH_KEY_LIST_PAGINATED: 'reception/participants-with-keys/{page}/{userInput}',
        PARTICIPANT_WITH_KEY_LIST: 'reception/participants-with-keys',
        /** PUT */
        GIVE_A_KEY_TO_NEW_PARTICIPANT: 'reception/give-a-key-to-new-participant/{id}'
    }
};
