export default {
    LIST: 'participant.list',
    ADD: 'participant.add',
    STATUSES: 'participant.statuses',

    API: {
        /** POST */
        STORE: 'participant',
        // Выкуп номера
        ROOM: {
            /** POST */
            REDEEM: 'participant/room/{roomId}/redeem',

            /** POST */
            CANCEL_REDEEM: 'participant/room/{roomId}/cancel-redeem'
        },
        // Статусы участников
        STATUS: {
            LIST: '/participant/status',
            /** POST */
            UPDATE: '/participant/status/{statusId}'
        },
        // Обновление по учатнику(ам)
        UPDATE: {
            /** POST */
            STATUS: '/participant/update/status',
            COMMENT: '/participant/{participantId}/comment',
            HAS_PHONED: '/participant/update/has-phoned'
        },
        REMOVE_HAS_PHONED_OF_EVENT: '/participant/remove-has-phoned-of-event',
        SERVICE: {
            /** GET */
            ENROLL_LIST: '/participant/{participantId}/enroll-list'
        },
        // Получить группу
        GROUP: '/participant/{participantId}/group',
        /** Гость прибыл  POST */
        ARRIVED: '/participant/arrived',
        /** PATCH */
        UPDATE_PARTICIPANT_FIELD: 'participant/{participantId}',
        /** POST */
        MOVE_TO_EVENT: 'participant/move-to-event/{eventId}'
    }
};
