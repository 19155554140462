import EVENT from '@/modules/event/enums/routes';
import ONLINE_EVENT from '@/modules/online-event/enums/routes';
import HOTEL from '@/modules/hotel/enums/routes';
import AUTH from '@/modules/auth/enums/routes';
import CABINET from '@/modules/cabinet/enums/routes';
import MANAGER from '@/modules/manager/enums/routes';
import PARTICIPANT from '@/modules/participant/enums/routes';
import SERVICE from '@/modules/service/enums/routes';
import SPECIALIST from '@/modules/specialist/enums/routes';
import STATS from '@/modules/stats/enums/routes';
import PRINT from '@/modules/print/enums/routes';
import E_QUEUE from '@/modules/e-queue/enums/routes';
import RECEPTION from '@/modules/reception/enums/routes';
import NOTIFICATIONS from '@/modules/notifications/enums/routes';
import TRANSFER from  '@/modules/transfer/enums/routes';

export default {
    EVENT,
    ONLINE_EVENT,
    HOTEL,
    AUTH,
    CABINET,
    MANAGER,
    PARTICIPANT,
    SERVICE,
    E_QUEUE,
    SPECIALIST,
    STATS,
    PRINT,
    RECEPTION,
    NOTIFICATIONS,
    HOME: 'app.home',
    TRANSFER
};
