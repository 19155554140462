import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import ROUTES from '@/modules/reception/enums/routes';

export default {
    async getNewParticipantList() {
        let url = urlBuilder(ROUTES.API.NEW_PARTICIPANT_LIST);
        try {
            let response = await api.get(url);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                error: e.response.message
            };
        }
    },
    async getParticipantWithKeyListPaginated(page, userInput) {
        let url = urlBuilder(ROUTES.API.PARTICIPANT_WITH_KEY_LIST_PAGINATED, { page, userInput });
        try {
            let response = await api.get(url);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                error: e.response.message
            };
        }
    },
    async getParticipantWithKeyList() {
        let url = urlBuilder(ROUTES.API.PARTICIPANT_WITH_KEY_LIST);
        try {
            let response = await api.get(url);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                error: e.response.message
            };
        }
    },
    async giveAKeyToNewParticipant(id) {
        let url = urlBuilder(ROUTES.API.GIVE_A_KEY_TO_NEW_PARTICIPANT, { id });
        try {
            let response = await api.put(url);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                error: e.response.message
            };
        }
    }
};
