import mutations from './mutations';
import actions from './actions';

export default {
    namespaced: true,
    state: {
        /** @type {ParticipantResource[]} */
        participants: [],
        participantsMeta: {},
        participantPage: 1,
        isLoading: false,
        selectedParticipants: [],
        reCheckInMode: false,
        /** @type {ParticipantFullCardResource | null} */
        participantCard: null,
        transactions: null
    },
    getters: {},
    mutations,
    actions
};
