<template>
    <div class="app-lk-participant-modal">
        <el-form
            ref="form"
            :rules="rules"
            :model="form"
            label-position="top"
            class="app-lk-participant-modal__form"
        >
            <el-row
                class="app-lk-participant-modal__row"
                :gutter="16"
            >
                <div class="participant-form__phone">
                    <el-form-item
                        prop="phone"
                        label="Номер телефона"
                        class="app-lk-participant-modal__form-item"
                    >
                        <app-phone-input
                            v-model="form.phone"
                            placeholder="Введите номер телефона"
                            :is-disabled="isDisabledPhoneField"
                            @change="_onPhoneChanged"
                        />
                    </el-form-item>
                </div>
            </el-row>
            <el-row
                class="app-lk-participant-modal__row"
                :gutter="16"
            >
                <el-col
                    :span="24"
                >
                    <el-form-item
                        label="Фамилия"
                        prop="surname"
                        :rules="[{ required: true, message: 'Укажите фамилию'}]"
                        class="app-lk-participant-modal__form-item"
                    >
                        <el-input v-model="form.surname" />
                    </el-form-item>
                </el-col>
                <el-col
                    :span="24"
                >
                    <el-form-item
                        label="Имя"
                        prop="name"
                        :rules="[{ required: true, message: 'Укажите имя'}]"
                        class="app-lk-participant-modal__form-item"
                    >
                        <el-input v-model="form.name" />
                    </el-form-item>
                </el-col>
                <el-col
                    :span="24"
                >
                    <el-form-item
                        label="Отчество"
                        prop="patronymic"
                        class="app-lk-participant-modal__form-item"
                    >
                        <el-input v-model="form.patronymic" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row
                class="app-lk-participant-modal__row"
                :gutter="16"
            >
                <el-col
                    :span="12"
                    :xs="24"
                    :sm="12"
                >
                    <el-form-item
                        label="Дата рождения"
                        prop="date_of_birth"
                        class="app-lk-participant-modal__form-item app-lk-participant-modal__date-form-item"
                    >
                        <flat-pickr
                            v-model="form.date_of_birth"
                            style="width: 100%; padding:0 30px;"
                            class="el-input__inner app-lk-participant-modal__date-input"
                            :config="pickerOptions"
                            placeholder="Выберите дату"
                        />
                        <span class="el-input__prefix"><i class="el-input__icon el-icon-date" /><!----></span>
                    </el-form-item>
                </el-col>
                <el-col
                    :span="12"
                    :xs="24"
                    :sm="12"
                >
                    <el-form-item
                        label="Пол"
                        prop="gender"
                        class="app-lk-participant-modal__form-item"
                    >
                        <el-select
                            v-model="form.gender"
                            style="width: 100%;"
                            :value="form.gender"
                            placeholder="Укажите пол"
                        >
                            <el-option
                                v-for="{title, value} in sexOptions"
                                :key="value"
                                :label="title"
                                :value="value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row
                class="app-lk-participant-modal__row"
                :gutter="20"
            >
                <el-col :span="24">
                    <el-form-item
                        label="Город"
                        prop="city"
                        :rules="[{ required: true, message: 'Укажите город'}]"
                        class="app-lk-participant-modal__form-item"
                    >
                        <el-autocomplete
                            v-model="form.city"
                            style="width: 100%;"
                            :fetch-suggestions="_getCitySuggestion"
                            placeholder="Город"
                            :trigger-on-focus="false"
                            @select="_onCitySuggestionSelect"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label="Электронная почта"
                        prop="email"
                        class="app-lk-participant-modal__form-item"
                    >
                        <el-input v-model="form.email" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col class="app-lk__col">
                    <el-link
                        v-if="_deletable"
                        class="app-lk-participant-modal__link"
                        type="danger"
                        @click="_onDelete"
                    >
                        <i class="app-lk-participant-modal__delete-icon far fa-trash-alt fa-lg" />
                        Удалить
                    </el-link>
                    <el-button
                        type="primary"
                        @click="_onSubmit"
                    >
                        {{ buttonText }}
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState, mapMutations } from 'vuex';
import client from '@/utils/dadataClient';
import AppPhoneInput from '@/components/form/AppPhoneInput';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/themes/material_blue.css';

export default {
    name: 'ParticipantForm',
    components: {
        AppPhoneInput,
        flatPickr
    },
    props: {
        currentParticipant: {
            type: Object,
            default: () => {
            }
        },
        editMode: {
            type: Boolean,
            default: false
        },
        leaderId: {
            type: [String, Number, Boolean],
            default: false
        },
        groupLeader: {
            type: Object,
            default: () => {
            }
        },
        user: {
            type: Object,
            default: () => {
            }
        },
        fillPhoneField: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            client,
            form: {
                name: '',
                surname: '',
                patronymic: '',
                date_of_birth: undefined,
                gender: undefined,
                city: undefined,
                email: undefined,
                phone: ''
            },
            participantId: null,
            isPhoneValid: false,
            rules: {
                phone: [
                    {
                        trigger: 'blur',
                        required: false,
                        validator: this._phoneValidator,
                        message: 'Укажите телефон'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: 'Пожалуйста, укажите имя участника',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        message: 'Имя не должно быть короче 2 символов',
                        trigger: 'blur'
                    }
                ],
                surname: [
                    {
                        required: true,
                        message: 'Пожалуйста, укажите фамилию участника',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        message: 'Фамилия не должна быть короче 2 символов',
                        trigger: 'blur'
                    }
                ],
                date_of_birth: [
                    {
                        required: true,
                        validator: this._dateValidator,
                        trigger: 'blur'
                    }
                ],
                gender: [
                    {
                        required: true,
                        message: 'Укажите пол',
                        trigger: 'blur'
                    }
                ],
                city: [
                    {
                        required: true,
                        message: 'Укажите город',
                        trigger: 'blur'
                    }
                ],
                email: [
                    {
                        type: 'email',
                        message: 'Пожалуйста, укажите корректный email',
                        trigger: ['blur', 'change']
                    }
                ]
            }
        };
    },
    computed: {
        ...mapState('cabinet/registration', [
            'leader',
            'participants',
            'isRegistrationInProcess'
        ]),

        ...mapState('config', [
            'datepickerDateFormat'
        ]),

        ...mapState('config/registration', [
            'maxAllowedAge'
        ]),

        ...mapState('enums', [
            'sexOptions'
        ]),

        isEditParticipantLeader() {
            return this.groupLeader ? (this.groupLeader.id === this.currentParticipant.id) : false;
        },

        // Проверка на доступность к заполнению поля с телефоном
        isDisabledPhoneField() {
            return !!(this.fillPhoneField ||
                this.isEditParticipantLeader ||
                this.editMode);
        },

        _deletable() {
            return this.editMode && (this.currentParticipant.attributes ? this.currentParticipant.attributes.deletable : false);
        },

        buttonText() {
            return this.editMode ? 'Сохранить' : 'Добавить';
        },

        leaderDateOfBirth() {
            return this.$prop('leader.date_of_birth');
        },

        isChild() {
            // todo: env
            return this.years > 3 && this.years <= 14;
        },

        isPhoneLengthEnough() {
            const phone = _.get(this, 'form.phone');
            return phone ? phone.length > 11 : false;
        },

        isPhoneErrorVisible() {
            return this.wasValidated || this.isPhoneLengthEnough;
        },

        pickerOptions() {
            return {
                wrap: true,
                dateFormat: 'd.m.Y',
                maxDate: 'today',
                allowInput: true
            };
        }
    },
    watch: {
        currentParticipant: {
            handler: function(participant) {
                if (participant.attributes) {
                    this.form = { ...participant.attributes };
                    let dateOfBirth = participant.attributes.date_of_birth;
                    this.form.date_of_birth = dateOfBirth
                        ? moment(participant.attributes.date_of_birth)
                            .format('DD.MM.YYYY') : null;
                    this.participantId = participant.id;
                    return;
                }
                this.participantId = null;
                this.form = {};
                this.checkPhoneFill();
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.checkPhoneFill();
    },
    methods: {
        ...mapMutations('cabinet/registration', [
            'setLeader',
            'setParticipants'
        ]),

        _phoneValidator(rule, value, callback) {
            if (this.isDisabledPhoneField) {
                return callback();
            }
            if (!value) {
                return callback();
            }
            if (this.isPhoneValid) {
                return callback();
            }
            callback(new Error('Проверьте телефон'));
        },

        _onSubmit() {
            this.$refs.form.validate()
                .then(() => {
                    if (this.currentParticipant.id) {
                        this.$emit('edit-participant', {
                            id: this.currentParticipant.id,
                            leaderId: this.leaderId,
                            data: this.form
                        });
                    } else {
                        this.$emit('add-participant', {
                            data: this.form
                        });
                    }
                })
                .catch(() => {});
        },

        _onDelete() {
            this.$confirm('Удалить участника?', 'Внимание', {
                customClass: 'app-lk-participant-modal__confirm'
            })
                .then(() => {
                    this.$emit('delete-participant', { id: this.currentParticipant.id });
                });
        },

        /**
         * @returns Promise<>
         */
        validate() {
            this.wasValidated = true;
            return new Promise((resolve, reject) => {
                if (!this.form.phone) {
                    this.$message('Укажите телефон');
                    return reject(new Error('empty_phone'));
                }
                /**
                 * @param {Boolean} isValid
                 */
                const onValidate = isValid => {
                    this.isValid = true;
                    resolve(isValid);
                };
                /**
                 * @param {Boolean} e
                 */
                const onError = e => {
                    this.isValid = false;
                    reject(e);
                };
                this.$refs.form.validate()
                    .then(onValidate)
                    .catch(onError);
            });
        },

        reset() {
            this.isPhoneTouched = false;
            this.isPhoneValid = false;
            this.$refs.form.resetFields();
        },

        _leaderPhoneValidator(rule, value, callback) {
            if (!value) {
                return callback(new Error('Укажите  телефон'));
            }
            if (this._isPhoneInGroup(value)) {
                return callback(new Error('Телефон используется другим участником группы'));
            }
            if (this.isPhoneValid) {
                return callback();
            }
            callback(new Error('Проверьте телефон'));
        },

        _isPhoneInGroup(phone) {
            if (!phone) {
                return false;
            }
            return this.participants.reduce((acc, participant) => {
                return acc || participant.phone === phone;
            }, false);
        },

        _dateValidator(rule, value, callback) {
            if (!value) {
                return callback(new Error('Укажите дату рождения'));
            }
            if (!moment(value, 'DD.MM.YYYY', true).isValid()) {
                return callback(new Error('Укажите дату в формате: 01.01.2001(день.месяц.год)'));
            }
            return callback();
        },

        /**
         * @param {{
         *     suggestion: DaDataSuggestion,
         *     value: String,
         * }} data
         */
        _onCitySuggestionSelect(data) {
            this.form.location_provider = 'dadata';
            this.form.location_payload = data.suggestion;
            this.form.location = data.suggestion.unrestricted_value;
            this.form.city = data.suggestion.data.city;
            this.form.country = data.suggestion.data.country;
            this.$refs.form.validateField('city');
        },

        /**
         * TODO вынести во Vuex
         * @param {String} query - Например, 'Моск'
         * @param {Function} cb
         */
        _getCitySuggestion(query, cb) {
            this.form.location_provider = null;
            this.form.location_payload = null;
            this.form.country = null;
            this.form.location = null;
            this.areSuggestionLoading = true;
            const onError = e => {
                const errorMessage = _.get(e, 'message', 'Не удалось получить список городов для подсказки.');
                this.$message.error(errorMessage);
            };
            this._suggest(query)
                .then(suggestions => {
                    /**
                     * При ошибке все равно резолвится,
                     * нет возможности ошибку отдельно обработать
                     */
                    if (!suggestions || !suggestions.length) {
                        // onError({ message: 'Ничего не найдено' });
                    }
                    cb(suggestions.map(suggestion => ({
                        value: suggestion.value,
                        suggestion
                    })));
                })
                .catch(onError)
                .finally(() => {
                    this.areSuggestionLoading = false;
                });
        },

        _suggest(query) {
            return this.client.suggest(
                'address',
                query,
                5,
                {
                    from_bound: {
                        value: 'city'
                    },
                    to_bound: {
                        value: 'city'
                    },
                    locations: [
                        {
                            country: '*',
                            city_type_full: 'город'
                        }
                    ]
                }
            );
        },

        /**
         * @param {string} phone
         * @param {Boolean} isValid
         * @param {String} country
         */
        _onPhoneChanged({ phone, isValid, country }) {
            this.isPhoneValid = isValid;
            this.$refs.form && this.$refs.form.validateField('phone');
        },

        _testDateOfBirth: _.debounce(function() {
            const date = moment(this.leaderDateOfBirth);
            if (moment.isMoment(date)) {
                this.years = moment()
                    .diff(date, 'years');
                this._testYears();
            }
        }, 300),

        _testYears() {
            if (this.isChild && typeof this.leader.children_program === 'undefined') {
                const leader = {
                    ...this.leader,
                    children_program: true
                };
                this.setLeader({ leader });
            }
        },
        checkPhoneFill() {
            if (this.fillPhoneField || this.isEditParticipantLeader) {
                this.form.phone = this.user.phone;
            }
        }
    }
};
</script>

<style lang="scss">
.participant-form__phone {
    padding: 0 8px;
}

.app-lk-participant-modal {
    &__confirm {
        @media (max-width: 420px) {
            width: 100% !important;
        }
    }
    &__row {
        margin: 5px 0 !important;
    }

    &__form-item {
        margin-bottom: 0 !important;
        @media (max-width: 450px) {
            .el-input {
                font-size: 16px;
            }
        }

        label {
            padding: 0 0 0 !important;
        }
    }

    &__date-form-item {
        padding: 0 0 15px !important;

        .el-form-item__content {
            line-height: 0;
        }

        .el-input__icon {
            line-height: 0;
        }
        .el-form-item__error {
            word-break: break-word;
        }
    }

    &__date-input {
        @media (max-width: 450px) {
            padding: 0 40px !important;
        }
    }

    &__delete-icon {
        margin-right: 5px;
    }

    &__link {
        padding: 0 8px;
        border-bottom: none !important;

        &:after {
            border: none !important;
        }
    }
}
</style>
