import { Event } from '@/api';
import showError from '@/utils/showError';

export default {
    /**
     * @param commit
     * @param {UUID} eventId
     * @param {UUID} scheduleId
     */
    removeSchedule({ commit }, { eventId, scheduleId }) {
        const onResponse = response => {
            commit('setScheduleIdToRemove', { scheduleId: null });
            commit('setScheduleRemoveInProgress', false);
            return Promise.resolve(response);
        };
        const onError = e => {
            commit('setScheduleIdToRemove', { scheduleId: null });
            commit('setScheduleRemoveInProgress', false);
            return Promise.reject(e);
        };
        commit('setScheduleIdToRemove', { scheduleId });
        commit('setScheduleRemoveInProgress', true);
        return Event.Service.Schedule.removeSchedule({ eventId, scheduleId })
            .then(onResponse)
            .catch(onError);
    },

    /**
     * @param commit
     * @param {UUID} eventId
     * @param {UUID} scheduleId
     * @param {UUID} schedulePlanId
     */
    removeSchedulePlan({ commit }, { eventId, scheduleId, schedulePlanId }) {
        const onResponse = response => {
            commit('setSchedulePlanIdToRemove', { schedulePlanId: null });
            commit('setSchedulePlanRemoveInProgress', false);
            return Promise.resolve(response);
        };
        const onError = e => {
            commit('setSchedulePlanIdToRemove', { schedulePlanId: null });
            commit('setSchedulePlanRemoveInProgress', false);
            return Promise.reject(e);
        };
        commit('setSchedulePlanIdToRemove', { schedulePlanId });
        commit('setSchedulePlanRemoveInProgress', true);
        return Event.Service.Plan.removeSchedulePlan({ eventId, scheduleId, schedulePlanId })
            .then(onResponse)
            .catch(onError);
    },

    getScheduleInfos({ commit }, { eventId, specialistId }) {
        /**
         * @param {ScheduleInfoResource[]} scheduleInfos
         */
        const onResponse = scheduleInfos => {
            commit('setScheduleInfos', { scheduleInfos });
        };
        const onError = e => {
            commit('setScheduleInfos', { scheduleInfos: [] });
        };
        Event.Service.Schedule.getScheduleInfos({ eventId, specialistId })
            .then(onResponse)
            .catch(onError);
    },

    getScheduleParticipantInfo({ commit }, { eventId, participantId }) {
        /**
         * @param {ScheduleParticipantInfoResource} participantInfo
         */
        const onResponse = participantInfo => {
            commit('setParticipantInfo', { participantInfo });
        };
        const onError = e => {
            commit('setParticipantInfo', { setParticipantInfo: null });
        };
        Event.Service.Schedule.getScheduleParticipantInfo({ eventId, participantId })
            .then(onResponse)
            .catch(onError);
    },

    addParticipantToSchedule({ commit }, { eventId, scheduleId, request }) {
        commit('setRegistrationProcess', true);
        return Event.Service.Schedule.addParticipantToSchedule({
            eventId,
            scheduleId,
            request
        })
            .then(response => {
                commit('setRegistrationResult', response);
                return response;
            })
            .finally(() => commit('setRegistrationProcess', false));
    },

    async getRecordedParticipants({ commit }, { eventId, serviceId }) {
        let response = await Event.Service.Schedule.getRecordedParticipants({ eventId, serviceId });
        return response;
    },

    async unregisterFromSchedule({ commit }, { eventId, scheduleId, participantId }) {
        try {
            let response = await Event.Service.Schedule.removeParticipantFromSchedule({
                eventId,
                scheduleId,
                participantId
            });
            return {
                success: true,
                data: response
            };
        } catch (e) {
            return {
                success: false,
                error: e
            };
        }
    }
};
