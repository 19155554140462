<template>
    <div class="hotel-building-edit-view">
        <h1 class="app-h1">
            Добавление копуса в отель "{{ hotelTitle }}"
        </h1>

        <hotel-building-form :hotel-id="hotelId" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';
import HotelBuildingForm from '../components/HotelBuildingForm';

export default {
    name: 'HotelBuildingAddView',

    components: {
        HotelBuildingForm
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        ...mapState('hotel/card', [
            'hotel',
            'building'
        ]),

        hotelId() {
            return this.$route.params.hotelId;
        },

        buildingId() {
            return this.$route.params.buildingId;
        },

        $breadcrumbs() {
            const bc = [
                {
                    title: 'Отели',
                    route: ROUTES.HOTEL.LIST
                }
            ];
            if (this.hotelTitle) {
                bc.push({
                    title: this.hotelTitle,
                    route: ROUTES.HOTEL.CARD.SHOW,
                    params: {
                        hotelId: this.hotelId
                    }
                });
            }
            bc.push({
                title: 'Добавление корпуса',
                route: ROUTES.HOTEL.CARD.BUILDING.ADD,
                params: {
                    hotelId: this.hotelId
                }
            });
            return bc;
        },

        hotelTitle() {
            return this.$prop('hotel.attributes.title');
        }
    },

    mounted() {
        if (!this.hotel) {
            this._loadHotel();
        }
    },

    methods: {
        ...mapActions('hotel/card', [
            'getHotel'
        ]),

        _loadHotel() {
            this.getHotel({
                hotelId: this.hotelId
            });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
