<template>
    <el-container class="reception">
        <el-main>
            <h1>
                Новые гости
            </h1>
            <new-participant-list />

            <h1>
                Заехало гостей: {{ participantWithKeyListCount }}
            </h1>
            <participant-with-key-list />
        </el-main>
    </el-container>
</template>

<script>
import NewParticipantList from '@/modules/reception/components/NewParticipantList';
import ParticipantWithKeyList from '@/modules/reception/components/ParticipantWithKeyList';
import { mapState } from 'vuex';

export default {
    name: 'ParticipantReception',
    components: { NewParticipantList, ParticipantWithKeyList },
    computed: {
        ...mapState('reception', [
            'participantWithKeyListMeta'
        ]),

        participantWithKeyListCount() {
            return this.participantWithKeyListMeta ? this.participantWithKeyListMeta.total : 0;
        }
    }
};
</script>

<style lang="scss">
    .reception {
        max-width: 1000px;
        h1 {
            font-size: 3em;
        }
    }
</style>
