<template>
    <div
        v-loading="participantListLoading"
        class="app-lk"
    >
        <h2 class="app-h2">
            {{ participantFullname }}
        </h2>
        <schedule-participant-enrol-list />

        <h1 class="app-h1">
            {{ eventInfo.title }}
        </h1>
        <p>{{ eventInfo.description }}</p>
        <el-alert
            title=""
            type="success"
            :closable="false"
        >
            После оплаты позвоните менеджеру для подтверждения брони и расселения:
            <br>
            +7 (918) 962-84-76 — Юлия (12:00–19:00, пн–сб)
            <br>
            +7 (918) 370-73-16 — Олег (дополнительный)
        </el-alert>
        <div class="app-lk__yellow-message">
            Пожалуйста, добавьте всех, даже детей до трех лет. Няню или бабушку, если они едут чтобы сидеть с ребенком.
        </div>
        <participants-table
            v-loading="participantListLoading"
            :participants="participantList"
            @edit="_onEditRowClick"
        />
        <el-row
            :gutter="20"
            type="flex"
            justify="end"
        >
            <el-col
                class="app-lk__total-row"
            >
                <div
                    v-if="!isEmptyGroup"
                    class="app-lk__total"
                >
                    Итого: {{ paySum }}р
                </div>
            </el-col>
        </el-row>
        <el-divider />
        <el-row
            type="flex"
            justify="end"
        >
            <div class="app-lk__promocode">
                <promo-code-control />
            </div>
        </el-row>
        <el-row :gutter="20">
            <el-col class="app-lk__col">
                <el-link
                    class="app-lk__link"
                    type="primary"
                    @click="_onAddButtonClick"
                >
                    <i class="fas fa-plus" />
                    Добавить
                </el-link>
                <el-button
                    v-if="showPaymentButton"
                    type="primary"
                    :loading="isPrepayButtonDisabled"
                    :disabled="isPrepayButtonDisabled"
                    @click="_onPrepayBtnClick"
                >
                    {{ prepayButtonText }}
                </el-button>
            </el-col>
        </el-row>
        <el-dialog
            class="app-lk__participant-modal"
            :visible.sync="showModal"
            width="100%"
            :top="calculateOffsetTop"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            @close="_onModalFormClose"
        >
            <participant-form
                ref="participantForm"
                :current-participant="selectedParticipant"
                :leader-id="leaderId"
                :group-leader="groupLeader"
                :edit-mode="editMode"
                :user="participant"
                :fill-phone-field="isEmptyGroup"
                @add-participant="_onAddParticipant"
                @edit-participant="_onEditParticipant"
                @delete-participant="_onDeleteParticipant"
            />
        </el-dialog>
        <el-dialog
            width="100%"
            :top="calculateOffsetTop"
            :visible.sync="showSuspendDialog"
        >
            <div>
                Мы набрали максимальное количество участников, но у нас есть лист ожидания, на случай если кто-то не
                приедет. Если вы хотите попасть в этот лист — позвоните Юлии по номеру +7 (918) 962-84-76.
            </div>
        </el-dialog>
    </div>
</template>

<script>
import _ from 'lodash';
import {mapGetters, mapActions} from 'vuex';
import ParticipantsTable from '@/containers/lk/components/participantsTable/ParticipantsTable';
import ParticipantForm from '@/containers/lk/components/participantForm/ParticipantForm';
import ScheduleParticipantEnrolList from '@/containers/lk/components/ScheduleParticipantEnrolList';
import PromoCodeControl from '@/containers/lk/components/promoCodeControl/PromoCodeControl';

export default {
    name: 'Dashboard',
    components: {
        PromoCodeControl,
        ParticipantForm,
        ParticipantsTable,
        ScheduleParticipantEnrolList
    },
    data() {
        return {
            PREPAY_BUTTON_TEXT: 'Внести предоплату',
            PREPAY_BUTTON_AFTER_CLICK_TEXT: 'Переход к оплате...',
            showModal: false,
            editMode: false,
            selectedParticipant: {},
            isPrepayButtonDisabled: false,
            prepayButtonText: 'Внести предоплату',
            showSuspendDialog: false
        };
    },
    computed: {
        ...mapGetters('lk/event', [
            'loading',
            'eventInfo',
            'eventId'
        ]),
        ...mapGetters('lk/participant', [
            'participant',
            'participantList',
            'participantListLoading',
            'leaderId',
            'groupLeader',
            'paySum',
            'isGroupPrepaid'
        ]),

        isEmptyGroup() {
            return this.participantList.length === 0;
        },
        calculateOffsetTop() {
            return window.innerWidth < 450 ? '2vh' : '15vh';
        },
        showPaymentButton() {
            return this.paySum > 0;
        },
        participantFullname() {
            if (this.$prop('participant.participants')
          && this.$prop('participant.participants').length > 0 && this.eventId) {
                return this.participant.participants.find(participant => participant.event_id === this.eventId).fullname;
            }

            return null;
        }
    },
    watch: {
        eventId: {
            handler: function(value) {
                if (value) {
                    this.fetchParticipantList({eventId: this.eventId})
                        .then(() => {
                            if (this.participantList.length === 0) {
                                this._checkIfGroupIsPrepaid();
                                this._onAddButtonClick();
                            }
                        });
                }
            }
        },
        deep: true,
        immediate: true
    },
    mounted() {
        if (this.eventId && !this.participantListLoading) {
            this.fetchParticipantList({eventId: this.eventId})
                .then(() => {
                    if (this.participantList.length === 0) {
                        this._checkIfGroupIsPrepaid();
                        this._onAddButtonClick();
                    }
                });
        }
    },
    methods: {
        ...mapActions('lk/event', [
            'fetchEventInfo'
        ]),
        ...mapActions('lk/participant', [
            'fetchParticipantList',
            'addParticipant',
            'editParticipant',
            'deleteParticipant',
            'prepayGroup'
        ]),
        ...mapActions('event/service/schedule', [
            'getScheduleParticipantInfo'
        ]),

        _onPrepayBtnClick() {
            if (this.eventInfo.registration_suspended) {
                this.showSuspendDialog = true;
                return;
            }
            /** Отключаем кнопку и выжидаем timeout перед редиректом на систему оплаты. */
            this.isPrepayButtonDisabled = true;
            this.prepayButtonText = 'Переход к оплате...';
            setTimeout(() => {
                this._prepay();
            }, 500);
        },

        _prepay: _.debounce(function() {
            this.prepayGroup({
                eventId: this.eventId
            })
                .then(response => {
                    this._makePaymentRequest(response);
                })
                .catch(err => {
                    const response = _.get(err, 'response.data');
                    this.$notify({
                        title: response.title,
                        message: response.message || response,
                        dangerouslyUseHTMLString: true
                    });
                    this._resetPrepayButton();
                });
        }, 500),
        _makePaymentRequest(response) {
            const url = _.get(response, 'attributes.url');
            const payload = _.get(response, 'attributes.payload');

            const robokassaForm = document.createElement('form');
            robokassaForm.method = 'POST';
            robokassaForm.action = url;

            const fields = [
                'Culture',
                'Description',
                'Encoding',
                'IncCurrLabel',
                'InvId',
                'IsTest',
                'MerchantLogin',
                'OutSum',
                'Receipt',
                'SignatureValue'
            ];

            fields.forEach(fieldName => {
                const inputField = document.createElement('input');
                inputField.type = 'hidden';
                inputField.name = fieldName;
                inputField.value = payload[fieldName];
                robokassaForm.appendChild(inputField);
            });
            document.body.appendChild(robokassaForm);
            robokassaForm.submit();
        },
        _resetPrepayButton() {
            this.isPrepayButtonDisabled = false;
            this.prepayButtonText = this.PREPAY_BUTTON_TEXT;
        },
        _onModalFormClose() {
            this.$refs.participantForm.reset();
            this.fetchParticipantList({eventId: this.eventId});
            this.showModal = false;
            this.selectedParticipant = {};
            this.editMode = false;
        },
        _onAddButtonClick() {
            this.editMode = false;
            this.showModal = true;
            this.$refs['participantForm'] && this.$refs['participantForm'].checkPhoneFill();
        },
        _onEditRowClick(id) {
            this.selectedParticipant = this.participantList.find(item => item.id === id);
            this.editMode = true;
            this.showModal = true;
            this.$refs['participantForm'] && this.$refs['participantForm'].checkPhoneFill();
        },
        _onAddParticipant({data}) {
            data.leader_id = this.leaderId;
            this.addParticipant({
                eventId: this.eventId,
                data
            })
                .then(res => {
                    this.$message.success('Участник добавлен');
                    this._onModalFormClose();
                })
                .catch(err => {
                    this.$message.error(err.response.data);
                });
        },
        _onEditParticipant({id, leaderId, data}) {
            this.editParticipant({
                eventId: this.eventId,
                participantId: id,
                data
            })
                .then(res => {
                    this.$message.success('Информация обновлена');
                    this._onModalFormClose();
                })
                .catch(err => {
                    this.$message.error('Ошибка');
                });
        },
        _onDeleteParticipant({id}) {
            this.deleteParticipant({
                eventId: this.eventId,
                participantId: id
            })
                .then(res => {
                    this.$message.success('Участник удален');
                    this._onModalFormClose();
                })
                .catch(err => {
                    this.$message.error(err.response.data);
                });
        },
        _checkIfGroupIsPrepaid() {
            if (this.isGroupPrepaid) {
                return this.$router.replace({name: 'LkDashboard', params: {eventId: this.eventId}});
            }
        }
    }
};
</script>

<style lang="scss">
.app-lk {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  @media (max-width: 450px) {
    padding: 0px;
  }

  &__total-row {
  }

  &__total {
    text-align: right;
    font-family: 'PT Sans', sans-serif;
    font-size: 20px;
    line-height: 14px;
    color: #000;
  }

  &__link {
    border-bottom: none !important;

    &:after {
      border: none !important;
    }
  }

  &__promocode {
  }

  &__col {
    display: flex;
    justify-content: space-between;
  }

  &__yellow-message {
    background: #fff9ba;
    padding: 19px 26px;
    font-size: 18px;
    font-family: "PT Sans", sans-serif;
    margin-top: 20px;
  }

  &__participant-modal {
    .el-dialog {
      max-width: 420px !important;
    }

    .el-dialog__headerbtn {
      width: 21px;
      height: 21px;
      border-radius: 100%;
      background: #eb2929;

      i {
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
</style>
