import { Event, Specialist } from '@/api';
import showError from '@/utils/showError';

export default {
    namespaced: true,
    state: {
        /** @type {SpecialistResource[]} */
        allSpecialists: []
    },

    getters: {},

    mutations: {
        setAllSpecialists(state, { allSpecialists }) {
            state.allSpecialists = allSpecialists;
        }
    },

    actions: {
        /**
         * @param commit
         * @param dispatch
         * @param {UUID} eventId
         * @param {UUID[]} specialistIds
         */
        setEventSpecialists({ commit, dispatch }, { eventId, specialistIds }) {
            const onResponse = () => {
                dispatch('event/service/specialist/getAllSpecialists', { eventId }, { root: true });
            };
            Event.Service.Specialist.setEventSpecialists({ eventId, specialistIds })
                .then(onResponse)
                .catch(showError('Не удалось сохранить специалистов'));
        },

        getAllSpecialists({ commit }) {
            /** @param {SpecialistResource[]} allSpecialists */
            const onResponse = allSpecialists => {
                commit('setAllSpecialists', { allSpecialists: allSpecialists.data });
                return Promise.resolve(allSpecialists);
            };
            return Specialist.list()
                .then(onResponse)
                .catch(e => {
                    commit('setAllSpecialists', { allSpecialists: [] });
                    return Promise.reject(e);
                });
        }
    }
};
