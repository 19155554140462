<template>
    <el-dialog
        :title="title"
        :visible.sync="isVisible"
        :close-on-click-modal="false"
        width="40%"
        @close="_onDialogClose"
    >
        <el-form
            ref="form"
            v-loading="isSpecialistToEditLoading"
            :model="form"
            :rules="rules"
        >
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item
                        label="Имя"
                        prop="name"
                    >
                        <el-input
                            v-model="form.name"
                            placeholder="Иванов Иван Иванович"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="Логин"
                        prop="username"
                    >
                        <el-input
                            v-model="form.username"
                            placeholder="ivanov"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item
                        label="Пароль"
                        prop="password"
                    >
                        <el-input
                            v-model="form.password"
                            type="password"
                            show-password
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="Повторите пароль"
                        prop="password_confirmation"
                    >
                        <el-input
                            v-model="form.password_confirmation"
                            type="password"
                            show-password
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-switch
                    v-model="form.enabled"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="Активен"
                    inactive-text="Отключен"
                />
            </el-row>
            <el-row>
                <el-button
                    type="primary"
                    @click="_send"
                >
                    {{ buttonText }}
                </el-button>
                <el-button @click="_cancel">
                    Отменить
                </el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import { Specialist } from '@/api';
import showError from '@/utils/showError';

export default {
    name: 'SpecialistFormPopup',

    data() {
        return {
            isVisible: false,
            passwordIsDirty: false,
            form: {
                name: '',
                username: '',
                enabled: false
            }
        };
    },

    computed: {
        ...mapState('specialist/list', [
            'specialistToEdit',
            'isSpecialistToEditLoading'
        ]),

        title() {
            return this.isEditMode
                ? `Редактирование специалиста "${this.form.name}"`
                : 'Добавление нового специалиста';
        },

        buttonText() {
            return this.isEditMode
                ? `Сохранить`
                : `Добавить`;
        },

        rules() {
            return {
                name: [
                    {
                        required: true,
                        message: 'Имя специалиста обязательно'
                    }
                ],
                password: [
                    {
                        min: 6,
                        message: 'Пароль должен содержать минимум 6 символов',
                        trigger: 'blur'
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (value === '') {
                                return callback();
                            }
                            if (value !== this.form.password_confirmation) {
                                return callback(new Error('Пароли не совпадают'));
                            }
                            if (value === this.form.password_confirmation) {
                                this.$refs.form.validateField('password_confirmation');
                            }
                            return callback();
                        },
                        trigger: 'change'
                    }
                ],
                password_confirmation: [
                    {
                        validator: (rule, value, callback) => {
                            if (value !== this.form.password) {
                                return callback(new Error('Пароли не совпадают'));
                            }
                            return callback();
                        },
                        trigger: 'change'
                    }
                ]
            };
        },

        specialistId() {
            return _.get(this, 'specialistToEdit.id');
        },

        isEditMode() {
            return !!this.specialistId;
        }
    },

    watch: {
        specialistToEdit() {
            this._setFormFromSpecialist();
        }
    },

    methods: {
        ...mapActions('specialist/list', [
            'setSpecialistToEdit'
        ]),

        open() {
            this.isVisible = true;
        },

        close() {
            this.isVisible = false;
        },

        _setFormFromSpecialist() {
            this.form = {
                ..._.get(this.specialistToEdit, 'attributes', {})
            };
        },

        _onDialogClose() {
            this.setSpecialistToEdit({ specialistId: null });
            this._resetForm();
        },

        _send() {
            this.$refs.form.validate()
                .then(this._doSend.bind(this))
                .catch(showError('Проверьте форму'));
        },

        _doSend() {
            this.loading = true;
            const onStoreResponse = specialist => {
                this.$emit('added', { specialist });
                this.close();
            };
            const onUpdateResponse = specialist => {
                this.$emit('updated', { specialist });
                this.close();
            };
            const setLoadingFalse = () => (this.loading = false);
            /** @type {CreateSpecialistRequest} */
            const specialist = this.form;
            if (this.isEditMode) {
                Specialist.update(specialist)
                    .then(onUpdateResponse)
                    .then(() => this.$message.success('Данные обновлены'))
                    .catch(showError('Не удалось обновить специалиста'))
                    .finally(setLoadingFalse);
            } else {
                Specialist.store(specialist)
                    .then(onStoreResponse)
                    .then(() => this.$message.success('Данные обновлены'))
                    .catch(showError('Не удалось создать специалиста'))
                    .finally(setLoadingFalse);
            }
        },

        _cancel() {
            this._resetForm();
            this.close();
        },

        _resetForm() {
            this.form = {};
            this.$refs.form.resetFields();
        }
    }
};
</script>
