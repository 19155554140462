<template>
    <div
        v-loading="loadingInfo"
        class="register-form"
    >
        <div>
            <h1 class="app-h1">
                {{ registerInfo.attributes.title }}
            </h1>
            <br>
        </div>
        <div
            v-if="availableForRegister"
            class="register-form__container"
        >
            <div class="participant-form">
                <div class="participant-form__container">
                    <el-form
                        ref="form"
                        label-position="top"
                        :rules="rules"
                        :model="form"
                        @submit.native.prevent="_loginAttempt"
                    >
                        <el-form-item
                            :show-message="isPhoneErrorVisible"
                            prop="phone"
                            label="Введите номер телефона:"
                        >
                            <app-phone-input
                                v-model="form.phone"
                                placeholder="Введите номер телефона"
                                @change="_onPhoneChanged"
                            />
                        </el-form-item>
                        <el-form-item
                            align="center"
                        >
                            <el-button
                                type="primary"
                                :loading="authorizing"
                                :disabled="authorizing"
                                @click="_loginAttempt"
                            >
                                Далее
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <confirm-sms-modal
            ref="smsModal"
            class="abow_dialog"
            :time-out="timeOut"
            :loading="authorizing"
            :timer-start="timerStart"
            @confirm-enter="_goToLk"
            @close-modal="_onCloseModal"
            @confirm-code="_confirmCallCode"
            @repeat-call="_onRepeatCall"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppPhoneInput from '@/components/form/AppPhoneInput';
import ConfirmSmsModal from '@/containers/lk/components/confirmSmsModal/ConfirmSmsModal';

export default {
    name: 'OnlineAuth',
    components: {
        ConfirmSmsModal,
        AppPhoneInput
    },
    data() {
        return {
            form: {},
            previousPhone: '',
            loadingInfo: true,
            availableForRegister: false,
            registerInfo: {
                attributes: {
                    title: 'Вход в личный кабинет'
                }
            },
            isPhoneValid: false,
            isPhoneTouched: false,
            wasValidated: false,
            timeOut: 0,
            timerStart: false,
            timer: null,
            rules: {
                phone: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (!value) {
                                return callback(new Error('Укажите  телефон'));
                            }
                            if (this.isPhoneValid) {
                                return callback();
                            }
                            return callback(new Error('Проверьте телефон'));
                        }
                    }
                ]
            }
        };
    },
    computed: {
        ...mapGetters('lk/participant', [
            'authorizing'
        ]),
        isPhoneLengthEnough() {
            const phone = this.form.phone;
            return phone ? phone.length > 11 : false;
        },

        isPhoneErrorVisible() {
            return this.wasValidated || this.isPhoneLengthEnough;
        }
    },
    mounted() {
        this.fetchEventInfo()
            .then(response => {
                this.availableForRegister = true;
                this.registerInfo = response.data.data;
            })
            .catch(error => {
                this.availableForRegister = false;
                this.registerInfo = error.response.data.data;
            })
            .finally(() => {
                this.loadingInfo = false;
            });
    },
    methods: {
        ...mapActions('lk/participant', [
            'authAttempt',
            'checkSmsCode'
        ]),
        ...mapActions('lk/onlineEvent', [
            'fetchEventInfo'
        ]),

        _startTimer() {
            this.timeOut = 60;
            this.timerStart = true;
            this.timer = setInterval(() => {
                if (this.timeOut === 0) {
                    clearInterval(this.timer);
                    return;
                }
                this.timeOut--;
            }, 1000);
        },

        _clearTimer() {
            clearInterval(this.timer);
        },

        _onCloseModal() {
            this.$refs.smsModal.closeModal();
        },

        _goToLk() {
            this.$router.push({ name: 'LkDashboard' });
        },

        _confirmCallCode({ code }) {
            this.$onlineLkAccessManager.loginUser({
                phone: this.form.phone,
                code
            }, true)
                .catch(() => {
                    this.$message.error('Произошла ошибка на сервере');
                });
        },

        _loginAttempt() {
            this.validate()
                .then(() => {
                    this.$onlineLkAccessManager.authAttempt({ phone: this.form.phone }, true)
                        .catch(err => {
                            if (err.status === 422) {
                                this.isPhoneValid = false;
                                this.$refs.form.validateField('phone');
                                return;
                            }
                            this.$refs.smsModal.openModal();
                            // Если логин идет 2й раз на тот же телефон - таймер перезапускать - не надо
                            if (this.previousPhone !== this.form.phone) {
                                this._clearTimer();
                                this._startTimer();
                            }
                            this.previousPhone = this.form.phone;
                            setTimeout(() => {
                                this.$message.success('Необходимо подтверждение телефона');
                                this.$refs.smsModal.$refs.codeInput.focus();
                            }, 500);
                        });
                })
                .catch(() => {
                    this.$refs.form.validateField('phone');
                });
        },

        _onRepeatCall() {
            this._startTimer();
            this.$onlineLkAccessManager.authAttempt({ phone: this.form.phone }, true)
                .catch(() => {
                    this.$refs.smsModal.openModal();
                    setTimeout(() => {
                        this.$message.success('Ожидайте повторный дозвон');
                    }, 500);
                });
        },

        _onPhoneChanged({ phone, isValid, country }) {
            this.wasValidated = false;
            this.isPhoneValid = isValid;
            this.isPhoneTouched = true;
            if (isValid) {
                this.wasValidated = true;
                this.$refs.form.validateField('phone');
            }
        },

        validate() {
            this.wasValidated = true;
            return new Promise((resolve, reject) => {
                if (!this.form.phone) {
                    this.$message('Укажите телефон');
                    return reject(new Error('empty_phone'));
                }

                this.$refs.form.validate()
                    .then(isValid => {
                        this.isValid = true;
                        resolve(isValid);
                    })
                    .catch(err => {
                        this.isValid = false;
                        reject(err);
                    });
            });
        }
    }
};
</script>

<style lang="scss">
</style>
