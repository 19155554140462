import Vue from 'vue';

/**
 * v-focus: Переключает фокус на элемент
 */

Vue.directive('focus', {
    inserted: function(el) {
        el.focus();
    }
});
