import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import { ROUTES } from '@/enums';
import _ from 'lodash';

export default {

    /**
     * @returns {Promise<PaymentResource>}
     */
    getCounter() {
        return api.get(urlBuilder(ROUTES.E_QUEUE.API.GET_QUEUE_COUNTER))
            .then(response => Promise.resolve(response.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {Integer} number
     * @returns {Promise<PaymentResource>}
     */
    setNewCounter({ number }) {
        return api.post(urlBuilder(ROUTES.E_QUEUE.API.UPDATE_QUEUE_COUNTER), {
            current_number: number
        })
            .then(response => Promise.resolve(response.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    callCustomer() {
        return api.post(urlBuilder(ROUTES.E_QUEUE.API.CALL_CUSTOMER))
            .then(response => Promise.resolve(response.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    quitLine() {
        return api.post(urlBuilder(ROUTES.E_QUEUE.API.QUIT_LINE))
            .then(response => Promise.resolve(response.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    getCustomer() {
        return api.get(urlBuilder(ROUTES.E_QUEUE.API.SHOW_CUSTOMER))
            .then(response => Promise.resolve(response.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    }

};
