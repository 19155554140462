import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import ROUTES from '@/modules/manager/enums/routes';

export default {

    async getManagerList() {
        let url = urlBuilder(ROUTES.API.LIST);
        try {
            let response = await api.get(url);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                error: e.response.message
            };
        }
    },

    async addManager(data) {
        let url = urlBuilder(ROUTES.API.STORE);
        try {
            let response = await api.post(url, data);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                error: e.response.data.errors
            };
        }
    },

    async updateManager({ managerId, data }) {
        let url = urlBuilder(ROUTES.API.UPDATE, { managerId });
        try {
            let response = await api.put(url, data);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                error: e.response.data.errors
            };
        }
    },

    async getManager(managerId) {
        let url = urlBuilder(ROUTES.API.GET, { managerId });
        try {
            let response = await api.get(url);
            return {
                success: true,
                data: response.data.data
            };
        } catch (e) {
            return {
                success: false,
                error: e.response.data
            };
        }
    }
};
