export default {
    /**
     * @type {Object<EventParticipantStatus, EventParticipantStatus>}
     */
    STATUSES: {
        NEW: 'NEW',
        WAIT_PREPAY: 'WAIT_PREPAY',
        PREPAID: 'PREPAID',
        CHECKED_IN: 'CHECKED_IN',
        PAYED: 'PAYED',
        ARRIVED: 'ARRIVED',
        REFUSED: 'REFUSED',
        MOVED: 'MOVED',
        STAFF: 'STAFF',
        CLUBS: 'CLUBS',
        IN_WAIT_LIST: 'IN_WAIT_LIST'
    },
    /**
     * @type {Object<EventParticipantStatus, String>}
     */
    STATUSES_RU: {
        NEW: 'Новый',
        WAIT_PREPAY: 'Ждем предоплату',
        PREPAID: 'Внес предоплату',
        CHECKED_IN: 'Заселен',
        PAYED: 'Оплачен',
        ARRIVED: 'Прибыл',
        REFUSED: 'Отказался',
        MOVED: 'Перенесен',
        STAFF: 'Персонал',
        CLUBS: 'Клубы',
        IN_WAIT_LIST: 'Лист ожидания'
    }
};
