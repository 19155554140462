import EQueueConfigView from '@/modules/e-queue/views/EQueueConfigView';
import EQueueManagerView from '@/modules/e-queue/views/EQueueManagerView';
import { ROUTES } from '@/enums';

export default [
    {
        path: 'config',
        name: ROUTES.E_QUEUE.CONFIG,
        component: EQueueConfigView
    },

    {
        path: 'manager',
        name: ROUTES.E_QUEUE.MANAGER,
        component: EQueueManagerView
    }
];
