<template>
    <div>
        <el-alert
            title=""
            type="success"
            :closable="false"
        >
            После оплаты позвоните менеджеру для подтверждения брони и расселения:
            <br>
            +7 (918) 962-84-76 — Юлия (12:00–19:00, пн–сб)
            <br>
            +7 (918) 370-73-16 — Олег (дополнительный)
        </el-alert>
        <br>
    </div>
</template>

<script>
export default {
    name: 'RegisterHeaderMessage'
};
</script>

<style scoped>

</style>