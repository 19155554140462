import Auth from './modules/auth/Auth';
import Dashboard from './modules/dashboard/Dashboard';
import RegisterGroup from './modules/registerGroup/RegisterGroup';

export default [
    {
        path: '/lk/auth',
        name: 'LkAuth',
        component: Auth
    },
    {
        path: '/lk/:eventId',
        name: 'LkDashboard',
        component: Dashboard
    },
    {
        path: '/lk/:eventId/reg',
        name: 'registerGroup',
        component: RegisterGroup
    }
];
