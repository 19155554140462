<template>
    <div class="hotel-building-form">
        <el-form
            ref="form"
            :rules="rules"
            :model="form"
            label-position="top"
        >
            <el-form-item
                prop="title"
                label="Название"
            >
                <el-input v-model="form.title" />
            </el-form-item>

            <el-form-item
                prop="description"
                label="Описание"
            >
                <el-input
                    v-model="form.description"
                    type="textarea"
                />
            </el-form-item>

            <el-form-item
                prop="enabled"
                label="Статус"
            >
                <el-switch
                    v-model="form.enabled"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="Включен"
                    inactive-text="Отключен"
                />
            </el-form-item>
        </el-form>

        <el-row>
            <el-button
                type="primary"
                @click="trySend"
            >
                {{ buttonText }}
            </el-button>
        </el-row>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Hotel } from '@/api';
import { ROUTES } from '@/enums';

export default {
    name: 'HotelBuildingForm',

    props: {
        hotelId: {
            type: String,
            default: null
        },

        buildingId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            form: {}
        };
    },

    computed: {
        ...mapState('hotel/card', [
            'buildingToEdit'
        ]),

        rules() {
            return {
                title: [
                    { required: true, message: 'Введите название корпуса' }
                ]
            };
        },

        isEditMode() {
            return !!this.buildingId;
        },

        buttonText() {
            return this.isEditMode ? 'Сохранить' : 'Создать';
        }
    },

    mounted() {
        if (this.buildingId) {
            this._getBuilding();
        }
    },

    methods: {
        ...mapActions('hotel/card', [
            'getBuildingToEdit'
        ]),

        trySend() {
            this.$refs.form.validate(isValid => {
                if (!isValid) {
                    this.$message.error('Проверьте форму');
                    return;
                }
                this._send();
            });
        },

        _send() {
            const data = this._formToCreateBuildingRequest();
            if (this.isEditMode) {
                Hotel.Building.update(data)
                    .then(response => {
                        this.$message.success('Корпус обновлен');
                    })
                    .catch(e => {
                        this.$message.error('Не удалось обновить корпус');
                    });
            } else {
                Hotel.Building.store(data)
                    .then(response => {
                        this.$message.success('Корпус создан');
                        this._reset();
                    })
                    .catch(e => {
                        this.$message.error('Не удалось создать корпус');
                    });
            }
        },

        /**
         * @returns {CreateBuildingRequest}
         * @private
         */
        _formToCreateBuildingRequest() {
            const data = this.form;
            data.hotel_id = this.hotelId;
            if (this.isEditMode) {
                data.building_id = this.buildingId;
            }
            return data;
        },

        _getBuilding() {
            const onError = e => {
                this.$message.error('Корпус не найден');
                this.$router.push({
                    name: ROUTES.HOTEL.CARD.SHOW,
                    params: {
                        hotelId: this.hotelId
                    }
                });
            };
            /** @param {BuildingResource} building */
            const onResponse = building => {
                if (!building) {
                    return onError(building);
                }
                this.form = this._formFromBuilding();
            };
            this.getBuildingToEdit({
                hotelId: this.hotelId,
                buildingId: this.buildingId
            })
                .then(onResponse)
                .catch(onError);
        },

        _formFromBuilding() {
            return {
                title: this.buildingToEdit.attributes.title,
                id: this.buildingToEdit.id,
                enabled: this.buildingToEdit.attributes.enabled,
                description: this.buildingToEdit.attributes.description
            };
        },

        _reset() {
            this.form = {};
        }
    }
};
</script>

<style lang="scss">
@import "@vars";
</style>
