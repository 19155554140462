import { unionBy } from 'lodash';

export default {

    setParticipants(state, { participants, meta }) {
        state.participants = participants;
        state.participantsMeta = meta;
    },

    setParticipantPage(state, page) {
        state.participantPage = parseInt(page, 10);
    },

    setParticipantCard(state, { participantCard }) {
        state.participantCard = participantCard;
    },

    isLoading(state, isLoading) {
        state.isLoading = isLoading;
    },

    setSelectedParticipants(state, { selectedParticipants }) {
        state.selectedParticipants = selectedParticipants;
    },

    setRecheckInMode(state, { mode = false }) {
        state.reCheckInMode = mode;
    },

    addSelectedParticipants(state, participants) {
        state.selectedParticipants = unionBy(state.selectedParticipants, participants, 'id');
    },

    removeParticipant(state, removeParticipantId) {
        let list = state.selectedParticipants.filter(
            participant => participant.id !== removeParticipantId
        );
        state.selectedParticipants = list;
    },

    setTransactions(state, { transactions }) {
        state.transactions = transactions;
    }
};
