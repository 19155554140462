import api from '@/api/managerApi';
import { ROUTES } from '@/enums';

export default {
    TransferRegistration: {
        /**
         * @returns {Promise<TransferRegistrationNotificationResource>}
         */
        show() {
            return api.get(ROUTES.NOTIFICATIONS.API.TRANSFER_REGISTRATION.SHOW)
                .then(response => Promise.resolve(response.data));
        },

        /**
         * @param {UpdateTransferRegistrationNotificationRequest} data
         * @returns {Promise<TransferRegistrationNotificationResource>}
         */
        update(data) {
            return api.put(ROUTES.NOTIFICATIONS.API.TRANSFER_REGISTRATION.UPDATE, data)
                .then(response => Promise.resolve(response.data));
        }
    }
};
