<template>
    <div class="register-form">
        <div class="register-form__container">
            <leader-form
                ref="form"
                :event-id="eventId"
                @phoneChanged="_onPhoneChanged"
            />
        </div>
        <send-sms-dialog
            ref="sms"
            :user-id="foundUserId"
            :event-id="eventId"
            :sms-code="smsCode"
        />
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import { Auth } from '@/api';
import SendSmsDialog from '@/modules/cabinet/components/register/SendSmsDialog';
import LeaderForm from '@/modules/cabinet/components/register/LeaderForm';

export default {
    name: 'RegisterLeader',

    components: {
        LeaderForm,
        SendSmsDialog
    },

    data() {
        return {
            form: {},
            user: {},
            smsCode: {}
        };
    },

    computed: {
        ...mapState('cabinet', [
            'event'
        ]),

        ...mapState('cabinet/registration', [
            'participants',
            'leader'
        ]),

        isChild() {
            // todo: env
            return this.years > 3 && this.years <= 14;
        },

        foundUserId() {
            return this.$prop('user.id');
        },

        eventId() {
            return this.$prop('event.id');
        },

        leaderId() {
            return this.$prop('leader.id');
        },

        phone() {
            return this.$prop('leader.phone');
        }
    },

    methods: {
        ...mapMutations('cabinet/registration', [
            'setParticipants'
        ]),

        /**
         * @returns {Promise<any>}
         */
        validate() {
            return new Promise((resolve, reject) => {
                if (!this.phone) {
                    return reject(new Error('Укажите телефон'));
                }
                this.$refs.form.validate()
                    .then(isValid => {
                        this.isValid = true;
                        resolve(isValid);
                    })
                    .catch(isValid => {
                        this.isValid = false;
                        reject(isValid);
                    });
            });
        },

        _onPhoneChanged({ isValid }) {
            if (!isValid) {
                return;
            }
            this._testPhone();
        },

        _openSmsDialog() {
            this.$refs.sms.open();
        },

        _testPhone: _.debounce(function() {
            if (!this.phone || this.leaderId) {
                return;
            }
            /** @type {TestPhoneRequest} */
            const request = {
                eventId: this.eventId,
                phone: this.phone
            };
            /**
             * @param {TestPhoneResultResource} result
             */
            const onResponse = result => {
                if (result.attributes.status === 'found') {
                    this.user = result.relationships.user;
                    this.smsCode = {
                        code: result.attributes.code,
                        show: result.attributes.show
                    };
                    this._openSmsDialog();
                }
            };
            Auth.testPhone(request)
                .then(onResponse)
                .catch(e => {
                    let message = _.get(e, 'response.data.errors.phone', 'Не удалось проверить телефон');
                    this.$message.error(message[0]);
                });
        }, 500)
    }
};
</script>

<style lang="scss">
@import "@vars";

.register-form {
    margin: 20px auto;

    &__container {
        max-width: 585px;
        margin: 0 auto;
    }

    &__phone {
        position: relative;
        z-index: 999;
        padding-bottom: 30px;
    }

    .el-checkbox {
        vertical-align: top;
    }

    .el-checkbox__input {
        vertical-align: top;
        margin-top: 2px;
    }

    .el-checkbox__label {
        vertical-align: top;
        white-space: normal !important;
    }
}
</style>
