import ParticipantApi from '@/modules/participant/api';

export default {
    async getStatusList({ commit }) {
        let response = await ParticipantApi.getStatusList();
        if (response.success) {
            commit('setStatusList', response.data);
        } else {
            return response.error;
        }
    },

    async updateStatus({ commit }, { statusId, color }) {
        let response = await ParticipantApi.updateStatus({
            statusId, color
        });
        if (response.success) {
            return response.data;
        } else {
            return response.error;
        }
    },

    async redeemRoom({ commit }, { roomId, participantId, eventId }) {
        let response = await ParticipantApi.redeemRoom({ roomId, participantId, eventId });
        return response;
    },

    async cancelredeemRoom({ commit }, { roomId, eventId }) {
        let response = await ParticipantApi.cancelRedeemRoom({ roomId, eventId });
        return response;
    },

    /** @param {AddParticipantData} data */
    async addParticipant({ commit }, data) {
        let response = await ParticipantApi.addParticipant(data);
        return response;
    },

    /**
     * @param participants[]
     * @returns {Promise<{data, success}|{success, error}>}
     */
    async participantsArrived({ commit }, participants) {
        let response = await ParticipantApi.participantsArrived(participants);
        return response;
    },

    async getEnrollList({ commit }, { participantId }) {
        let response = await ParticipantApi.getEnrollList(participantId);
        return response;
    },

    getEventHistoryList({ commit }, participantId) {
        return ParticipantApi.getEventHistoryList(participantId)
            .then(response => {
                commit('setEventHistoryList', response);
            });
    },

    deleteParticipant({ commit }, participantId) {
        return ParticipantApi.deleteParticipant(participantId)
            .then(response => {
                return response;
            });
    },

    addToTransfer({ commit }, participantId) {
        return ParticipantApi.addToTransfer(participantId)
            .then(response => {
                return response;
            });
    },

    getNotVisitedEvents({ commit }, participantId) {
        return ParticipantApi.getNotVisitedEvents(participantId)
            .then(response => {
                commit('setNotVisitedEventList', response);
            });
    },
    addParticipantToNotVisitedEvent({ commit }, { participantId, eventId, status }) {
        return ParticipantApi
            .addParticipantToNotVisitedEvent({ participantId, eventId, status })
            .then(response => {
                return response;
            });
    }
};
