<template>
    <div class="specialist-list-view">
        <h1 class="app-h1">
            Список специалистов
        </h1>

        <el-button
            type="primary"
            @click="_openAddForm"
        >
            Добавить специалиста
        </el-button>

        <specialist-list
            ref="list"
            @edit="_onSpecialistEdit"
        />

        <br>
        <br>

        <specialist-form-popup
            ref="form"
            @added="_onSpecialistAdded"
            @updated="_onSpecialistUpdated"
        />
    </div>
</template>

<script>
import SpecialistList from '../components/SpecialistList';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';
import SpecialistFormPopup from '@/modules/specialist/components/SpecialistFormPopup';

export default {
    name: 'SpecialistListView',

    components: {
        SpecialistFormPopup,
        SpecialistList
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        $breadcrumbs() {
            return [
                {
                    title: 'Специалисты',
                    route: ROUTES.SPECIALIST.LIST
                }
            ];
        }
    },

    methods: {
        _onSpecialistEdit() {
            this.$refs.form.open();
        },

        _openAddForm() {
            this.$refs.form.open();
        },

        /**
         * @param {SpecialistResource} specialist
         * @private
         */
        _onSpecialistAdded({ specialist }) {
            this.$message.success(`Специалист "${specialist.attributes.name}" добавлен успешно`);
            this.$refs.list.reload();
        },

        _onSpecialistUpdated({ specialist }) {
            this.$message.success(`Специалист "${specialist.attributes.name}" обновлен успешно`);
            this.$refs.list.reload();
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
