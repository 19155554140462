<template>
    <div class="registration-view-suspended">
        <div class="registration-view-suspended__text">
            <el-alert
                type="warning"
                center
                :closable="false"
            >
                {{ registrationSuspenedMessage }}
            </el-alert>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegisterSuspended',

    computed: {
        registrationSuspenedMessage() {
            return 'Мы уже набрали максимальное количество участников, но у нас есть лист ожидания, на случай если кто-то не приедет. Если вы хотите попасть в этот лист — позвоните Юлии по номеру +7 (918) 962-84-76.';
        }
    }
};
</script>

<style scoped>

</style>