import participant from '@/store/lk/modules/participant';
import event from '@/store/lk/modules/event';
import transfer from '@/store/lk/widgets/transfer';
import lkTransfer from '@/store/lk/modules/transfer';
import onlineEvent from '@/store/lk/modules/onlineEvent';
import onlineParticipant from '@/store/lk/modules/onlineParticipant';

export default {
    namespaced: true,
    modules: {
        participant,
        event,
        onlineEvent,
        onlineParticipant,
        transfer,
        lkTransfer
    }
};
