export default {
    /**
     * @param state
     * @param {JustHotelResource | null} hotel
     */
    setHotel(state, { hotel }) {
        state.hotel = hotel;
    },

    setBuildingCard(state, { buildingCard }) {
        state.buildingCard = buildingCard;
    },

    setRoomCard(state, { roomCard }) {
        state.roomCard = roomCard;
    },

    isLoading(state, isLoading) {
        state.isLoading = isLoading;
    },

    setSelectedBuildingId(state, { selectedBuildingId }) {
        state.selectedBuildingId = selectedBuildingId;
    },

    /**
     * @param state
     * @param {EventPlaceResource[]} eventPlacesWithParticipants
     */
    setEventPlacesWithParticipants(state, { eventPlacesWithParticipants }) {
        state.eventPlacesWithParticipants = eventPlacesWithParticipants;
    }
};
