import ROLES from '@/modules/manager/enums/roles';

export default {
    namespaced: true,
    state: {
        sexOptions: [
            {
                title: 'М',
                value: 1
            },
            {
                title: 'Ж',
                value: 2
            }
        ],

        roomCategories: {
            comfort: {
                id: 'e6f5557b-db56-4f7d-84aa-b46dafc1a592',
                title: 'Комфорт',
                description: null,
                slug: 'comfort'
            },

            comfort_plus: {
                id: 'e79d0f52-c599-4fb1-9b03-7063a0026a77',
                title: 'Комфорт +',
                description: null,
                slug: 'comfort_plus'
            }
        },

        managerRoles: [
            {
                title: ROLES.RU.MANAGER,
                value: ROLES.MANAGER
            },
            {
                title: ROLES.RU.ADMIN,
                value: ROLES.ADMIN
            },
            {
                title: ROLES.RU.SENIOR_MANAGER,
                value: ROLES.SENIOR_MANAGER
            }
        ]
    },
    actions: {},
    getters: {},
    mutations: {}
};
