<template>
    <div
        v-if="isReady"
        class="hotel-building-room-add-view"
    >
        <h1 class="app-h1">
            Новый номер
        </h1>
        <hotel-building-room-form
            :building-id="buildingId"
            :hotel-id="hotelId"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';
import HotelBuildingRoomForm from '../components/HotelBuildingRoomForm';

export default {
    name: 'HotelBuildingRoomAddView',

    components: {
        HotelBuildingRoomForm
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        ...mapState('hotel/card', [
            'hotel',
            'buildingToView',
            'roomToEdit'
        ]),

        isReady() {
            return !!this.hotelId && !!this.buildingId;
        },

        $breadcrumbs() {
            const bc = [
                {
                    title: 'Отели',
                    route: ROUTES.HOTEL.LIST
                }
            ];
            if (this.hotel) {
                bc.push({
                    title: this.hotel.attributes.title,
                    route: ROUTES.HOTEL.CARD.SHOW,
                    params: {
                        hotelId: this.hotelId
                    }
                });
            }
            if (this.buildingToView) {
                bc.push({
                    title: this.buildingToView.attributes.title,
                    route: ROUTES.HOTEL.CARD.BUILDING.SHOW,
                    params: {
                        hotelId: this.hotelId,
                        buildingId: this.buildingId
                    }
                });
            }
            bc.push({
                title: 'Добавление номера',
                route: ROUTES.HOTEL.CARD.BUILDING.ROOM.ADD,
                params: {
                    hotelId: this.hotelId,
                    buildingId: this.buildingId
                }
            });
            return bc;
        },

        hotelId() {
            return this.$route.params.hotelId;
        },

        buildingId() {
            return this.$route.params.buildingId;
        },

        roomId() {
            return this.$route.params.roomId;
        }
    },

    mounted() {
        this._getRoom();
        if (!this.hotel || this.hotel.id !== this.hotelId) {
            this.getHotel({
                hotelId: this.hotelId
            });
        }
        if (!this.buildingToView || this.buildingToView.id !== this.buildingId) {
            this.getBuilding({
                hotelId: this.hotelId,
                buildingId: this.buildingId
            });
        }
    },

    methods: {
        ...mapActions('hotel/card', [
            'getHotel',
            'getBuilding',
            'getRoomToEdit'
        ]),

        _getRoom() {
            if (!this.buildingId || !this.hotelId) {
                return;
            }
            this.getRoomToEdit({
                hotelId: this.hotelId,
                buildingId: this.buildingId,
                roomId: this.roomId
            });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
