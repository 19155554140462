import _ from 'lodash';
import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import ROUTES from '@/enums/routes';

export default {
    /**
     * Список всех категорий номеров.
     * @returns {Promise<RoomCategoryResource[]>}
     */
    list() {
        const url = urlBuilder(ROUTES.HOTEL.API.ROOM_CATEGORY.LIST);
        return api.get(url)
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    }
};
