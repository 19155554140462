import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import { ROUTES } from '@/enums';
import _ from 'lodash';

export default {
    /**
     * @param {UUID} paymentId
     * @returns {Promise<PaymentResource>}
     */
    show({ paymentId }) {
        return api.get(urlBuilder(ROUTES.CABINET.PAYMENT.API.SHOW, {
            paymentId
        }))
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    }
};
