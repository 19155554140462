<template>
    <div
        v-if="isReady"
        class="hotel-building-view"
    >
        <h1>
            {{ buildingToView.title }}
        </h1>

        <hotel-building-rooms-list
            :hotel="hotel"
            :building="buildingToView"
            :rooms="rooms"
        />

        <el-row :gather="20">
            <el-button
                type="default"
                @click="editBuilding"
            >
                <i class="el-icon-edit" />
                Редактировать корпус
            </el-button>
        </el-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';
import { Hotel } from '@/api';
import HotelBuildingRoomsList from '../components/card/HotelBuildingRoomsList';

export default {
    name: 'HotelBuildingView',

    components: {
        HotelBuildingRoomsList
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        ...mapState('hotel/card', [
            'hotel',
            'buildingToView'
        ]),

        rooms() {
            return this.$prop('buildingToView.relationships.rooms', []);
        },

        isReady() {
            return !!this.buildingToView;
        },

        hotelId() {
            return this.$route.params.hotelId;
        },

        buildingId() {
            return this.$route.params.buildingId;
        },

        $breadcrumbs() {
            const bc = [
                {
                    title: 'Отели',
                    route: ROUTES.HOTEL.LIST
                }
            ];
            if (this.hotelTitle) {
                bc.push({
                    title: this.hotelTitle,
                    route: ROUTES.HOTEL.CARD.SHOW,
                    params: {
                        hotelId: this.hotelId
                    }
                });
            }
            if (this.buildingToView) {
                bc.push({
                    title: `Корпус: ${this.buildingToView.attributes.title}`,
                    route: ROUTES.HOTEL.CARD.BUILDING.SHOW,
                    params: {
                        hotelId: this.hotelId,
                        buildingId: this.buildingId
                    }
                });
            }
            return bc;
        },

        hotelTitle() {
            return this.$prop('hotel.attributes.title');
        }
    },

    mounted() {
        this._getBuilding();
        if (!this.hotel || this.hotel.id !== this.hotelId) {
            this._getHotel();
        }
    },

    methods: {
        ...mapActions('hotel/card', [
            'getBuilding',
            'getHotel'
        ]),

        editBuilding() {
            this.$router.push({
                name: ROUTES.HOTEL.CARD.BUILDING.EDIT,
                params: {
                    hotelId: this.hotelId,
                    buildingId: this.buildingId
                }
            });
        },

        _getHotel() {
            if (!this.hotelId) {
                return;
            }
            this.getHotel({
                hotelId: this.hotelId
            });
        },

        _getBuilding() {
            if (!this.buildingId || !this.hotelId) {
                return;
            }
            this.getBuilding({
                hotelId: this.hotelId,
                buildingId: this.buildingId
            });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

</style>
