import { ROUTES } from '@/enums';

/**
 * Components
 */
const EventAddView = () => import(/* webpackChunkName: "EventAddView" */ '@/modules/event/views/EventAddView');
const EventListView = () => import(/* webpackChunkName: "EventListView" */ '@/modules/event/views/EventListView');
const EventCardView = () => import(/* webpackChunkName: "EventCardView" */ '@/modules/event/views/EventCardView');
const EventParticipantCardView = () => import(/* webpackChunkName: "EventParticipantCardView" */ '@/modules/event/views/EventParticipantCardView');
const EventEditView = () => import(/* webpackChunkName: "EventEditView" */ '@/modules/event/views/EventEditView');
const EventServiceParticipants = () => import(/* webpackChunkName: "EventServiceParticipants" */ '@/modules/event/components/card/service/EventServiceParticipants');

export default [
    {
        path: '',
        name: ROUTES.EVENT.LIST,
        component: EventListView
    },

    {
        path: 'add',
        name: ROUTES.EVENT.ADD,
        component: EventAddView
    },

    {
        path: ':eventId',
        redirect: {
            name: ROUTES.EVENT.CARD.PARTICIPANTS
        }
    },

    {
        path: ':eventId/participants',
        name: ROUTES.EVENT.CARD.PARTICIPANTS,
        component: EventCardView,
        meta: {
            tab: ROUTES.EVENT.CARD.PARTICIPANTS
        }
    },

    {
        path: ':eventId/transfer',
        name: ROUTES.EVENT.CARD.TRANSFER,
        component: EventCardView,
        meta: {
            tab: ROUTES.EVENT.CARD.TRANSFER
        }
    },
    {
        path: ':eventId/hotel',
        name: ROUTES.EVENT.CARD.HOTEL,
        component: EventCardView,
        meta: {
            tab: ROUTES.EVENT.CARD.HOTEL
        }
    },

    {
        path: ':eventId/stats',
        name: ROUTES.EVENT.CARD.STATS,
        component: EventCardView,
        meta: {
            tab: ROUTES.EVENT.CARD.STATS
        }
    },

    {
        path: ':eventId/services/list',
        name: ROUTES.EVENT.CARD.SERVICE.LIST,
        component: EventCardView,
        meta: {
            tab: ROUTES.EVENT.CARD.SERVICES,
            serviceTab: ROUTES.EVENT.CARD.SERVICE.LIST,
            denyTo: ['manager']
        }
    },

    {
        path: ':eventId/services/:serviceId/participants',
        name: ROUTES.EVENT.CARD.SERVICE.PARTICIPANTS,
        component: EventServiceParticipants
    },

    {
        path: ':eventId/services/schedule',
        name: ROUTES.EVENT.CARD.SERVICE.SCHEDULE,
        component: EventCardView,
        meta: {
            tab: ROUTES.EVENT.CARD.SERVICES,
            serviceTab: ROUTES.EVENT.CARD.SERVICE.SCHEDULE,
            denyTo: ['manager']
        }
    },

    {
        path: ':eventId/services/add-participant',
        name: ROUTES.EVENT.CARD.SERVICE.ADD_PARTICIPANT,
        component: EventCardView,
        meta: {
            tab: ROUTES.EVENT.CARD.SERVICES,
            serviceTab: ROUTES.EVENT.CARD.SERVICE.ADD_PARTICIPANT
        }
    },

    {
        path: ':eventId/services/specialists',
        name: ROUTES.EVENT.CARD.SERVICE.SPECIALISTS,
        component: EventCardView,
        meta: {
            tab: ROUTES.EVENT.CARD.SERVICES,
            serviceTab: ROUTES.EVENT.CARD.SERVICE.SPECIALISTS,
            denyTo: ['manager']
        }
    },

    {
        path: ':eventId/participants/:participantId',
        name: ROUTES.EVENT.PARTICIPANT.CARD,
        component: EventParticipantCardView
    },

    {
        path: ':eventId/edit',
        name: ROUTES.EVENT.EDIT,
        component: EventEditView
    }
];
