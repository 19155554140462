export default {
    namespaced: true,
    state: {
        /**
         * Максимальный возраст участника при регистрации, лет
         */
        maxAllowedAge: 80
    },
    actions: {},
    getters: {},
    mutations: {}
};
