import Event from '@/modules/event/api';
import { Hotel } from '@/api';

export default {
    /**
     * @param commit
     * @param {UUID} eventId
     * @param {UUID} hotelId
     * @return {Promise<EventHotelCardResource>}
     */
    getHotel({ commit }, { eventId, hotelId }) {
        commit('setHotel', { hotel: null });
        commit('isLoading', true);
        return new Promise((resolve, reject) => {
            /** @param {JustHotelResource} hotel */
            const onResponse = hotel => {
                commit('setHotel', { hotel });
                resolve({ data: hotel });
            };
            Hotel.getJustHotel({ hotelId })
                .then(onResponse)
                .catch(reject)
                .finally(() => {
                    commit('isLoading', false);
                });
        });
    },

    /**
     * @param commit
     * @param {UUID} eventId
     * @param {UUID} hotelId
     * @param {UUID} buildingId
     * @return {Promise<void>}
     */
    async getBuildingData({ commit }, { eventId, hotelId, buildingId }) {
        /** Не делаем запрос, если !id */
        if (!buildingId) {
            commit('setBuildingCard', { buildingCard: null });
            return;
        }
        let response = await Event.Hotel.getBuildingData({ eventId, hotelId, buildingId });
        if (response.success) {
            commit('setBuildingCard', {
                buildingCard: response.data
            });
        } else {
            commit('setBuildingCard', { buildingCard: null });
        }
    },

    getEventPlacesWithParticipants({ commit }, { eventId, hotelId }) {
        commit('isLoading', true);
        /** @param {EventPlaceResource[]} eventPlacesWithParticipants */
        const onResponse = eventPlacesWithParticipants => {
            commit('setEventPlacesWithParticipants', { eventPlacesWithParticipants });
        };
        Event.Hotel.getPlaces({
            eventId,
            hotelId
        })
            .then(onResponse)
            .catch(e => {
                commit('setEventPlacesWithParticipants', { eventPlacesWithParticipants: [] });
            })
            .finally(() => {
                commit('isLoading', false);
            });
    }

};
