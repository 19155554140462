<template>
    <el-collapse>
        <el-collapse-item style="margin-bottom: -2px;">
            <template slot="title">
                {{ item.dateStart }}
            </template>
            <div><span class="service-label">Услуга:</span> {{ item.serviceTitle }}</div>
            <div><span class="service-label">Проводит:</span> {{ item.specialists[0].attributes.name }}</div>
            <div><span class="service-label">Место:</span> {{ item.location }}</div>
        </el-collapse-item>
    </el-collapse>
</template>

<script>
export default {
    name: 'ScheduleParticipantEnrolListItem',
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    }
};
</script>

<style lang="scss">
.service-label {
    color: #979797;
}
</style>
