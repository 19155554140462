import client from '@/api/managerApi';

export default {
    getFilterAge({ eventId }) {
        return client.get(`/widget/participantage/${eventId}/settings`);
    },
    getWidgetTable({ eventId, data }) {
        return client.get(`/widget/participantage/${eventId}`, { params: data });
    }
};
