import { ClientOnlineLk } from '@/api/index';

const defaultState = () => {
    return {
        registerUser: {},
        participant: {
            firstname: '',
            lastname: '',
            date_of_birth: undefined,
            city: undefined,
            online_captain_id: undefined,
            email: undefined,
            phone: '',
            phone2: '',
            termsAgree: true
        }, // Зарегистрированный в лк участник,
        captains: [],
        captainsIsLoading: false,
        authorized: false,
        authorizing: false
    };
};

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        participant: state => state.participant,
        authorizing: state => state.authorizing,
        authorized: state => state.authorized,
        registerUser: state => state.registerUser,
        captains: state => state.captains,
        captainsIsLoading: state => state.captainsIsLoading
    },
    mutations: {
        setParticipant(state, { data }) {
            state.participant = data;
            state.authorized = true;
        },
        registerUser(state, { data }) {
            state.registerUser = data;
        },
        setIsAuthorized(state, { data }) {
            state.authorized = data;
        },
        setAuthorizing(state, { authorizing }) {
            state.authorizing = authorizing;
        },
        setCaptainsIsLoading(state, { loading }) {
            state.captainsIsLoading = loading;
        },
        setCaptains(state, { data }) {
            state.captains = data;
        }
    },
    actions: {
        fetchParticipant({ commit }, { eventId }) {
            return ClientOnlineLk.getParticipants(eventId)
                .then(res => {
                    commit('setParticipant', { data: res.data.data });
                    return Promise.resolve(res);
                })
                .catch(err => Promise.reject(err));
        },
        fetchCaptains({ commit }) {
            commit('setCaptainsIsLoading', { loading: true });
            return ClientOnlineLk.getCaptains()
                .then(response => {
                    commit('setCaptains', { data: response.data.data });
                    return Promise.resolve(response);
                })
                .catch(err => Promise.reject(err))
                .finally(() => commit('setCaptainsIsLoading', { loading: false }));
        },
        addParticipant({ commit }, { eventId, participantId, data }) {
            return ClientOnlineLk.addParticipant(eventId, participantId, data)
                .then(res => Promise.resolve(res))
                .catch(err => Promise.reject(err));
        },

        editParticipant({ commit }, { eventId, participantId, data }) {
            return ClientOnlineLk.editParticipant(eventId, participantId, data)
                .then(res => Promise.resolve(res))
                .catch(err => Promise.reject(err));
        }
    }
};
