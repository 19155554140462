export default {
    LIST: 'event.list',
    ADD: 'event.add',
    CARD: {
        /** {eventId} */
        PARTICIPANTS: 'event.card.participants',
        TRANSFER: 'event.card.transfer',
        HOTEL: 'event.card.hotel',
        STATS: 'event.card.stats',
        SERVICES: 'event.card.services',
        SERVICE: {
            LIST: 'event.card.services.list',
            PARTICIPANTS: 'event.card.services.participants',
            SCHEDULE: 'event.card.services.schedule',
            ADD_PARTICIPANT: 'event.card.services.add-participant',
            SPECIALISTS: 'event.card.services.specialists'
        }
    },
    /** {eventId} */
    EDIT: 'event.edit',

    PARTICIPANT: {
        /** {eventId} */
        LIST: 'event.participant.list',
        /** {eventId} */
        ADD: 'event.participant.add',
        /** {eventId} {participantId} */
        CARD: 'event.participant.card',
        /** {eventId} {participantId} */
        EDIT: 'event.participant.edit'
    },

    API: {
        SERVICE: {
            /** GET */
            INDEX: 'event/{eventId}/service',
            /** POST ?AddServiceToEventRequest */
            STORE: 'event/{eventId}/service',
            /** PUT ?UpdateEventServiceRequest */
            UPDATE: 'event/{eventId}/service/{eventServiceId}',
            /** GET todo: serviceId -> eventServiceId */
            SHOW: 'event/{eventId}/service/{serviceId}',
            /** GET todo: serviceId -> eventServiceId */
            RECORDED_PARTICIPANTS: 'event/{eventId}/service/{serviceId}/participant',

            /** Типы участий в услуге. */
            PLAN: {
                /** DELETE */
                DELETE: 'event/{eventId}/service/{eventServiceId}/plan/{planId}'
            }
        },
        SCHEDULE: {
            INDEX: 'event/{eventId}/schedule',
            /** GET */
            SCHEDULE_PARTICIPANT_INFO: 'event/{eventId}/schedule/participant/{participantId}',
            /** POST ?CreateScheduleRequest */
            STORE: 'event/{eventId}/schedule',
            /** PUT ?UpdateScheduleRequest */
            UPDATE: 'event/{eventId}/schedule/{scheduleId}',
            /** POST ?ScheduleParticipantStoreRequest */
            ADD_PARTICIPANT: 'event/{eventId}/schedule/{scheduleId}/participant',
            REMOVE_PARTICIPANT: 'event/{eventId}/schedule/{scheduleId}/participant/{participantId}',
            /** POST ?CreateScheduleRequest */
            CHECK: 'event/{eventId}/schedule-check',
            /** POST ?CreateScheduleRequest */
            CHECK_UPDATE_REQUEST: 'event/{eventId}/schedule-check/{scheduleId}',
            /** DELETE */
            DELETE: 'event/{eventId}/schedule/{scheduleId}',
            /** GET */
            GET_SERVICE_SELECTOR: 'event/{eventId}/schedule-service-selector',

            /** Типы участий в услуге. */
            PLAN: {
                /** POST */
                STORE: 'event/{eventId}/schedule/{scheduleId}/plan',
                /** DELETE */
                DELETE: 'event/{eventId}/schedule/{scheduleId}/plan/{schedulePlanId}',
                /** PUT ?SchedulePlanUpdateRequest */
                UPDATE: 'event/{eventId}/schedule/{scheduleId}/plan/{schedulePlanId}'
            }
        },
        SPECIALIST: {
            /** GET */
            INDEX: 'event/{eventId}/specialist',
            /** POST ?AddSpecialistToEventRequest */
            STORE: 'event/{eventId}/specialist',
            /** GET */
            SHOW: 'event/{eventId}/specialist/{specialistId}',
            /** PUT ?specialistIds */
            SET_SPECIALISTS: 'event/{eventId}/specialist'
        },
        LOCATIONS: {
            /** GET */
            INDEX: 'event/{eventId}/location',
            /** POST ?AddLocationToEventRequest */
            STORE: 'event/{eventId}/location',
            /** GET */
            SHOW: 'event/{eventId}/location/{locationId}'
        },
        /** GET */
        LIST: 'event',
        /** POST */
        STORE: 'event',
        /** PUT */
        UPDATE: 'event/{eventId}',
        /** GET */
        EVENTS_TO_MOVE: 'event/to-move/{eventId}',

        /** POST */
        MOVE_PERSONAL_TO_EVENT: 'event/import-personnel',

        CARD: {
            /** GET */
            SHOW: 'event/{eventId}'
        },
        /** DELETE */
        DELETE: 'event/{eventId}',

        HOTEL: {
            /** GET */
            CARD: 'event/{eventId}/hotel/{hotelId}',
            /** GET */
            GET_BUILDINGS: 'event/{eventId}/hotel/{hotelId}/buildings',
            /** GET */
            GET_PLACES: 'event/{eventId}/hotel/{hotelId}/places',
            BUILDING: {
                SHOW: '/event/{eventId}/hotel/{hotelId}/buildings/{buildingId}'
            }
        },

        REGISTRATION: {
            /** GET {eventId} */
            GET_EVENT_STATUS: 'event/{eventId}/registration/status',
            /** GET {eventId} ?phone */
            CHECK_PHONE: 'event/{eventId}/registration/phone-status',
            /** GET {eventId} {leaderId} */
            GET_PAYMENT_INFO_FOR_GROUP: 'event/{eventId}/registration/payment-info/{leaderId}',
            /** POST {eventId}
             * {
             *     code: String,
             *     phone: String,
             *     userId: UUID,
             * }
             * */
            GET_LAST_GROUP_BY_PHONE: 'event/{eventId}/get-last-group-by-phone',
            /** GET {eventId} - последняя группа текущего аквторизованного пользователя */
            GET_LAST_GROUP: 'event/{eventId}/get-last-group',
            /** POST {eventId}
             * {
             *     request: CreateParticipantRequest,
             *     participants: CreateParticipantRequest[]
             * }
             * */
            REGISTER: 'event/{eventId}/registration',
            /** POST {eventId} {participantId} */
            PREPAY: 'event/{eventId}/registration/prepay/{participantId}',
            /** POST ?CreateParticipantRequest */
            ADD_GROUP_PARTICIPANT: 'event/{eventId}/registration/{parentParticipantId}/participant',
            /** GET */
            GROUP_PARTICIPANTS: 'event/{eventId}/registration/{parentParticipantId}/participant',
            /** DELETE */
            DELETE_GROUP_PARTICIPANT: 'event/{eventId}/registration/{parentParticipantId}/participant/{participantId}',
            /** PUT */
            UPDATE_GROUP_PARTICIPANT: 'event/{eventId}/registration/{parentParticipantId}/participant/{participantId}'
        },

        PARTICIPANT: {
            /** GET */
            LIST: 'event/{eventId}/participant',
            /** POST */
            STORE: 'event/{eventId}/participant',
            /** PUT */
            UPDATE: 'event/{eventId}/participant/{participantId}',
            UPDATE_CHILDREN_PROGRAM: 'event/{eventId}/participant/{participantId}/children-program',
            /** GET */
            CARD: 'event/{eventId}/participant/{participantId}',
            /** GET */
            FULL_CARD: 'event/{eventId}/participant-full-card/{participantId}',
            /** DELETE */
            DELETE: 'event/{eventId}/participant/{participantId}',

            TRANSACTIONS: {
                LIST: 'events/{eventId}/participants/{participantId}/transactions',
                STORE: 'events/{eventId}/participants/{participantId}/transactions/store'
            },

            MOVE_TO_EVENT: 'move-to-event/{eventId}'
        },

        EXPORTS: {
            XLS: '/events/{eventId}/participants/export/xls',
            RECEPTION: { XLS: '/events/participants-with-keys/export/xls' }
        }
    }
};
