export default {
    /**
     * @param state
     * @param {ScheduleInfoResource | null} scheduleInfoToEdit
     */
    setScheduleInfoToEdit(state, { scheduleInfoToEdit }) {
        state.scheduleInfoToEdit = scheduleInfoToEdit;
    },

    /**
     * Заменяем в стейте нужные структуры для удаления типа участия.
     *
     * @param state
     * @param {SchedulePlanResource} schedulePlan
     */
    removeSchedulePlanFromScheduleInfoToEdit(state, { schedulePlan }) {
        /** @type {ScheduleInfoResource} */
        let scheduleInfoToEdit = state.scheduleInfoToEdit;
        if (!scheduleInfoToEdit) {
            return;
        }
        /** @type {ScheduleResource} */
        let schedule = scheduleInfoToEdit.relationships.schedule;
        if (!schedule) {
            return;
        }
        /** @type {SchedulePlanResource[]} */
        let plans = schedule.relationships.plans.filter(plan => plan.id !== schedulePlan.id);
        schedule = { ...schedule, relationships: { ...schedule.relationships, plans } };
        scheduleInfoToEdit = {
            ...scheduleInfoToEdit,
            relationships: { ...scheduleInfoToEdit.relationships, schedule }
        };
        state.scheduleInfoToEdit = scheduleInfoToEdit;
        /**
         * Удаляем также элемент из списка.
         */
        let scheduleInfos = state.scheduleInfos;
        if (!scheduleInfos) {
            return;
        }
        scheduleInfos = scheduleInfos.filter(x => x.id !== scheduleInfoToEdit.id);
        scheduleInfos.push(scheduleInfoToEdit);
        state.scheduleInfos = [...scheduleInfos];
    },

    /**
     * Заменяем в стейте нужные структуры для добавления нового типа участия.
     *
     * @param state
     * @param {SchedulePlanResource} schedulePlan
     */
    addSchedulePlanToScheduleInfoToEdit(state, { schedulePlan }) {
        /** @type {ScheduleInfoResource} */
        let scheduleInfoToEdit = state.scheduleInfoToEdit;
        if (!scheduleInfoToEdit) {
            return;
        }
        /** @type {ScheduleResource} */
        let schedule = scheduleInfoToEdit.relationships.schedule;
        if (!schedule) {
            return;
        }
        /** @type {SchedulePlanResource[]} */
        let plans = schedule.relationships.plans;
        let existedPlan = plans.find(plan => plan.id === schedulePlan.id);
        if (existedPlan) {
            return;
        }
        plans = [...plans, schedulePlan];
        schedule = { ...schedule, relationships: { ...schedule.relationships, plans } };
        scheduleInfoToEdit = {
            ...scheduleInfoToEdit,
            relationships: { ...scheduleInfoToEdit.relationships, schedule }
        };
        state.scheduleInfoToEdit = scheduleInfoToEdit;
        /**
         * Обновляем также элемент в списке.
         */
        let scheduleInfos = state.scheduleInfos;
        if (!scheduleInfos) {
            return;
        }
        scheduleInfos = scheduleInfos.filter(x => x.id !== scheduleInfoToEdit.id);
        scheduleInfos.push(scheduleInfoToEdit);
        state.scheduleInfos = [...scheduleInfos];
    },

    setParticipantInfo(state, { participantInfo }) {
        state.participantInfo = participantInfo;
    },

    /**
     * @param state
     * @param {"participant" | "specialist"} mode
     */
    setScheduleMode(state, { mode }) {
        state.mode = mode;
    },

    setScheduleInfos(state, { scheduleInfos }) {
        state.scheduleInfos = scheduleInfos;
    },

    /**
     * @param state
     * @param {SpecialistResource | null} selectedSpecialist
     */
    setSelectedSpecialist(state, { selectedSpecialist }) {
        state.selectedSpecialist = selectedSpecialist;
    },

    /**
     * @param state
     * @param {ServiceResource | null} selectedService
     */
    setSelectedService(state, { selectedService }) {
        state.selectedService = selectedService;
    },

    setScheduleIdToRemove(state, { scheduleId }) {
        state.scheduleIdToRemove = scheduleId;
    },

    setScheduleRemoveInProgress(state, scheduleRemoveInProgress) {
        state.scheduleRemoveInProgress = Boolean(scheduleRemoveInProgress);
    },

    setSchedulePlanIdToRemove(state, { schedulePlanId }) {
        state.schedulePlanIdToRemove = schedulePlanId;
    },

    setSchedulePlanRemoveInProgress(state, schedulePlanRemoveInProgress) {
        state.schedulePlanRemoveInProgress = Boolean(schedulePlanRemoveInProgress);
    },

    setRegistrationProcess(state, status) {
        state.registrationFetching = status;
    },

    setRegistrationResult(state, result) {
        state.registrationFetchingInfo = result;
    }
};
