import { ROUTES } from '@/enums';
import ReceptionDashboard from '@/modules/reception/views/Dashboard';
import ParticipantsWithKeysListPrint from '@/modules/print/participants/ParticipantsWithKeysList';

export default [
    {
        path: '',
        name: ROUTES.RECEPTION.DASHBOARD,
        component: ReceptionDashboard
    },
    {
        path: 'print/participants-with-keys',
        name: ROUTES.RECEPTION.PRINT.PARTICIPANT_WITH_KEY_LIST,
        component: ParticipantsWithKeysListPrint
    }
];
