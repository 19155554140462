import { OnlineEvent } from '@/api/index';

const defaultState = {
    loading: false,
    event: {}
};

export default {
    namespaced: true,
    state: { ...defaultState },
    getters: {
        loading: state => state.loading,
        event: state => state.event
    },
    mutations: {
        setLoading(state, { loading }) {
            state.loading = loading;
        },
        setEvent(state, { data }) {
            state.event = data;
        }
    },
    actions: {
        fetchEvent({ commit }, { eventId }) {
            commit('setLoading', { loading: true });
            return OnlineEvent.getOnlineEvent(eventId)
                .then(response => {
                    commit('setEvent', { data: response.data.data.attributes });
                    return Promise.resolve(response);
                })
                .catch(err => Promise.reject(err))
                .finally(() => commit('setLoading', { loading: false }));
        },
        createEvent({ commit }, { data }) {
            commit('setLoading', { loading: true });
            return OnlineEvent.createOnlineEvent(data)
                .then(response => {
                    return Promise.resolve(response);
                })
                .catch(err => Promise.reject(err))
                .finally(() => commit('setLoading', { loading: false }));
        },
        updateEvent({ commit }, { eventId, data }) {
            commit('setLoading', { loading: true });
            return OnlineEvent.updateOnlineEvent(eventId, data)
                .then(response => {
                    return Promise.resolve(response);
                })
                .catch(err => Promise.reject(err))
                .finally(() => commit('setLoading', { loading: false }));
        },
        deleteEvent({ commit }, { eventId }) {
            commit('setLoading', { loading: true });
            return OnlineEvent.deleteOnlineEvent(eventId)
                .then(response => {
                    return Promise.resolve(response);
                })
                .catch(err => Promise.reject(err))
                .finally(() => commit('setLoading', { loading: false }));
        }
    }
};
