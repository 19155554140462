<template>
    <div
        v-loading="isLoading"
        class="print-checking-list"
    >
        <div class="print-checking-list__title">
            Заехавшие гости: {{ preparedList.length }}
        </div>
        <el-table
            :data="preparedList"
        >
            <el-table-column label="ФИО">
                <template slot-scope="scope">
                    {{ scope.row.fullname }}
                </template>
            </el-table-column>
            <el-table-column label="Заселён в">
                <template slot-scope="scope">
                    <i class="far fa-building" /> {{ scope.row.building }}, №{{ scope.row.room }}
                </template>
            </el-table-column>
            <el-table-column label="Заехал">
                <template slot-scope="scope">
                    {{ scope.row.arrival_date }}
                </template>
            </el-table-column>
            <el-table-column label="Выедет">
                <template slot-scope="scope">
                    {{ scope.row.departure_date }}
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import ReceptionApi from '@/modules/reception/api';
import _ from 'lodash';
import moment from 'moment';

export default {
    name: 'ParticipantsWithKeysListForPrinting',
    data() {
        return {
            listItems: [],
            isLoading: false
        };
    },
    computed: {
        preparedList() {
            return this.listItems.map(participant => {
                return {
                    id: _.get(participant, 'id'),
                    fullname: _.get(participant, 'attributes.fullname'),
                    building: _.get(participant, 'relationships.places')[0].relationships.building.attributes.title,
                    room: _.get(participant, 'relationships.places')[0].relationships.room.attributes.title,
                    arrival_date: moment(_.get(participant, 'attributes.arrival_date')).format('D MMMM'),
                    departure_date: moment(_.get(participant, 'attributes.departure_date')).format('D MMMM')
                };
            });
        }
    },
    async mounted() {
        this.isLoading = true;
        let response = await ReceptionApi.getParticipantWithKeyList();
        if (response.success) {
            this.listItems = response.data.data;
        }
        this.isLoading = false;

        setTimeout(() => window.print(), 1000);
    }
};
</script>

<style lang="scss">

.print-checking-list {
    margin: 0 auto;
    padding: 35px 0;
    max-width: 570px;

    &__title {
        margin-bottom: 30px;
        text-align: center;
        padding: 10px 0;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        letter-spacing: 3px;
        font-size: 24px;
        text-transform: uppercase;
    }
}
</style>
