import ParticipantAdd from '@/modules/participant/views/ParticipantAdd.vue';
import ParticipantList from '@/modules/participant/views/ParticipantList.vue';
import ParticipantStatuses from '@/modules/participant/views/ParticipantStatuses';

import { ROUTES } from '@/enums';

export default [
    {
        path: '',
        name: ROUTES.PARTICIPANT.LIST,
        component: ParticipantList
    },

    {
        path: 'add',
        name: 'participant.add',
        component: ParticipantAdd
    },

    {
        path: 'statuses',
        name: ROUTES.PARTICIPANT.STATUSES,
        component: ParticipantStatuses
    }
];
