import _ from 'lodash';
import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import ROUTES from '@/enums/routes';

export default {
    /**
     * @return {Promise<ServiceResource[]>}
     */
    list() {
        return api.get(urlBuilder(ROUTES.SERVICE.API.LIST))
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} serviceId
     * @returns {Promise<ServiceResource>}
     */
    show({ serviceId }) {
        return api.get(urlBuilder(ROUTES.SERVICE.API.SHOW, { serviceId }))
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {CreateServiceRequest} data
     * @returns {Promise<any>}
     */
    store(data) {
        return api.post(urlBuilder(ROUTES.SERVICE.API.STORE), data)
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {CreateServiceRequest} data
     * @returns {Promise<ServiceResource>}
     */
    update(data) {
        return api.put(urlBuilder(ROUTES.SERVICE.API.UPDATE, {
            serviceId: data.id
        }), data)
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} serviceId
     * @return {Promise<Object>}
     */
    remove({ serviceId }) {
        return api.delete(urlBuilder(ROUTES.SERVICE.API.DELETE, { serviceId }))
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    }
};
