<template>
    <div class="app-layout-fullscreen">
        <slot name="content" />
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'LayoutFullscreen',

    computed: {
        ...mapState('sidebar', [
            'isCollapsed'
        ]),

        width() {
            return this.isCollapsed ? '65px' : '300px';
        }
    }
};
</script>

<style lang="scss">
    @import "@vars";

    .app-layout-fullscreen {
        @include flex-row-centered;
        height: 100%;
        background-image: url(/assets/img/fullscreen-bg.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
</style>
