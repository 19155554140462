import { ROUTES } from '@/enums';
import api from '@/api/managerApi';

export default {

    async login({ username, password }) {
        let response;
        try {
            response = await api.post(ROUTES.AUTH.API.MANAGER.LOGIN, { username, password });
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                message: e.message
            };
        }
    },

    async logout() {
        try {
            let response = await api.get(ROUTES.AUTH.API.LOGOUT);
            return {
                success: true
            };
        } catch (e) {
            return {
                success: false,
                message: e.message
            };
        }
    },

    async me() {
        try {
            let response = await api.get(ROUTES.AUTH.API.MANAGER.ME);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                message: e.message
            };
        }
    }
};
