import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import storage from '@/utils/storage';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

const auth = {
    loggedIn() {
        return !!storage.get('Manager.Auth.Token').data;
    },
    userRole() {
        const userData = storage.get('Manager.Auth.User').data;
        return userData ? userData.role : '';
    }
};

router.beforeEach((to, from, next) => {
    if (to.matched.some(m => m.meta.requiresAuth)) {
        // Если нет авторизации, редирект на страницу входа
        if (!auth.loggedIn()) {
            next('/auth/login');
        } else {
            if (to.matched.some(m => m.meta.accessRoles)) {
                let routeWithAccessRoles = to.matched.find(item => item.meta.accessRoles);
                if (routeWithAccessRoles && routeWithAccessRoles.meta.accessRoles.includes(auth.userRole())) {
                    let routeWithDenyTo = to.matched.find(item => item.meta.denyTo);
                    // Если в meta.denyTo[] указана роль пользователя, то переход прекращаем.
                    if (routeWithDenyTo && routeWithDenyTo.meta.denyTo.includes(auth.userRole())) {
                        next(false);
                    } else {
                        next(); // По умолчанию разрешаем переход, если есть requiresAuth и accessRoles[] с role
                        // пользователя из localStorage
                    }
                } else {
                    next('/auth/login');
                }
            } else {
                next(); // Разрешаем переход только если есть requiresAuth
            }
        }
    } else {
        next();
    }
});

export default router;
