import { Hotel } from '@/api';

export default {
    namespaced: true,

    state: {
        /** @type {HotelResource} */
        hotel: null,
        /** @type {Building} */
        buildingToView: null,
        /** @type {Building} */
        buildingToEdit: null,
        /** @type {Room} */
        roomToView: null,
        /** @type {Room} */
        roomToEdit: null
    },

    actions: {
        getHotel({ commit }, { hotelId }) {
            commit('setHotel', { hotel: null });
            return new Promise((resolve, reject) => {
                Hotel.show({ hotelId })
                    .then(hotel => {
                        commit('setHotel', { hotel });
                        resolve({ hotel });
                    })
                    .catch(reject);
            });
        },

        getBuilding({ commit }, { hotelId, buildingId }) {
            commit('setBuildingToView', { buildingToView: null });
            return new Promise((resolve, reject) => {
                Hotel.Building.show({ hotelId, buildingId })
                    .then(buildingToView => {
                        commit('setBuildingToView', { buildingToView });
                        resolve({ building: buildingToView });
                    })
                    .catch(reject);
            });
        },

        getBuildingToEdit({ commit }, { hotelId, buildingId }) {
            commit('setBuildingToEdit', { buildingToEdit: null });
            return new Promise((resolve, reject) => {
                Hotel.Building.show({ hotelId, buildingId })
                    .then(buildingToEdit => {
                        commit('setBuildingToEdit', { buildingToEdit });
                        resolve({ building: buildingToEdit });
                    })
                    .catch(reject);
            });
        },

        getRoom({ commit }, { hotelId, buildingId, roomId }) {
            commit('setRoomToView', { roomToView: null });
            return new Promise((resolve, reject) => {
                const withParticipants = true;
                Hotel.Room.show({ roomId, withParticipants })
                    .then(roomToView => {
                        commit('setRoomToView', { roomToView });
                        resolve({ room: roomToView });
                    })
                    .catch(reject);
            });
        },

        getRoomToEdit({ commit }, { hotelId, buildingId, roomId }) {
            commit('setRoomToEdit', { roomToEdit: null });
            return new Promise((resolve, reject) => {
                const withParticipants = true;
                Hotel.Room.show({ roomId, withParticipants })
                    .then(roomToEdit => {
                        commit('setRoomToEdit', { roomToEdit });
                        resolve({ room: roomToEdit });
                    })
                    .catch(reject);
            });
        }
    },

    getters: {},

    mutations: {
        /**
         * @param state
         * @param {HotelResource} hotel
         */
        setHotel(state, { hotel }) {
            state.hotel = hotel;
        },

        setBuildingToView(state, { buildingToView }) {
            state.buildingToView = buildingToView;
        },

        setBuildingToEdit(state, { buildingToEdit }) {
            state.buildingToEdit = buildingToEdit;
        },

        setRoomToView(state, { roomToView }) {
            state.roomToView = roomToView;
        },

        setRoomToEdit(state, { roomToEdit }) {
            state.roomToEdit = roomToEdit;
        }
    }
};
