export default {
    TRANSFER_REGISTRATION: 'notifications.transfer-registration',
    API: {
        TRANSFER_REGISTRATION: {
            /** GET */
            SHOW: 'notifications/transfer-registration',
            /** PUT */
            UPDATE: 'notifications/transfer-registration'
        }
    }
};
