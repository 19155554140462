<template>
    <div
        v-loading="isLoading"
        class="registration-view"
    >
        <h1 class="app-h1">
            {{ event.attributes.title }}
        </h1>

        <p>{{ event.attributes.description }}</p>


        <div v-if="isRegistrationActive">
            <register-form v-if="isFirstStep" />
            <register-suspended v-else-if="isRegistrationSuspended" />
            <register-payment v-else />
        </div>
        <div v-else-if="isRegistrationEnded">
            <el-alert
                :title="registrationInactiveMessage"
                type="warning"
                :closable="false"
            />
            <h2 class="app-h2">
                Время проведения регистрации:
            </h2>
            <p>
                {{ registrationStart | moment('DD MMMM, YYYY') }}
                &mdash;
                {{ registrationEnd | moment('DD MMMM, YYYY') }}
            </p>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import { Event } from '@/api';
import RegisterPayment from '../components/register/RegisterPayment';
import RegisterLeader from '@/modules/cabinet/components/register/RegisterLeader';
import RegisterGroup from '@/modules/cabinet/components/register/RegisterGroup';
import { mapParticipantResourceToCreateParticipantRequest } from '@/modules/cabinet/mappers';
import RegisterForm from '@/modules/cabinet/components/register/RegisterForm';
import RegisterSuspended from '@/modules/cabinet/components/register/RegisterSuspended';
import RegisterHeaderMessage from '@/modules/cabinet/components/register/RegisterHeaderMessage';

export default {
    name: 'CabinetRegisterView',

    components: {
        RegisterSuspended,
        RegisterForm,
        RegisterPayment
    },

    data() {
        return {
            /** @type {EventStatusResource | null} */
            status: null,
            isLoading: false
        };
    },

    computed: {
        ...mapState('cabinet', [
            'event'
        ]),

        ...mapState('auth', [
            'user',
            'isAuthorized'
        ]),

        ...mapState('cabinet/registration', [
            'participants',
            'leader'
        ]),

        isRegistrationActive() {
            return this.$prop('status.attributes.isActive', false);
        },

        isRegistrationEnded() {
            return this.$prop('status.attributes.isEnded', false);
        },

        isRegistrationSuspended() {
            return this.$prop('status.attributes.isSuspended', false);
        },

        registrationInactiveMessage() {
            return this.isRegistrationEnded
                ? 'Регистрация на событие завершена.'
                : 'Регистрация на событие ещё не началась';
        },

        isFirstStep() {
            return !this.participants ||
                !this.participants.length ||
                !this.leader ||
                !this.isAuthorized;
        },

        eventId() {
            return this.$prop('event.id');
        },

        userId() {
            return this.$prop('user.id');
        },

        registrationStart() {
            return this.$prop('event.attributes.registration_start_at');
        },

        registrationEnd() {
            return this.$prop('event.attributes.registration_end_at');
        }
    },

    watch: {
        eventId() {
            this._getEventStatus();
        },

        userId() {
            this._getGroupInfoForAuthorizedUser();
        }
    },

    async mounted() {
        this.isLoading = true;
        await this._getEventStatus();
        if (this.userId) {
            await this._getGroupInfoForAuthorizedUser();
        }
        this.isLoading = false;
    },

    methods: {
        ...mapMutations('cabinet/registration', [
            'setLeader',
            'setParticipants',
            'setIsRegistrationInProcess'
        ]),

        ...mapMutations('auth', [
            'setAuth'
        ]),

        _getGroupInfoForAuthorizedUser() {
            if (!this.isFirstStep) {
                return;
            }
            /**
             * @param {UUID} response.event_id
             * @param {ParticipantResource[]} response.participants
             */
            const onResponse = response => {
                /** @type {CreateParticipantRequest[]} */
                const participants = response.participants
                    .map(mapParticipantResourceToCreateParticipantRequest);
                this.lastEventId = response.event_id;
                if (this.lastEventId === this.eventId) {
                    /** @type {CreateParticipantRequest} */
                    const leader = _.find(participants, { parent_participant_id: null });
                    if (leader) {
                        this.setLeader({ leader });
                    }
                    this.setParticipants({ participants });
                } else {
                }
            };
            return Event.Registration.getLastGroup({
                eventId: this.eventId
            })
                .then(onResponse)
                .catch(e => {
                });
        },

        /**
         * Информация о сотоянии события: разрешена ли ещё регистрация,
         * доступно ли оно и т.д.
         */
        _getEventStatus() {
            if (!this.eventId) {
                return;
            }
            /**
             * @param {EventStatusResource} status
             */
            const onResponse = status => {
                this.status = status;
            };
            return Event.Registration.getEventStatus({
                eventId: this.eventId
            })
                .then(onResponse)
                .catch(e => {
                    this.$message.error('Не удалось получить статус события');
                });
        }

    }
};
</script>

<style lang="scss">
@import "@vars";

.auth-login-view {
  padding: 50px;
}

.registration-view {
  &__container {
    max-width: 400px;
    margin: 0 auto;
  }

  &-suspended {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    &__text {
      max-width: 500px;
      font-size: 1.5em;
    }
  }
}

</style>
