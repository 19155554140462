export default {
    namespaced: true,

    state: {
        /** @type {CreateParticipantRequest[]} */
        participants: [],
        /** @type {CreateParticipantRequest} */
        leader: null,
        isRegistrationInProcess: false
    },

    actions: {},

    mutations: {
        /**
         * @param state
         * @param {CreateParticipantRequest[]} participants
         */
        setParticipants(state, { participants }) {
            const time = (new Date()).getTime();
            state.participants = participants.map(x => {
                x.__time = time;
                return x;
            });
        },

        addParticipant(state, { participant }) {
            participant.__time = (new Date()).getTime();
            const participants = [...state.participants];
            participants.push(participant);
            state.participants = participants;
        },

        /**
         * @param state
         * @param {Number} index
         * @param {CreateParticipantRequest} participant
         */
        updateParticipant(state, { index, participant }) {
            participant.__time = (new Date()).getTime();
            const participants = [...state.participants];
            participants[index] = participant;
            state.participants = participants;
        },

        removeParticipantByIndex(state, { index }) {
            const participants = [...state.participants];
            participants.splice(index, 1);
            state.participants = participants;
        },

        /**
         * @param state
         * @param {CreateParticipantRequest} leader
         */
        setLeader(state, { leader }) {
            state.leader = leader;
        },

        setIsRegistrationInProcess(state, isRegistrationInProcess) {
            state.isRegistrationInProcess = !!isRegistrationInProcess;
        }
    },

    modules: {}
};
