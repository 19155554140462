<template>
    <div
        v-loading="isLoading"
        class="new-participant-list"
    >
        <el-table
            :data="preparedList"
            :show-header="false"
        >
            <el-table-column>
                <template slot-scope="scope">
                    {{ scope.row.surname }}  {{ scope.row.name }}
                </template>
            </el-table-column>
            <el-table-column width="220px">
                <template slot-scope="scope">
                    <span
                        v-if="scope.row.previousPlace"
                        class="new-participant-with-previous-place"
                    >
                        <span class="previous-place"><i class="far fa-building" />{{ scope.row.previousPlace.building.title }}, №{{ scope.row.previousPlace.room.title }}</span>
                        <i class="fas fa-arrow-down" />
                        <span class="current-place"><i class="far fa-building" />{{ scope.row.building }}, №{{ scope.row.room }}</span>
                    </span>
                    <span
                        v-else
                        class="new-participant-place"
                    >
                        <i class="far fa-building" /><span class="place-number">{{ scope.row.building }}</span>, №<span class="place-number">{{ scope.row.room }}</span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column width="200px">
                <template slot-scope="scope">
                    <el-button
                        type="primary"
                        size="mini"
                        @click="_onGiveKeyClickBtn(scope.row.id)"
                    >
                        <i class="fas fa-key" /> выдан
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'NewParticipantList',
    data() {
        return {
            isLoading: false
        };
    },
    computed: {

        ...mapState('reception', [
            'newParticipantList'
        ]),

        preparedList() {
            return this.newParticipantList.map(participant => {
                return {
                    id: _.get(participant, 'id'),
                    fullname: _.get(participant, 'attributes.fullname'),
                    surname: _.get(participant, 'attributes.surname'),
                    name: _.get(participant, 'attributes.name'),
                    building: _.get(participant, 'relationships.places[0].relationships.building.attributes.title'),
                    room: _.get(participant, 'relationships.places[0].relationships.room.attributes.title'),
                    previousPlace: _.get(participant, 'attributes.previous_place')
                };
            });
        }
    },
    async mounted() {
        this.isLoading = true;
        await this.getNewParticipantList();
        this.isLoading = false;

        this.$echo.channel('Reception')
            .listen('NewParticipant', ({ participant }) => {
                if (!participant.has_room_key) {
                    this.removeParticipantFromLists(participant.id);
                    this.pushNewParticipantToList(participant);
                }
            })
            .listen('ParticipantUpdate', ({ participant }) => {
                this.removeParticipantFromLists(participant.id);
                this.pushNewParticipantToList(participant);
            });
    },
    methods: {
        ...mapActions('reception', [
            'getNewParticipantList',
            'giveAKeyToNewParticipant',
            'removeParticipantFromLists',
            'pushNewParticipantToList'
        ]),

        async _onGiveKeyClickBtn(id) {
            await this.giveAKeyToNewParticipant(id);
        }
    }
};
</script>

<style lang="scss">
    .new-participant-list {
        .el-table {
            font-size: 2em;
        }

        .el-button {
            font-size: 1em;
            padding: 15px;
        }
    }

    .new-participant-place {
        line-height: 2em;
    }

    .new-participant-with-previous-place {
        display: grid;
        grid-template-columns: min-content min-content;
        grid-template-rows: 1.7em 1.7em;

        .previous-place {
            grid-column: 1;
            grid-row: 1;
            white-space: nowrap;
        }

        .fa-arrow-down {
            grid-column: 2;
            grid-row-start: 1;
            grid-row-end: 3;
            align-self: center;
            justify-self: left;
            color: red;
            margin-left: 5px;
        }

        .current-place {
            grid-column: 1;
            grid-row: 2;
            white-space: nowrap;
        }
    }

    .place-number {
        font-size: 1.8em;
    }

    .fa-building {
        margin-right: 2px;
    }
</style>
