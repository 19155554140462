import api from '@/api/managerApi';
import _ from 'lodash';
import urlBuilder from '@/utils/urlBuilder';

export function doGet(urlTpl, urlParams = {}) {
    return api.get(urlBuilder(urlTpl, urlParams))
        .then(r => Promise.resolve(r.data.data))
        .catch(e => Promise.reject(_.get(e, 'response.data', e)));
}

export function doPut(urlTpl, urlParams = {}, payload = {}) {
    return api.put(urlBuilder(urlTpl, urlParams), payload)
        .then(r => Promise.resolve(r.data.data))
        .catch(e => Promise.reject(_.get(e, 'response.data', e)));
}

export function doPost(urlTpl, urlParams = {}, payload = {}) {
    return api.post(urlBuilder(urlTpl, urlParams), payload)
        .then(r => Promise.resolve(r.data.data))
        .catch(e => Promise.reject(_.get(e, 'response.data', e)));
}

export function doDelete(urlTpl, urlParams = {}) {
    return api.delete(urlBuilder(urlTpl, urlParams))
        .then(r => Promise.resolve(r.data.data))
        .catch(e => Promise.reject(_.get(e, 'response.data', e)));
}
