import actions from './actions';
import mutations from './mutations';
import { EVENT } from '@/enums';

export default {
    namespaced: true,
    state: {
        /** @type {"specialist" | "participant"} */
        // mode: 'specialist',
        mode: EVENT.SCHEDULE.MODE.SERVICE,
        /** @type {ScheduleInfoResource[]} */
        scheduleInfos: [],
        /** @type {ScheduleParticipantInfoResource | null} */
        participantInfo: null,
        /** @type {SpecialistResource | null} */
        selectedSpecialist: null,
        /** @type {ServiceResource | null} */
        selectedService: null,
        scheduleIdToRemove: null,
        scheduleRemoveInProgress: false,
        setSchedulePlanIdToRemove: null,
        schedulePlanRemoveInProgress: false,
        /** @type {ScheduleInfoResource | null} */
        scheduleInfoToEdit: null,

        /** @type {Boolean} */
        registrationFetching: false,

        registrationFetchingInfo: {}
    },
    getters: {},
    mutations,
    actions
};
