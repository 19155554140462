import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import ROUTES from '@/enums/routes';

export default {
    store(data) {
        return api.post(urlBuilder(ROUTES.HOTEL.API.BUILDING.STORE), data)
            .then(response => Promise.resolve(response.data.data));
    },

    /**
     * @param {CreateBuildingRequest} data
     * @returns {Promise<BuildingResource>}
     */
    update(data) {
        return api.put(urlBuilder(ROUTES.HOTEL.API.BUILDING.UPDATE, {
            buildingId: data.building_id
        }), data)
            .then(response => Promise.resolve(response.data.data));
    },

    remove({ hotelId, buildingId }) {
        return api.delete(urlBuilder(ROUTES.HOTEL.API.BUILDING.DELETE, { buildingId }))
            .then(response => Promise.resolve(response.data.data));
    },

    getBuildingShowResource() {
        // todo
    },

    /**
     * @param hotelId
     * @param buildingId
     * @returns {Promise<Building>}
     */
    show({ hotelId, buildingId }) {
        return api.get(urlBuilder(ROUTES.HOTEL.API.BUILDING.SHOW, { buildingId }))
            .then(response => Promise.resolve(response.data.data));
    }
};
