<template>
    <section>
        <el-form
            ref="form"
            :model="form"
            :rules="rules"
        >
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item
                        label="Имя"
                        prop="name"
                    >
                        <el-input
                            v-model="form.name"
                            placeholder="Иван"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="Фамилия"
                        prop="surname"
                    >
                        <el-input
                            v-model="form.surname"
                            placeholder="Иванов"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item
                        label="Имя в дательном падеже"
                        prop="dative_name"
                    >
                        <el-input
                            v-model="form.dative_name"
                            placeholder="Иванову"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item
                        label="Логин"
                        prop="username"
                    >
                        <el-input
                            v-model="form.username"
                            placeholder="ivanov"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="Пароль"
                        prop="password"
                    >
                        <el-input
                            v-model="form.password"
                            type="password"
                            show-password
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item
                        label="Роль"
                        prop="role"
                    >
                        <el-select
                            v-model="form.role"
                            placeholder="Выберите роль"
                        >
                            <el-option
                                v-for="role in roles"
                                :key="role.value"
                                :label="role.title"
                                :value="role.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-switch
                        v-model="form.enabled"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-text="Активен"
                        inactive-text="Отключен"
                    />
                </el-col>
            </el-row>
            <el-row>
                <el-button
                    type="primary"
                    @click="_onAddClickBtn"
                >
                    Добавить
                </el-button>
            </el-row>
        </el-form>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import ROLES from '../enums/roles';
import ROUTES from '../enums/routes';

export default {
    name: 'ManagerAddForm',

    props: {
        managerId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            form: {
                name: null,
                surname: null,
                dative_name: null,
                username: null,
                password: null,
                role: ROLES.MANAGER,
                enabled: true
            },
            rules: {
                name: [
                    { required: true, message: 'Введите данные', trigger: 'blur' },
                    { min: 3, message: 'Должно содержать минимум 3 симовола', trigger: 'blur' }
                ],
                surname: [
                    { required: true, message: 'Введите данные', trigger: 'blur' },
                    { min: 3, message: 'Должно содержать минимум 3 симовола', trigger: 'blur' }
                ],
                dative_name: [
                    { required: true, message: 'Введите данные', trigger: 'blur' },
                    { min: 3, message: 'Должно содержать минимум 3 симовола', trigger: 'blur' }
                ],
                username: [
                    { required: true, message: 'Введите данные', trigger: 'blur' },
                    { min: 3, message: 'Должно содержать минимум 3 симовола', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: 'Введите данные', trigger: 'blur' },
                    { min: 6, message: 'Должно содержать минимум 6 символов', trigger: 'blur' }
                ]
            }
        };
    },

    computed: {
        roles() {
            return [
                {
                    title: ROLES.RU.MANAGER,
                    value: ROLES.MANAGER
                },
                {
                    title: ROLES.RU.HOTEL,
                    value: ROLES.HOTEL
                }
            ];
        }
    },
    mounted() {
        if (this.managerId) {
            this._getManagerData();
        }
    },

    methods: {
        ...mapActions('manager', [
            'addManager',
            'getManager',
            'updateManager'
        ]),

        _onAddClickBtn() {
            this._submitForm('form');
        },

        async _tryUpdateManager() {
            let response = await this.addManager(this.form);
            if (response.success) {
                this.$message.success(response.data);
                this.$router.push(
                    {
                        name: ROUTES.LIST
                    }
                );
            } else {
                this.$message.error(JSON.stringify(response.error));
            }
        },

        _submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this._tryUpdateManager(this.form);
                } else {
                    return false;
                }
            });
        },

        async _getManagerData() {
            let response = await this.getManager(this.managerId);
            if (response.success) {
                this.form = response.data;
            } else {
                this.$message.error('Ошибка: ' + JSON.stringify(response.error));
                this.$router.push({ name: ROUTES.LIST });
            }

        }
    }
};
</script>

<style scoped>

</style>
