import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ru';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import storage from './utils/storage';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ru-RU';
import VueTheMask from 'vue-the-mask';
import VueMoment from 'vue-moment';
import CONFIG from '@/config';
import '@/directives';
import Echo from 'laravel-echo';
import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import AccessManager from '@/services/AccessManager';
import OnlineLkAccessManager from '@/services/OnlineLkAccessManager';
import socketIoClient from 'socket.io-client';
import pusher from 'pusher-js';

/**
 * локаль для флатпикера устанавливаем глобально
 * сам компонент подключается пока локально
 * */
flatpickr.localize(Russian); // default locale is now Russian

Vue.use(VueMoment, { moment });
Vue.use(VueTheMask);

moment.locale('ru');

/**  Изменяем неподходящие локали */
locale.el.pagination.pagesize = ' на стр.';

Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });

Vue.prototype.$prop = function(path, defaultValue = undefined) {
    return _.get(this, path, defaultValue);
};

Vue.prototype.$storage = storage;

/**
 * Настройка Echo для подключения к laravel-websockets
 */
window.io = socketIoClient;
window.Pusher = pusher;
Vue.prototype.$echo = new Echo({
    broadcaster: 'pusher',
    key: 'fest',
    wsHost: CONFIG.LARAVEL_ECHO_HOST,
    wsPort: CONFIG.WEBSOCKET_PORT,
    wssPort: CONFIG.WEBSOCKET_SECURE_PORT,
    enabledTransports: ['ws', 'wss'],
    disableStats: true,
    forceTLS: CONFIG.WEBSOCKET_FORCE_TLS === 'true',
    authEndpoint: CONFIG.API_HOST + '/api/broadcasting/auth'
});

Vue.prototype.$accessManager = new AccessManager({
    store,
    router,
    loading: ElementUI.Loading.service,
    message: ElementUI.Message
});

Vue.prototype.$onlineLkAccessManager = new OnlineLkAccessManager({
    store,
    router,
    loading: ElementUI.Loading.service,
    message: ElementUI.Message
});

Vue.use({
    install: function(Vue, options) {
        Object.defineProperty(Vue.prototype, 'uniqId', {
            get: function uniqId() {
                return this._uid;
            }
        });
    }
});
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
