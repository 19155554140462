import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import { ROUTES } from '@/enums';
import _ from 'lodash';
import { doGet } from '@/utils/api';

export default {
    /**
     * @param {UUID} eventId
     * @param {UUID} scheduleId
     * @return {Promise<>}
     */
    removeSchedule({ eventId, scheduleId }) {
        const url = urlBuilder(ROUTES.EVENT.API.SCHEDULE.DELETE, { eventId, scheduleId });
        return api.delete(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} eventId
     * @param {UUID} specialistId
     * @return {Promise<ScheduleInfoResource[]>}
     */
    getScheduleInfos({ eventId, specialistId }) {
        const url = urlBuilder(ROUTES.EVENT.API.SCHEDULE.INDEX, { eventId });
        return api.get(url, {
            params: {
                main_specialist_id: specialistId
            }
        })
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} eventId
     * @param {UUID} participantId
     * @return {Promise<ScheduleParticipantInfoResource>}
     */
    getScheduleParticipantInfo({ eventId, participantId }) {
        const url = urlBuilder(ROUTES.EVENT.API.SCHEDULE.SCHEDULE_PARTICIPANT_INFO, {
            eventId,
            participantId
        });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} eventId
     * @param {CreateScheduleRequest} request
     */
    createSchedule({ eventId, request }) {
        const url = urlBuilder(ROUTES.EVENT.API.SCHEDULE.STORE, { eventId });
        return api.post(url, request)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} eventId
     * @param {UUID} scheduleId
     * @param {UpdateScheduleRequest} request
     */
    updateSchedule({ eventId, scheduleId, request }) {
        const url = urlBuilder(ROUTES.EVENT.API.SCHEDULE.UPDATE, { eventId, scheduleId });
        return api.put(url, request)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} eventId
     * @param {CreateScheduleRequest} request
     */
    checkScheduleStoreRequest({ eventId, request }) {
        const url = urlBuilder(ROUTES.EVENT.API.SCHEDULE.CHECK, { eventId });
        return api.post(url, request)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} eventId
     * @param {UUID} scheduleId
     * @param {UpdateScheduleRequest} request
     */
    checkScheduleUpdateRequest({ eventId, scheduleId, request }) {
        const url = urlBuilder(ROUTES.EVENT.API.SCHEDULE.CHECK_UPDATE_REQUEST, { eventId, scheduleId });
        return api.post(url, request)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} eventId
     * @param {UUID} scheduleId
     * @param {ScheduleParticipantStoreRequest} request
     * @return {Promise<{}>}
     */
    addParticipantToSchedule({ eventId, scheduleId, request }) {
        const url = urlBuilder(ROUTES.EVENT.API.SCHEDULE.ADD_PARTICIPANT, { eventId, scheduleId });
        return api.post(url, request)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} eventId
     * @param {UUID} scheduleId
     * @param {UUID} participantId
     * @return {Promise<{}>}
     */
    removeParticipantFromSchedule({ eventId, scheduleId, participantId }) {
        const url = urlBuilder(ROUTES.EVENT.API.SCHEDULE.REMOVE_PARTICIPANT, { eventId, scheduleId, participantId });
        return api.delete(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    async getRecordedParticipants({ eventId, serviceId }) {
        const url = urlBuilder(ROUTES.EVENT.API.SERVICE.RECORDED_PARTICIPANTS, { eventId, serviceId });
        try {
            let response = await api.get(url);
            return {
                success: true,
                data: response.data.data,
                meta: response.data.meta
            };
        } catch (e) {
            return {
                success: false,
                message: e.message
            };
        }
    },

    /**
     * @param {UUID} eventId
     * @param {String} filter
     * @returns {Promise<*>}
     */
    getServiceSelector({ eventId, filter = '' }) {
        return doGet(ROUTES.EVENT.API.SCHEDULE.GET_SERVICE_SELECTOR, { eventId });
    }
};
