export default {
    /**
     * @param state
     * @param {EventResource} event
     */
    setResource(state, { event }) {
        state.resource = event;
    },

    setActiveTabRoute(state, { activeTabRoute }) {
        state.activeTabRoute = activeTabRoute;
    },

    isLoading(state, isLoading) {
        state.isLoading = isLoading;
    },

    setEventsToMove(state, eventsToMove) {
        state.eventsToMove = [...eventsToMove];
    }
};
