export default {
    LOGIN: 'auth.login',

    API: {
        MANAGER: {
            ME: '/auth/manager/me',
            LOGIN: '/auth/manager/login'
        },
        USER: {
            ME: '/auth/user/me'
        },
        /** POST */
        TEST_PHONE: '/auth/test-phone',
        LOGOUT: '/auth/logout'
    }
};
