<template>
    <div class="user-list-view">
        <h1>
            Добавить пользователя
        </h1>
        <manager-add-form />
    </div>
</template>

<script>

import ManagerAddForm from '@/modules/manager/components/ManagerAddForm';
import breadcrumbs from '@/mixins/breadcrumbs';
import ROUTES from '../enums/routes';

export default {
    name: 'ManagerAddView',
    components: { ManagerAddForm },
    mixins: [
        breadcrumbs
    ],
    computed: {
        $breadcrumbs() {
            return [
                {
                    title: 'Пользователи',
                    route: ROUTES.LIST
                },
                {
                    title: 'Добавить',
                    route: ROUTES.ADD
                }
            ];
        }
    }
};
</script>
