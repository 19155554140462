import _ from 'lodash';
import Event from '@/modules/event/api';
import Hotel from '@/modules/hotel/api';
import Participant from '@/modules/participant/api';

export default {
    /**
     * @param commit
     * @param eventId
     * @param {Number} page
     * @param {EventParticipantsListFilter | null} filter
     * @return {Promise<any>}
     */
    getParticipants({ commit }, { eventId, page, filter = null }) {
        commit('isLoading', true);
        return new Promise((resolve, reject) => {
            /** @param {EventParticipantsIndexResource} resource */
            const onResponse = resource => {
                commit('setParticipants', {
                    participants: resource.data,
                    meta: resource.meta,
                    links: resource.links
                });
                resolve({ participants: resource });
            };
            const search = _.trim(_.get(filter, 'search', null));
            const fio = _.trim(_.get(filter, 'fio', null));
            const status = _.trim(_.get(filter, 'status', null));
            Event.Participant.list({ eventId, page, search, fio, status })
                .then(onResponse)
                .catch(reject)
                .finally(() => commit('isLoading', false));
        });
    },

    getParticipant({ commit }, { eventId, participantId }) {
        commit('setParticipant', {
            participant: null
        });
        commit('setIsParticipantLoading', true);
        return new Promise((resolve, reject) => {
            Event.Participant.show({ eventId, participantId })
                .then(participant => {
                    commit('setParticipant', {
                        participant
                    });
                    resolve({ participant });
                })
                .catch(reject)
                .finally(() => commit('setIsParticipantLoading', false));
        });
    },

    getParticipantFullCard({ commit }, { eventId, participantId }) {
        commit('isLoading', true);
        return new Promise((resolve, reject) => {
            Event.Participant.getFullCard({ eventId, participantId })
                .then(participantCard => {
                    commit('setParticipantCard', {
                        participantCard
                    });
                    resolve({ participantCard });
                })
                .catch(reject)
                .finally(() => {
                    commit('isLoading', false);
                });
        });
    },

    async participantCheckout({ commit }, { participantId }) {
        let response = await Hotel.Place.checkOut({ participantId });
        if (response.success) {
            return {
                success: true,
                data: response.data.message
            };
        } else {
            return {
                success: false,
                data: response.message
            };
        }
    },

    async getTransactions({ commit }, { eventId, participantId }) {
        let response = await Event.Transactions.list({ eventId, participantId });
        if (response.success) {
            /** @type {TransactionResource} response.data */
            commit('setTransactions', { transactions: response.data });
        }
    },

    async getParticipantGroup({ commit }, { participantId }) {
        let response = await Event.Participant.getParticipantGroup({ participantId });
        return response;
    },

    async updateStatus({ commit }, data) {
        let response = await Event.Participant.updateStatus(data);
        return response;
    },

    async updateComment({ commit }, { participantId, comment }) {
        let response = await Participant.updateComment({ participantId, comment });
        return response;
    },

    moveParticipantsToEvent({ commit }, { eventId, participants }) {
        return Participant.moveParticipantsToEvent({ eventId, participants })
            .then(response => {
                commit('setSelectedParticipants', { selectedParticipants: response });
                return Promise.resolve(response);
            })
            .catch(e => Promise.reject(`Ошибка ${e}`));
    },

    async addHistoryEvent(_, { participants, eventId, status }) {
        let response = await Event.Participant.addHistoryEvent({ participants, eventId, status });
        return response;
    },

    deleteParticipant(_, { participantId }) {
        return Participant.deleteParticipant(participantId);
    },

    /**
     * Сброс галочки обзвона для event
     * @param commit
     * @param eventId
     * @returns {*}
     */
    removeHasPhonedOfEvent({ commit }, eventId) {
        return Participant.removeHasPhonedOfEvent(eventId);
    }
};

/**
 * @typedef {Object} EventParticipantsListFilter
 * @property {String | null} [fio] - фильтр по ФИО с поддержкой нечеткого поиска
 * @property {String | null} [search] - фильтр по прочим полям
 * @property {EventParticipantStatus | null} [status] - фильтр по стутусу участника
 */
