export default {
    LIST: 'service.list',
    API: {
        /** GET */
        LIST: 'service',
        /** GET */
        SHOW: 'service/{serviceId}',
        /** PUT */
        UPDATE: 'service/{serviceId}',
        /** POST */
        STORE: 'service',
        /** DELETE */
        DELETE: 'service/{serviceId}'
    }
};
