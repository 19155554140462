import mutations from './mutations';
import actions from './actions';

import hotel from './modules/hotel';
import participants from './modules/participants';
import service from './modules/service';
import transfer from './modules/transfer/transferParticipants';

export default {
    namespaced: true,
    state: {
        isLoading: false,
        /** @type {EventResource | null} */
        resource: null,
        /**
         * Маршрут активной вкладки карточки события.
         * @type {String | null}
         */
        activeTabRoute: null,
        eventsToMove: null
    },
    getters: {},
    mutations,
    actions,
    modules: {
        participants,
        service,
        hotel,
        transfer
    }
};
