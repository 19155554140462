<template>
    <div class="guest-list">
        <h3>
            Зарегистрировать гостя на Фестиваль "Благость"
        </h3>
        <el-row>
            <el-input
                v-model="phone"
                placeholder="Номер телефона"
            />
        </el-row>

        <el-row>
            <el-button type="primary">
                Продолжить
            </el-button>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'GuestAdd',

    data() {
        return {
            phone: null
        };
    }

};
</script>
