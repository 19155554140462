import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import { ROUTES } from '@/enums';
import _ from 'lodash';

export default {
    /**
     * @param {UUID} eventId
     * @param {Number} page
     * @param {String | null} search - поиск ILIKом по почте, телефону
     * @param {String | null} fio - нечеткий поиск по ФИО
     * @param {EventParticipantStatus | null} status
     * @returns {Promise<EventParticipantsIndexResource>}
     */
    list({ eventId, page, search = null, fio = null, status = null }) {
        const url = urlBuilder(ROUTES.EVENT.API.PARTICIPANT.LIST, { eventId });
        return api.get(url, {
            params: {
                page,
                search,
                fio,
                status
            }
        })
            .then(response => Promise.resolve(response.data));
    },

    /**
     * @param {UUID} eventId
     * @param {UUID} participantId
     * @returns {Promise<ParticipantResource>}
     */
    show({ eventId, participantId }) {
        return api.get(urlBuilder(ROUTES.EVENT.API.PARTICIPANT.CARD, {
            eventId,
            participantId
        }))
            .then(response => Promise.resolve(response.data.data));
    },

    getFullCard({ eventId, participantId }) {
        const url = urlBuilder(ROUTES.EVENT.API.PARTICIPANT.FULL_CARD, {
            eventId,
            participantId
        });
        return api.get(url, {
            params: {
                _with: [
                    'PlaceResource.Hotel',
                    'PlaceResource.Building',
                    'PlaceResource.Room'
                ]
            }
        })
            .then(response => Promise.resolve(response.data.data));
    },

    /**
     * @param {UUID} eventId
     * @param {CreateParticipantRequest} data
     * @returns {Promise<ParticipantResource>}
     */
    store(eventId, data) {
        return api.post(urlBuilder(ROUTES.EVENT.API.PARTICIPANT.STORE, { eventId }), data)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => {
                if (e && e.response && e.response.data) {
                    return Promise.reject(e.response.data);
                } else {
                    return Promise.reject(e);
                }
            });
    },

    /**
     * @param {UUID} eventId
     * @param {CreateParticipantRequest} data
     * @returns {Promise<ParticipantResource>}
     */
    update(eventId, data) {
        return api.put(urlBuilder(ROUTES.EVENT.API.PARTICIPANT.UPDATE, {
            eventId,
            participantId: data.participant_id
        }), data)
            .then(response => Promise.resolve(response.data.data));
    },

    /**
     * @param {UUID} eventId
     * @param {UUID} participantId
     * @param {Boolean} childrenProgram
     * @return {Promise<any | never>}
     */
    updateChildrenProgram({ eventId, participantId, childrenProgram }) {
        return api.put(urlBuilder(ROUTES.EVENT.API.PARTICIPANT.UPDATE_CHILDREN_PROGRAM, {
            eventId,
            participantId
        }), { childrenProgram })
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    remove(eventId, participantId) {
        let url = `event/${eventId}/participant/${participantId}`;
        return api.delete(url, {
            eventId,
            participantId
        })
            .then(response => Promise.resolve(response.data.data));
    },

    /** @type UpdateParticipantStatus data */
    async updateStatus(data) {
        try {
            let response = await api.put(ROUTES.PARTICIPANT.API.UPDATE.STATUS, data);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            if (e && e.response && e.response.data) {
                return {
                    success: false,
                    data: 'Ошибка: ' + e.response.data
                };
            }
        }
    },

    async getParticipantGroup({ participantId }) {
        try {
            let response = await api.get(urlBuilder(ROUTES.PARTICIPANT.API.GROUP, {
                participantId
            }));
            return {
                success: true,
                data: response.data.data
            };
        } catch (e) {
            if (e && e.response && e.response.data) {
                return {
                    success: false,
                    data: 'Ошибка: ' + e.response.data
                };
            }
        }
    },

    async addHistoryEvent({ participants, eventId, status }) {
        try {
            let response = await api.post(
                urlBuilder(ROUTES.EVENT.API.PARTICIPANT.MOVE_TO_EVENT, { eventId }),
                { participants, status }
            );
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            if (e && e.response && e.response.data) {
                return {
                    success: false,
                    data: 'Ошибка: ' + e.response.data
                };
            }
        }
    }
};
