export default {
    namespaced: true,
    state: {
        /** @type {Boolean} */
        isCollapsed: true
    },
    actions: {},
    getters: {},
    mutations: {
        /**
         * @param state
         * @param {Boolean} isCollapsed
         */
        setIsCollapsed(state, isCollapsed) {
            state.isCollapsed = isCollapsed;
        }
    }
};
