import client from '@/api/userApi';

export default {
    /**
     * Получение списка доступных мест
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    getPlaces(params) {
        return client.get('/lk/transfer/places', { params });
    },

    getTransferData(eventId) {
        return client.get(`/lk/${eventId}/transfer`);
    },

    updateTransferData(eventId, data) {
        return client.put(`/lk/${eventId}/transfer`, data);
    }
};
