import { ROUTES } from '@/enums';

/**
 * Components
 */
const NotificationsTransferRegistrationView = () => import(/* webpackChunkName: "NotificationsTransferRegistrationView" */ '@/modules/notifications/views/NotificationsTransferRegistrationView');

export default [
    {
        path: 'transfer-registration',
        name: ROUTES.NOTIFICATIONS.TRANSFER_REGISTRATION,
        component: NotificationsTransferRegistrationView
    }
];
