import { render, staticRenderFns } from "./RegisterSuspended.vue?vue&type=template&id=9cc92600&scoped=true&"
import script from "./RegisterSuspended.vue?vue&type=script&lang=js&"
export * from "./RegisterSuspended.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cc92600",
  null
  
)

export default component.exports