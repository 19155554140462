<template>
    <div />
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: 'AuthService',

    computed: {
        ...mapState('auth/manager', {
            managerStoreToken: 'accessToken'
        }),

        userAccessToken() {
            return this.$storage.get('Auth.Token').data;
        },
        managerAccessToken() {
            return this.$storage.get('Manager.Auth.Token').data;
        }
    },

    watch: {
        managerStoreToken(token) {
            this.addTokenToEcho(token);
        }
    },

    mounted() {
        if (this.managerAccessToken) {
            this._tryLoginManager();
        }
        if (this.userAccessToken) {
            this._tryLoginUser();
        }
    },

    methods: {
        ...mapActions('auth/manager', {
            managerMe: 'me'
        }),

        ...mapActions('auth', {
            userMe: 'me'
        }),

        async _tryLoginUser() {
            await this.userMe({ accessToken: this.userAccessToken });
        },

        async _tryLoginManager() {
            await this.managerMe({ accessToken: this.managerAccessToken });
        },

        addTokenToEcho(token) {
            this.$echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + token;
        }
    }
};
</script>
