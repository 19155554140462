<template>
    <div class="event-service-participants">
        <div class="event-service-participants-header">
            <div class="event-service-participants-header__title">
                {{ title }}
            </div>
        </div>
        <div class="event-service-participants-body">
            <div
                v-for="(value, key) in list"
                :key="key"
                class="event-service-participants-list-item"
            >
                <div class="event-service-participants-list-item__date">
                    {{ key }}
                </div>
                <div
                    v-for="(item, itemIndex) in value"
                    :key="itemIndex"
                    class="event-service-participants-list-item-participants"
                >
                    <div class="event-service-participants-list-item-participants__time">
                        {{ item.time }}
                    </div>
                    <div class="event-service-participants-list-item-participants__item">
                        <div
                            v-for="(participant, index) in item.participants"
                            :key="participant.id"
                            class="event-service-participant-data"
                        >
                            <div class="event-service-participant-data__fullname">
                                {{ index+1 }}. {{ participant.fullname }}                                 <div class="event-service-participant-data__plan">
                                    {{ participant.plan }}
                                </div>
                            </div>
                            <div class="event-service-participant-data__phone">
                                {{ participant.phone }}
                                {{ participant.phone2 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import JSONStorage from '@/utils/storage';
import _ from 'lodash';

export default {
    name: 'ServiceScheduledParticipants',
    data() {
        return {
            title: null,
            list: null
        };
    },

    created() {
        this._getPrintData();
    },

    mounted() {
        if (this.list) {
            setTimeout(() => window.print(), 1000);
        }
        this.clearStorage();
    },

    destroyed() {
        this.clearStorage();
    },
    methods: {

        _getPrintData() {
            let printData = JSONStorage.get('ServiceScheduledParticipants');
            this.title = _.get(printData, 'data.title', 'Нет данных');
            this.list = _.get(printData, 'data.list', []);
        },

        clearStorage() {
            JSONStorage.remove('ServiceScheduledParticipants');
        }
    }
};
</script>

<style lang="scss" scoped>
.event-service-participants {
    margin: 0 auto;
    max-width: 550px;

    .event-service-participant-data {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        padding: 17px 0;
        border-bottom: 1px solid #ebeef5;

        &__plan {
            font-size: 12px;
        }
    }

    .event-service-participants-list-item {
        padding: 16px 0;

        &__date {
            font-size: 24px;
            margin-bottom: 15px;
        }
    }

    .event-service-participants-list-item-participants {
        display: flex;
        border-top: 1px solid #000;

        &__time {
            flex: 1;
            font-size: 18px;
            padding-top: 16px;
        }

        &__item {
            flex: 3;
            display: flex;
            flex-direction: column;
        }
    }

    &-header {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 600px;

        &__title {
            font-size: 36px;
            text-align: center;
        }

        &__icon {
            font-size: 36px;
            color: #1871f8;
            cursor: pointer;
        }
    }

}

</style>
