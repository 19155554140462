/**
 * @enum {string}
 */
export default {
    TYPES: {
        INCOME: 'income',
        EXPENSE: 'expense'
    },
    CATEGORIES: {
        PREPAY: 'prepay',
        CHECKIN: 'checkin',
        CONSULTATION: 'consultation'
    }
};
