import HotelAddView from '@/modules/hotel/views/HotelAddView';
import HotelEditView from '@/modules/hotel/views/HotelEditView';
import HotelListView from '@/modules/hotel/views/HotelListView';
import HotelCardView from '@/modules/hotel/views/HotelCardView';
import HotelBuildingEditView from '@/modules/hotel/views/HotelBuildingEditView';
import HotelBuildingAddView from '@/modules/hotel/views/HotelBuildingAddView';
import HotelBuildingView from '@/modules/hotel/views/HotelBuildingView';
import HotelBuildingRoomView from '@/modules/hotel/views/HotelBuildingRoomView';
import HotelBuildingRoomEditView from '@/modules/hotel/views/HotelBuildingRoomEditView';
import HotelBuildingRoomAddView from '@/modules/hotel/views/HotelBuildingRoomAddView';
import { ROUTES } from '@/enums';

export default [
    {
        path: '',
        name: ROUTES.HOTEL.LIST,
        component: HotelListView
    },

    {
        path: 'add',
        name: ROUTES.HOTEL.ADD,
        component: HotelAddView
    },

    {
        path: ':hotelId',
        name: ROUTES.HOTEL.CARD.SHOW,
        component: HotelCardView
    },

    {
        path: ':hotelId/edit',
        name: ROUTES.HOTEL.EDIT,
        component: HotelEditView
    },

    /** Номер */
    {
        path: ':hotelId/buildings/:buildingId/rooms/add',
        name: ROUTES.HOTEL.CARD.BUILDING.ROOM.ADD,
        component: HotelBuildingRoomAddView
    },

    {
        path: ':hotelId/buildings/:buildingId/rooms/:roomId',
        name: ROUTES.HOTEL.CARD.BUILDING.ROOM.SHOW,
        component: HotelBuildingRoomView
    },

    {
        path: ':hotelId/buildings/:buildingId/rooms/:roomId/edit',
        name: ROUTES.HOTEL.CARD.BUILDING.ROOM.EDIT,
        component: HotelBuildingRoomEditView
    },

    {
        path: ':hotelId/buildings/add',
        name: ROUTES.HOTEL.CARD.BUILDING.ADD,
        component: HotelBuildingAddView
    },

    /** Корпус */
    {
        path: ':hotelId/buildings/:buildingId',
        name: ROUTES.HOTEL.CARD.BUILDING.SHOW,
        component: HotelBuildingView
    },

    {
        path: ':hotelId/buildings/:buildingId/edit',
        name: ROUTES.HOTEL.CARD.BUILDING.EDIT,
        component: HotelBuildingEditView
    }
];
