import _ from 'lodash';
import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import { ROUTES } from '@/enums';

export default {

    /**
     * Получение расширенной информации для отеля
     * в рамках события.
     *
     * @param eventId
     * @param hotelId
     * @returns {PromiseLike<EventHotelCardResource>}
     */
    getInfo({ eventId, hotelId }) {
        const url = urlBuilder(ROUTES.EVENT.API.HOTEL.CARD, { eventId, hotelId });
        return api.get(url, {
            params: {
                _with: [
                    'PlaceResource.Participants'
                ]
            }
        })
            .then(response => Promise.resolve(response.data));
    },

    /**
     * Список корпусов для отеля с информацией по событию.
     *
     * @param eventId
     * @param hotelId
     * @returns {PromiseLike<BuildingResource[]>}
     */
    getBuildings({ eventId, hotelId }) {
        const url = urlBuilder(ROUTES.EVENT.API.HOTEL.GET_BUILDINGS, { eventId, hotelId });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    getPlaces({ eventId, hotelId }) {
        const url = urlBuilder(ROUTES.EVENT.API.HOTEL.GET_PLACES, { eventId, hotelId });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    async getBuildingData({ eventId, hotelId, buildingId }) {
        const url = urlBuilder(ROUTES.EVENT.API.HOTEL.BUILDING.SHOW, {
            eventId,
            hotelId,
            buildingId
        });
        try {
            let response = await api.get(url);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                message: e.message
            };
        }
    }
};
