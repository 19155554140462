import { ROUTES } from '@/enums';
import ManagerListView from '@/modules/manager/views/ManagerListView';
import ManagerAddView from '@/modules/manager/views/ManagerAddView';
import ManagerEditView from '@/modules/manager/views/ManagerUpdateView';

export default [
    {
        path: '',
        name: ROUTES.MANAGER.LIST,
        component: ManagerListView
    },
    {
        path: 'add',
        name: ROUTES.MANAGER.ADD,
        component: ManagerAddView
    },
    {
        path: ':managerId/edit',
        name: ROUTES.MANAGER.EDIT,
        component: ManagerEditView
    }
];
