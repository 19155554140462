<template>
    <div class="fest-app">
        <router-view />
        <auth-service />
    </div>
</template>

<script>
import AuthService from '@/modules/auth/AuthService';

export default {
    name: 'App',

    components: {
        AuthService
    },

    data() {
        return {
            boundMouseMoveHandler: null
        };
    },

    mounted() {
        this.boundMouseMoveHandler = this._mouseMoveHandler.bind(this);
        window.addEventListener('mousemove', this.boundMouseMoveHandler);
    },

    destroyed() {
        window.removeEventListener('mousemove', this.boundMouseMoveHandler);
    },

    methods: {
        _mouseMoveHandler(event) {
            window.cursorX = event.clientX;
            window.cursorY = event.clientY;
        }
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";
@import "@/assets/themes/default/scss/index.scss";

.fest-app {
    height: 100%;
}
</style>
