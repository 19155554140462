import client from '../userApi';

export default {
    // Роуты Личного Кабинета онлайн-фестиваля
    getEventInfo() {
        return client.get('/onlinelk/event-for-register');
    },

    getParticipants(eventId) {
        return client.get(`/onlinelk/${eventId}/participants`);
    },

    getCaptains() {
        return client.get('/online-captains');
    },

    addParticipant(eventId, participantId, data) {
        return client.put(`/onlinelk/${eventId}/participants/${participantId}`, data);
    },

    editParticipant(eventId, participantId, data) {
        return client.put(`/onlinelk/${eventId}/participants/${participantId}`, data);
    }
};
