<template>
    <div class="promocode">
        <el-input
            v-model="promoCode"
            class="promocode-input"
            placeholder="Промо код"
        />
        <el-button
            class="promocode-input-btn"
            :type="applyBtnType"
            size="small"
            :loading="isLoading"
            :disabled="applyBtnIsDisabled"
            @click="onApplyBtnClick"
        >
            {{ applyBtnText }}
        </el-button>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { isEmpty } from 'lodash';

export default {
    name: 'PromoCodeControl',

    data() {
        return {
            promoCode: null,
            isLoading: false,
            promoCodeIsApplied: false
        };
    },

    computed: {
        eventId() {
            return this.$route.params.eventId;
        },
        applyBtnIsDisabled() {
            return isEmpty(this.promoCode) || this.promoCodeIsApplied;
        },
        applyBtnText() {
            return this.promoCodeIsApplied ? '✓ Применен' : 'Применить';
        },
        applyBtnType() {
            return this.promoCodeIsApplied ? 'success' : 'primary';
        }
    },

    methods: {
        ...mapActions('lk/participant', ['applyPromoCode']),

        onApplyBtnClick() {
            this.isLoading = true;
            this.applyPromoCode({ eventId: this.eventId, promoCode: this.promoCode })
                .then(resp => {
                    if (!resp.success) {
                        throw new Error(resp.message);
                    }
                    this.promoCodeIsApplied = true;
                    this.$message.success(resp.message);
                })
                .catch(e => {
                    this.$message.error(e.message);
                })
                .finally(() => this.isLoading = false);
        }
    }
};
</script>

<style lang="scss" scoped>
.promocode {
  display: flex;
  gap: 10px;
  justify-content: flex-end;

  &-input {
    flex-basis: 150px;
  }

  &-input-btn {
  }
}
</style>