import _ from 'lodash';
import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import ROUTES from '@/enums/routes';

export default {
    /**
     * @return {Promise<SpecialistResource[]>}
     */
    list(params = {}) {
        return api.get(urlBuilder(ROUTES.SPECIALIST.API.LIST), {
            params
        })
            .then(response => Promise.resolve(_.get(response, 'data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} specialistId
     * @returns {Promise<SpecialistResource>}
     */
    show({ specialistId }) {
        return api.get(urlBuilder(ROUTES.SPECIALIST.API.SHOW, { specialistId }))
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {CreateSpecialistRequest} data
     * @returns {Promise<any>}
     */
    store(data) {
        return api.post(urlBuilder(ROUTES.SPECIALIST.API.STORE), data)
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {CreateSpecialistRequest} data
     * @returns {Promise<SpecialistResource>}
     */
    update(data) {
        return api.put(urlBuilder(ROUTES.SPECIALIST.API.UPDATE, {
            specialistId: data.id
        }), data)
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} specialistId
     * @return {Promise<Object>}
     */
    remove({ specialistId }) {
        return api.delete(urlBuilder(ROUTES.SPECIALIST.API.DELETE, { specialistId }))
            .then(response => Promise.resolve(_.get(response, 'data.data', null)))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    }
};
