import _ from 'lodash';
import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import { ROUTES } from '@/enums';
import Specialist from './service/specialist';
import Schedule from './service/schedule';
import Plan from './service/plan';

export default {
    Specialist,
    Schedule,
    Plan,

    /**
     * @param {UUID} eventId
     * @returns {Promise<EventServiceResource[]>}
     */
    getEventServices({ eventId }) {
        const url = urlBuilder(ROUTES.EVENT.API.SERVICE.INDEX, { eventId });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    getEventService({ eventId, serviceId }) {
        const url = urlBuilder(ROUTES.EVENT.API.SERVICE.SHOW, { eventId, serviceId });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    getEventLocations({ eventId }) {
        const url = urlBuilder(ROUTES.EVENT.API.LOCATIONS.INDEX, { eventId });
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} eventId
     * @param {AddServiceToEventRequest} request
     * @return {Promise<EventServiceResource>}
     */
    addServiceToEvent({ eventId, request }) {
        const url = urlBuilder(ROUTES.EVENT.API.SERVICE.STORE, { eventId });
        return api.post(url, request)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    },

    /**
     * @param {UUID} eventId
     * @param {UUID} eventServiceId
     * @param {UpdateEventServiceRequest} request
     * @return {Promise<EventServiceResource>}
     */
    updateEventService({ eventId, eventServiceId, request }) {
        const url = urlBuilder(ROUTES.EVENT.API.SERVICE.UPDATE, { eventId, eventServiceId });
        return api.put(url, request)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(_.get(e, 'response.data', e)));
    }
};
