export default {
    setNewParticipantList(state, { data }) {
        state.newParticipantList = data;
    },
    setParticipantWithKeyList(state, { data, meta }) {
        state.participantWithKeyList = data;
        state.participantWithKeyListMeta = meta;
    },
    setParticipantWithKeyListIsLoading(state, isLoading) {
        state.participantWithKeyListIsLoading = isLoading;
    }
};
