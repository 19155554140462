<template>
    <el-tooltip :content="title">
        <span>
            <i class="bed-info-icon fas fa-bed" />
            1/{{ count }}
        </span>
    </el-tooltip>
</template>

<script>
export default {
    name: 'BedInfoIcon',

    props: {
        type: {
            type: Number,
            default: 0
        },

        title: {
            type: String,
            default: null
        }
    },

    computed: {
        count() {
            return this.type === 0 ? 1 : 2;
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.bed-info-icon {
    margin-right: 5px;
}
</style>
