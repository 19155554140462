import api from '@/api/managerApi';
import urlBuilder from '@/utils/urlBuilder';
import ROUTES from '@/modules/participant/enums/routes';
import _ from 'lodash';

export default {
    async getStatusList() {
        let url = urlBuilder(ROUTES.API.STATUS.LIST);
        try {
            let response = await api.get(url);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                error: e.response.message
            };
        }
    },

    async getEnrollList(participantId) {
        let url = urlBuilder(ROUTES.API.SERVICE.ENROLL_LIST, { participantId });
        try {
            let response = await api.get(url);
            return {
                success: true,
                data: response.data.data
            };
        } catch (e) {
            return {
                success: false,
                error: e.response.message
            };
        }
    },

    async updateComment({ participantId, comment }) {
        let url = urlBuilder(ROUTES.API.UPDATE.COMMENT,
            { participantId });
        try {
            let response = await api.put(url, {
                comment
            });
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            if (e.response) {
                return {
                    success: false,
                    error: e.response.message
                };
            }
        }
    },

    updateHasPhoned({ participantId, hasPhoned }) {
        let url = ROUTES.API.UPDATE.HAS_PHONED;
        return api.post(url, {
            id: participantId,
            has_phoned: hasPhoned
        })
            .then(response => Promise.resolve(response.data.message))
            .catch(e => Promise.reject(JSON.stringify(_.get(e, 'response.data.message'))));
    },

    removeHasPhonedOfEvent(eventId) {
        let url = ROUTES.API.REMOVE_HAS_PHONED_OF_EVENT;
        return api.post(url, {
            event_id: eventId
        })
            .then(response => Promise.resolve(response.data.message))
            .catch(e => Promise.reject(JSON.stringify(_.get(e, 'response.data.message'))));
    },

    async updateStatus({ statusId, color }) {
        let url = urlBuilder(ROUTES.API.STATUS.UPDATE, {
            statusId
        });
        try {
            let response = await api.put(url, {
                color
            });
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            return {
                success: false,
                error: e.response.message
            };
        }
    },

    async redeemRoom({ roomId, participantId, eventId }) {
        const url = urlBuilder(ROUTES.API.ROOM.REDEEM, {
            roomId
        });
        try {
            let response = await api.post(url, {
                participant_id: participantId,
                event_id: eventId
            });
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            if (e.response) {
                return {
                    success: false,
                    error: e.response.message
                };
            }
        }
    },

    async cancelRedeemRoom({ roomId, eventId }) {
        const url = urlBuilder(ROUTES.API.ROOM.CANCEL_REDEEM, {
            roomId
        });
        try {
            let response = await api.post(url, {
                event_id: eventId
            });
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            if (e.response) {
                return {
                    success: false,
                    error: e.response.message
                };
            }
        }
    },
    updateParticipantField(participantId, data) {
        return api.patch(urlBuilder(ROUTES.API.UPDATE_PARTICIPANT_FIELD, {
            participantId
        }), data)
            .then(response => Promise.resolve(response.data.data));
    },

    /** @param {AddParticipantData} data */
    async addParticipant(data) {
        let url = ROUTES.API.STORE;
        try {
            let response = await api.post(url, data);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            if (e.response) {
                return {
                    success: false,
                    error: e.response.data
                };
            }
        }
    },

    /**
     * @param {Array} participants
     * [ { id {uuid} ]
     * @returns {Promise<void>}
     */
    async participantsArrived(participants) {
        let url = ROUTES.API.ARRIVED;
        try {
            let response = await api.post(url, participants);
            return {
                success: true,
                data: response.data
            };
        } catch (e) {
            if (e.response) {
                return {
                    success: false,
                    error: e.response.data
                };
            }
        }
    },

    moveParticipantsToEvent({ eventId, participants }) {
        let url = urlBuilder(ROUTES.API.MOVE_TO_EVENT, { eventId });
        return api.post(url, { participants })
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(JSON.stringify(_.get(e, 'response.data.message'))));
    },
    getEventHistoryList(participantId) {
        let url = `/participant/${participantId}/event-history`;
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(JSON.stringify(_.get(e, 'response.data'))));
    },
    deleteParticipant(participantId) {
        let url = `/participant/${participantId}`;
        return api.delete(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(JSON.stringify(_.get(e, 'response.data'))));
    },
    addToTransfer(participantId) {
        let url = `/participant/${participantId}/add-to-transfer`;
        return api.post(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(JSON.stringify(_.get(e, 'response.data'))));
    },
    getNotVisitedEvents(participantId) {
        let url = `/participant/${participantId}/events-not-visited`;
        return api.get(url)
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(JSON.stringify(_.get(e, 'response.data'))));
    },
    addParticipantToNotVisitedEvent({ participantId, eventId, status }) {
        let url = `/participant/${participantId}/events-not-visited`;
        return api.post(url, {
            eventId,
            status
        })
            .then(response => Promise.resolve(response.data.data))
            .catch(e => Promise.reject(JSON.stringify(_.get(e, 'response.data'))));
    }
};
