<template>
    <div>
        <el-table
            :data="statusList"
            style="width: 100%"
        >
            <el-table-column
                prop="attributes.title"
                label="Статус"
                width="180"
            />
            <el-table-column
                prop="attributes.color"
                label="Цвет"
                width="180"
            >
                <template slot-scope="scope">
                    <el-color-picker
                        v-model="scope.row.attributes.color"
                        size="small"
                        @change="_onColorPickerChange({
                            statusId: scope.row.id,
                            color: scope.row.attributes.color
                        })"
                    />
                </template>
            </el-table-column>
            <!--<el-table-column-->
            <!--prop="icon"-->
            <!--label="Иконка"-->
            <!--&gt;-->
            <!--<template slot-scope="scope">-->
            <!--<i class="fas" :class="scope.row.icon" style="padding-right: 20px"></i>-->
            <!--<el-select v-model="scope.row.icon" placeholder="Иконка" clearable>-->
            <!--<el-option-->
            <!--v-for="icon in icons"-->
            <!--:key="icon"-->
            <!--:value="icon"-->
            <!--&gt;-->
            <!--<i class="fas" :class="icon" style="padding-right: 20px"></i> <span>{{icon}}</span>-->
            <!--</el-option>-->
            <!--</el-select>-->
            <!--</template>-->
            <!--</el-table-column>-->
        </el-table>
        <el-row>
            <el-button type="primary">
                Новый статус TODO
            </el-button>
        </el-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'ParticipantStatuses',
    data() {
        return {};
    },

    computed: {
        ...mapState('participant', [
            'statusList'
        ])
    },

    mounted() {
        this.getStatusList();
    },

    methods: {
        ...mapActions('participant', [
            'getStatusList',
            'updateStatus'
        ]),

        async _onColorPickerChange({ statusId, color }) {
            let result = await this.updateStatus({ statusId, color });
            this.$message.info(result);
        }

    }

};
</script>

<style scoped>

</style>
