export default {
    REGISTER: 'cabinet.register',
    PROFILE: 'cabinet.profile',

    PAYMENT: {
        SUCCESS: 'cabinet.payment.success',

        API: {
            /** GET */
            SHOW: '/payment/{paymentId}'
        }
    },

    EVENT: {
        /**
         * Регистрация на событие.
         * {eventId}
         */
        REGISTER: 'cabinet.event.register'
    },

    API: {}
};
