import client from '../userApi';

export default {
    login({ phone }) {
        return client.post('/onlinelk/login', { phone });
    },

    logout() {
        return client.post('/onlinelk/logout');
    },

    auth({ phone, code }) {
        return client.post('/onlinelk/verify', { code, phone });
    },

    me() {
        return client.get('/auth/user/me');
    }
};
