<template>
    <div class="participants-list">
        <table class="table">
            <tr
                v-for="(participant, index) in preparedParticipants"
                :key="`${index}${participant.__time}`"
            >
                <td>
                    <div>
                        {{ participant.surname }}
                        {{ participant.name }}
                        {{ participant.patronymic }}
                    </div>
                    <div v-if="participant.phone">
                        {{ participant.phone }}
                    </div>
                    <div v-else>
                        <i>Без телефона</i>
                    </div>
                    <div>
                        {{ participant.email }}
                    </div>
                </td>
                <td>
                    <span v-if="participant.date_of_birth">
                        {{ participant.date_of_birth | moment('DD MMM YYYY') }}
                    </span>
                    <span v-else>
                        Не указана
                    </span>
                </td>
                <td style="width: 40px;">
                    <gender-icon :gender="participant.gender" />
                </td>
                <td
                    v-if="paymentInfo"
                    style="width: 80px;"
                >
                    {{ price(participant) }} <i class="fas fa-ruble-sign" />
                </td>
                <td
                    v-if="areControlsVisible"
                    style="width: 40px;"
                >
                    <el-tooltip
                        content="Изменить данные участника"
                        transition="none"
                        placement="top"
                    >
                        <el-button
                            size="small"
                            type="text"
                            @click="_edit(index)"
                        >
                            <i class="el-icon-edit" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip
                        v-if="!participant.isLeader"
                        transition="none"
                        content="Удалить участника"
                        placement="top"
                    >
                        <el-button
                            size="small"
                            type="text"
                            @click="_remove(index)"
                        >
                            <i class="el-icon-delete" />
                        </el-button>
                    </el-tooltip>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import GenderIcon from '@/components/common/GenderIcon';

export default {
    name: 'ParticipantsList',

    components: {
        GenderIcon
    },

    props: {
        /**  @type {CreateParticipantRequest[]} */
        participants: {
            type: Array,
            default: () => []
        },

        /** @type {CreateParticipantRequest | null} */
        leader: {
            type: Object,
            default: null
        },

        readonly: {
            type: Boolean,
            default: false
        },

        paymentInfo: {
            type: Boolean,
            default: false
        },

        cost: {
            type: Number,
            default: 0.0
        }
    },

    computed: {
        areControlsVisible() {
            return !this.readonly;
        },

        preparedParticipants() {
            if (!Array.isArray(this.participants)) {
                return [];
            }
            return this.participants.map(x => ({
                ...x,
                cost: this.cost,
                isLeader: this._isLeader(x)
            }));
        }
    },

    methods: {
        price(participant) {
            return participant.is_small_child ? 0 : participant.cost;
        },

        _remove(index) {
            const participant = this.participants[index];
            if (participant) {
                this.$confirm('Удалить участника?')
                    .then(() => {
                        this.$emit('remove', {
                            participant,
                            index
                        });
                    })
                    .catch(() => {
                    });
            }
        },

        _edit(index) {
            const participant = this.participants[index];
            this.$emit('edit', {
                participant,
                index
            });
        },

        /**
         * @param {CreateParticipantRequest} participant
         * @private
         */
        _isLeader(participant) {
            if (this.leader && this.leader.id) {
                return this.leader.id === participant.id;
            }
            if (this.leader && !this.leader.id) {
                return this.leader.name === participant.name &&
                    this.leader.patronymic === participant.patronymic &&
                    this.leader.surname === participant.surname;
            }
            return false;
        }
    }
};
</script>
