import axios from 'axios';
import CONFIG from '@/config';

const getToken = () => {
    const token = localStorage.getItem('Auth.Token');
    return token && token !== 'undefined' ? 'Bearer ' + token : '';
};

const client = axios.create({
    baseURL: CONFIG.API_HOST + CONFIG.API_PREFIX,
    headers: {
        Authorization: getToken()
    }
});

// TODO: Почему код закомментриван
client.interceptors.request.use(
    (config) => {
        //let token = getToken();
        // if (token) {
        //     config.headers['Authorization'] = `${token}`;
        // }
        return config;
    },
    error => Promise.reject(error.response)
);

export default client;
