<template>
    <el-dialog
        :title="title"
        :visible.sync="isVisible"
        width="60%"
        @close="_onDialogClose"
    >
        <div class="group-member-form">
            <el-form
                ref="form"
                :rules="rules"
                :model="form"
                label-position="top"
            >
                <el-row :gutter="16">
                    <el-col :span="24">
                        <el-form-item
                            :show-message="isPhoneErrorVisible"
                            prop="phone"
                        >
                            <app-phone-input
                                ref="phone"
                                v-model="form.phone"
                                :is-disabled="!isPhoneEditingAvailable"
                                placeholder="Введите номер телефона"
                                @change="_onPhoneChanged"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="16">
                    <el-col :span="8">
                        <el-form-item
                            label="Фамилия"
                            prop="surname"
                            required
                        >
                            <el-input v-model="form.surname" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item
                            label="Имя"
                            prop="name"
                            required
                        >
                            <el-input v-model="form.name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item
                            label="Отчество"
                            prop="patronymic"
                        >
                            <el-input v-model="form.patronymic" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="16">
                    <el-col :span="12">
                        <el-form-item
                            label="Дата рождения"
                            prop="date_of_birth"
                            required
                        >
                            <el-date-picker
                                v-model="form.date_of_birth"
                                :picker-options="dateOfBirthOptions"
                                :format="datepickerDateFormat"
                                style="width: 100%;"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="Выберите дату"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="Пол"
                            prop="gender"
                            required
                        >
                            <el-select
                                v-model="form.gender"
                                style="width: 100%;"
                                placeholder="Укажите пол"
                            >
                                <el-option
                                    v-for="{itemTitle, value} in sexOptions"
                                    :key="value"
                                    :label="itemTitle"
                                    :value="value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="16">
                    <el-col :span="12">
                        <el-button
                            plain
                            type="primary"
                            @click="close"
                        >
                            Закрыть
                        </el-button>
                    </el-col>
                    <el-col
                        :span="12"
                        align="right"
                    >
                        <el-button
                            type="primary"
                            @click="_submit"
                        >
                            {{ buttonText }}
                        </el-button>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </el-dialog>
</template>

<script>
import moment from 'moment';
import { mapState, mapMutations } from 'vuex';
import { Event } from '@/api';
import { mapParticipantResourceToCreateParticipantRequest } from '@/modules/cabinet/mappers';
import AppPhoneInput from '@/components/form/AppPhoneInput';

export default {
    name: 'GroupMemberForm',

    components: {
        AppPhoneInput
    },

    props: {
        /** @type {CreateParticipantRequest} */
        participant: {
            type: Object,
            default: null
        },

        index: {
            type: Number,
            default: -1
        }
    },

    data() {
        return {
            /** @type {CreateParticipantRequest} */
            form: {
                children_program: true
            },
            isVisible: false,
            years: undefined,
            phone: null,
            isPhoneValid: false,
            isPhoneTouched: false,
            wasValidated: false
        };
    },
    computed: {
        ...mapState('cabinet', [
            'event'
        ]),

        ...mapState('config', [
            'datepickerDateFormat'
        ]),

        ...mapState('cabinet/registration', [
            'leader',
            'participants'
        ]),

        ...mapState('enums', [
            'sexOptions'
        ]),

        ...mapState('config/registration', [
            'maxAllowedAge'
        ]),

        title() {
            return this.isEditMode ? 'Редактирование участника' : 'Добавление участника';
        },

        buttonText() {
            return this.isEditMode ? 'Сохранить' : 'Добавить';
        },

        isEditMode() {
            return !!this.participant;
        },

        leaderId() {
            return this.$prop('leader.id', null);
        },

        isPhoneLengthEnough() {
            const phone = _.get(this, 'form.phone');
            return phone ? phone.length > 11 : false;
        },

        isPhoneErrorVisible() {
            return this.wasValidated || this.isPhoneLengthEnough;
        },

        isPhoneEditingAvailable() {
            return !this.$prop('participant.id', null);
        },

        leaderPhone() {
            return this.$prop('leader.phone', null);
        },

        eventId() {
            return this.$prop('event.id', null);
        },

        rules() {
            return {
                phone: [
                    {
                        validator: this._groupMemberPhoneValidator,
                        trigger: 'blur'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: 'Пожалуйста, укажите имя участника',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        message: 'Имя не должно быть короче 2 символов',
                        trigger: 'blur'
                    }
                ],
                surname: [
                    {
                        required: true,
                        message: 'Пожалуйста, укажите фамилию участника',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        message: 'Фамилия не должна быть короче 2 символов',
                        trigger: 'blur'
                    }
                ]
            };
        },

        dateOfBirthOptions() {
            const maxAllowedAge = this.maxAllowedAge;
            return {
                disabledDate(date) {
                    const diffInYears = moment()
                        .diff(date, 'years');
                    const diff = moment()
                        .diff(date);
                    return diffInYears > maxAllowedAge || diff < 0;
                }
            };
        }
    },

    watch: {
        participant() {
            this._setFormFromParticipant();
        }
    },

    mounted() {
        this._setFormFromParticipant();
    },

    methods: {
        ...mapMutations('cabinet/registration', [
            'addParticipant',
            'updateParticipant'
        ]),

        toggle() {
            this.isVisible = !this.isVisible;
        },

        open() {
            this.isVisible = true;
        },

        close() {
            this.isVisible = false;
        },

        reset() {
            this.$refs.form.resetFields();
            this.$refs.phone.clear();
            this.form = {};
        },

        _onDialogClose() {
            this.reset();
        },

        _groupMemberPhoneValidator(rule, value, callback) {
            if (!value || !this.isPhoneEditingAvailable) {
                return callback();
            }
            if (!this.isPhoneValid) {
                return callback(new Error('Проверьте телефон'));
            }
            if (this._isPhoneInGroup(value)) {
                return callback(new Error('Телефон используется другим участником группы'));
            }
            this._checkIfParticipantWithThatPhoneExists()
                .then(() => callback())
                .catch(() => callback(new Error(`Извините, но телефон ${value} уже зарегистрирован на событие`)));
        },

        _isPhoneInGroup(phone) {
            this.wasValidated = true;
            if (!phone) {
                return false;
            }
            if (this.leaderPhone === phone) {
                return true;
            }
            return this.participants.reduce((acc, participant) => {
                if (this.participant && this.participant.phone === phone) {
                    return acc;
                }
                return acc || participant.phone === phone;
            }, false);
        },

        _onPhoneChanged({ phone, isValid }) {
            this.isPhoneTouched = true;
            this.isPhoneValid = isValid;
        },

        _checkIfParticipantWithThatPhoneExists() {
            return new Promise((resolve, reject) => {
                /** @param {EventPhoneStatusResource} phoneStatus */
                const onResponse = phoneStatus => {
                    if (phoneStatus.attributes.isPhoneUsedOnThisEvent) {
                        return reject(phoneStatus);
                    }
                    resolve(phoneStatus);
                };
                Event.Registration.checkPhone({
                    phone: this.form.phone,
                    eventId: this.eventId
                })
                    .then(onResponse)
                    .catch(reject);
            });
        },

        _setFormFromParticipant() {
            if (this.isEditMode && !!this.participant) {
                const form = { ...this.participant };
                form.date_of_birth = form.date_of_birth
                    ? new Date(form.date_of_birth)
                    : null;
                this.form = form;
            } else {
                this.form = {};
            }
        },

        _submit() {
            this.wasValidated = true;
            this.$refs.form.validate()
                .then(() => {
                    if (this.isEditMode) {
                        this._updateParticipant();
                    } else {
                        this._addParticipant();
                    }
                })
                .catch(() => {
                    this.$message('Проверьте форму');
                });
        },

        /**
         *
         * @private
         * @returns {CreateParticipantRequest}
         */
        _formToCreateParticipantRequest() {
            /** @type {CreateParticipantRequest} */
            const participant = {
                ...this.form
            };
            if (!this.isEditMode) {
                participant.parent_participant_id = this.leaderId;
            }
            return participant;
        },

        _updateParticipant() {
            /** @type {CreateParticipantRequest} */
            const participant = this._formToCreateParticipantRequest();
            /**
             * Любое редактирование сбрасывает привязку к прошлому участнику,
             * т.к. может измениться критическая информация.
             */
            participant.__previous_participant_id = undefined;
            /**
             * @param {ParticipantResource} resource
             */
            const onResponse = resource => {
                const updatedParticipant = mapParticipantResourceToCreateParticipantRequest(resource);
                this.updateParticipant({
                    index: this.index,
                    participant: updatedParticipant
                });
                this.$message.success('Информация об участнике обновлена');
            };
            if (participant.id) {
                Event.Registration.updateParticipantInfo({
                    eventId: this.eventId,
                    participantId: participant.id,
                    parentParticipantId: this.leaderId,
                    participant
                })
                    .then(onResponse)
                    .catch(e => {
                        this.$message.error('Не удалось обновить информацию об участнике');
                    });
            } else {
                this.updateParticipant({
                    index: this.index,
                    participant
                });
                this.$message.success('Информация об участнике обновлена');
            }
            this.$emit('updated', { participant });
        },

        _addParticipant() {
            /** @type {CreateParticipantRequest} */
            const participant = this._formToCreateParticipantRequest();
            this.$emit('added', { participant });
            this.reset();
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.group-member-form {
  .el-checkbox {
    vertical-align: top;
  }

  .el-checkbox__input {
    vertical-align: top;
    margin-top: 2px;
  }

  .el-checkbox__label {
    vertical-align: top;
    white-space: normal !important;
  }
}
</style>
