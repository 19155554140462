<template>
    <div
        v-loading="participantWithKeyListIsLoading"
        class="participant-with-key-list"
    >
        <app-table
            paginatable
            :rows="preparedList"
            :config="config"
            :pagination="pagination"
            :searchable="false"
            :action-buttons-span="16"
            :filters-span="24"
            :configurable-columns="false"
            class="participant-with-key-list__table"
            @paginatorChange="_onPaginatorChange"
        >
            <div slot="filter">
                <el-col :span="8">
                    <el-input
                        v-model="userInput"
                        prefix-icon="el-icon-search"
                    />
                </el-col>
            </div>
            <el-col slot="actionButtons">
                <el-button
                    class="button-print"
                    type="primary"
                    plain
                    @click="_onPrintClick"
                >
                    <i class="fas fa-print" />
                </el-button>
                <el-button
                    class="button-export"
                    type="primary"
                    plain
                    @click="_onExportClick"
                >
                    <i class="fas fa-file-export" />
                </el-button>
            </el-col>
        </app-table>
    </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import AppTable from '@/components/table/AppTable';
import ROUTES from '@/enums/routes';
import api from '@/api/managerApi';
import CONFIG_ROUTES from '@/config';
import urlBuilder from '@/utils/urlBuilder';

export default {
    name: 'ParticipantWithKeyList',

    components: {
        AppTable
    },

    data() {
        return {
            userInput: null,
            isLoading: false,
            page: null
        };
    },

    computed: {
        ...mapState('reception', [
            'participantWithKeyList',
            'participantWithKeyListMeta',
            'participantWithKeyListIsLoading'
        ]),

        preparedList() {
            return this.participantWithKeyList.map(participant => {
                return {
                    id: _.get(participant, 'id'),
                    surname: _.get(participant, 'attributes.surname'),
                    name: _.get(participant, 'attributes.name'),
                    fullname: _.get(participant, 'attributes.fullname'),
                    building: _.get(participant, 'relationships.places[0].relationships.building.attributes.title'),
                    room: _.get(participant, 'relationships.places[0].relationships.room.attributes.title'),
                    arrival_date: moment(_.get(participant, 'attributes.arrival_date')).format('D MMMM'),
                    departure_date: moment(_.get(participant, 'attributes.departure_date')).format('D MMMM')
                };
            });
        },

        pagination() {
            return {
                totalRecords: this.$prop('participantWithKeyListMeta.total'),
                perPage: this.$prop('participantWithKeyListMeta.per_page'),
                lastPage: this.$prop('participantWithKeyListMeta.last_page'),
                currentPage: this.page
            };
        },

        config() {
            return {
                columns: [
                    {
                        title: 'Фамилия, имя, отчество',
                        render: row => `${row.surname} ${row.name}`
                    },
                    {
                        title: 'Заселён в',
                        render: row => this._createBuildingMessage(row)
                    },
                    {
                        title: 'Заехал',
                        render: row => row.arrival_date
                    },
                    {
                        title: 'Выедет',
                        render: row => row.departure_date
                    }
                ]
            };
        },

        exportXlsLink() {
            return CONFIG_ROUTES.API_HOST + '/api' + urlBuilder(ROUTES.EVENT.API.EXPORTS.RECEPTION.XLS);
        }
    },

    watch: {
        '$route'(to) {
            this.page = to.query.page;
            this.userInput = to.query.userInput;
        },

        page: {
            async handler(page) {
                if (page === null) {
                    return;
                }

                if (parseInt(page) > 0) {
                    await this._loadParticipantWithKeyList(this.page);
                } else {
                    this.$router.push({ query: { page: 1 } });
                }
            },
            immediate: true
        },

        userInput: {
            handler(userInput) {
                if (userInput !== null) {
                    this.$router.push({ query: { page: 1, userInput } });
                    this._applyFilters();
                }
            },
            deep: true
        }
    },

    async mounted() {
        if (parseInt(this.$route.query.page) < 1) {
            let userInput = this.$route.query.userInput;
            this.$router.push({ query: { page: 1, userInput } });
        }
        this.page = parseInt(this.$route.query.page);
        this.userInput = this.$route.query.userInput;
    },

    methods: {
        ...mapActions('reception', [
            'getParticipantWithKeyList'
        ]),

        _createBuildingMessage(row) {
            return (row.building && row.room)
                ? `<i class="far fa-building"></i> ${row.building}, №${row.room}`
                : 'Не заселен';
        },

        _applyFilters: _.debounce(async function() {
            await this._loadParticipantWithKeyList(1);
        }, 500),

        _onPaginatorChange(page) {
            this.$router.push({ query: { page, userInput: this.userInput } });
        },

        async _loadParticipantWithKeyList(page) {
            await this.getParticipantWithKeyList({ page, userInput: this.userInput });
            let lastPage = this.participantWithKeyListMeta ? this.participantWithKeyListMeta.last_page : 1;
            if (page > lastPage) {
                this.$router.push({ query: {
                    page: lastPage,
                    userInput: this.userInput
                } });
            }
        },

        _onPrintClick() {
            let printRouter = this.$router.resolve({ name: ROUTES.RECEPTION.PRINT.PARTICIPANT_WITH_KEY_LIST });
            window.open(printRouter.href, '_blank');
        },

        _onExportClick() {
            api({
                url: this.exportXlsLink,
                method: 'GET',
                responseType: 'blob' // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'participants.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        }
    }
};
</script>

<style lang="scss">
.participant-with-key-list {
    .action-buttons {
        text-align: right;

        .button-export {
            margin-right: -10px;
        }
    }
}
</style>
