<template>
    <layout-fullscreen>
        <template slot="content">
            <el-card class="auth-login-view">
                <h1 class="app-h1">
                    Вход
                </h1>

                <el-form
                    :model="form"
                    :rules="rules"
                    @submit.native.prevent
                >
                    <el-form-item label="Логин">
                        <el-input v-model="form.login" />
                    </el-form-item>

                    <el-form-item label="Пароль">
                        <el-input
                            v-model="form.password"
                            type="password"
                        />
                    </el-form-item>

                    <el-button
                        type="primary"
                        native-type="submit"
                        style="margin-top: 10px; width: 100%;"
                        @click="_login"
                    >
                        Войти
                    </el-button>
                </el-form>
            </el-card>
        </template>
    </layout-fullscreen>
</template>

<script>
import LayoutFullscreen from '@/views/layouts/LayoutFullscreen';
import { ROUTES } from '@/enums';
import ROLES from '@/modules/manager/enums/roles';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'AuthLoginView',

    components: {
        LayoutFullscreen
    },

    props: {
        logoutMe: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            form: {
                login: null,
                password: null
            }
        };
    },

    computed: {
        ...mapState('auth/manager', ['isAuthorized']),
        ...mapState('auth', ['manager']),

        rules() {
            return {};
        }
    },

    async mounted() {
        if (this.logoutMe) {
            await this.logout();
        }
        if (this.isAuthorized) {
            this._redirect();
        }
    },

    methods: {
        ...mapActions('auth/manager', ['login', 'logout']),

        _redirect() {
            let route = ROUTES.HOME;
            if (this.manager.user.role === ROLES.HOTEL) {
                route = ROUTES.RECEPTION.DASHBOARD;
            }

            this.$router.push({
                name: route
            });
        },

        async _login() {
            let result = await this.login({
                username: this.form.login,
                password: this.form.password
            });
            if (result.success) {
                this._redirect();
            } else {
                this.$message.error('Ошибка авторизации!');
            }
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.auth-login-view {
    padding: 50px;
}
</style>
