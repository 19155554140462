<template>
    <div
        v-loading="isLoading"
        class="manager-list"
    >
        <el-table
            :data="preparedList"
        >
            <el-table-column
                prop="fullname"
                label="Фамилия, имя"
            >
                <template slot-scope="scope">
                    {{ scope.row.fullname }}
                    <i
                        class="el-icon-edit-outline manager-list__icon manager-list__edit"
                        @click="edit(scope.row)"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="username"
                label="Логин"
            />
            <el-table-column
                prop="role"
                label="Роль"
            />
            <el-table-column
                label="Включен"
            >
                <template slot-scope="scope">
                    <el-switch
                        :value="scope.row.enabled"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        disabled
                    />
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-button
                type="primary"
                @click="_onCreateClickBtn"
            >
                Добавить
            </el-button>
        </el-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ROUTES } from '@/enums';

export default {
    name: 'ManagerList',
    data() {
        return {
            isLoading: false
        };
    },
    computed: {

        ...mapState('manager', [
            'managerList'
        ]),

        preparedList() {
            return this.managerList.map(manager => {
                return {
                    id: _.get(manager, 'id'),
                    fullname: _.get(manager, 'attributes.fullname'),
                    username: _.get(manager, 'attributes.username'),
                    role: _.get(manager, 'attributes.role'),
                    enabled: _.get(manager, 'attributes.enabled')
                };
            });
        }
    },
    async mounted() {
        this.isLoading = true;
        await this.getManagerList();
        this.isLoading = false;
    },
    methods: {
        ...mapActions('manager', [
            'getManagerList'
        ]),

        _onCreateClickBtn() {
            return this.$router.push({
                name: ROUTES.MANAGER.ADD
            });
        },

        edit(item) {
            this.$router.push({
                name: ROUTES.MANAGER.EDIT,
                params: {
                    managerId: item.id
                }
            });
        }

    }
};
</script>

<style lang="scss">
@import "@vars";

.manager-list {
    &__icon {
        font-size: 1.2em;
        cursor: pointer;
        visibility: hidden;
    }

    &__edit {
        color: #0486fe;
        margin-left: 10px;
    }

    &__remove {
        color: #eb2929;
    }

    .el-table__row {
        &:hover {
            .manager-list__icon {
                visibility: visible;
            }
        }
    }
}
</style>
