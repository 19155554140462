import Service from '@/modules/service/api';
import Event from '@/modules/event/api';

export default {
    getAllServices({ commit }) {
        return Service.list()
            .then(allServices => {
                commit('setAllServices', { allServices });
                return Promise.resolve(allServices);
            })
            .catch(e => {
                commit('setAllServices', { allServices: [] });
                return Promise.reject(e);
            });
    },

    getServices({ commit }, { eventId }) {
        commit('setAreEventServicesLoading', true);
        return new Promise((resolve, reject) => {
            /** @param {EventServiceResource[]} services */
            const onResponse = services => {
                commit('setServices', { services });
                resolve(services);
            };
            Event.Service.getEventServices({ eventId })
                .then(onResponse)
                .catch(e => {
                    commit('setServices', { services: [] });
                    reject(e);
                })
                .finally(() => {
                    commit('setAreEventServicesLoading', false);
                });
        });
    },

    getEventSpecialists({ commit }, { eventId }) {
        commit('setAreSpecialistsLoading', true);
        return new Promise((resolve, reject) => {
            /** @param {SpecialistResource[]} specialists */
            const onResponse = specialists => {
                commit('setSpecialists', { specialists });
                resolve(specialists);
            };
            Event.Service.Specialist.getEventSpecialists({ eventId })
                .then(onResponse)
                .catch(e => {
                    commit('setSpecialists', { specialists: [] });
                    reject(e);
                })
                .finally(() => {
                    commit('setAreSpecialistsLoading', false);
                });
        });
    },

    getLocations({ commit }, { eventId }) {
        commit('setAreLocationsLoading', true);
        return new Promise((resolve, reject) => {
            /** @param {EventLocationResource[]} specialists */
            const onResponse = locations => {
                commit('setLocations', { locations });
                resolve(locations);
            };
            Event.Service.getEventLocations({ eventId })
                .then(onResponse)
                .catch(e => {
                    commit('setLocations', { setLocations: [] });
                    reject(e);
                })
                .finally(() => {
                    commit('setAreLocationsLoading', false);
                });
        });
    }

};
