<template>
    <div class="participant-form">
        <div class="participant-form__container">
            <el-form
                ref="form"
                :rules="rules"
                :model="form"
                label-position="top"
            >
                <div
                    v-if="!leaderId"
                    class="participant-form__phone"
                >
                    <el-form-item
                        :show-message="isPhoneErrorVisible"
                        prop="phone"
                        label="Введите номер телефона (пример: +79101234567)"
                    >
                        <app-phone-input
                            v-model="form.phone"
                            placeholder="Введите номер телефона"
                            @change="_onPhoneChanged"
                        />
                    </el-form-item>
                </div>
                <div v-else>
                    <el-input
                        disabled
                        :value="form.phone"
                    />
                </div>
                <hr>

                <el-row :gutter="16">
                    <el-col :span="8">
                        <el-form-item
                            label="Фамилия"
                            prop="surname"
                            :rules="[{ required: true, message: 'Укажите фамилию'}]"
                        >
                            <el-input v-model="form.surname" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item
                            label="Имя"
                            prop="name"
                            :rules="[{ required: true, message: 'Укажите имя'}]"
                        >
                            <el-input v-model="form.name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item
                            label="Отчество"
                            prop="patronymic"
                        >
                            <el-input v-model="form.patronymic" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="16">
                    <el-col :span="12">
                        <el-form-item
                            label="Дата рождения"
                            prop="date_of_birth"
                            :rules="[{ required: true, message: 'Укажите дату рождения'}]"
                        >
                            <el-date-picker
                                v-model="form.date_of_birth"
                                :picker-options="dateOfBirthOptions"
                                :format="datepickerDateFormat"
                                value-format="yyyy-MM-dd"
                                style="width: 100%;"
                                type="date"
                                placeholder="Выберите дату"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="Пол"
                            prop="gender"
                            required
                        >
                            <el-select
                                v-model="form.gender"
                                style="width: 100%;"
                                placeholder="Укажите пол"
                            >
                                <el-option
                                    v-for="{title, value} in sexOptions"
                                    :key="value"
                                    :label="title"
                                    :value="value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item
                    label="Город"
                    prop="city"
                    :rules="[{ required: true, message: 'Укажите город'}]"
                >
                    <el-autocomplete
                        v-model="form.city"
                        style="width: 100%;"
                        :fetch-suggestions="_getCitySuggestion"
                        placeholder="Город"
                        :trigger-on-focus="false"
                        @select="_onCitySuggestionSelect"
                    />
                </el-form-item>

                <el-form-item
                    label="Электронная почта"
                    prop="email"
                >
                    <el-input v-model="form.email" />
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState, mapMutations } from 'vuex';
import client from '@/utils/dadataClient';
import AppPhoneInput from '@/components/form/AppPhoneInput';

/**
 * @emits send {{ request: CreateParticipantRequest }}
 * @emits phoneInput
 * @emits dateOfBirthInput
 * @emits input
 */
export default {
    name: 'LeaderForm',

    components: {
        AppPhoneInput
    },

    props: {
        eventId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            isPhoneValid: false,
            isPhoneTouched: false,
            user: null,
            /** @type {CreateParticipantRequest} */
            form: {},
            isFormDirty: false,
            years: undefined,
            isValid: false,
            datepickerValueFormat: 'yyyy-MM-dd HH:mm:ss',
            client,
            areSuggestionLoading: false,
            /** Форма хотя бы раз была отправлена */
            wasValidated: false
        };
    },
    computed: {
        ...mapState('cabinet/registration', [
            'leader',
            'participants',
            'isRegistrationInProcess'
        ]),

        ...mapState('config', [
            'datepickerDateFormat'
        ]),

        ...mapState('config/registration', [
            'maxAllowedAge'
        ]),

        ...mapState('enums', [
            'sexOptions'
        ]),

        leaderDateOfBirth() {
            return this.$prop('leader.date_of_birth');
        },

        isChild() {
            // todo: env
            return this.years > 3 && this.years <= 14;
        },

        leaderId() {
            return this.$prop('leader.id');
        },

        formStorageKey() {
            return 'LeaderRegistrationForm';
        },

        isPhoneLengthEnough() {
            const phone = _.get(this, 'form.phone');
            return phone ? phone.length > 11 : false;
        },

        isPhoneErrorVisible() {
            return this.wasValidated || this.isPhoneLengthEnough;
        },

        rules() {
            return {
                phone: [
                    {
                        required: true,
                        validator: this._leaderPhoneValidator,
                        trigger: 'blur'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: 'Пожалуйста, укажите имя участника',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        message: 'Имя не должно быть короче 2 символов',
                        trigger: 'blur'
                    }
                ],
                surname: [
                    {
                        required: true,
                        message: 'Пожалуйста, укажите фамилию участника',
                        trigger: 'blur'
                    },
                    {
                        min: 2,
                        message: 'Фамилия не должно быть короче 2 символов',
                        trigger: 'blur'
                    }
                ],
                date_of_birth: [
                    {
                        required: true,
                        message: 'Пожалуйста, укажите дату рождения',
                        trigger: 'blur'
                    },
                    {
                        type: 'date',
                        required: true,
                        message: 'Дата рождения указана неверно',
                        trigger: 'blur'
                    }
                ],
                city: [
                    {
                        required: true,
                        message: 'Укажите город',
                        trigger: 'blur'
                    }
                ],
                email: [
                    {
                        type: 'email',
                        message: 'Пожалуйста, укажите корректный email',
                        trigger: ['blur', 'change']
                    }
                ]
            };
        },

        dateOfBirthOptions() {
            const maxAllowedAge = this.maxAllowedAge;
            return {
                disabledDate(date) {
                    const diffInYears = moment()
                        .diff(date, 'years');
                    const diff = moment()
                        .diff(date);
                    return diffInYears > maxAllowedAge || diff < 0;
                }
            };
        }
    },
    watch: {
        form: {
            handler() {
                this._formChanged();
            },
            deep: true
        },

        'leader.date_of_birth'() {
            this._testDateOfBirth();
        },

        leader() {
            this.form = _.get(this, 'leader', {});
        }
    },

    beforeMount() {
        if (!this.leader) {
            this._restoreForm();
        } else {
            this.form = this.leader;
        }
    },

    methods: {
        ...mapMutations('cabinet/registration', [
            'setLeader',
            'setParticipants'
        ]),

        /**
         * @returns Promise<>
         */
        validate() {
            this.wasValidated = true;
            return new Promise((resolve, reject) => {
                if (!this.form.phone) {
                    this.$message('Укажите телефон');
                    return reject(new Error('empty_phone'));
                }
                /**
                 * @param {Boolean} isValid
                 */
                const onValidate = isValid => {
                    this.isValid = true;
                    resolve(isValid);
                };
                /**
                 * @param {Boolean} e
                 */
                const onError = e => {
                    this.isValid = false;
                    reject(e);
                };
                this.$refs.form.validate()
                    .then(onValidate)
                    .catch(onError);
            });
        },

        reset() {
            this.form = {};
            this.isPhoneTouched = false;
            this.isPhoneValid = false;
            this.$refs.form.resetFields();
        },

        _leaderPhoneValidator(rule, value, callback) {
            if (!value) {
                return callback(new Error('Укажите  телефон'));
            }
            if (this._isPhoneInGroup(value)) {
                return callback(new Error('Телефон используется другим участником группы'));
            }
            if (this.isPhoneValid) {
                return callback();
            }
            callback(new Error('Проверьте телефон'));
        },

        _isPhoneInGroup(phone) {
            if (!phone) {
                return false;
            }
            return this.participants.reduce((acc, participant) => {
                return acc || participant.phone === phone;
            }, false);
        },

        /**
         * @param {{
         *     suggestion: DaDataSuggestion,
         *     value: String,
         * }} data
         */
        _onCitySuggestionSelect(data) {
            this.form.location_provider = 'dadata';
            this.form.location_payload = data.suggestion;
            this.form.location = data.suggestion.unrestricted_value;
            this.form.city = data.suggestion.data.city;
            this.form.country = data.suggestion.data.country;
            this.$refs.form.validateField('city');
        },

        /**
         * @param {String} query - Например, 'Моск'
         * @param {Function} cb
         */
        _getCitySuggestion(query, cb) {
            this.form.location_provider = null;
            this.form.location_payload = null;
            this.form.country = null;
            this.form.location = null;
            this.areSuggestionLoading = true;
            const onError = e => {
                const errorMessage = _.get(e, 'message', 'Не удалось получить список городов для подсказки.');
                this.$message.error(errorMessage);
            };
            this._suggest(query)
                .then(suggestions => {
                    /**
                     * При ошибке все равно резолвится,
                     * нет возможности ошибку отдельно обработать
                     */
                    if (!suggestions || !suggestions.length) {
                        // onError({ message: 'Ничего не найдено' });
                    }
                    cb(suggestions.map(suggestion => ({
                        value: suggestion.value,
                        suggestion
                    })));
                })
                .catch(onError)
                .finally(() => {
                    this.areSuggestionLoading = false;
                });
        },

        _suggest(query) {
            return this.client.suggest(
                'address',
                query,
                5,
                {
                    from_bound: {
                        value: 'city'
                    },
                    to_bound: {
                        value: 'city'
                    },
                    locations: [
                        {
                            country: '*',
                            city_type_full: 'город'
                        }
                    ]
                }
            );
        },

        /**
         * @param {string} phone
         * @param {Boolean} isValid
         * @param {String} country
         */
        _onPhoneChanged({ phone, isValid, country }) {
            this.isPhoneValid = isValid;
            this.$refs.form && this.$refs.form.validateField('phone');
            this.isPhoneTouched = true;
            this._formChanged();
            this.setLeader({
                leader: {
                    ...this.leader,
                    ...{
                        phone: this.form.phone
                    }
                }
            });
            this.$emit('input', this.form);
            this.$emit('phoneChanged', { phone, isValid, country });
        },

        _testDateOfBirth: _.debounce(function() {
            const date = moment(this.leaderDateOfBirth);
            if (moment.isMoment(date)) {
                this.years = moment()
                    .diff(date, 'years');
                this._testYears();
            }
        }, 300),

        _testYears() {
            if (this.isChild && typeof this.leader.children_program === 'undefined') {
                const leader = {
                    ...this.leader,
                    children_program: true
                };
                this.setLeader({ leader });
            }
        },

        _formChanged() {
            this._saveForm();
            /**
             * Костыль: пока регистрацию отправляем, не обновляем
             * данные о лидере в стейте из формы регистрации.
             * Из-за debounce обновление из формы прилетает позже,
             * чем обновление от сервера и затирает серверные данные.
             */
            if (this.isFormDirty && !this.isRegistrationInProcess) {
                this.setLeader({ leader: this.form, from: 'LeaderForm' });
            }
            this.isFormDirty = true;
        },

        _saveForm: _.debounce(function() {
            if (!this.isFormDirty) {
                return;
            }
            const formDataToStore = _.omit(this.form, [
                'id',
                '__previous_participant_id',
                '__time',
                'user_id'
            ]);
            this.$storage.put('Cabinet.RegisterForm.' + this.formStorageKey, formDataToStore);
        }, 500),

        _restoreForm() {
            const { data } = this.$storage.get('Cabinet.RegisterForm.' + this.formStorageKey);
            if (data) {
                try {
                    data.date_of_birth = new Date(data.date_of_birth);
                } catch (e) {
                    data.date_of_birth = null;
                }
                this.form = data;
                if (data.phone) {
                    this.$emit('phoneChanged', {
                        phone: this.form.phone,
                        isValid: true,
                        country: null
                    });
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.participant-form {
  margin: 20px auto;

  &__container {
    max-width: 585px;
    margin: 0 auto;
  }

  .el-checkbox {
    vertical-align: top;
  }

  .el-checkbox__input {
    vertical-align: top;
    margin-top: 2px;
  }

  .el-checkbox__label {
    vertical-align: top;
    white-space: normal !important;
  }
}
</style>
