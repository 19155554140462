<template>
    <div class="app-lk-participants-table">
        <table
            v-if="showTable"
            v-loading="loading"
            class="app-lk-participants-table__table table"
        >
            <tr
                v-for="(participant) in participants"
                :key="participant.attributes.id"
            >
                <td>
                    <div>
                        {{ participant.attributes.surname }}
                        {{ participant.attributes.name }}
                        {{ participant.attributes.patronymic }}
                    </div>
                </td>
                <td>
                    <span v-if="participant.attributes.date_of_birth">
                        {{ participant.attributes.date_of_birth | moment('DD MMM YYYY') }}
                    </span>
                    <span
                        v-else
                        class="table__text-error"
                    >
                        Не указана
                    </span>
                </td>
                <td>
                    <div>{{ participant.attributes.prepay }} р</div>
                </td>
                <td
                    class="app-lk-participants-table__controls-row"
                    style="width: 50px;"
                >
                    <div v-if="participant.attributes.editable">
                        <img
                            class="app-lk-participants-table__edit-icon"
                            src="@/assets/img/editable_icon.svg"
                            @click="_onEditClick(participant.id)"
                        >
                    </div>
                    <div v-else>
                        -
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: 'ParticipantsTable',
    props: {
        participants: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        showTable() {
            return this.participants.length > 0;
        }
    },
    methods: {
        _onEditClick(id) {
            this.$emit('edit', id);
        }
    }
};
</script>

<style lang="scss">
.app-lk-participants-table {
    &__table {
        padding: 0 30px;
        @media (max-width: 450px) {
            padding: 0 0px;
            font-size: 12px;
            td {
                padding: 10px 0;
            }
        }

        tr {
            text-align: center;
        }

        .table__text-error {
            font-weight: bold;
            color: #f00;
        }
    }

    &__controls-row {
        max-width: 50px;
    }

    &__edit-icon {
        cursor: pointer;
    }
}
</style>
