<template>
    <div class="cabinet-profile-view">
        <h1 class="app-h1">
            Евстафьев Алистрат Мергенович
        </h1>

        <el-card>
            <div
                slot="header"
                class="clearfix"
            >
                <h2 class="app-h2">
                    Общие данные
                </h2>
            </div>

            <p>
                Вы зарегистрировалось на весенний фестиваль Благость и внесли предоплату. В таблице ниже показана вся
                ваша группа, размер скидки и сколько нужно будет доплатить когда приедете на фестиваль.
            </p>

            <el-table
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="name"
                    label="Фамилия, имя, отчество"
                />

                <el-table-column
                    prop="birthday"
                    label="Дата рождения"
                />

                <el-table-column
                    prop="phone"
                    label="Номер телефона"
                />

                <el-table-column
                    prop="prepaid"
                    label="Предоплата"
                />

                <el-table-column
                    prop="discount"
                    label="Скидка"
                />

                <el-table-column
                    prop="should_pay"
                    label="Доплата"
                />
            </el-table>

            <div style="text-align: right;">
                <p>
                    Предоплата: 5000 ₽
                </p>

                <p>
                    Общая скидка: 5000 ₽
                </p>

                <p>
                    Итого доплатить: 143 000 ₽
                </p>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'CabinetProfileView',

    components: {},

    data() {
        return {};
    },

    computed: {
        tableData() {
            return [
                {
                    name: 'Евстафьев Алистрат Мергенович',
                    birthday: '5 декабря 1982 г',
                    phone: '+7 999 999-99-99',
                    prepaid: 'Внесена',
                    discount: '2000 ₽',
                    should_pay: '36 000 ₽'
                },
                {
                    name: 'Евстафьев Алистрат Мергенович',
                    birthday: '5 декабря 1982 г',
                    phone: '+7 999 999-99-99',
                    prepaid: 'Внесена',
                    discount: '2000 ₽',
                    should_pay: '36 000 ₽'
                },
                {
                    name: 'Евстафьев Алистрат Мергенович',
                    birthday: '5 декабря 1982 г',
                    phone: '+7 999 999-99-99',
                    prepaid: 'Внесена',
                    discount: '2000 ₽',
                    should_pay: '36 000 ₽'
                },
                {
                    name: 'Евстафьев Алистрат Мергенович',
                    birthday: '5 декабря 1982 г',
                    phone: '+7 999 999-99-99',
                    prepaid: 'Внесена',
                    discount: '2000 ₽',
                    should_pay: '36 000 ₽'
                }
            ];
        }
    }
};
</script>

<style lang="scss">
    @import "@vars";

    .cabinet-profile-view {
    }
</style>
